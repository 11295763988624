import React,{useState, useEffect} from 'react'
import { Topbar } from './Topbar'
import { Avatar, Box, Button, Chip, CircularProgress, Divider, Grid, TextField, Typography , Tooltip} from '@mui/material'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PropTypes from 'prop-types';
import collegeimage from '../../assets/img/college.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Highschoolimage from '../../assets/img/highschool.png';
import honoursimage from '../../assets/img/honorsnew.svg';
import testingimage from '../../assets/img/testing.png';
import sportimage from '../../assets/img/sports.png';
import viewsImage from '../../assets/img/InsightsIcon1.png'
import messagesImage from '../../assets/img/Insightsicon2.png'
import searchImage from '../../assets/img/insightsIcon3.png'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import InsightsIcon from '@mui/icons-material/Insights';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SportsIcon from '../../assets/img/Admin_sports.png'
import Experiance_icon from '../../assets/img/experiance_icon.jpeg'
import CollegeIcon from '../../assets/img/Admin_College.png'
import Highschool_Icon from '../../assets/img/Admin_highschool.png'
import Honors_icon from '../../assets/img/Admin_honors.png'
import bgimagecover from '../../assets/img/bgcover.svg';
import { Link,useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json'
import animationData2 from '../../assets/delete.json'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function CircularProgressWithLabel(props) { 
  
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex',}}>
      <CircularProgress variant="determinate" {...props}   size={120} // Adjust the size as needed
      thickness={5}  />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
export const Admin_Collaborator_profileview = () => {



 
 

  // useEffect(() => {
  //   if (progress === 100) {
  //     setIsButtonDisabled(false); 
  //   } else {
  //     setIsButtonDisabled(true); 
  //   }
  // }, [progress]);
  const [collaboratorlaravelUrlfinal, setcollaboratorlaravelUrlfinal] = useState(null);
  const [collaboratorbackgroundurlimagefinal, setcollaboratorbackgroundurlimagefinal] = useState(null);
  const [collaborators, setCollaborators] = useState([]);
  const [storedAccessToken, setAccessToken] = useState('');
  const [collegeId, setCollegeId] = useState('');
  const [Collegesdata, setCollegesdata] = useState([]);
  const [HighSchooldata, setHighSchooldata] = useState([]);
  const [Honoursdata, setHonoursdata] = useState([]); 
  const [testingdata,setTestingdata] = useState([]);
  const [Activitiesdata,setActivitiesdata] = useState([]);
  const [Experiencedata,setExperiencedata] = useState([]);
  const [Summarydata, setSummarydata]  = useState([]);
 const[userInfo, setUserImfo]= useState([])
 const[analytics, setAnalytics]= useState([])
 const [ collaboratorID, setCollaboratorID]=useState(null);
 const [open, setOpen] = useState(false);
 const [deleteopen, setdeleteOpen] = useState(false);
 const [message, setMessage] = useState('');
 const [isLoading, setIsLoading] = React.useState(false);
 const [isLoading2, setIsLoading2] = React.useState(false);


    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const storedAccessToken = localStorage.getItem('accessToken');
  
        if (!storedAccessToken) {
          console.error('Access token not found');
          window.location.href = '/admin-login';
          return;
        }
  
        setAccessToken(storedAccessToken);
  
        // Extract id from the URL query string
        const params = new URLSearchParams(window.location.search);
        const encodedId = params.get('id');
  
        if (!encodedId) {
          console.error('ID not found in the URL');
          return;
        }
  
        const decodedId = atob(encodedId); // Decode the id if it's encoded
  
        const response = await fetch(`${apiUrl}/admin/collaborator/details/${decodedId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedAccessToken}`,
          },
        });
  
        console.log('API Response:', response);
  
        if (response.status === 200) {
          try {
            const data = await response.json();
            console.log('Fetched Data:', data);
  
            const collegesdata = data.user.colaborator_colleges_data;
            // console.log(collegesdata,"This is college data");
            const highschooldata = data.user.colaborator_high_school;
            // console.log(highschooldata,"This is college data");
            const userId = highschooldata.userId;
            setCollaboratorID(decodedId)
            console.log(collaboratorID,"decodedId")
            const hounoursdata = data.user.colaborator_awards;
            // console.log(hounoursdata,"This is Hnors data");
            const testingdata = data.user.colaborator_testing;
            // console.log(testingdata,"This is testing data");
            const activitiesdata = data.user.colaborator_experience;
            // console.log(activitiesdata,"This is activitiesdata data");
const collaboratorExperience= data.user.colaborator_workexperience;
// console.log(collaboratorExperience,"This is collaboratorExperience data");

            const personalessay = data.user.colaborator_summary            ;
console.log(personalessay,"This is personalessay data");
         
  const userInfo=data.user.user_personal_data
  const analytics=data.analytics
  console.log(analytics,"This is analytics")
  const profile1url = userInfo.avatarImage;
  const laravelUrl = profile1url.replace('/public/', '/storage/app/public/');
  const laravelUrlfinal = laravelUrl.replace('/public/storage/', '/public/');
  setcollaboratorlaravelUrlfinal(laravelUrlfinal);
  const backgroundurl = userInfo.background_image;
               
  let backgroundurlimagefinal;

  if (backgroundurl) {
    const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/');
    backgroundurlimagefinal = backgroundurlimage.replace('/public/storage/', '/public/');
    setcollaboratorbackgroundurlimagefinal(backgroundurlimagefinal);
  } else {
    backgroundurlimagefinal = '';
  }
  console.log(laravelUrlfinal,"user image"); 
            setCollegesdata(collegesdata);
            setHighSchooldata(highschooldata);
            setHonoursdata(hounoursdata);
            setTestingdata(testingdata);
            setActivitiesdata(activitiesdata);
            setSummarydata(personalessay);
            setExperiencedata(collaboratorExperience);
            setUserImfo(userInfo);
            setAnalytics(analytics);
          } catch (jsonError) {
            console.error('Error parsing JSON:', jsonError);
          }
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('Data fetch failed:', error);
      }
    };
  
 

  useEffect(() => {
    fetchData();
  }, [collegeId]);
  const calculateProgress = () => {
    let progress = 10;
    if (Collegesdata.length > 0) progress += 40;
    if (HighSchooldata.length > 0) progress += 40;
    if (Honoursdata.length > 0) progress += 0;
    if (testingdata.length > 0) progress += 0;
    if (Activitiesdata.length > 0) progress += 10;
    if (Experiencedata.length > 0) progress += 0;
    if (Summarydata.length > 0) progress += 0;
    return progress;
  };



    const progress = calculateProgress();


  const handleRejectProfile = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const storedAccessToken = localStorage.getItem('accessToken');

      if (!storedAccessToken) {
        console.error('Access token not found');
        window.location.href = '/admin-login';
        return;
      }

      // Extract id from the URL query string
      const params = new URLSearchParams(window.location.search);
      const encodedId = params.get('id');

      if (!encodedId) {
        console.error('ID not found in the URL');
        return;
      }

      const decodedId = atob(encodedId); // Decode the id if it's encoded

      const requestBody = {
        reasons: message // Include the message in the request body
      };

      const response = await fetch(`${apiUrl}/admin/reject/collaborator/${decodedId}?reasons=${encodeURIComponent(message)}`, {
        method: 'GET', // Change method to 'POST'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
      });
      

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const responseData = await response.json(); // Extract response data

      // Handle response data as needed
      setdeleteOpen(false);
      setIsLoading2(false);
      console.log('Profile rejected successfully:', responseData);
      console.log('Message:', message);

      // Optionally update state or trigger any other actions based on the response
      fetchData()
    } catch (error) {
      console.error('Error rejecting profile:', error);
      // Handle errors if needed
    }
  };

  
  // useEffect(() => {
  //   handleRejectProfile(); // Call handleRejectProfile function
  // }, []);

    const handleClick = () => {
      // handleRejectProfile();
     
      setdeleteOpen(true);
    };
  
    const handleAcceptProfile = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const storedAccessToken = localStorage.getItem('accessToken');
  
        if (!storedAccessToken) {
          console.error('Access token not found');
          window.location.href = '/admin-login';
          return;
        }
  
        setAccessToken(storedAccessToken);
  
        // Extract id from the URL query string
        const params = new URLSearchParams(window.location.search);
        const encodedId = params.get('id');
  
        if (!encodedId) {
          console.error('ID not found in the URL');
          return;
        }
  
        const decodedId = atob(encodedId); // Decode the id if it's encoded
    
        const response = await fetch(`${apiUrl}/admin/eccept/collaborator/${decodedId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedAccessToken}`,
          },
        });
    
        if (!response.ok) {
          throw new Error('Request failed');
        }
    
        // Handle success if needed
        setOpen(true);
        setIsLoading(false);
        fetchData()
        console.log('Profile Accepted successfully');
      } catch (error) {
        console.error('Error rejecting profile:', error);
        setOpen(false);
        // Handle errors if needed
      }
    };
    // useEffect(() => {
      
    //   handleAcceptProfile();
    // }, []); 

    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: animationData2, // The Lottie JSON data
    };
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData, // The Lottie JSON data
    };
    useEffect(() => {
      if (open) {
        const timer = setTimeout(() => {
          setOpen(false); // Close the dialog after 2 seconds
        }, 2000);
  
        // Clear the timer when the component unmounts or when open state changes
        return () => clearTimeout(timer);
      }
    }, [open]);
    // useEffect(() => {
    //   if (deleteopen) {
    //     const timer = setTimeout(() => {
    //       setdeleteOpen(false); // Close the dialog after 2 seconds
    //     }, 2000);
  
       
    //     return () => clearTimeout(timer);
    //   }
    // }, [deleteopen]);
  
    const handleClick2 = () => {
      setIsLoading(true);
      handleAcceptProfile();
      // setOpen(true);
     
    };
    const [selectedCollegeId, setSelectedCollegeId] = useState('');
    const [selectedHighschoolId, setSelectedHighschoolId] = useState('');
    const [selectedPersonalEssayId, setSelectedPersonalEssayId] = useState('');
    const [selectedTestingId, setSelectedTestingId] = useState('');
    const [selectedHonoursId, setSelectedHonoursId] = useState('');
    const [selectedActivityId, setSelectedActivityId] = useState('');
    const [selectedEperienceId, setSelectedEperienceId] = useState('');
    const handleEditClickActivities = (id) => {
      setSelectedActivityId(id); // Set the selected college id in the state
      console.log(setSelectedActivityId,"selectedTestingId")
    };
    const handleEditClickTesting = (id) => {
      ;
      setSelectedTestingId(id); // Set the selected college id in the state
      console.log(selectedTestingId,"selectedTestingId")
    };
    const handleEditClickExperience = (id) => {
      setSelectedEperienceId(id); // Set the selected college id in the state
      
    };
    const handleEditClickHonours = (id) => {
      setSelectedHonoursId(id); // Set the selected college id in the state
      console.log(setSelectedHonoursId,"selectedTestingId")
    };
    const handleEditClickHighschool = (id) => {
      setSelectedHighschoolId(id); // Set the selected college id in the state
      
    };
    const handleEditClickSummary = (id) => {
      setSelectedPersonalEssayId(id); // Set the selected college id in the state
      
    };
    // Function to handle clicking the edit icon
    const handleEditClick = (id) => {
      setSelectedCollegeId(id); // Set the selected college id in the state
      
    };
    const navigate = useNavigate();
    useEffect(() => {
    
      if(selectedCollegeId){
    
      
        navigate(`/College_detail?id=${selectedCollegeId}&userid=${collaboratorID}&type=admin`);
      
   
      }
      else if(selectedHighschoolId){
      
          navigate(`/Highschool_detail?id=${selectedHighschoolId}&userid=${collaboratorID}&type=admin`);
        
      
      }
      else if(selectedPersonalEssayId){
        navigate(`/Summary_detail?id=${selectedPersonalEssayId}&userid=${collaboratorID}&type=admin`);
      }
      else if(selectedHonoursId){
        navigate(`/Honours_detail?id=${selectedHonoursId}&userid=${collaboratorID}&type=admin`);
      }
      else if(selectedTestingId){
        navigate(`/Testing_detail?id=${selectedTestingId}&userid=${collaboratorID}&type=admin`);
      }
      else if(selectedActivityId){
        navigate(`/Activities_detail?id=${selectedActivityId}&userid=${collaboratorID}&type=admin`);
      }
      else if(selectedEperienceId){
        navigate(`/Experience_detail?id=${selectedEperienceId}&userid=${collaboratorID}&type=admin`);

      }
    }, [selectedCollegeId,selectedHighschoolId,selectedPersonalEssayId,selectedTestingId,selectedHonoursId,selectedActivityId,selectedEperienceId,collaboratorID]);
    const states = [
      { code: "AL", name: "Alabama" },
      { code: "AK", name: "Alaska" },
      { code: "AZ", name: "Arizona" },
      { code: "AR", name: "Arkansas" },
      { code: "CA", name: "California" },
      { code: "CO", name: "Colorado" },
      { code: "CT", name: "Connecticut" },
      { code: "DE", name: "Delaware" },
      { code: "FL", name: "Florida" },
      { code: "GA", name: "Georgia" },
      { code: "HI", name: "Hawaii" },
      { code: "ID", name: "Idaho" },
      { code: "IL", name: "Illinois" },
      { code: "IN", name: "Indiana" },
      { code: "IA", name: "Iowa" },
      { code: "KS", name: "Kansas" },
      { code: "KY", name: "Kentucky" },
      { code: "LA", name: "Louisiana" },
      { code: "ME", name: "Maine" },
      { code: "MD", name: "Maryland" },
      { code: "MA", name: "Massachusetts" },
      { code: "MI", name: "Michigan" },
      { code: "MN", name: "Minnesota" },
      { code: "MS", name: "Mississippi" },
      { code: "MO", name: "Missouri" },
      { code: "MT", name: "Montana" },
      { code: "NE", name: "Nebraska" },
      { code: "NV", name: "Nevada" },
      { code: "NH", name: "New Hampshire" },
      { code: "NJ", name: "New Jersey" },
      { code: "NM", name: "New Mexico" },
      { code: "NY", name: "New York" },
      { code: "NC", name: "North Carolina" },
      { code: "ND", name: "North Dakota" },
      { code: "OH", name: "Ohio" },
      { code: "OK", name: "Oklahoma" },
      { code: "OR", name: "Oregon" },
      { code: "PA", name: "Pennsylvania" },
      { code: "RI", name: "Rhode Island" },
      { code: "SC", name: "South Carolina" },
      { code: "SD", name: "South Dakota" },
      { code: "TN", name: "Tennessee" },
      { code: "TX", name: "Texas" },
      { code: "UT", name: "Utah" },
      { code: "VT", name: "Vermont" },
      { code: "VA", name: "Virginia" },
      { code: "WA", name: "Washington" },
      { code: "WV", name: "West Virginia" },
      { code: "WI", name: "Wisconsin" },
      { code: "WY", name: "Wyoming" },
      { code: "PR", name: "Puerto Rico" },
      { code: "GU", name: "Guam" },
      { code: "VI", name: "U.S. Virgin Islands" },
      { code: "AS", name: "American Samoa" },
      { code: "MP", name: "Northern Mariana Islands" },
      { code: "INTL", name: "International" }
    ];

    const getStateFullName = (stateCode) => {
      const state = states.find((s) => s.code === stateCode);
      return state ? state.name : '';
    };
  


    const handleCloseDialog = () => {
      setdeleteOpen(false); // Close the dialog
    };
    const handleMessageChange = (e) => {
      const value = e.target.value;
      setMessage(value);
      console.log(value, "this is message");
    };






  
  return (
    <div><Topbar/>
    <Grid
    container
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    className='admin_collaborator_main_Grid' 
   
    >
        <Dialog
          open={deleteopen}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content" sx={{display:'flex',alignItems:'flex-end',gap:'15px'}}>
          <Lottie options={defaultOptions2} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            {/* <typography className="DeleteText">Delete</typography>
          
            <typography className='Submitted_text2'> Your data has been successfully saved!</typography> */}
             <TextField  label="Write a message"  
              //  value={searchTerm}
                // onChange={(e) => setSearchTerm(e.target.value)}
                value={message}
        onChange={handleMessageChange}
                   id="search" size='small' fullWidth className='Admin_textfield1' multiline rows={4} sx={{width:'400px'}}/>
          </div>
            <div style={{display:'flex',gap:'5px',alignItems:'flex-end',justifyContent:'flex-end',alignContent:'flex-end'}}>
            <Button onClick={handleCloseDialog} variant='outlined' color="error" >Cancel</Button>
         <Button  onClick={() => {
    const message = document.getElementById('search').value; // Get the value from the text field
    handleRejectProfile(message);
    setIsLoading2(true);
  
}} color="error"  variant='contained'>{isLoading2 ? (
  // Display the circular loading icon
  <CircularProgress size={24} color="inherit" />
) : (
  // Display the "SUBMIT" text
  "REJECT"
)}</Button>
         </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
        <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Profile have been approved successfully</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
      <Grid container

lg={8.8}
xl={9.7}
xs={12}
sm={12}

md={8.7} className='admin_collaborator_main_Grid2' 
 >
<Grid item
        xs={12}
        sm={12}
        lg={12}
        md={12}
        xl={12}
        className='admin_collaborator_profile_main_Grid' 
        >
          {userInfo && (
<Box className='admin_collaborator_profile_main_Grid_Box'>

  <Box sx={{backgroundImage: collaboratorbackgroundurlimagefinal ? `url(${collaboratorbackgroundurlimagefinal})` : `url(${bgimagecover})`,}} className='admin_collaborator_profile_main_Grid_Background_Box'>

  </Box>
  <Box className='admin_collaborator_profile_information_Grid_Background_Box'>
    
    <Box className='admin_collaborator_profile_information'>
    <div>
    <Avatar  src={collaboratorlaravelUrlfinal} className='avatar_Box'   sx={{
                border: '2px solid white',
                width: '80px',
                height: '80px',
                transform: "translate(-50%, -100%) !important",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
              }}>

    </Avatar>
    </div>
 
  <typography   className='user_name_Text'>
  {userInfo.legal_name}
</typography >

   
    <typography className='user_description_Text'>
    {userInfo.Headline || 'Helping Students apply to college the smart way'}
    </typography>
 
    <Box className='admin_collaborator_profile_information_Box'>
  {Collegesdata.some(college => college.decisionOptions === "Attended") && (
    <>
      {Collegesdata
        .filter(college => college.decisionOptions === "Attended")
        .map((college, index) => {
          const collegeNamecollaborator = JSON.parse(college.collegeName);
          const Collegenameadded = collegeNamecollaborator ? collegeNamecollaborator.title : "";

          return (
            <React.Fragment key={index}>
        <typography className='college_name_Text'>
          <SchoolOutlinedIcon sx={{ height: "15px", width: "15px", marginRight: '10px' }} />
          {Collegenameadded}
        </typography>
        {index < Collegesdata.length - 1 && <Divider variant="inset" orientation="vertical" flexItem className='future-Divider' sx={{ mx: 3 }} />}
      </React.Fragment>
          );
        })}
    </>
  )}
  <Divider variant="inset" orientation="vertical" flexItem className='future-Divider' sx={{ mx: 3 }} />
  <typography className='college_name_Text'>
    <CalendarMonthIcon sx={{ height: "15px", width: "15px", marginRight: '10px' }} /> 
    {/* {new Date(userInfo.created_at).toLocaleDateString()} */} 
    Joined {new Date(userInfo.created_at).toLocaleString('en-us', { month: 'short', year: 'numeric' })}
  </typography>
</Box>


    
    
    <Box className='student_button_Admin'>
    <Button variant='contained' sx={{boxShadow:"none"}}>VIEW AS STUDENT</Button>
    {/* <SystemUpdateAltIcon sx={{height:"35px",width:"35px",color:'#006CB7'}}/> */}
   
    </Box>
    <Tooltip title={userInfo.reasons} arrow>
   <Chip
       size="small"
       variant="filled"
       label="* Last Comment"
       color='primary'
       sx={{ marginBottom:'20px'}}
   />
   </Tooltip>
   
    </Box>


  </Box>

</Box>
)}
</Grid>

    
      <Grid item
        xs={12}
        sm={12} 
        lg={12}
        xl={12}
        className="admin_college_Grid">

<Box
    className="admin_college_Grid_Box">

   

  <typography className="college_Main_Text" >
  Colleges Applied to
</typography>

{Collegesdata &&  Collegesdata.map((college, index) => {
        const collegeNameData = JSON.parse(college.collegeName);
        const MajorNameData = JSON.parse(college.major);
        return (
<>
      <div key={index}  className='admin_college_Grid_Div'>

    
      <div  className="admin_college_Grid_Div2">
        <div className="admin_college_Grid_Div3">
          <div>
            <Avatar className='admin_college-Avatar' src={CollegeIcon}></Avatar>
          </div>
        
          <div  className="Admin_DivCustom">
            <div
            className="Admin_DivCustom3"
             
            >
              <div className="Admin_DivCustom4">
              <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClick(college.id);
  }}
>
              <typography className="Admin_TypographyCustom">{collegeNameData.title}</typography>
             
</Link>
              </div>
              
              <typography className="Admin_TypographyCustom2">{getStateFullName(college.highSchoolLocation)}</typography>

              {/* <typography  style={{color:'green',gap:'5px'}}  >
     <CalendarTodayIcon sx={{height:"15px",width:"15px"}}/><typography style={{marginRight:'15px'}}> Taken In: 2022-2024
     </typography>
     {MajorNameData.title}</typography> */}
     {MajorNameData.map((major, majorIndex) => (
                                        <typography className="Admin_Major_text" key={majorIndex} style={{
                                          color: college.decisionOptions === "Accepted"
                                            ? "#2E7D32"
                                            : college.decisionOptions === "Rejected"
                                            ? "#D32F2F"
                                            : college.decisionOptions === "Attended"
                                            ? "#0000FF"
                                            : college.decisionOptions === "Waitlisted"
                                            ? "#EF6C00"
                                            : college.decisionOptions === "Deferred"
                                            ? "#9C27B0"
                                            : "default"
                                        }}>{major.title}</typography>
                                    ))} 
            </div>

            <typography className="Admin_TypographyCustom2">Supplementary Essay</typography>
            <div className="Admin_Supplementary_essay">
            <typography className="Admin_Supplementary_essay">
            {college.supplementary_eassy.length > 100
                ? `${college.supplementary_eassy.substring(0, 400)}...`
                : college.supplementary_eassy}
            </typography>
            </div>
          </div>

        </div>
       
      </div>
      <div className="Admin_DivCustom5" style={{ display: "flex", alignItems: "center" }}>
  <Chip
    
    sx={{ padding: "16px 8px",marginLeft:'10px' }}
    label={college.decisionOptions}
    color={
      college.decisionOptions === "Accepted" ? "success" :
      college.decisionOptions === "Attended" ? "primary" :
      college.decisionOptions === "Rejected" ? "error" :
      college.decisionOptions === "Waitlisted" ? "warning" :
      college.decisionOptions === "Deferred" ? "secondary" : "default"
    }
    size="small"
    variant="filled"
  />
 
</div>
</div>
{index < Collegesdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
</>
);
})}   
     
  


  </Box>

      </Grid>
      <Grid item
     xs={12}
     sm={12}
     lg={12}
     xl={12}
   className="admin_highschool_Grid">
    <Box className="Admin_BoxCustom6">

   
  <Box className="Admin_BoxCustom7" >
    <typography className="college_Main_Text">High School Education</typography>
   
  </Box>

  {HighSchooldata &&  HighSchooldata.map((highschool, index) => {
       
        return (

          <>
      <div key={index}className='Admin_DivCustom_later2'  style={{width:"100%"}}>
        <div className="Admin_DivCustom6">

        
         <Avatar  src={Highschool_Icon}>

</Avatar>
<div  className="Admin_DivCustom7" style={{width:"100%"}}>

<div className="Admin_DivCustom8" style={{width:"100%"}}>
<Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHighschool(highschool.id);
  }}
>
        <typography className="Admin_TypographyCustom" > {highschool.hight_school_name }</typography>
        </Link>
</div>
{/* <typography className="Admin_TypographyCustom2">Degree:{highschool.degree_high || "NO degree"} </typography> */}
        <div className="Admin_DivCustom9"><CalendarTodayIcon className='CalenderCustom' sx={{width:"15px",height:"15px",color:"#58595B"}}/>
        <typography className="Admin_date_highschool"> {new Date(highschool.start_date).toLocaleString('en-us', { year: 'numeric' })}</typography>
        <typography className="Admin_date_highschool">- {new Date(highschool.end_date).toLocaleString('en-us', { year: 'numeric' })}</typography>
       
        </div>
     
        </div>
        </div>
        </div>
        {index < HighSchooldata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
        </>
       );
      })} 
   



  </Box>
   </Grid>
   <Grid item
     xs={12}
     sm={12}
     lg={12}
     xl={12}
      className="admin_honors_Grid">
      <Box className="Admin_BoxCustom9">
        <Box className="Admin_BoxCustom10">
          <typography  className="college_Main_Text">
            Honors
          </typography>
        </Box>
        {Honoursdata &&  Honoursdata.map((Honors, index) => {
        const awardsArray = JSON.parse(Honors.awards);
       return (
<>
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="Admin_DivCustom10">
            <Avatar  src={Honors_icon} />
            <div className='Admin_honors_sub_Div'>
             
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHonours(Honors.id);
  }}
>
                  <typography variant="body1" className="Admin_TypographyCustom8">
                  {awardsArray[0]?.['award-title-0']}
                  </typography></Link>
              
           
             
     
              {/* <typography className="Admin_TypographyCustom2">
              {awardsArray[0]?.['issuing-organization-0']}
              </typography> */}
              <div className="Admin_DivCustom9">
                {/* <CalendarTodayIcon className='CalenderCustom' sx={{width:"15px",height:"15px",color:"#58595B"}}/> */}
        {/* <typography className="Admin_date_highschool">Issued {new Date(awardsArray[0]?.['start-date-0']).toLocaleString('en-us', { month: 'short', year: 'numeric' })}</typography> */}
        {/* <typography className="Admin_date_highschool">End Date</typography> */}
       
        </div>
        <Chip
  label={awardsArray[0]?.['level-0']}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px",marginTop:'15px', padding: "16px 8px"}}
/>
    <typography className="Admin_Supplementary_essay">
    {awardsArray[0]?.['award-description-0']}
            </typography>
            </div>
          </div>
        </div>
          {index < Honoursdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
          </>
          );})} 
        <Divider className="Admin_DividerCustom3" variant="inset" />
        {/* <Box className='admin_future_Plans_Box'>
          <Box><typography className="Admin_TypographyCustom" >Future Plans</typography></Box>
        <Box className='Honors_Future'>
        {Honoursdata &&  Honoursdata.map((Honors, index) => {
          return(
<>
            <div key={index} style={{display:'flex'}}>
          <Box className='Honors_sub_Future'>
            <typography className="Admin_TypographyCustom2">Highest degree intend to earn</typography>
            <typography className="college_Main_Text">{Honors.highest_degree_you_earn}</typography>
          </Box>
          <Divider variant="inset" orientation="vertical" flexItem  className='future-Divider' sx={{ mx: 5 }}/>
          <Box className='Honors_sub_Future'>
            <typography className="Admin_TypographyCustom2">Career Interest</typography>
            <typography className="college_Main_Text">{Honors.career_intrest}</typography>
          </Box>
          </div>
           {index < Honoursdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
           </>
            )
        })}
          </Box>
        </Box> */}
      </Box>
    </Grid>
    <Grid className="admin_testing_Grid" item
     xs={12}
     sm={12}
     lg={12}
     xl={12}>
      <Box className="BoxCustom12">
        <Box className="BoxCustom13">
          <typography  className="college_Main_Text">
            Testing
          </typography>
        
        </Box>
        <div  style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}>
        {testingdata &&  testingdata.map((testing, index) => {
          const testDataArray = JSON.parse(testing.testdata);

          // Extracting the "testname" property from the first element
          const testname = testDataArray[0]?.['testname-0'];
          const attempts = testDataArray[0]?.['attempts-0'];
          const score = testDataArray[0]?.['score-0'];

          // const createdat = testDataArray[0]?.['created_at'];
       
       return (

        
          <Box key={index} className="Admin_DivCustom100">
            {/* <Avatar className="Colleges_Avatar_img" src={testingimage} /> */}
            
              <div>
            <Avatar className='Colleges_Avatar_img' src={testingimage}>

</Avatar>
</div>
<div className="DivCustom17">
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickTesting(testing.id);
  }}
  
>
              <typography className="college_Main_Text">
              {testname}
              </typography></Link>
              <typography className="Admin_TypographyCustom2">
                Attempts: {attempts}
              </typography>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <CalendarTodayIcon sx={{ width: '15px', height: '15px', color: '#58595B' }} />
                <typography className="Admin_date_highschool">{new Date(testing.created_at).toLocaleDateString()}</typography>
              </Box>
              <Chip
  label={`Highest Score: ${score}`}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px",padding: "16px 8px"}}
/>
            </div>
            <div>
          
          </div>
        
          {index % 2 === 0 && index < testingdata.length && (
 <Divider orientation="vertical" className="Admin_DividerCustom4" />
)}
          </Box>
           );})} 
           </div>
          {/* <div className="DivCustom18">
            <Divider orientation="vertical" className="DividerCustom4" />
            <div className="DivCustom19">
              <Typography variant="body1" className="Testing_Obtained_text">
                Highest Score
              </Typography>
              <Typography variant="body1">
                <span className="SpanCustom">sdf</span>
              </Typography>
            </div>
          </div> */}
   
      </Box>
    </Grid>
    <Grid item
     xs={12}
     sm={12}
     lg={12}
     xl={12}
   className="admin_sports_Grid">
    <Box className="Admin_BoxCustom15" >

   
  <Box  className="Admin_BoxCustom16">
    <typography className="college_Main_Text">Activities</typography>
   
  </Box>
  {Activitiesdata &&  Activitiesdata.map((sports, index) => {
          const sportsDataArray = JSON.parse(sports.sports_activities);
          const sportName = sportsDataArray[0]?.['high-school-name-0'];
          const Joinedas = sportsDataArray[0]?.['participated-as0'];
          const level = sportsDataArray[0]?.['high-activities-select-0'];
          const description = sportsDataArray[0]?.['description-0'];

          return (
            <>
  <div key={index} className="Admin_DivCustom_later3">

          <div className="Admin_DivCustom10">
          <div>
            <Avatar  src={SportsIcon} />
            </div>
            <div className="Admin_DivCustom17">
              
            <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickActivities(sports.id);
  }}
>
              <typography className="college_Main_Text">
              {sportName}
              </typography></Link>
              <typography className="Admin_TypographyCustom2">
                {Joinedas}
              </typography>
              {/* <Box>
                <CalendarTodayIcon  sx={{ width: '15px', height: '15px', color: '#58595B' }} />
                <typography className="Admin_date_highschool">Date</typography>
              </Box> */}
              <Chip
  label={level}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px",padding: "16px 8px"}}
/>
<typography className="Admin_Supplementary_essay">
              {description}
            </typography>
</div>
          {/* <Divider orientation="vertical" className="Admin_DividerCustom4" /> */}
            </div>
          </div>
          
        </div>
        {index < Activitiesdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
        </>
        );})} 
         
  



  </Box>

   </Grid>
   <Grid item
   xs={12}
   sm={12}
   lg={12}
   xl={12}
   className="admin_wokexperience_Grid">
    <Box className="Admin_BoxCustom15" >

   
  <Box  className="Admin_BoxCustom16">
    <typography className="college_Main_Text">Work Experience</typography>
  
  </Box>
  {/* <div className='Collaborator_completeprofile_activities' style={{display:"flex",justifyContent:"space-between"}}> */}
  {Experiencedata && Experiencedata.map((experience, index) => {
     const workDataArray = JSON.parse(experience.work_experience);
     const jobtitle = workDataArray[0]?.['Title-0'];
     const organization = workDataArray[0]?.['organization-name-0'];
     const jobType = workDataArray[0]?.['job-type-0'];
     const startDate = workDataArray[0]?.['start-date-0'];
     const endDate = workDataArray[0]?.['end-date-0'];
     const description = workDataArray[0]?.['job-description-0'];
     const option = workDataArray[0]?.['options-0'];

     return (
      <>
      <div key={index}   style={{display:"flex",flexDirection:"row",gap:'15px'}}>
   
   <div>
            <Avatar  src={Experiance_icon} />
            </div>

        
     
<div style={{display:"flex",flexDirection:"column",gap:10}}>

<Link
 style={{ textDecoration: 'none' }}
to="#"

onClick={(e) => {
e.preventDefault();
handleEditClickExperience(experience.id);
}}
>
            <typography  className="college_Main_Text"> {jobtitle}</typography></Link>
            <typography style={{ fontWeight: '600', fontSize: '16px' }}>{organization}-{jobType}</typography>
            
            <Box>
           <CalendarTodayIcon sx={{height:"15px",width:"15px",color:"#2E7D32"}}/> <typography className="workDate">
      
           {new Date(startDate).toLocaleString('en-us', { month: 'short', year: 'numeric' })} -
           {option!== 'yes' ? (
            <>
        {new Date(endDate).toLocaleString('en-us', { month: 'short', year: 'numeric' })}
        </>
        ) : (
          'Present' // or any text you want to display when `item[`options-${i}`]` is 'no'
        )}
        </typography>
            </Box>
  <typography className="Admin_TypographyCustom2">Job Description:</typography>
<typography  className='Personal_text2'>{description}</typography>

            </div>
            <Divider className="Admin_DividerCustom3" variant="inset" />
        
          {/* <div className='volunteer_Hour_Div'>
            <typography className="Admin_TypographyCustom_Volunteer">Volunteer Hours</typography>
            <typography className="college_Main_Text">{experience.volunteer_hours} Hours</typography>
            <typography className="Admin_TypographyCustom2">Per Week</typography>
          </div>
           */}
          </div>
           {index < Experiencedata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
           </>
     )
     })}
           



  </Box>
   </Grid>
   <Grid item
   xs={12}
   sm={12}
   lg={12}
   xl={12}
   className="admin_essay_Grid">
    <Box className="Admin_BoxCustom20">

   
  

         <Box className="Admin_BoxCustom21">
    <typography className="college_Main_Text">Personal Essay</typography>
 
  </Box>
  {Summarydata && Summarydata.map((summary, index) => {
    
     return (
      <Link
      key={index}
      to="#"
      className='Text_decoration'
      onClick={(e) => {
        e.preventDefault();
        handleEditClickSummary(summary.id);
      }}
    >
        <typography  className="Admin_TypographyCustom2">
        {summary.personal_eassay}
        </typography>
        </Link>
     )
    })}

  </Box>
   </Grid>
      </Grid>
      {userInfo.profile_status === 'accepted'   ? (
      <Grid item lg={3}
      xl={2.1}
      xs={12}
      sm={12}
      md={3} 
      className='admin_collaborator_profile_Grid'
      >
         <Box className='progress_main_Grid' >
      <typography className='progress_main_Grid_Text'>Profile Insights</typography>
      <Box className='progress_main_Grid2' >
        
      
        <InsightsIcon className='progress_main_Grid_Avatar' />
  
  <Box className='progress_main_Grid2_Text'>
  <typography className='progress_main_Grid2_Text_Main'>{analytics.profile_views}</typography>
  <typography className='progress_main_Grid2_Text_Secondary'>Profile views</typography>
  </Box>
      </Box>
  <Divider variant="middle" />
  <Box className='progress_main_Grid2' >
        
       
          <MarkEmailReadIcon className='progress_main_Grid_Avatar' sx={{color:'#EF6C00'}} />
    
    <Box className='progress_main_Grid2_Text'>
    <typography className='progress_main_Grid2_Text_Main'>{analytics.total_messages}</typography>
    <typography className='progress_main_Grid2_Text_Secondary'>Messages</typography>
    </Box>
        </Box>
        <Divider variant="middle" />
        <Box className='progress_main_Grid2' >
        
    
        <ContentPasteSearchIcon className='progress_main_Grid_Avatar' sx={{color:'#2E7D32'}}/>
  
  <Box className='progress_main_Grid2_Text'>
  <typography className='progress_main_Grid2_Text_Main'>{analytics.total_search}</typography>
  <typography className='progress_main_Grid2_Text_Secondary'>Search Appearance</typography>
  </Box>
      </Box>
  
  
     </Box>
  
        </Grid>
      ) : (
        <Grid item lg={3}
        xl={2.1}
        xs={12}
        sm={12}
        md={3} 
        className='admin_collaborator_profile_Grid'
        >
           <Box className='progress_main_Grid' >
        <typography  className='progress_main_Grid_Text'>Profile Status</typography>
       <Box className='progress_secondary_Grid'>
       <CircularProgressWithLabel value={progress}  color="success"  className='CircularProgressWithLabel' />
       <Box className='progress_sub_secondary_Grid'>
       <typography className='progress_sub_secondary_Grid_Text'><CheckCircleOutlineIcon sx={{color:"green"}}/>Personal information .  10%</typography>
       <typography className='progress_sub_secondary_Grid_Text'>{Collegesdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Colleges Applied to :  40%</typography>
            <typography className='progress_sub_secondary_Grid_Text'>{HighSchooldata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}High School Education :  40%</typography>
        <typography className='progress_sub_secondary_Grid_Text'>{Honoursdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Honors</typography>
         <typography className='progress_sub_secondary_Grid_Text'>{testingdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Testing</typography>
       <typography className='progress_sub_secondary_Grid_Text'>{Activitiesdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Activities :  10%</typography>
              <typography className='progress_sub_secondary_Grid_Text'>{Experiencedata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Work Experience </typography>
      <typography className='progress_sub_secondary_Grid_Text'> {Summarydata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Personal Essay</typography>
       
      
      
            </Box>
            <Button 
      sx={{ boxShadow:"none",'&.Mui-disabled': {
        pointerEvents: 'visible', // Override the pointer-events property
      },
    marginTop:'20px',
  
    
    }}
    disabled={userInfo.profile_status === 'rejected' || userInfo.profile_status === null}
    onClick={handleClick}
    fullWidth
       variant='outlined' color="error"> REJECT PROFILE</Button>
       <Button 
       fullWidth
      sx={{ boxShadow:"none",'&.Mui-disabled': {
        pointerEvents: 'visible', // Override the pointer-events property
      },}}
      disabled={userInfo.profile_status === 'rejected' || userInfo.profile_status === null}
      onClick={handleClick2}
       variant='contained' color="success">{isLoading ? (
        // Display the circular loading icon
        <CircularProgress size={24} color="inherit" />
      ) : (
        // Display the "SUBMIT" text
        "ACCEPT PROFILE"
      )}</Button>
        
       </Box>
    
       </Box>
    
          </Grid>
)}
    </Grid>
     </div>
  )
}
