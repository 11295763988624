import React, { useState,useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Tobar from '../Tobar';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography,Button } from '@mui/material'
import TextField from '@mui/material/TextField';
import { DropzoneArea } from "mui-file-dropzone";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useLocation,useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Topbar as Topbar2 } from '../../Admin/Topbar';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './profile.css'
import AdUnitsIcon from '@mui/icons-material/AdUnits';
const Summary = ( {  ...props }) => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const[Type , setTypes]=useState()
  const[iddata,setDataId]=useState()
  function encodeAlphanumeric(userid) {
    const encoded = btoa(userid); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  } 
    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={Type === 'admin' ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(iddata)}` : `/complete_profile`}
     
    >
      Profile
    </Link>,
 
    <Typography key="3" color="text.primary">
     Personal Essay
    </Typography>
  ];
  const [files, setFiles] = useState([]);
  const [Errorpersonaleassay, setErrorpersonaleassay] = useState('');
  const [Errorfiles, setErrorfiles] = useState('');
  const [open, setOpen] = React.useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [transformedImageUrls, settransformedImageUrls] = useState([]);
  const [personalEssayData, setPersonalEssayData] = useState([]);
  const [formid, setformid] = useState('');
  const [formData, setFormData] = useState({
    personal_eassay: '',
    path_images:''
  

  });
  const handleChanged = (newFiles) => {
   
    
    setFiles(newFiles);
  };
  

  useEffect(() => {
  
     
    const searchParams = new URLSearchParams(location.search);
    const selectedPersonalEssayId = searchParams.get('id'); //change this const to form id 
    setformid(selectedPersonalEssayId);
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    // const userId = userData.id;
    const urlParams = new URLSearchParams(window.location.search);
    const userid = urlParams.get('userid');
    setDataId(userid)
    console.log('Highschool ID:', selectedPersonalEssayId); 
    const type = urlParams.get('type');
  setTypes(type); // set the Type using setTypes

  // Now, Type should be accessible
  // alert(Type); 
    if(selectedPersonalEssayId !== ''){
      try {
      fetch(`${apiUrl}/get_personal_eassy_form_data`, {   // chgange this url 
        method: 'post',
        body: JSON.stringify({ formid:selectedPersonalEssayId }), // pass the form id const here 
    })
    .then(response => {
        if (response.status === 200) {
            return response.json(); // Convert the response to JSON
        } else {
            throw new Error('Request failed');
        }
    })
    .then(data => {
      const personal_eassaydata = data.formdata[0];
     
      setPersonalEssayData(data.formdata);                     
        console.log(data, "setPersonalEssayData"); 
        const pathImagesArray = JSON.parse(personal_eassaydata.path_images);

      const transformedImageUrlsimages = pathImagesArray.map((imageUrl) => {
        // Unescape forward slashes
        
        
        // Perform the replacements
        const laravelUrl = imageUrl.replace('/public/storage/', '/storage/app/public/');
        
         return laravelUrl;
      });
      
      settransformedImageUrls(transformedImageUrlsimages);
      console.log(transformedImageUrlsimages,"images urlsssddd");
        setFormData({
          personal_eassay:personal_eassaydata.personal_eassay || '',
          path_images: personal_eassaydata.path_images || '',
       
        });
     
    })
    .catch(error => {
        console.error('Data fetch failed:', error);
    });
} catch (error) {
    console.error('Data upload failed:', error);
}
    }

    
}, []); 

  const handleSubmit = async () => {
    
    if (Type !== 'admin') {
    const personal_eassay = document.getElementsByName('personal_eassay')[0].value;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;
    // if (!personal_eassay) {
    //   setErrorpersonaleassay("Personal essay is required.");
    //   return false; 
    // }else{
    //   setErrorpersonaleassay("");
    // }
    // if (files.length === 0) {
    //   setErrorfiles("Please upload at least one file.");
    //   return false;
    // }else{
    //   setErrorfiles("");
    // }

    let isAnyFieldFilled = false;

    if (personal_eassay) {
      isAnyFieldFilled = true;
    }
  
    if (files.length > 0 ) {
      isAnyFieldFilled = true;
    }
  
    if (!isAnyFieldFilled) {
      setErrorpersonaleassay("Personal essay is required.");
      setErrorfiles("Please upload at least one file.");
      return false;
    } else {
      setErrorpersonaleassay("");
      setErrorfiles("");
    }

    const formData = new FormData(); // Create a FormData object

  // Append text data to the FormData object
  formData.append('personal_eassay', personal_eassay);
  formData.append('userId', userId);

  // Append each file to the FormData object
  for (let i = 0; i < files.length; i++) {
    formData.append(`files[]`, files[i]); 
  }
  //  alert(formid);
  if(formid!== ''){
    // alert(formid);
    //formData.formid = formid; 
    formData.append('formid', formid);
  }else{
    formData.append('formid', '');
  }
    try {
      setIsLoading(true);
      setOpen(true);
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/save_collaborator_summary`, {
        method: 'post',
        body: formData, // Send the entire formData object as the body
      });
      // setShowAlert(true);
      if (response.status === 201) {
       
            navigate(`/complete_profile`)
            setShouldNavigate(true);
      } else {
        console.error('Data upload failed:', response.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      setOpen(false);
      console.error('Data upload failed:', error);
    }
  }
  else if(Type == 'admin'){
    window.location.href = `/admin_Collaborator_profileview?id=${encodeAlphanumeric(iddata)}`;
  }
  };
  useEffect(() => {
    if (shouldNavigate) {
      const navigateTimer = setTimeout(() => {
        // Navigate to the desired page after 2 seconds
        // setShowAlert(false);
        setIsLoading(true);
        setOpen(true);
        navigate('/complete_profile');
      }, 3000);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(navigateTimer);
    }
  }, [shouldNavigate]);
  const handlePersonalEssayChange = (e) => {
    
    const inputValue = e.target.value;
  
    // Validation: Allow only alphabets, spaces, dots, hyphens, and limit to 650 words
    // const sanitizedValue = inputValue.replace(/[^a-zA-Z\s.-]/g, '');

      // Allow only up to 650 words
      const words = inputValue.split(/\s+/);
      const wordCount = words.length;
  
    if (wordCount > 65000) {
      setErrorpersonaleassay('Personal essay cannot exceed 650 words.');
      return;
    }
  
    // Update the state with the sanitized value
    setFormData({ ...formData, personal_eassay: inputValue });
    setErrorpersonaleassay('');
  };

  const parsedImages = JSON.parse(formData.path_images || '[]');

  const replaceUrl = (url) => {
    return url.replace('public/storage', 'storage/app/public');
  };
  const getFileNameFromUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const pathArray = decodedUrl.split('/');
    const filename = pathArray[pathArray.length - 1];
  
    // Remove query parameters and fragments from the filename
    return filename.split(/[?#]/)[0];
  };
  
  return (
    <div>
    {/* <Tobar /> */}
    {Type === 'admin' ? <Topbar2 /> : <Tobar />}
    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "20px"
      }}
      >
            <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
        <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>

        <Box className="Test_BoxDiv">
        <Box className="Collaborator_summary_maindiv" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
          <div role="tabpanel">
          <Box className="Test_paddingBox" >
          <div >
  <TextField  disabled={Type === 'admin'}  id="outlined-basic" label="Personal Essay *" name="personal_eassay" error={Boolean(Errorpersonaleassay)} helperText={Errorpersonaleassay} variant="outlined" fullWidth   minRows={10}  multiline size="small" value={formData.personal_eassay} onChange={handlePersonalEssayChange} />
  </div>
  <div className='Test_paddingDiv'>
  {Type !== 'admin' && (
  <DropzoneArea
    onChange={handleChanged}
    files={formData.path_images ? [formData.path_images] : []}
    maxFileSize={2500000} // Max file size in kilobytes (25 KB)
    acceptedFiles={['application/pdf']} 
    classes={{
      root: "custom-dropzone-root",
    }}
    dropzoneText={
      <div>
        <span style={{ textDecoration: 'underline', color: '#006CB7',fontWeight:600 }}>Upload resume</span> or drag and drop <br />
        <span style={{ color:"rgba(0, 0, 0, 0.6)",}}>PDF, DOC, or JPEG (max 3MB)</span>
      </div>
    }
    dropzoneProps={{
      icon: <AdUnitsIcon fontSize="large" color="primary" />, // Add this line to include the icon
    }}
  />
)}
 
{Array.isArray(parsedImages) && parsedImages.length > 0 && (
  parsedImages.map((fileUrl, index) => (
    <div key={index}>
      {console.log("File Path:", fileUrl)}
      <a
        href={replaceUrl(fileUrl)}
        target="_blank"
        rel="noopener noreferrer"
        style={{textDecoration:"none"}}
      >
        {/* {getFileNameFromUrl(fileUrl)} */}
        Uploaded File
      </a>
    </div>
  ))
)}
    {/* {  formData.path_images && (
  <img src={formData.path_images} alt="Image" />
)} */}
    {Errorfiles && (
    <FormHelperText sx={{color:"#d32f2f"}}>{Errorfiles}</FormHelperText>
  )}


  </div>

  
  </Box>
 

          </div>
         
          <div className='Test_buttonDiv'>
  <Link
      underline="hover"
      key="1"
      color="inherit"
       href={Type === 'admin' ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(iddata)}` : `/complete_profile`}
     
    >
    <Button className='Collaborator_Summary_button' variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:"none"}}>Back</Button></Link>
  
    <Button className='Collaborator_Summary_button' variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}} onClick={() => handleSubmit(iddata)}   disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    Type === 'admin' ? 'CLOSE' : 'SAVE'
                  )}</Button>
                 
  </div>
         </Box>
        </Box>
        {/* {showAlert && (
        <Alert
          severity="success"
          sx={{
            position: 'absolute',
            bottom: 0, // Place the Alert at the bottom
            left: 0,
            right: 0,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Your data was successfully saved! Redirecting...
        </Alert>
      )} */}
      </Grid>
    </Grid>
  </div>
  )
}

export default Summary