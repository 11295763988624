import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import headerImage from '../assets/img/toplogo.svg';
import Collaborator from '../assets/img/collaborator-2.svg';
import student from '../assets/img/student.png';
import '../App.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

const Welcome = () => {
  // Initialize state to track the button text and the clicked state of divs
  const [buttonText, setButtonText] = useState("Join as Collaborator");
  const [clickedDiv, setClickedDiv] = useState('collaborator');
  const [failMessage, setFailMessage] = useState('');
const  navigate=useNavigate()
  // Function to handle the click event on Welcome_paper
  const handleWelcomeClick = () => {
    setButtonText("Join as Collaborator");
    setClickedDiv('collaborator');
  };

  // Function to handle the click event on Welcome_paper1
  const handleWelcome1Click = () => {
    setButtonText("Join as Student");
    setClickedDiv('student');
  };
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userDetailsParam = searchParams.get('userDetails');
    const failsParam = searchParams.get('fails');
    if (userDetailsParam) {
      try {
        // Parse the JSON string from the URL
        const userData = JSON.parse(userDetailsParam);

        // Set the entire userDetails object in localStorage
        localStorage.setItem('userData', JSON.stringify(userData));

        // Check the 'type' property
        if (userData.type === 'student') {
          // If 'type' is 'student', navigate to '/student'
          navigate('/student');
        } else if (userData.type === 'collaborator') {
          // If 'type' is 'collaborator', navigate to '/profileview'
          navigate('/profileview');
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }else if (failsParam) {
      // Handle failed login due to email already being registered for a different user type
      console.error('Login failed:', failsParam);
      setFailMessage(failsParam);
      // Display an appropriate message to the user or handle the failure as needed
    }
  }, [location.search]);
  return (
    // <section className='Welcome_container'>
      <Grid container sm={12} xs={12} lg={12} xl={12} md={12} sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"12vh"}}>

      
      <Grid className='Welcome_grid_Main'  item sm={12} xs={10} lg={12} xl={12} md={12} sx={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",}}>

    
      {/* <div className='Welcome'> */}
      {/* {failMessage ? (
        <p>{failMessage}</p>
      ) : (
        <div>
         
        </div>
      )} */}
      <Link to={`/`}>
        <img className='Welcome_img_applyupp' style={{marginBottom:"70px"}} src={headerImage} alt="Header" /> </Link>
        <typography className="Welcome_join_text">Join as a Collaborator or Student</typography>
        <div className='welcome_Button_div'>
          <div className={`Welcome_paper ${clickedDiv === 'collaborator' ? 'clicked' : ''}`} onClick={handleWelcomeClick}>
            <img className='Welcome_img' src={Collaborator} alt="Header" />
            <typography className={`Welcome_desc ${clickedDiv === 'collaborator' ? 'clicked-text' : ''}`}>I am a Collaborator,<br /> willing to upload my college<br /> applications</typography>
          </div>
          <div className={`Welcome_paper1 ${clickedDiv === 'student' ? 'clicked' : ''}`} onClick={handleWelcome1Click}> 
            <img className='Welcome_img' src={student} alt="Header" />
            <typography className={`Welcome_desc ${clickedDiv === 'student' ? 'clicked-text' : ''}`}>I am a Student,<br /> seeking help with my college<br /> applications</typography>
          </div>
        </div>
        <Link to={`/login?type=${clickedDiv}`}>
          <Button
            sx={{ marginTop: "40px",boxShadow:"none" ,width: "310px",}}
            variant="contained"
            color="primary"
            size="medium"
          >
            {buttonText}
          </Button>
        </Link>
        <typography className='Welcome__already'>Already have an account? <Link className='Welcome_link' to={`/login?type=${clickedDiv}`}>Login</Link> </typography>
      {/* </div> */}
      </Grid>
      </Grid>
    // </section>
  );
};

export default Welcome;
