import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import Tobar from './Tobar';
import './collaborator.css';

const Profile_submitted = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  return (
    <div>
    <Tobar />
    {/* <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    > */}
       
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "20px",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        minHeight:'92vh'

      }}
      >
          <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Submitted_text">Profile Submitted</typography>
            <typography className='Submitted_text2'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography>
            <typography className='Submitted_text2'>We,ll send you an email once its approved.</typography>
          </div>
       
      </Grid>
    {/* </Grid> */}
  </div>
  )
}

export default Profile_submitted
