import { Box, Grid, Link } from '@mui/material'
import React, { useState } from 'react'
import { Topbar } from './Topbar'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Collaborator_home from '../collaborator/collaborator_home';
import Collaborator_students from '../collaborator/Collaborator_students';
import SearchStudents from '../collaborator/SearchStudents';
import CollaboratorNotification from '../collaborator/CollaboratorNotification';
import Collaborator_messages_request from '../collaborator/Collaborator_messages_request';
import Messages from '../collaborator/Messages';
import { Admin_collaborator } from './Admin_collaborator';
import { Admin_student } from './Admin_student';
import { Admin_notification } from './Admin_notification';
import { Admin_searchcollaborator } from './Admin_searchcollaborator';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import './admin.css'

export const Admin_dashborad = () => {
  const [activeComponent, setActiveComponent] = useState('Admin_collaborator');
  return (
  <div>
    <Topbar/>
    <div className='wrapper'>
    <Grid container
    gap={3}
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    className='admin_dashboard_Container'
    
    >
          <Grid item
      lg={2.2}
      xl={2.1}
      xs={0}
      sm={0}
      md={2.1}
      className='admin_dashobard_container_Left_screen'
      >
<Box className='admin_dashobard_container_Left_Box_screen'>
  <Link 
   className={`admin_dashobard_container_Left_Box_Link_screen ${activeComponent === 'Admin_collaborator' ? 'active' : ''}`}
   to={'#'}
 
   onClick={() => setActiveComponent('Admin_collaborator')}>
  <PeopleAltOutlinedIcon/>
  Collaborators</Link>
  <Link   className={`admin_dashobard_container_Left_Box_Link_screen ${activeComponent === 'Admin_student' ? 'active' : ''}`}
   to={'#'}
  
   onClick={() => setActiveComponent('Admin_student')}>
   <SchoolOutlinedIcon/>
  Students</Link>
  {/* <Link className={`admin_dashobard_container_Left_Box_Link ${activeComponent === 'Admin_Notification' ? 'active' : ''}`}
   to={'#'}

   onClick={() => setActiveComponent('Admin_Notification')}>
  <NotificationsActiveOutlinedIcon />
  Notifications</Link>
  <Link className={`admin_dashobard_container_Left_Box_Link ${activeComponent === 'searchCollaborator' ? 'active' : ''}`}
   to={'#'}
 
   onClick={() => setActiveComponent('searchCollaborator')}>
 <ScreenSearchDesktopOutlinedIcon />
  Search Collaborator</Link> */}
</Box>

      </Grid>
      <Grid item
      lg={2.31}
      xl={2.1}
      xs={0}
      sm={0}
      md={2.1}
      className='admin_dashobard_container_Left'
      >
<Box className='admin_dashobard_container_Left_Box'>
  <Link 
   className={`admin_dashobard_container_Left_Box_Link ${activeComponent === 'Admin_collaborator' ? 'active' : ''}`}
   to={'#'}
 
   onClick={() => setActiveComponent('Admin_collaborator')}>
  <PeopleAltOutlinedIcon/>
  Collaborators</Link>
  <Link   className={`admin_dashobard_container_Left_Box_Link ${activeComponent === 'Admin_student' ? 'active' : ''}`}
   to={'#'}
  
   onClick={() => setActiveComponent('Admin_student')}>
   <SchoolOutlinedIcon/>
  Students</Link>
  {/* <Link className={`admin_dashobard_container_Left_Box_Link ${activeComponent === 'Admin_Notification' ? 'active' : ''}`}
   to={'#'}

   onClick={() => setActiveComponent('Admin_Notification')}>
  <NotificationsActiveOutlinedIcon />
  Notifications</Link>
  <Link className={`admin_dashobard_container_Left_Box_Link ${activeComponent === 'searchCollaborator' ? 'active' : ''}`}
   to={'#'}
 
   onClick={() => setActiveComponent('searchCollaborator')}>
 <ScreenSearchDesktopOutlinedIcon />
  Search Collaborator</Link> */}
</Box>

      </Grid>
      <Grid
    
    item
   
    lg={activeComponent === 'Admin_collaborator' ? 9.4 : 9.4}  // Set different values for lg based on the activeComponent
xl={activeComponent === 'Admin_collaborator' ? 9.6 : 9.6}  // Set different values for xl based on the activeComponent
xs={activeComponent === 'Admin_collaborator' ?  12:12}   // Set different values for xs based on the activeComponent
sm={activeComponent === 'Admin_collaborator' ? 12 : 12}   // Set different values for sm based on the activeComponent
md={activeComponent === 'Admin_collaborator' ? 9.4 :9.4} 
    sx={{
      // flex: 1,
// overflowY: 'auto', // Allow it to expand to fill available space
minHeight:'100vh'
    }}
   
  >
    {activeComponent === 'Admin_collaborator' && <Admin_collaborator/>}
  

    {activeComponent === 'Admin_student' && <Admin_student />}
    {activeComponent === 'Admin_Notification' && <Admin_notification />}
    {activeComponent === 'notifications' && <CollaboratorNotification/>}
    {activeComponent === 'searchCollaborator' && <Admin_searchcollaborator/>}
  </Grid> 
    </Grid>
    </div>
  </div>
  )
}
