import React, { useState, useEffect } from 'react'
import UniLogos from '../components/UniLogos/UniLogos'
import FooterMain from '../components/footer/footer'
import { Grid,Button } from '@mui/material'
import Headermain from '../components/header/header'
import '../App.css';
import TextField from '@mui/material/TextField';
import call from '../assets/img/call.png';
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import Divider from '@mui/material/Divider';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from '@mui/material/CircularProgress';
import animationData from '../assets/lottiefinal.json'; 
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom'
import MuiPhoneInput from "material-ui-phone-number";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";


const Contact_us = () => {
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [contactSuccess, setcontactSuccess] = React.useState(null);
  const [phoneValue, setPhoneValue] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    setName('');
    setEmail('');
    setPhoneValue('');
    setMessage('');
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setNameError('');
    setEmailError('');
    setPhoneError('');
    setMessageError('');
    
    // const name = document.getElementsByName('name')[0].value;
    // const email = document.getElementsByName('email')[0].value;
    // const message = document.getElementsByName('message')[0].value;
   // const phone_number = document.getElementsByName('phone_number')[0].value;
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;

    if (!name) {
      setNameError('Name is required');
      isValid = false;
    }

    if (!email) {
      setEmailError('Email is required');
      isValid = false;
    }else if (!emailFormat.test(email)) {
      setEmailError('Invalid email format');
      isValid = false;
    }

    // You can add more validation rules for email format, phone number, etc.

    if (phoneValue) {
      const isValidnumbercheck = isValidPhoneNumber(phoneValue);
      
      if (!isValidnumbercheck) {
        setPhoneError('Enter a valid phone number');
        isValid = false;
      }
    }else{
      
      setPhoneError('Enter a valid phone number');
      isValid = false;
    }
    
    if (!message) {
      setMessageError('Message is required');
      isValid = false;
    }

    if (!isValid) {
      return; // Do not proceed if validation fails
    }
   

    const formData = new FormData(); // Create a FormData object 

  // Append text data to the FormData object
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone_number', phoneValue);
    formData.append('message', message);
  

 
    try {
      setIsLoading(true);
      
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/contact_us_page`, {
        method: 'post',
        body: formData, // Send the entire formData object as the body
      });
      // setShowAlert(true);
      if (response.status === 201) {
        const data = await response.json();
        setOpen(true);
        
        setcontactSuccess(data.message);
        setIsLoading(false);
        setName('');
        setEmail('');
        setPhoneValue('');
        setMessage('');
      }else if(response.status === 400){
        setIsLoading(false);
        setEmailError('Email doesnot exist. Invalid email address.');
        return;

      } else {
        console.error('Data upload failed:', response.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      setOpen(false);
      console.error('Data upload failed:', error);
    }
    
  };
  const handlePhoneChange = (value) => {
    
  
    // const isValid = isValidPhoneNumber(value);
    
    // if (!isValid) {
    //   setPhoneError('Please enter a valid phone number');
    // } else {
    //   setPhoneError('');
    //   setPhoneValue(value);
    // }
    setPhoneValue(value);

    
  
};
  return (
    <>
    
    <Grid
  container
  component="main"
  className="contact_main" // Add the class name of your choice
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
  <Grid
  container
  component="main"
  className="contact_one" // Add the class name "contact_one"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
          <div className='contactmain'>
    <div className='contactmain_2'>
       <Headermain/>
       </div>
       </div>
       <Grid
  item
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
  className="contact_two" // Add the class name "contact_two"
>
          
          
     
      
      
  
            <typography className="companyTitle">
            Need any Help?
            </typography>
            <typography className="companyDescription">
            Visit our <Link to={'/Faqs'} className='companyDescriptionLink'>FAQ page</Link> or contact us below
            </typography>
          </Grid>
          </Grid>
          
          <div className='contactmain_3' >
    <div className='contactmain_4' >
    <Grid
  item
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
  className="contact_three" // Add the class name of your choice
>
<Grid
  item
  lg={5}
  xl={5}
  xs={12}
  sm={12}
  md={5} 
  sx={{width:"-webkit-fill-available"}}
  className="contact_four" // Add the class name of your choice
>
        {/* <div className='contactmain_5' >
       
        <CallOutlinedIcon className="contact_icon" />

               
                    <Grid item className="contact_five">
          <typography className='contactusText'>Customer service Number</typography>
          <typography className='contactusTextsecondary'>+1 (424) 244-6216</typography>
        </Grid>
        </div> */}
        {/* <div className='contactmain_6'>
        <Divider sx={{color:"#FFF",borderColor:"#FFFF"}}  color="white" />
        </div> */}
        <div className='contactmain_8' >
        
                    <MailOutlineIcon className="contact_icon"  />
                
                    <Grid item className="contact_five">
          <typography className='contactusText'>Email us at</typography>
          <typography className='contactusTextsecondary'>support@applyupp.com
</typography>
<typography className='contactusTextsecondary'>
sales@applyupp.com</typography>
        </Grid>
        </div>
        <div className='contactmain_6' >
        <Divider sx={{color:"#000",borderColor:"#000",strokeWidth:'0.25px'}}  color="white"/>
        </div>
        <div className='contactmain_7' >
        
        <LocationOnOutlinedIcon className="contact_icon"  />

              
        <Grid item className="contact_five">
          <typography className='contactusText'>Physical Mailing address</typography>
          <typography className='contactusTextsecondary'>4545 Spring Valley Road,<br/> Farmers Branch, TX</typography>
        </Grid>
        </div>

      </Grid>
      <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {contactSuccess && (
                <div className="success-message">{contactSuccess}</div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{display:"flex",justifyContent:"center",paddingBottom:"35px",paddingTop:"30px"}}>
            <Link to={`/contact_us`} style={{ textDecoration: "none" }}>
              {" "}
              <Button  variant ="outlined"onClick={handleClose}>Back </Button>
            </Link>
          </DialogActions>
        </Dialog>
      <Grid
  lg={7}
  xl={7}
  xs={12}
  sm={7}
  md={7}
  item
  className="contact_six" // Add the class name of your choice
>
     
        <TextField Email name="name"  value={name} onChange={(e) => setName(e.target.value)} label="Name" id="name" fullWidth size='small' error={!!nameError}
        helperText={nameError}/>
        <TextField fullWidth label="Email"  value={email}   onChange={(e) => setEmail(e.target.value)} id="email" name="email" className='contact_textinput'  size='small' error={!!emailError}
        helperText={emailError}/>
        <MuiPhoneInput
                  defaultCountry={"us"}
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={phoneValue}
                  //onChange={setPhoneValue}
                  onChange={handlePhoneChange}
                  style={{ marginTop: 16, marginBottom: 8 }}
                  error={!!phoneError}
                  helperText={phoneError}
                />
        {/* <TextField fullWidth label="Phone Number" name="phone_number" id="phone_number"  className='contact_textinput'  size='small' error={!!phoneError}
        helperText={phoneError}/> */}
        <TextField
        fullWidth
          id="message"
          name="message"
          value={message}
          label="Message"
          multiline
          minRows={6}
          className='contact_textinput'
          size='small'
          onChange={(e) => setMessage(e.target.value)}
          error={!!messageError}
          helperText={messageError}
        />
        <Button className="contact_button" onClick={handleSubmit} disabled={isLoading}  variant="contained">{isLoading ? (
                      // Display the circular loading icon
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      // Display the "SUBMIT" text
                      "Send message"
                    )}</Button>
     
       
      </Grid>
    </Grid>
   
    </div> 
    </div>
 
    </Grid>
      
        
    
    
    <FooterMain />
    </>
  )
}

export default Contact_us


