import { Box, Divider, Grid, typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import mainlogo from '../../assets/img/mainwelcome.png';
import './Students.css';

const StudentNotification = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const checkuser = JSON.parse(localStorage.getItem('userData'));
    if (!checkuser) {
      // Redirect to the welcome page if user data is not available
      window.location.href = '/welcome';
      return; // Stop execution if redirection occurs
    }

    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userId = checkuser.id; // Access user ID directly from checkuser
        const url = `${apiUrl}/get_students_notifications/${userId}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data, "This is data"); // Log the data received from the API
          if (data && data.notifications && Array.isArray(data.notifications)) {
            setNotifications(data.notifications); // Update state with notifications array
          } else {
            throw new Error('Invalid data format');
          }
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('Data fetch failed:', error);
      }
    };

    fetchData();
  }, []);

  console.log(notifications, "This is notifications");


  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // Extract last two digits of the year
    const options = {
      year: '2-digit',  // Use numeric year format like "2024"
      month: 'short', // Use short month format like "Feb"
      day: '2-digit', // Use 2-digit day format like "13"
      hour: '2-digit', // Use 2-digit hour format like "12"
      minute: '2-digit', // Use 2-digit minute format like "25"
      hour12: true, // Use 12-hour format (AM/PM)
    };
    return `${date.toLocaleDateString('en-US', options).replace(',', '')}`;
  }

  return (
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', background: "rgba(255, 255, 255, 0.80)" }}>
      <Grid container lg={12} xl={12} xs={12} sm={12} md={12} sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Box className="Student_profile_view_mainDiv_notification">
          <div style={{ marginBottom: '45px' }}>
            <typography  className="Student_Profile_view_welcome">Notifications</typography>
          </div>
          <div style={{ width: '100%' }}>
            {notifications && notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <div key={index}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems:'center',alignContent:'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                      <typography className="Student_Profile_view_Admin">Message Request:</typography>
                      <div style={{textAlign:'start',marginLeft:'5px',alignItems:'center',alignSelf:'center'}}>
                      <typography className="Student_Profile_view_Admin2"> Your message request to {notification.sendto_name
} is <typography style={{ fontWeight: 'bold' }}>{notification.status}</typography></typography>
</div>
                    </div>
                    <div>
                      <typography className="Student_Profile_view_Admin2">{formatDate(notification.created_at)}</typography>
                    </div>
                  </div>
                  {index < notifications.length - 1 && <Divider variant="inset" flexItem sx={{ mx: 3, my: 3 }} />}
                </div>
              ))
            ) : (
              <Grid container lg={12} xl={12} xs={12} sm={12} md={12} sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <Box className="Student_profile_view_mainDiv">
                  <img src={mainlogo} alt="Logo" className="Student_profile-view-main" />
                  <typography className="Student_Profile_view_welcome">When you receive a notification, it will appear here</typography>
                </Box>
              </Grid>
            )}
          </div>
        </Box>
      </Grid>
    </div>
  );
};

export default StudentNotification;
