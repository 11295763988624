import React , { useState, useRef,useEffect  } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import './Students.css'
import backgroundimage from "../../assets/img/bg-img.png"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Lottie from 'react-lottie';

import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import PropTypes from 'prop-types'; // Import PropTypes
import { Link,useNavigate } from 'react-router-dom';
import profile1 from '../../assets/img/profile.jpg';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SchoolIcon from '@mui/icons-material/School';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Premium from '../../assets/img/Rectangle.png'

import Profile_submitted from '../collaborator/Profile_submitted';

import honoursimage from '../../assets/img/honours.png';
import Highschoolimage from '../../assets/img/highschool.png';
import collegeimage from '../../assets/img/college.png';
import sportimage from '../../assets/img/sports.png';
import testingimage from '../../assets/img/testing.png';
// import { useNavigate } from 'react-router-dom';
import test1 from '../../assets/img/Testlogo.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Topbar from './Topbar';
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import animationData2 from '../../assets/deletenew.json'; 
import deletedata from '../../assets/img/delete.svg';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DialogTitle from "@mui/material/DialogContent";
import {  IconButton } from '@mui/material';
import bgimagecover from '../../assets/img/bgcover.svg';
import "cropperjs/dist/cropper.css";
import { getCroppedImg } from "./../Signup/cropperUtils";
import Cropper from "react-cropper";
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Experiance_icon from '../../assets/img/experiance_icon.jpeg'

const StudentCompleteProfile = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2, // The Lottie JSON data
  };
  console.log("first")
  

  const [screenSizeLessThan680, setScreenSizeLessThan680] = useState(window.innerWidth > 680);

  useEffect(() => {
    function handleResize() {
      const isLessThan680 = window.innerWidth > 680;
      setScreenSizeLessThan680(isLessThan680);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [screenSizeGreaterThan680, setScreenSizeGreaterThan680] = useState(window.innerWidth < 680);

  useEffect(() => {
    function handleResize() {
      const isGreaterThan680 = window.innerWidth < 680;
      setScreenSizeGreaterThan680(isGreaterThan680);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
   
    fetchUsersFromApi();
  }, []);

  const fetchUsersFromApi = () => {
    
    // Fetch all users from the API
    // Replace 'api/users' with your actual API endpoint for users
    const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
    fetch(`${apiUrl}/get_profileformessage`, {
      method: 'post',
      body: JSON.stringify({ userId }), // Send the entire formData object as the body
      }) 
      .then((response) => response.json())
      .then((data) => {
        console.log(data.collaborators); 
        //setMessages(data.collaborators);
        setcollaboratorschatusers(data.collaborators);
        console.log("This is data ",data.collaborators)
      })
      .catch((error) => {
        console.error("Error fetching users: ", error);
      });
  };
  
 
  const handleprofileView =()=>{
    navigate('/student_profile_edit')
  }
    const useStyle = {
        Button: {
          "&:hover": {
            backgroundColor: "#ffffff !important",
            boxShadow: "none !important",
          },
          "&:active": {
            boxShadow: "none !important",
            backgroundColor: "#3c52b2 !important",
          },
        },
      };
      const [progress, setProgress] = React.useState(10); 
      const [Collegesdata, setCollegesdata] = useState([]);
      const [HighSchooldata, setHighSchooldata] = useState([]);
      const [Honoursdata, setHonoursdata] = useState([]); 
      const [Testingdata,setTestingdata] = useState([]);
      const [Activitiesdata,setActivitiesdata] = useState([]);
    const [Experiencedata,setExperiencedata] = useState([]);

      const [Summarydata, setSummarydata]  = useState([]);
      const [collegeId, setCollegeId] = useState(null);
      const [showButtons, setShowButtons] = useState(true);
      const [showDeleteDataImage, setShowDeleteDataImage] = useState(false);
    
    
      const userData = JSON.parse(localStorage.getItem('userData'));
      console.log(userData,'userData123')
      const joinDate = new Date(userData.created_at);
      const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
      const profile1url = userData.avatarImage;
      const laravelUrl = profile1url ? profile1url.replace('/public/', '/storage/app/public/'): null;
    const laravelUrlfinal =laravelUrl? laravelUrl.replace('/public/storage/', '/public/'): null;
      const backgroundurl = userData.background_image;

      let backgroundurlimagefinal; // Initialize the variable

      if (backgroundurl) {
        const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/');
        backgroundurlimagefinal = backgroundurlimage.replace('/public/storage/', '/public/');
      } else {
        // Handle the case where backgroundurl is empty or null
        backgroundurlimagefinal = ''; // Set it to an empty string or any other appropriate default value
      }
      // Testingdata.forEach((item, index) => {
      //   const testData = JSON.parse(item.testdata);
      //   if (testData && testData.length > 0) {
      //     testData.forEach((test, testIndex) => {
      //       const testname = test[`testname-${testIndex}`];
      //       const startDate = new Date(test[`start-date-${testIndex}`]);
      //       const year = startDate.getFullYear();
      //       const attempts = test[`attempts-${testIndex}`];
      //       const score = test[`score-${testIndex}`];
      
      //       console.log(`Test ${testIndex + 1}:`);
      //       console.log(`Test Name: ${testname}`);
      //       console.log(`Year: ${year}`);
      //       console.log(`Attempts: ${attempts}`);
      //       console.log(`Score: ${score}`);
      //     });
      //   }
      // });
      
      
      // console.log(Testingdata,"Testingdata");
      // console.log(laravelUrlfinal,"user image");
      console.log( Collegesdata,"colleges data")
      console.log(HighSchooldata,"Hogh school data")
      // console.log( HighSchooldata,"HighSchooldata")
      // console.log( Activitiesdata,"Activitiesdata")
          
      // if (Activitiesdata && Activitiesdata.length > 0) {
      //   const sportsActivities = JSON.parse(Activitiesdata[0].sports_activities); // Assuming there is only one item in Activitiesdata
      
      //   if (sportsActivities && sportsActivities.length > 0) {
      //     sportsActivities.forEach((activity, index) => {
      //       const highSchoolName = activity[`high-school-name-${index}`];
      //       const highActivitiesSelect = activity[`high-activities-select-${index}`];
      
      //       console.log(`High School Name: ${highSchoolName}`);
      //       console.log(`High Activities Select: ${highActivitiesSelect}`);
      //     });
      //   }
      // }
      
      // HighSchooldata.map((item, index) => {
      //   const endDateYear = new Date(item.end_date).getFullYear(); // Extract the year from end_date
      //   const startDateYear = new Date(item.start_date).getFullYear(); // Extract the year from start_date
      
      //   console.log(`High School Name: ${item.hight_school_name}`);
      //   console.log(`End Date Year: ${endDateYear}`);
      //   console.log(`Start Date Year: ${startDateYear}`);
        
      //   // Do whatever you want with this data
      //   // You can also store it in an array or state if needed
      // });
      
      // console.log(Summarydata,"summary data")
  // Check if Honoursdata exists and has data
  // if (Honoursdata && Honoursdata.length > 0) {
  //   Honoursdata.forEach((item, index) => {
  //     const awards = JSON.parse(item.awards);
  
  //     if (awards && awards.length > 0) {
  //       awards.forEach((award, awardIndex) => {
  //         const awardTitle = award[`award-title-${awardIndex}`];
  //         const level = award[`level-${awardIndex}`];
  //         const awardDescription = award[`award-description-${awardIndex}`];
  
  //         console.log(`Award Title ${awardIndex + 1}: ${awardTitle}`);
  //         console.log(`Level ${awardIndex + 1}: ${level}`);
  //         console.log(`Award Description ${awardIndex + 1}: ${awardDescription}`);
  //       });
  //     }
  //   });
  // }
  
  
      // Collegesdata.forEach((college, index) => {
      //   console.log(`College ${index + 1}:`);
      //   console.log(`Major: ${college.major}`);
      //   console.log(`Supplementary Essay: ${college.supplementary_eassy}`);
      //   console.log(`High School Location: ${college.highSchoolLocation}`);
      //   console.log(`College Name: ${college.collegeName}`);
       
      // });

 

      React.useEffect(() => {
        const timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);
        return () => {
          clearInterval(timer);
        };
      }, []);
      const [selectedImage, setSelectedImage] = useState(null);
      const [imageLoaded, setImageLoaded] = useState(true);
      const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
      const [profilefile, setprofilefile]= useState(null);
      const cropperRef = useRef(null);
  
      // Function to handle image selection
      const handleImageSelection = async (event) => {
        const file = event.target.files[0];
        if (file) {
          // Check if the file size is greater than 2MB (2 * 1024 * 1024 bytes)
          const maxSize = 2 * 1024 * 1024;
          if (file.size > maxSize) {
            setSnackbarOpen2(true); // Open Snackbar2 if file size exceeds 2MB
            fileInputRef2.current.value = '';
            return;
          }
          
          const reader = new FileReader();
          reader.onload = (e) => {
            setSelectedImage(e.target.result);
          };
          reader.readAsDataURL(file);
        }
        
        setImageLoaded(true);
        setIsCropDialogOpen(true);
      };
      
      const [showFullSummary, setShowFullSummary] = useState(false);

      const handleViewMore = () => {
        setShowFullSummary(!showFullSummary);
      };
      const handleCrop = async () => {
        console.log(imageLoaded,"crop code status ");
       // Get the cropped image data
       if (cropperRef.current && imageLoaded) {
         const imageElement = cropperRef.current;
         const cropper = imageElement.cropper;
     
         // Get the cropped data synchronously
         const croppedData = cropper.getData();
     
         // Get the cropped image asynchronously
         cropper.getCroppedCanvas().toBlob(async (croppedImageBlob) => {
           // Convert the blob to a data URL
           const croppedImage = URL.createObjectURL(croppedImageBlob);
     
           // Update the state with the cropped image
           setprofilefile(croppedImageBlob);
           setSelectedImage(croppedImage);
           setIsCropDialogOpen(false);
           try {
            // Make a POST request to the API endpoint
            const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;
          
          const formData = new FormData();
          formData.append('userId', userId);
          formData.append('avatarImage', croppedImageBlob);
          console.log(formData, "form data for image update");
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/profileImagechange`, {
              method: 'post', 
              body: formData,
            });
          
            // Check the response status and handle it accordingly
            if (response.status === 200) {
              // Registration successful, you can handle the response data here
              const data = await response.json();
              localStorage.removeItem('userData');
              localStorage.setItem('userData', JSON.stringify(data.user));
              console.log(data,'user data');
              
                
              //history.push(`/signupprofile?type=${type}`);
              // You can redirect the user to a success page or perform other actions
            } else if(response.status === 422) {
              // Handle other response statuses or errors
              const data = await response.json();
              console.error('Registration failed:', data);
              
              
              
            }
          } catch (error) {
            // Handle network errors or other exceptions
            console.error('Registration failed:', error);
           
          }
     
           // Now you can send the croppedImage to your server or use it as needed
           console.log("Cropped Image Blob:", croppedImageBlob);
         });
       } else {
         setImageLoaded(true);
         console.log("Cropper not initialized or image not loaded");
       }
     }; 
  
      const handleCloseDialogcrop = () => {
        setIsCropDialogOpen(false);
        setSelectedImage(null);
      };
    const [membership, setMembership] = useState();
    useEffect(() => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
  
      try {
        fetch(`${apiUrl}/getuserdetailsforeditpage`, {
          method: 'post',
          body: JSON.stringify({ userId: userId }),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
             
            } else {
              throw new Error('Request failed');
            }
          })
          .then((data) => {
            console.log(data,"this is data");
           
            // Assuming 'membership' is the key for the membership value in the response data from your table
            const membershipValue = data.user.membership ;
            setMembership(membershipValue);
             console.log(data.user.membership,"this is membership value");
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
          });
      } catch (error) {
        // Handle fetch-related errors here
        console.error(error);
      }
      function encodeAlphanumeric(id) {
        const encoded = btoa(id); // Using btoa to Base64 encode the ID
        // You can further process the encoded string if needed
        return encoded;
      } 
    }, []);
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [isSnackbarOpen2, setSnackbarOpen2] = useState(false);
      const [image, setImage] = useState(null); // State to store the uploaded image file
      const [showImageUploader, setShowImageUploader] = useState(false); // State to control the image uploader visibility
      const fileInputRef = useRef(null); // Ref to access the hidden file input
      const fileInputRef2 = useRef(null); // Ref to access the hidden file input
    
      const handleImageChange = async () => {
        const file = fileInputRef.current.files[0];
        if (file) {
          // Check if the file size is greater than 5MB (5 * 1024 * 1024 bytes)
          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            setSnackbarOpen(true);
            setTimeout(() => {
              setSnackbarOpen(false);
            }, 3000);
            fileInputRef.current.value = '';
            return;
          }
       
          const reader = new FileReader();
          reader.onload = (e) => {
            setImage(e.target.result);
            setShowImageUploader(false); // Hide the image uploader after an image is uploaded
          };
          reader.readAsDataURL(file);
        }
        try {
          // Make a POST request to the API endpoint
          const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
  
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('background_image', file);
        console.log(formData, "form data for image update");
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/profilebackgroundImagechange`, {
            method: 'post', 
            body: formData,
          });
    
          // Check the response status and handle it accordingly
          if (response.status === 200) {
            // Registration successful, you can handle the response data here
            const data = await response.json();
            localStorage.removeItem('userData');
            console.log('Registration successful');
            localStorage.setItem('userData', JSON.stringify(data.user));
            window.location.reload();
            //history.push(`/signupprofile?type=${type}`);
            // You can redirect the user to a success page or perform other actions
          } else if(response.status === 422) {
            // Handle other response statuses or errors
            const data = await response.json();
            console.error('Registration failed:', data);
            
            
            
          }
        } catch (error) {
          // Handle network errors or other exceptions
          console.error('Registration failed:', error);
         
        }
      };
    
      const handleIconClick = () => {
        // Trigger the hidden file input when the icon is clicked
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };
  
    
      const [open, setOpen] = React.useState(false);
      const handleClose = () => {
        setOpen(false);
        
        
      };
      const handleOpen = () => {
        setOpen(true);
      };
  
      
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // You can adjust the background color and opacity
      display: open ? 'block' : 'none',
      zIndex: 1000, // Adjust the z-index as needed to ensure it's above other elements
    };


    useEffect(() => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
  
        fetch(`${apiUrl}/get_all_students_forms_profile_data`, {
            method: 'post',
            body: JSON.stringify({ userId }), // Send the entire formData object as the body
        })
        .then(response => {
            if (response.status === 200) {
                return response.json(); // Convert the response to JSON
            } else {
                throw new Error('Request failed');
            }
        })
        .then(data => {
            const collegesdata = data.CollegesData;
            const highschooldata = data.HighschoolData;
            // console.log(highschooldata,"HighSchool Data 22");
            const hounoursdata = data.awardsData;
            const testingdata = data.TestingData;
            const activitiesdata = data.ExperiencesData;
            const personalessay = data.SummaryData;
            const studentExperience=data.studentExperiencesData;
            setCollegesdata(collegesdata);
            setHighSchooldata(highschooldata);
            // console.log(setHighSchooldata,"This is high school data ");
            setHonoursdata(hounoursdata);
            setTestingdata(testingdata);
            setActivitiesdata(activitiesdata);
            setSummarydata(personalessay);
            setExperiencedata(studentExperience)
            //console.log(collegesdata); // Log the awardsData
           // alert("here");
        })
        .catch(error => {
            console.error('Data fetch failed:', error);
        });
    } catch (error) {
        console.error('Data upload failed:', error);
    }
    }, [collegeId]);
    
     // Initialize with null or a default value


    const [openClick, setOpenClick] = useState(false);
   const [deleteType, setDeleteType] = useState('');
   const [deleteId, setDeleteId] = useState(null);
 const[confirmationText,setConfirmationText ]=useState('')
   const handlecollegeDelete = (id, type) => {
     setDeleteType(type); // Set the delete type (college or high school)
     setDeleteId(id); // Set the selected college/high school id
     setOpenClick(true); // Open the dialog`
     if(type === 'college'){
      setConfirmationText('College');
     }
     else if (type === 'highschool') {
      setConfirmationText('High school');
     }
     else if (type === 'honours') {
      setConfirmationText('Honours');

     }
     else if (type === 'testing') {
      setConfirmationText('Testing');

     }
     else if (type === 'activity') {
      setConfirmationText('Activity');

     }
     else if (type === 'summary') {
      setConfirmationText('Personal Essay'); 

     }
     else if (type === 'experience') {
      setConfirmationText('Experience'); 

     }
   };
 
   const handleCloseDialog = () => { 
    setOpenClick(false);
  };
  useEffect(() => {
    if (showDeleteDataImage) {
      const timeout = setTimeout(() => {
        handleCloseDialog();
        // lines to fix delete issue 
        setShowDeleteDataImage(false)
        setShowButtons(true)
      }, 2000); // Adjust the time in milliseconds (e.g., 3000 ms = 3 seconds)
      
      return () => {
    
        clearTimeout(timeout); // Clear the timeout if the component unmounts or showDeleteDataImage changes
      };
    }
    console.log(defaultOptions2,"This is deafult");
  }, [showDeleteDataImage]);
 
   const handleDeleteConfirmation = async () => {
     try {
       // Make a POST request to the appropriate API endpoint based on deleteType
       const apiUrl = process.env.REACT_APP_API_URL;
       const userData = JSON.parse(localStorage.getItem('userData'));
       const userId = userData.id;
 
       let endpoint = '';
   

       if (deleteType === 'college') {
         endpoint = `${apiUrl}/del_student_college_appliedfor_form_data`;
        
       } else if (deleteType === 'highschool') {
         endpoint = `${apiUrl}/del_student_high_school_form_data`;
         
       }
       else if (deleteType === 'honours') {
        endpoint = `${apiUrl}/del_student_honours_form_data`;
        
      }
      else if (deleteType === 'testing') {
        endpoint = `${apiUrl}/del_student_testing_form_data`;
        
      }
      else if (deleteType === 'activity') {
        endpoint = `${apiUrl}/del_student_activities_form_data`;
        
      }
      else if (deleteType === 'experience') {
        endpoint = `${apiUrl}/del_student_work_experience_form_data`;
        
      }
      else if (deleteType === 'summary') {
        endpoint = `${apiUrl}/del_student_personal_form_form_data`;
        
      }
       if (endpoint) {
         const response = await fetch(endpoint, {
           method: 'post',
           body: JSON.stringify({ userId, formid: deleteId }),
         });
 
         if (response.status === 200) {
          setCollegeId(deleteId);
           const responseData = await response.json();
           const message = responseData.message;
           console.log(message);
           // Handle success or show a notification
         }
       }
     } catch (error) {
       // Handle network errors or other exceptions
       console.error('Delete failed:', error);
     }
     setShowDeleteDataImage(true)
    //  setOpenClick(false);
     setShowButtons(false)
   };
      const navigate = useNavigate();
      const [selectedCollegeId, setSelectedCollegeId] = useState('');
      const [selectedHighschoolId, setSelectedHighschoolId] = useState('');
      const [selectedPersonalEssayId, setSelectedPersonalEssayId] = useState('');
      const [selectedTestingId, setSelectedTestingId] = useState('');
      const [selectedHonoursId, setSelectedHonoursId] = useState('');
      const [selectedActivityId, setSelectedActivityId] = useState('');
      const [selectedExperienceId, setSelectedExperienceId] = useState('');

  
      const handleEditClickActivities = (id) => {
        setSelectedActivityId(id); // Set the selected college id in the state
        console.log(setSelectedActivityId,"selectedTestingId")
      };
      const handleEditClickTesting = (id) => {
        setSelectedTestingId(id); // Set the selected college id in the state
        console.log(selectedTestingId,"selectedTestingId")
      };

      const handleEditClickHonours = (id) => {
        setSelectedHonoursId(id); // Set the selected college id in the state
        console.log(setSelectedHonoursId,"selectedTestingId")
      };
      const handleEditClickHighschool = (id) => {
        setSelectedHighschoolId(id); // Set the selected college id in the state
        
      };
      const handleEditClickExperience = (id) => {
        setSelectedExperienceId(id); // Set the selected college id in the state
        
      };
      const handleEditClickSummary = (id) => {
        setSelectedPersonalEssayId(id); // Set the selected college id in the state
        
      };
      // Function to handle clicking the edit icon
      const handleEditClick = (id) => {
        setSelectedCollegeId(id); // Set the selected college id in the state
        
      };
      useEffect(() => {
      
        if(selectedCollegeId){
        
         navigate(`/Student_colleges?id=${selectedCollegeId}`);
        }
        else if(selectedHighschoolId){
          navigate(`/Student_high_school?id=${selectedHighschoolId}`);
        }
        else if(selectedPersonalEssayId){
          navigate(`/Student_summary?id=${selectedPersonalEssayId}`);
        }
        else if(selectedHonoursId){
          navigate(`/Student_Honors?id=${selectedHonoursId}`);
        }
        else if(selectedTestingId){
          navigate(`/Student_Testing?id=${selectedTestingId}`);
        }
        else if(selectedActivityId){
          navigate(`/Student_activities?id=${selectedActivityId}`);
        }
        else if(selectedExperienceId){
          navigate(`/Student_work_experience?id=${selectedExperienceId}`);
        }
      }, [selectedCollegeId,selectedHighschoolId,selectedPersonalEssayId,selectedTestingId,selectedHonoursId,selectedActivityId,selectedExperienceId]);
  
      const [activeComponent, setActiveComponent] = useState('collaborator_home');
      const [selectedMessage, setSelectedMessage] = useState(null);
      const [messages, setMessages] = useState([]);
    const [collaboratorschatusers, setcollaboratorschatusers] = useState([]);
  
    const handleItemClick = (message) => {
      // Update the badge number to 0 when a message is clicked
      message.badgenumber = 0;
      console.log(message, "new id message");
      setSelectedMessage(message);
    };
    const states = [
      { code: "AL", name: "Alabama" },
      { code: "AK", name: "Alaska" },
      { code: "AZ", name: "Arizona" },
      { code: "AR", name: "Arkansas" },
      { code: "CA", name: "California" },
      { code: "CO", name: "Colorado" },
      { code: "CT", name: "Connecticut" },
      { code: "DE", name: "Delaware" },
      { code: "FL", name: "Florida" },
      { code: "GA", name: "Georgia" },
      { code: "HI", name: "Hawaii" },
      { code: "ID", name: "Idaho" },
      { code: "IL", name: "Illinois" },
      { code: "IN", name: "Indiana" },
      { code: "IA", name: "Iowa" },
      { code: "KS", name: "Kansas" },
      { code: "KY", name: "Kentucky" },
      { code: "LA", name: "Louisiana" },
      { code: "ME", name: "Maine" },
      { code: "MD", name: "Maryland" },
      { code: "MA", name: "Massachusetts" },
      { code: "MI", name: "Michigan" },
      { code: "MN", name: "Minnesota" },
      { code: "MS", name: "Mississippi" },
      { code: "MO", name: "Missouri" },
      { code: "MT", name: "Montana" },
      { code: "NE", name: "Nebraska" },
      { code: "NV", name: "Nevada" },
      { code: "NH", name: "New Hampshire" },
      { code: "NJ", name: "New Jersey" },
      { code: "NM", name: "New Mexico" },
      { code: "NY", name: "New York" },
      { code: "NC", name: "North Carolina" },
      { code: "ND", name: "North Dakota" },
      { code: "OH", name: "Ohio" },
      { code: "OK", name: "Oklahoma" },
      { code: "OR", name: "Oregon" },
      { code: "PA", name: "Pennsylvania" },
      { code: "RI", name: "Rhode Island" },
      { code: "SC", name: "South Carolina" },
      { code: "SD", name: "South Dakota" },
      { code: "TN", name: "Tennessee" },
      { code: "TX", name: "Texas" },
      { code: "UT", name: "Utah" },
      { code: "VT", name: "Vermont" },
      { code: "VA", name: "Virginia" },
      { code: "WA", name: "Washington" },
      { code: "WV", name: "West Virginia" },
      { code: "WI", name: "Wisconsin" },
      { code: "WY", name: "Wyoming" },
      { code: "PR", name: "Puerto Rico" },
      { code: "GU", name: "Guam" },
      { code: "VI", name: "U.S. Virgin Islands" },
      { code: "AS", name: "American Samoa" },
      { code: "MP", name: "Northern Mariana Islands" },
      { code: "INTL", name: "International" }
    ];

    const getStateFullName = (stateCode) => {
      const state = states.find((s) => s.code === stateCode);
      return state ? state.name : '';
    };
 
    const handleSignOut = () => {
      // Clear user data from localStorage
      localStorage.removeItem('userData');
      
      navigate(`/signup?type=collaborator`);
    };
    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };
    const handleCloseSnackbar2 = () => {
      setSnackbarOpen2(false);
    };
  return (
    <div >
    <Topbar/>
      
      <Grid container 
      gap="20px"
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      className='Student_Grid_main_profile'
     >
   <Grid item

    lg={8.5}
    xl={9.7}
    xs={12}
    sm={12}
    md={8.5}
    className="Student_Grid_item_custom"
   
    >
        <Grid item
         className="Student_GridItemCustom2">
 
 <Box
  className="Student_BoxCustom"
 
>
  <Box
   className="Student_BoxCustom_main"
   sx={{
    
    width:"-webkit-fill-available",
    backgroundImage:  backgroundurlimagefinal ? `url(${backgroundurlimagefinal})` : `url(${bgimagecover})`,
     backgroundSize: 'cover',
     backgroundPosition: 'center',
    //  backgroundColor: image ? 'transparent' : 'black',
   
   }}
 
  >
     <div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000} // 3 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert severity="error" onClose={handleCloseSnackbar}>
          File size exceeds the limit of 5MB.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSnackbarOpen2}
        autoHideDuration={3000} // 3 seconds
        onClose={handleCloseSnackbar2}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert severity="error" onClose={handleCloseSnackbar2}>
          File size exceeds the limit of 2MB.
        </MuiAlert>
      </Snackbar>

      {/* Rest of your component */}
    </div>
    <label htmlFor="image-input" className="image-uploader">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        id="image-input"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        onClick={handleIconClick}
      />
      <CameraAltOutlinedIcon
        className="upload-icon"
       
      />
    </label>
  </Box>
  {selectedImage && (
                              <div>
                               <Dialog open={isCropDialogOpen} onClose={handleCloseDialogcrop} >
                               <DialogTitle>Crop Image
                               <IconButton
              aria-label="close"
              onClick={handleCloseDialogcrop}
              style={{ position: 'absolute', top: 5, right: 5 }}
            >
              <CloseIcon />
            </IconButton>
                               </DialogTitle>
                               <DialogContent>
                                 <Cropper
                                   ref={cropperRef}
                                   src={selectedImage}
                                   style={{ height: "80%", width: "80%", }}
                                   aspectRatio={1}
                                   guides={true}
                                   dragMode="none"
                                   zoomOnWheel={false}
                                 />
                                 <div style={{display:"flex",justifyContent:"center"}}>
                                 <Button variant="contained" sx={{marginTop:"16px",justifyContent:"center"}} onClick={handleCrop}>Crop Image</Button></div>
                               </DialogContent>
                             </Dialog>
                           </div>
                
                )}
  <div>
      <input
      ref={fileInputRef2}
        type="file"
        accept="image/*"
        id="image-input2"
        onChange={handleImageSelection}
        style={{ display: 'none'}}
      />

      <div onClick={() => document.getElementById('image-input2').click()} style={{cursor:'pointer'}}>
        <Avatar
          className="AvatarCustom"
          alt="Cindy Baker"
          src={selectedImage || laravelUrlfinal}
        
        />
      </div>
    </div>
</Box>

<div className='Edit_icon_profile' >

<  ModeEditOutlineOutlinedIcon sx={{color:'#58595B'}} onClick={handleprofileView}/>
</div>
   <Box className="Student_profile_view_mainDiv2" >
 
   <typography className="Student_Profile_view_welcome"> {userData.legal_name}</typography>
   <typography className="Profile_view_desc">{userData.Headline || "Applying to college the smart way"}</typography>
   
  <Box className="Student_Complete_profile">
  {HighSchooldata.map((item, index) => (
    <>
  <typography key={index} className='Profile_view_location2'>
    <SchoolIcon sx={{ height: "15px", width: "15px" }} />
    {item.hight_school_name}
  </typography>
  <Divider orientation="vertical" flexItem style={{ backgroundColor: '#006CB7' }} />

   </>
))}

    <typography className='Profile_view_date2'><CalendarMonthIcon sx={{height:"15px",width:"15px"}}/>joined {formattedJoinDate}</typography>
  </Box>
  {/* <Link  to="/signup?type=collaborator" className='feature_maindiv_Link'> */}
   <Button onClick={handleSignOut}  className='View_as_collaborator' variant='contained'  sx={{marginTop:"30px"}}>become a collaborator</Button>
   {/* </Link> */}
   </Box>
   </Grid>
   {screenSizeGreaterThan680 && (
    
  <>
  {membership === 0 && (
      
   <Grid item 
   sx={{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
      backgroundColor: "#FFF2D8",
     
      padding: "20px",
      borderWidth: '1px',
      borderColor: '#FBA900',
      borderStyle: 'solid', // Add this line to specify the border style
   }}
>
  <Box sx={{ display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',textAlign:'center'}}>
        <div>
    
    <img src={Premium} alt='Icon1'/>
    </div>
     
   <div style={{paddingBottom:'20px',paddingTop:'20px',display:'flex',flexDirection:'column'}}>
        <typography className='Premium_text_main'>Go Premium</typography>
  
        <typography className='Premium_text'>Explore Colleges</typography>
        <typography className='Premium_text'>View real applications</typography>
        <typography className='Premium_text'>Read admission essays</typography>
        <typography className='Premium_text'>Message Collaborators</typography>
        </div>
        <div style={{width:'100%'}}>
        <Link to='/Student_pricing'>
        <Button
        variant="outlined"
        color="warning"
        size="small"
        fullWidth
      >
      UPGRADE TO PREMIUM
      </Button>
      </Link>
        </div>
   

  </Box>
</Grid>)}
</>  
 
    
   )}
<Grid item
className="Student_GridItemCustom">
    <Box
     className="Student_colleges_maindiv">

   
  <Box
   className="Student_colleges_header">
  <typography className="Student_colleges_interested_text" style={{ fontWeight: '600', fontSize: '20px' }}>
  Colleges Interested In</typography>

    <Box
    className="Student_colleges_addicon">
    {Collegesdata && Collegesdata.length > 0 ? (
     <Link to={`/Student_colleges`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link> 
        ):(null)}
    </Box>
  </Box>
  {/* <Dialog
        open={openClick}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the  {confirmationText} data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button   onClick={handleDeleteConfirmation}>Yes</Button>
          <Button onClick={handleCloseDialog} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog> */}
     <Dialog
        open={openClick}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
       <div style={{ padding: "30px", }}>
    {showDeleteDataImage ? (
    <DialogContent>
    <Lottie options={defaultOptions2} height={200} width={200}   sx={{
'@media (max-width: 600px)': {
height: 130,
width: 130,
},
}}
/>

 
  <DialogContentText id="alert-dialog-description">
  <typography className="delete_confirm">Deleted !</typography>
  </DialogContentText>
</DialogContent>
    ) : (
      <DialogContent>
        
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
          <img src={deletedata} alt="deletedata" style={{ width: "80px", height: "80px" }} />
        </div>
        <typography className="delete_confirm">Confirm Deletion</typography>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the {confirmationText} data?
        </DialogContentText>
      </DialogContent>
    )}
    {showButtons && (
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{
            background: "var(--grey-300, #E0E0E0)",
            color: "black",
            width: "45%",
            boxShadow: "none",
            "&:hover": {
              background: "var(--grey-300, #E0E0E0)",
              color: "black",
            },
          }}
          variant="contained"
          onClick={handleCloseDialog}
          autoFocus
        >
          No, Cancel
        </Button>
        <Button
          sx={{
            background: "var(--error, #D32F2F)",
            width: "45%",
            boxShadow: "none",
            "&:hover": {
              background: "var(--error, #D32F2F)",
              color: "white",
            },
          }}
          variant="contained"
          onClick={handleDeleteConfirmation}
        >
          Yes, Delete
        </Button>
      </DialogActions>
    )}
  </div>
      </Dialog>
  {Collegesdata.length > 0 ? (
  // Render content related to the data
  
  Collegesdata && Collegesdata.map((college, index) => {
    const majorData = typeof college.major === 'string' ? JSON.parse(college.major) : [];
    
    // Check if majorData is an array before using map
    const majorTitles = Array.isArray(majorData)
      ? majorData.map((major) => major.title).join('  | ')
      : '';
      const collegeNamecollaborator = JSON.parse(college.collegeName); // Parse the JSON and access the first item
    const Collegenameadded = collegeNamecollaborator ? collegeNamecollaborator.title : ""; // Access the "title" property 

 
    return (
      <>
  <div className='Student_Colleges_DivCustom_later'>
  <div key={index} className="Student_Colleges_DivCustom">
    <div className="Student_Colleges_DivCustom2">
      <div>
        <Avatar className='student_div_Avatar_img' src={collegeimage}></Avatar>
      </div>
      <div className="Student_Colleges_DivCustom">
        <div className="Student_Colleges_DivCustom3">
          <div className="Student_Colleges_DivCustom4">
            <typography className="Student_Colleges_TypographyCustom">{Collegenameadded}</typography>
          </div>
          <typography className="Student_Colleges_TypographyCustom2">{getStateFullName(college.highSchoolLocation)}</typography>
          <typography className="student_major_title" >{majorTitles}</typography>
        </div>
        <typography className="Student_Colleges_TypographyCustom3">Supplementary Essay</typography>
        <typography className="student_colleges_Desc">
          {college.supplementary_eassy.length > 100
            ? `${college.supplementary_eassy.substring(0, 400)}...`
            : college.supplementary_eassy}
        </typography>
      </div>
    </div>
  </div>
  <div className="Student_colleges_DivCustom5">
  <ModeEditOutlineOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: "#58595B", cursor: "pointer" }}
    onClick={(e) => {
      e.preventDefault();
      handleEditClick(college.id);
    }}
  />

  <DeleteOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: "#D32F2F", cursor: "pointer" }}
    onClick={() => handlecollegeDelete(college.id, "college")}
  />
</div>

</div>

       {index < Collegesdata.length - 1 && (
        // Add Divider only if it's not the last item in the array
        <Divider variant="inset" className="Student_DividerCustom"  />
      )}
     </>
    );
  })
) : (
  // Render the default content when there is no data
  <Box className="Student_Colleges_BoxCustom5">
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} />Add the colleges you plan on applying to
      </typography>
    </Box>
    <Link to={`/Student_colleges`} sx={{ textDecoration: "none" }}>
      <Button variant="outlined"> + ADD College</Button>
    </Link>
  </Box>
)}

  </Box>
   </Grid>
 
   <Grid item
   className="Student_Highschool_GridItemCustom3"
   
   >
    <Box className="Student_Highschool_maindiv">

   
  <Box className="Student_Highschool_header">
    <typography className="TypographyCustom4">High School Education</typography>
    <Box className="Student_Highschool_addicon">
    {HighSchooldata && HighSchooldata.length > 0 ? (
     <Link to={`/Student_high_school`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/></Link>
        ):(null)}
    </Box>
  </Box>

  {HighSchooldata && HighSchooldata.length > 0 ? (
  // Render content related to the data
  HighSchooldata && HighSchooldata.map((item, index) => {
    // Render the data here as needed
    return (
      <>
    
    <div className='Student_Highschool_DivCustom_later2' key={index}>
  <div className="Student_Highschool_DivCustom6">
    <Avatar className='student_div_Avatar_img'   src={Highschoolimage}></Avatar>
    <div className="Student_Highschool_DivCustom7">
      <div className="Student_Highschool_DivCustom8">
        <typography className="Student_Highschool_TypographyCustom5">{item.hight_school_name}</typography>
      </div>
      {/* <typography className="degree_name">Degree: {item.degree_high}</typography> */}
      <div className="Student_Highschool_DivCustom9">
        <CalendarTodayIcon className='CalenderCustom' sx={{width:"15px",height:"15px",color:"#58595B"}} />
        <typography  className="date_highschool">
          {new Date(item.start_date).getFullYear()}&nbsp;&nbsp;-
        </typography>
        <typography className="date_highschool">
          {new Date(item.end_date).getFullYear()}
        </typography>
      </div>
    </div>
  </div>
  <div className='Student_colleges_DivCustom5' style={{ display: "flex", gap: "10px" }}>
  <ModeEditOutlineOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#58595B', cursor: 'pointer' }}
    onClick={(e) => {
      e.preventDefault();
      handleEditClickHighschool(item.id);
    }}
  />

  <DeleteOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#D32F2F', cursor: 'pointer' }}
    onClick={() => handlecollegeDelete(item.id, 'highschool')}
  />
</div>

</div>

         {index < HighSchooldata.length - 1 && (
          // Add Divider only if it's not the last item in the array
          <Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"49px"}}  />
        )}
          </>
    );
  })
) : (
  // Render the "Add High School" button if no data is present
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} /> Add your high school education here.
    </typography>
    <Link to={`/Student_high_school`} sx={{ textDecoration: "none" }}>
      <Button variant="outlined"> + ADD HIGH SCHOOL</Button>
    </Link>
  </Box>
)}



  </Box>
   </Grid>
   <Grid item
    className="Student_Honors_GridItemCustom4">
    <Box className="Student_Honors_maindiv">

   
  <Box className="Student_Honors_header">
    <typography className="TypographyCustom7">Honors</typography>
    <Box className="Student_Honors_addicon">
    {Honoursdata && Honoursdata.length > 0 ? (
     <Link to={`/Student_Honors`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/></Link>
        ):(null)}
    </Box>
  </Box>

  {Honoursdata && Honoursdata.length > 0 ? (
  // Render the awards/honours data
  Honoursdata && Honoursdata.map((item, index) => {
    const awards = JSON.parse(item.awards);
    if (awards && awards.length > 0) {
      return awards.map((award, awardIndex) => (
        <>
      <div key={awardIndex} >
  <div className="Student_Honors_DivCustom10">
    <Avatar className='student_div_Avatar_img'  src={honoursimage}></Avatar>
    <div className="Student_Honors_DivCustom11">
      <div className="Student_Honors_DivCustom12">
        <div className="Student_Honors_DivCustom13">
          <typography className="Student_Honors_TypographyCustom8">
            {award[`award-title-${awardIndex}`]}
          </typography>
        </div>
        <typography className="Students_Honours_level">Level: {award[`level-${awardIndex}`]}</typography>
      </div>
      <typography className="Students_Honours_desc">
        {award && award[`award-description-${awardIndex}`] && award[`award-description-${awardIndex}`].length > 400
          ? `${award[`award-description-${awardIndex}`].substring(0, 400)}...`
          : award && award[`award-description-${awardIndex}`]}
      </typography>
    </div>
    <div className='Student_colleges_DivCustom5' style={{ display: "flex", gap: "10px" }}>
  <ModeEditOutlineOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#58595B', cursor: 'pointer' }}
    onClick={(e) => {
      e.preventDefault();
      handleEditClickHonours(item.id);
    }}
  />

  <DeleteOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#D32F2F', cursor: 'pointer' }}
    onClick={() => handlecollegeDelete(item.id, 'honours')}
  />
</div>

  </div>
</div>

         {index < Honoursdata.length - 1 && (
          // Add Divider only if it's not the last item in the array
          <Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"49px"}}  />
        )}
        </>
      ));
    }
    return null;
  })
) : (
  // Render the "Add Honours" button if no data is present
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} /> Add any honors you have received
    </typography>
    <Link to={`/Student_Honors`} sx={{ textDecoration: "none" }}>
      <Button variant="outlined"> + ADD HONOR</Button>
    </Link>
  </Box>
)}

  </Box>
   </Grid>
   <Grid item
   className="GridItemCustom5"
   
 >
    <Box className="Student_Testing_maindiv">

   
  <Box className="Student_Testing_header">
    <typography className="student_testing_text" style={{ fontWeight: '600', fontSize: '20px' }}>Testing</typography>
    <Box className="Student_Testing_addicon">
    {Testingdata && Testingdata.length > 0 ? (
      <Link className="LinkCustom" to={`/Student_Testing`} ><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link>
        ):(null)}

    </Box>
  </Box>

  {Testingdata && Testingdata.length > 0 ? (
  // Render the testing data
  Testingdata && Testingdata.map((item, index) => {
    const testData = JSON.parse(item.testdata);
    return (
      <>
      {testData.map((test, testIndex) => (
        <div className='Student_testing_maindiv' key={testIndex}>
          <div className="Student_testing_div1">
            <Avatar className='student_div_Avatar_img'  src={testingimage}></Avatar>
            <div className="student_testing_divgap">
              <typography className="student_main_text">
                {test[`testname-${testIndex}`]}
              </typography>
              <typography className="Student_testing_attempts">
                Attempts: {test[`attempts-${testIndex}`]}
              </typography>
              <Box sx={{display:"flex",gap:"6px"}}>
                <CalendarTodayIcon className="CalendarTodayIconCustom"  sx={{width:"15px",height:"15px",color:"#58595B"}} />
                <typography>
                  {new Date(test[`start-date-${testIndex}`]).getFullYear()}
                </typography>
              </Box>
            </div>
           
          </div>
          <div className="Student_testing_maindiv2">
              <div className="Student_testing_divider"></div> {/* Vertical divider */}
              <div className="Student_testing_inerdiv">
                <typography className="Student_Testing_Obtained_text">Highest Score</typography>
                <Typography>
                  <span className='SpanCustom'>{test[`score-${testIndex}`]}</span>
                </Typography>
              </div>
            </div>
            <div className='Student_colleges_DivCustom5' style={{ display: "flex", gap: "10px" }}>
  <ModeEditOutlineOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#58595B', cursor: 'pointer' }}
    onClick={(e) => {
      e.preventDefault();
      handleEditClickTesting(item.id);
    }}
  />

  <DeleteOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#D32F2F', cursor: 'pointer' }}
    onClick={() => handlecollegeDelete(item.id, 'testing')}
  />
</div>

        </div>
        
      ))}
        {index < Testingdata.length - 1 && (
            // Add Divider only if it's not the last item in the array
            <Divider className="DividerCustom3" variant="inset" />
          )}
    </>
    
    );
  })
) : (
  // Render the "Add Testing" button if no data is present
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} /> Add your SAT/ACT/AP scores.
    </typography>
    <Link to={`/Student_Testing`} sx={{ textDecoration: "none" }}>
      <Button variant="outlined"> + ADD TEST</Button>
    </Link>
  </Box>
)}



  </Box>
   </Grid>
   
   <Grid item
   
   className="GridItemCustom6">
    <Box className="Student_Activities_maindiv">

   
  <Box className="Student_Activities_header">
    <typography className="TypographyCustom12">Activities</typography>
    <Box className="Student_Activities_addicon">
    {Activitiesdata && Activitiesdata.length > 0 ? (
      <Link to={`/Student_activities`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link>
        ):(null)}
    </Box>
  </Box>
  <div className='Student_Activities_display' style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}>
  {Activitiesdata && Activitiesdata.length > 0 ? (
  // Render the extra-curricular activities data
  Activitiesdata && Activitiesdata.map((activity, index) => {
    const sportsActivities = JSON.parse(activity.sports_activities);

    return (
      <>
      {sportsActivities.map((item, i) => (
        <div className="DivCustom20" key={i}>
          <div className="DivCustom21">
            <div className="DivCustom22">
              <div className="DivCustom23">
                <Avatar  className='AvatarCustom2' src={sportimage}></Avatar>
              </div>
              <div className="DivCustom23">
                <typography className="TypographyCustom13">
                  {item[`high-school-name-${i}`]}
                </typography>
                <typography className='Personal_sports_text3'>{item[`participated-as${i}`]}</typography>
                {/* <typography className="TypographyCustom14">
                  Level {item[`high-activities-select-${i}`]}
                </typography> */}
                          <Chip
  label={`${item[`high-activities-select-${i}`]} `}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px"}}
/>
<div>


<typography  className='Personal_text2'>{item[`description-${i}`].length > 10
                ? `${item[`description-${i}`].substring(0, 70)}...`
                : item[`description-${i}`]}</typography>
</div>
              </div>
            </div>
          </div>
          <div className='Student_colleges_DivCustom5' style={{ display: "flex", gap: "10px" }}>
  <ModeEditOutlineOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#58595B', cursor: 'pointer' }}
    onClick={(e) => {
      e.preventDefault();
      handleEditClickActivities(activity.id);
    }}
  />

  <DeleteOutlinedIcon
    className='Mui_edit_icon Text_decoration'
    sx={{ color: '#D32F2F', cursor: 'pointer' }}
    onClick={() => handlecollegeDelete(activity.id, 'activity')}
  />

  {index % 2 === 0 && index < Activitiesdata.length && (
    <div className="DividerCustom4" style={{ marginRight: "20px", marginLeft: "20px" }}></div>
  )}
</div>

        </div>
      ))}
    </>
    
    );
  })
) : (
  // Render the default content when there is no data
  <Box  className="BoxCustom19">
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} /> Add any extracurricular activities you participated in
      </typography>
    </Box>
    <Link to={`/Student_activities`} sx={{ textDecoration: "none" }}>
      <Button variant="outlined"> + ADD ACTIVITY</Button>
    </Link>
  </Box>
)}

</div>


  </Box>
   </Grid>
     
   <Grid item
   
   className="GridItemCustom6">
    <Box className="Student_Activities_maindiv">

   
  <Box className="Student_Activities_header">
    <typography className="TypographyCustom12">Work Experiences</typography>
    <Box className="Student_Activities_addicon">
    {Experiencedata && Experiencedata.length > 0 ? (
      <Link to={`/Student_work_experience`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link>
        ):(null)}
    </Box>
  </Box>
  {/* <div className='Student_Activities_display' style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}> */}
  {Experiencedata && Experiencedata.length > 0 ? (
  // Render the extra-curricular activities data
  Experiencedata && Experiencedata.map((activity, index) => {
    const work_experience = JSON.parse(activity.work_experience);

    return (
      <>
        <div className='collaborator_view_experience_div' key={index} style={{display:"flex",justifyContent:"space-between"}}>
        {work_experience.map((item, i) => (
          <div key={i} >
            <div style={{display:"flex",flexDirection:"row",gap:'15px'}}>
            <Avatar  src={Experiance_icon} />
        <div style={{display:"flex",flexDirection:"row",gap:10}}>
     
<div style={{display:"flex",flexDirection:"column",gap:10}}>
            <typography  className='Personal_text_main'> {item[`Title-${i}`]}</typography>
            <typography style={{ fontWeight: '600', fontSize: '16px' }}>{item[`organization-name-${i}`]} - {item[`job-type-${i}`]}</typography>
            <Box>
           <CalendarTodayIcon sx={{height:"15px",width:"15px",color:"#2E7D32"}}/> <typography className="workDate">
  {new Date(item[`start-date-${i}`]).toLocaleString('default', { month: 'short' })}{' '}
  {new Date(item[`start-date-${i}`]).getFullYear()} -{' '}
  {item[`options-${i}`] !== 'yes' ? (
  <>
    {new Date(item[`end-date-${i}`]).toLocaleString('default', { month: 'short' })}{' '}
    {new Date(item[`end-date-${i}`]).getFullYear()}
  </>
) : (
  'Present' // or any text you want to display when `item[`options-${i}`]` is 'no'
)}
</typography>
            </Box>
  <typography className="job_text">Job Description:</typography>
<typography  className='Personal_text2'>{item[`job-description-${i}`]}</typography>

            </div>
          
            </div>
        
            </div>
          
          </div>
        ))}
       <div className='Student_colleges_DivCustom5' style={{ display: "flex" ,gap:"10px"}}>
            <Link
              className="LinkCustom2"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleEditClickExperience(activity.id);
              }}
            >
              <ModeEditOutlineOutlinedIcon  className='Mui_edit_icon' sx={{ color: '#58595B' }} />
            </Link>
            <Link
              to="#"
              className='Text_decoration'
              onClick={() => handlecollegeDelete(activity.id, 'experience')}
            >
              <DeleteOutlinedIcon  className='Mui_edit_icon' sx={{ color: '#D32F2F' }} />
            </Link>
            {/* {index % 2 === 0 && index < Activitiesdata.length  && (
  <div className="DividerCustom4" style={{ marginRight: "20px", marginLeft: "20px" }}></div>
)} */}
          </div>
      </div>
      {index < Experiencedata.length - 1 && (
          // Add Divider only if it's not the last item in the array
          <Divider className="DividerCustom2" variant="inset"/>
        )}
    </>
    
    );
  })
) : (

  <Box  className="BoxCustom19">
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} />Add any work experience you have
      </typography>
    </Box>
    <Link to={`/Student_work_experience`} sx={{ textDecoration: "none" }}>
      <Button variant="outlined">+ Add Experience</Button>
    </Link>
  </Box>
)}

{/* </div> */}


  </Box>
   </Grid>
   <Grid item
   className="GridItemCustom7">
   <Box className="BoxCustom20">

   
  

{Summarydata.length > 0 ? (

<div >
 
  {Summarydata.map((summary, index) => (
    <div key={index} >
       <Box className="BoxCustom21">
  <typography className="TypographyCustom9">Personal Essay</typography>
  <div className='Student_colleges_DivCustom5'  style={{display:"flex",gap:"10px"}}>

  
  <Link
     
     to="#"
     className='Text_decoration'
     onClick={(e) => {
       e.preventDefault();
       handleEditClickSummary(summary.id);
     }}
   >
     <ModeEditOutlineOutlinedIcon  className='Mui_edit_icon' sx={{color:'#58595B'}}/>
   </Link> 
   <Link
to="#"
className='Text_decoration'
onClick={() => handlecollegeDelete(summary.id, 'summary')}
><DeleteOutlinedIcon  className='Mui_edit_icon' sx={{color:'#D32F2F'}}/></Link>
</div>
</Box>
{summary.personal_eassay ? (
        <typography className="Summary_desc">
          {showFullSummary
            ? summary.personal_eassay
            : summary.personal_eassay.length > 100
            ? `${summary.personal_eassay.substring(0, 600)}...`
            : summary.personal_eassay}
          {summary.personal_eassay.length > 100 && (
            <span className="ViewMoreLink" onClick={handleViewMore}>
              {showFullSummary ? '  View Less' : '  View More'}
            </span>
          )}
        </typography>
      ) : (
        <typography className="NoSummaryMessage">No summary available</typography>
      )}
    
   
    </div>
  ))}
</div>
) : (

<Box className="BoxCustom22">
      <Box className="BoxCustom23">
  <typography className="TypographyCustom15">Personal Essay</typography></Box>
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} />Write your personal statement essay
    </typography>
  </Box>
  <Link to={`/Student_summary`}className='Text_decoration'>
    <Button variant="outlined"> + ADD ESSAY</Button>
  </Link>
</Box>
)}


</Box>

   </Grid>
  
   </Grid>
   <Grid item 
       lg={3.2}
       xl={2.13}
       xs={5.3}
       sm={5}
       md={3.2}
     sx={{backgroundColor:"transparent",padding:"4px"}}>
      {screenSizeLessThan680 && (
        <>
         {membership === null && (
         <Grid item 
   sx={{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
      backgroundColor: "#FFF2D8",
     
      padding: "20px",
      borderWidth: '1px',
      borderColor: '#FBA900',
      borderStyle: 'solid', // Add this line to specify the border style
   }}
>
  <Box sx={{ display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',textAlign:'center'}}>
        <div>
    
    <img src={Premium} alt='Icon1'/>
    </div>
     
   <div style={{paddingBottom:'20px',paddingTop:'20px',display:'flex',flexDirection:'column'}}>
        <typography className='Premium_text_main'>Go Premium</typography>
  
        <typography className='Premium_text'>Explore Colleges</typography>
        <typography className='Premium_text'>View real applications</typography>
        <typography className='Premium_text'>Read admission essays</typography>
        <typography className='Premium_text'>Message Collaborators</typography>
        </div>
        <div style={{width:'100%'}}>
        <Link to='/Student_pricing'>
        <Button
        variant="outlined"
        color="warning"
        size="small"
        fullWidth
      >
      UPGRADE TO PREMIUM
      </Button>
      </Link>
        </div>
   

  </Box>
</Grid>

         )}

     <Grid item 
   
   sx={{backgroundColor:"white",padding:"20px",marginTop:'20px'}}>
     <Box sx={{display:'flex',flexDirection:'column',paddingRight:'10px'}}>
         <typography className='message_text'>Messaging</typography>
         <TextField
   id="outlined-basic"
   label="Search Chats"
   name="Search Chats"
   variant="outlined"
   fullWidth
   size="small"
   InputLabelProps={{
     sx: {
       fontSize: '14px', // Set the desired font size for the label
     },
   }}
 />
 
       
 
      </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', cursor: 'pointer' }}>
   {collaboratorschatusers && collaboratorschatusers.map((message) => (
     <div
       key={message.id}
       className={activeComponent === 'collaboratorschatusers' && selectedMessage?.id === message.id ? 'active' : ''}
       onClick={() => {
         setActiveComponent('collaboratorschatusers');
         setSelectedMessage({ ...message, badgenumber: 0 }); // Set the selected message when clicked
 
         handleItemClick(message)
       }}
     >
       <CardHeader
         sx={{
           padding: '20px 0px 0px 0px',
         }}
         avatar={
           <Badge
             badgeContent={message.badgenumber}
             color="primary"
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
             }}
           >
             <Avatar
               alt={message.sender}
               src={message.senderpic}
               sx={{
                 width: '40px',
                 height: '40px',
                 borderRadius: '20px',
               }}
             />
           </Badge>
         }
         title={
           <span
             style={{
               fontSize: '14px',
               fontWeight: 'bold',
             }}
           >
             {message.sender}
           
           </span>
         }
         subheader={
           <span
             style={{
               fontSize: '12px',
             }}
           >
             {message.content}
           </span>
         }
       />
     </div>
   ))}
 </Box>
 
 </Grid>
 </>
)}
       </Grid>






      </Grid>
      </div>
  )
}

export default StudentCompleteProfile
