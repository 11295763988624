import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link,useNavigate } from 'react-router-dom';
import './Students.css'
import backgroundimage from "../../assets/img/bg-img.png"
import profilebg1 from "../../assets/img/profilebg.png"
import profile1 from '../../assets/img/profile.jpg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import mainlogo from '../../assets/img/mainwelcome.png'

import Collaborator_home from '../collaborator/collaborator_home';

import Messages from './Messages';

import Collaborator_students from '../collaborator/Collaborator_students';
import TextField from '@mui/material/TextField';
import Chatpic from '../../assets/img/_Avatar_.png';
import Premium from '../../assets/img/Rectangle.png'
import Chatpic1 from '../../assets/img/_Avatar_1.png';
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import Topbar from './Topbar';
import StudentHome from './StudentHome';
import SearchCollaborator from './SearchCollaborator';
import StudentCollaborator from './StudentCollaborator';
import StudentNotification from './StudentNotification';
import Messagerequest from './Messagerequest';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import bgimagecover from '../../assets/img/bgcover.svg';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import CircularProgress from '@mui/material/CircularProgress';
const StudentProfileview = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
    const [activeComponent, setActiveComponent] = useState('student_home');

    const [selectedMessage, setSelectedMessage] = useState(null); // Track the selected message
    const [messages, setMessages] = useState([]);
    const [collaboratorschatusers, setcollaboratorschatusers] = useState([]);
  
    const [loadinguser, setLoadinguser] = useState(true); 
    const [screenSizeLessThan680, setScreenSizeLessThan680] = useState(window.innerWidth > 680);

    useEffect(() => {
      function handleResize() {
        const isLessThan680 = window.innerWidth > 680;
        setScreenSizeLessThan680(isLessThan680);
      }
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const [membership, setMembership] = useState();
    useEffect(() => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
  
      try {
        fetch(`${apiUrl}/getuserdetailsforeditpage`, {
          method: 'post',
          body: JSON.stringify({ userId: userId }),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
             
            } else {
              throw new Error('Request failed');
            }
          })
          .then((data) => {
            console.log(data,"this is data");
           
            // Assuming 'membership' is the key for the membership value in the response data from your table
            const membershipValue = data.user.membership;
            setMembership(membershipValue);
            setLoadinguser(false);
             console.log(data.user.membership,"this is membership value");
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
          });
      } catch (error) {
        // Handle fetch-related errors here
        console.error(error);
      }
      function encodeAlphanumeric(id) {
        const encoded = btoa(id); // Using btoa to Base64 encode the ID
        // You can further process the encoded string if needed
        return encoded;
      } 
    }, []);
  
    useEffect(() => {
   
      fetchUsersFromApi();
    }, [activeComponent]);
  
    const fetchUsersFromApi = () => {
      // Fetch all users from the API
      // Replace 'api/users' with your actual API endpoint for users
      const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
      fetch(`${apiUrl}/get_profileformessage`, {
        method: 'post',
        body: JSON.stringify({ userId }), // Send the entire formData object as the body
        }) 
        .then((response) => response.json())
        .then((data) => {
          console.log(data.collaborators); 
          //setMessages(data.collaborators);
          setcollaboratorschatusers(data.collaborators);
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    };
    
    const navigate = useNavigate();
    
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate(`/login`);
      return null; 
    }
    const joinDate = new Date(userData.created_at);
    const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
    const profile1url = userData.avatarImage;
    const laravelUrl = profile1url ? profile1url.replace('/public/', '/storage/app/public/'): null;
    const laravelUrlfinal =laravelUrl? laravelUrl.replace('/public/storage/', '/public/'): null;
    console.log(laravelUrlfinal,"user image"); 
    const backgroundurl = userData.background_image;

    let backgroundurlimagefinal; // Initialize the variable

    if (backgroundurl) {
      const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/')|| null;
      backgroundurlimagefinal =backgroundurlimage? backgroundurlimage.replace('/public/storage/', '/public/'):null;
    } else {
      // Handle the case where backgroundurl is empty or null
      backgroundurlimagefinal = ''; // Set it to an empty string or any other appropriate default value
    }
  
    const handleItemClick = (message) => {
      // Update the badge number to 0 when a message is clicked
      message.badgenumber = 0;
      console.log(message, "new id message");
      setSelectedMessage(message);
    };
    
  return (
    <div >
    <Topbar/>
      
      <Grid container 
      gap={4}
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      className='Hidden_Student2'
      sx={{
       padding:"20px",
    height:"auto",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }} >
        <Grid className='main_menu_Student'  sx={{background: "rgba(255, 255, 255, 0.80)",
  backdropfilter: "blur(25px)",display:"flex",flexDirection:"row",justifyContent:"space-between"}} xs={12}sm={12}>
     <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'student_home' ? 'active' : ''}`}
                   to={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('student_home')}
                 >
                 <HomeOutlinedIcon/>
                
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'studentsss' ? 'active' : ''}`}
                   href={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('studentsss')}
                 >
                   <PeopleAltOutlinedIcon/>
                 
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'Messagesrequest' ? 'active' : ''}`}
                   to={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('Messagesrequest')}
                 >
                  <MailOutlineOutlinedIcon />
                 
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'notifications' ? 'active' : ''}`}
                   href={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('notifications')}
                 >
                    <NotificationsActiveOutlinedIcon />
                  
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'search_Collaborator' ? 'active' : ''}`}
                   href={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('search_Collaborator')}
                 >
                   <ScreenSearchDesktopOutlinedIcon />
                 
                 </Link>
  
   </Grid>
 
   {(screenSizeLessThan680 || activeComponent === 'student_home') && (
       <Grid item
       className='Hidden_student3'
      rowGap={3}
        lg={2}
        xl={2}
        xs={12}
        sm={3.5}
        md={2} sx={{backgroundColor:"transparent",display:"flex",flexDirection:"column",  width: '200px', // Adjust the width as needed
        position: 'sticky',
        top: 0,
        height: '100vh', // Full viewport height
         }} >
   <Grid item xs={false} 
     sx={{background: "rgba(255, 255, 255, 0.80)",
     backdropfilter: "blur(25px)",height:"50%",padding:"4px",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
       <Box className="profile-view-bg" sx={{      
        backgroundImage:  backgroundurlimagefinal ? `url(${backgroundurlimagefinal})` : `url(${bgimagecover})`,
        // backgroundColor: backgroundurlimagefinal ? 'transparent' : '#D9D9D9',
  }}>
            <Avatar
              className='Avatat_student'
               alt="Cindy Baker"
               src={laravelUrlfinal} // Make sure profile1 contains the correct image path
               sx={{
                border: '2px solid white', // Optional border
                width: "10vh", // Adjust the Avatar's width
                height: '10vh', // Adjust the Avatar's height
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                top:"70%",
               
              }}
             />
        </Box>
        <div style={{padding:"5px"}}></div>
   <Box className="Student_Profile-view" >
       <typography className='Student_Profile_view_text'>{userData.legal_name}</typography>
       <typography className='Student_Profile_view_location'><LocationOnIcon sx={{height:"15px",width:"15px"}} />{userData.city}</typography>
       <typography className='Student_Profile_view_date'><CalendarMonthIcon sx={{height:"15px",width:"15px"}}/>joined {formattedJoinDate}</typography>
       <Link to={'/student_complete_profile'}>
       <Button variant="outlined" ><typography className='Student_Button_one'>view Profile</typography></Button>
       </Link>
   </Box>
   
   </Grid>
   
   <Grid xs={0} sm={0}   className='Hidden_student'
               item
               sx={{ background: "rgba(255, 255, 255, 0.80)",
               backdropfilter: "blur(25px)", height: "50%", padding: "4px",display:"flex" }}
             >
               <Box className="Student_profile_view_Button_div">
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'student_home' ? 'active' : ''}`}
                   to={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('student_home')}
                 >
                 <HomeOutlinedIcon/>
                   Home
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'studentsss' ? 'active' : ''}`}
                   href={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('studentsss')}
                 >
                   <PeopleAltOutlinedIcon/>
                   Students
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'Messagesrequest' ? 'active' : ''}`}
                   to={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('Messagesrequest')}
                 >
                  <MailOutlineOutlinedIcon />
                   Messaging
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'notifications' ? 'active' : ''}`}
                   href={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('notifications')}
                 >
                    <NotificationsActiveOutlinedIcon />
                   Notifications
                 </Link>
                 <Link
                   className={`Student_Profile_view_Button ${activeComponent === 'search_Collaborator' ? 'active' : ''}`}
                   href={'#'}
                   style={{ textDecoration: "none" }}
                   onClick={() => setActiveComponent('search_Collaborator')}
                 >
                   <ScreenSearchDesktopOutlinedIcon />
                   Search Collaborator
                 </Link>
               </Box>
             </Grid>
   
       </Grid>
   )}
   {(screenSizeLessThan680 || activeComponent === 'student_home') && (
    <>
    {console.log('Membership Value:', membership)}
   {membership === null && (
         <Grid item xs={12} className='main_menu_Student' 
   sx={{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
      backgroundColor: "#FFF2D8",
      height: "50%",
      padding: "20px",
      borderWidth: '1px',
      borderColor: '#FBA900',
      borderStyle: 'solid', // Add this line to specify the border style
   }}
>
  <Box sx={{ display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',textAlign:'center'}}>
        <div>
    
    <img src={Premium} alt='Icon1'/>
    </div>
     
   <div style={{paddingBottom:'20px',paddingTop:'20px',display:'flex',flexDirection:'column'}}>
        <typography className='Premium_text_main'>Go Premium</typography>
  
        <typography className='Premium_text'>Explore Colleges</typography>
        <typography className='Premium_text'>View real applications</typography>
        <typography className='Premium_text'>Read admission essays</typography>
        <typography className='Premium_text'>Message Collaborators</typography>
        </div>
        <div style={{width:'100%'}}>
        <Link to='/Student_pricing'>
        <Button
        variant="outlined"
        color="warning"
        size="small"
        fullWidth
      >
      UPGRADE TO PREMIUM
      </Button>
      </Link>
        </div>
   

  </Box>
</Grid>
)}</>
   )}
   
       <Grid
             item
            
             lg={7.1}
             xl={7.3}
             xs={12}
             sm={4.3}
             md={7.1}
             sx={{
              flex: 1,
  overflowY: 'auto', // Allow it to expand to fill available space
            }}

            
           >
             {activeComponent === 'student_home' && <StudentHome/>}
             {activeComponent === 'collaboratorschatusers' && selectedMessage && (
               <Messages sender1={selectedMessage} />
             )}
   
             {activeComponent === 'studentsss' && <StudentCollaborator/>}
             {activeComponent === 'search_Collaborator' && <SearchCollaborator />}
             {activeComponent === 'notifications' && <StudentNotification/>}
             {activeComponent === 'Messagesrequest' && <Messagerequest/>}

             {/* {activeComponent === 'notifications' && <NotificationsComponent />} */}
           </Grid>
      
           <Grid item
         className='Student_message_maindiv'
      rowGap={3}
        lg={2}
        xl={2}
        xs={12}
        sm={2}
        md={2} sx={{backgroundColor:"transparent",display:"flex",flexDirection:"column",   width: '200px', // Adjust the width as needed
        position: 'sticky',
        top: 0,
        height: '100vh', // Full viewport height
         }} >
          {console.log('Membership Value:', membership)}
          {loadinguser ? (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>
) : (
          membership === null && (       
             <Grid item 
         className='Student_profile_paymentdiv'
   sx={{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
      backgroundColor: "#FFF2D8",
      height: "50%",
      padding: "20px",
      borderWidth: '1px',
      borderColor: '#FBA900',
      borderStyle: 'solid', // Add this line to specify the border style
   }}
>
  <Box sx={{ display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',textAlign:'center'}}>
        <div>
    
    <img src={Premium} alt='Icon1'/>
    </div>
     
   <div style={{paddingBottom:'20px',paddingTop:'20px',display:'flex',flexDirection:'column'}}>
        <typography className='Premium_text_main'>Go Premium</typography>
  
        <typography className='Premium_text'>Explore Colleges</typography>
        <typography className='Premium_text'>View real applications</typography>
        <typography className='Premium_text'>Read admission essays</typography>
        <typography className='Premium_text'>Message Collaborators</typography>
        </div>
        <div style={{width:'100%'}}>
        <Link to='/Student_pricing'>
        <Button
        variant="outlined"
        color="warning"
        size="small"
        fullWidth
      >
      UPGRADE TO PREMIUM
      </Button>
      </Link>
        </div>
   

  </Box>
</Grid>
)
)}
      {(screenSizeLessThan680 || activeComponent === 'Messagesrequest') && (
   <Grid item 
   className='Student_search_messagediv'
   xs={12}
     sx={{backgroundColor:"white", height: membership === 0 ? "50%" : "100%",padding:"30px",overflowX: 'auto',}}>
       <Box sx={{display:'flex',flexDirection:'column',paddingRight:'10px'}}>
           <typography className='Student_message_text'>Messaging</typography>
           <TextField
     id="outlined-basic"
     label="Search Chats"
     name="Search Chats"
     variant="outlined"
     fullWidth
     size="small"
     InputLabelProps={{
       sx: {
         fontSize: '14px', // Set the desired font size for the label
       },
     }}
   />
   
         
   
        </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', cursor: 'pointer' }}>
     {collaboratorschatusers && collaboratorschatusers.map((message,index) => (
       <div
         key={message.id}
         className={activeComponent === 'collaboratorschatusers' && selectedMessage?.id === message.id ? 'active' : ''}
         onClick={() => {
           setActiveComponent('collaboratorschatusers');
           setSelectedMessage({ ...message, badgenumber: 0 }); // Set the selected message when clicked
   
           handleItemClick(message)
         }}
       >
         <CardHeader
           sx={{
             padding: '20px 0px 0px 0px',
           }}
           avatar={
             <Badge
               badgeContent={message.badgenumber}
               color="primary"
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'right',
               }}
             >
               <Avatar
                 alt={message.sender}
                 src={message.senderpic}
                 sx={{
                   width: '40px',
                   height: '40px',
                   borderRadius: '20px',
                 }}
               />
             </Badge>
           }
           title={
            <typography
             className="message_text_secondary"
            >
              {index === 0 ? message.sender + ' *' : message.sender} 
            </typography>
          }
           subheader={
             <span
               style={{
                 fontSize: '12px',
               }}
             >
               {message.content}
             </span>
           }
         />
       </div>
     ))}
   </Box>
   
   </Grid>
      )}
   
       </Grid>

      </Grid>
      </div>
  )
}

export default StudentProfileview
