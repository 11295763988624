import React,{useState,useEffect}  from 'react'
import Grid from '@mui/material/Grid';
import Tobar from '../Tobar';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography, Tabs, Tab,InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation,useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import './profile.css'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Topbar as Topbar2 } from '../../Admin/Topbar';

const Honours = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const urlParams = new URLSearchParams(window.location.search);
  const userid = urlParams.get('userid');
  function encodeAlphanumeric(userid) {
    const encoded = btoa(userid); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  } 
  const[Type , setTypes]=useState()
  const[iddata,setDataId]=useState()
    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const isSmallScreen = useMediaQuery('(max-width:680px)');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  const location = useLocation();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={Type === 'admin' ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(iddata)}` : `/complete_profile`}
     
    >
      Profile
    </Link>,
 
    <Typography key="3" color="text.primary">
      Honors
    </Typography>
  ];
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [awardBoxes, setAwardBoxes] = useState([{}]);
    const [ErrorawardBoxes, setErrorawardBoxes] = useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [ErrorHighestdegreeyouearn, setErrorHighestdegreeyouearn] = useState('');
    const [ErrorCareerintrest, setErrorCareerintrest] = useState('');
    // const [showAlert, setShowAlert] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
  const [formData, setFormData] = useState({})

    const [honoursData, setHonoursData] = useState([]);
    const [formid, setformid] = useState('');
    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    // };
     
  useEffect(() => {
  
     
    const searchParams = new URLSearchParams(location.search);
    const selectedHonoursId = searchParams.get('id'); //change this const to form id 
    setformid(selectedHonoursId);
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;
    console.log('Honours ID:', selectedHonoursId); 
    const userid = urlParams.get('userid');
    setDataId(userid) 
    const type = urlParams.get('type');
  setTypes(type); // set the Type using setTypes
    if(selectedHonoursId !== ''){
      try {
      fetch(`${apiUrl}/get_honours_form_data`, {   // chgange this url 
        method: 'post',
        body: JSON.stringify({ formid:selectedHonoursId }), // pass the form id const here 
    })
    .then(response => {
        if (response.status === 200) {
            return response.json(); // Convert the response to JSON
        } else {
            throw new Error('Request failed');
        }
    })
    .then(data => {
      const Honours_Data = data.formdata[0];
     
      setHonoursData(data.formdata);                     
        console.log(data, "setHonoursData"); 
        setFormData({
          highest_degree_you_earn:Honours_Data.highest_degree_you_earn || '',
          career_intrest:Honours_Data.career_intrest || '',
       
        });
        const parsedTestData = JSON.parse(Honours_Data.awards || '[]');
        const formDataArray = parsedTestData.map((item, index) => ({
          'award-title': item[`award-title-${index}`] || '',
          'start-date': dayjs(item[`start-date-${index}`]).format('MM/DD/YYYY') || '',
          'level': item[`level-${index}`] || '',
          'award-description': item[`award-description-${index}`] || '',
          'issuing-organization': item[`issuing-organization-${index}`] || '',
          'grade-award': item[`grade-award-${index}`] || '',
         
        }));
     console.log(parsedTestData,"awards")
      setAwardBoxes(parsedTestData);
     console.log(formDataArray,"formDataArray,AWards")
    })
    .catch(error => {
        console.error('Data fetch failed:', error);
    });
} catch (error) {
    console.error('Data upload failed:', error);
}
    }

    
}, []); 
const handleHighestDegreeChange = (event) => {
  setFormData({
    ...formData,
    highest_degree_you_earn: event.target.value,
  });
};

// const handleCareerInterestChange = (event) => {
//   setFormData({
//     ...formData,
//     career_intrest: event.target.value,
//   });
// };

    const navigate = useNavigate(); // Initialize navigate
    const moveToNextTab = async (index) => {
      if (Type !== 'admin') {
      if (value === 0) {
        // Validate if any field is empty in awardBoxes
        const isAnyFieldEmpty = awardBoxes.some((box,index) => {
          // Define the fields that should not be empty
          const mandatoryFields = [
            `award-title-${index}`,
            // `issuing-organization-${index}`,
            `start-date-${index}`,
            `level-${index}`,
            `grade-award-${index}`,
            // `award-description-${index}`,
          ];
      
          // Check if any mandatory field is empty in any box
          return mandatoryFields.some((field) => !box[field]);
        });
      
        if (isAnyFieldEmpty) {
          setErrorawardBoxes('Title, Level(s) of Recognition, Issue Date, and Grade Level are all required.');
          return false;
        }
      
        // Check for duplicate data in awardBoxes (as in your initial code)
        const processedHonourData = preprocessData(awardBoxes);
        const isDuplicate = processedHonourData.some((current, index) =>
          processedHonourData.slice(index + 1).some((other) => {
            const currentValues = Object.values(current).sort().join('');
            const otherValues = Object.values(other).sort().join('');
            return currentValues === otherValues;
          })
        );
      
        if (isDuplicate) {
          setErrorawardBoxes('Duplicate data. Please enter unique values for each award box field.');
          return false;
        }
      
        // Check if at least one award box is present
        if (awardBoxes.length > 0) {
          setErrorawardBoxes('');
        } else {
          setErrorawardBoxes('Please add at least one Honour');
          return false;
        }
      }
      if (value === 1) {
        const highest_degree_you_earn = document.getElementsByName('highest_degree_you_earn')[0].value;
        const career_intrest = document.getElementsByName('career_intrest')[0].value;
        if (highest_degree_you_earn.trim() === '' && career_intrest.trim() === '') {
          setErrorHighestdegreeyouearn('Highest Degree  is required.');
          setErrorCareerintrest('Career Interest is required.');
          return false;
        } else {
          setErrorHighestdegreeyouearn('');
          setErrorCareerintrest('');
        }
        if (highest_degree_you_earn.trim() === '') {
          setErrorHighestdegreeyouearn('Highest Degree  is required.');
          return false;
        } else {
          setErrorHighestdegreeyouearn('');
        }
        if (career_intrest.trim() === '') {
          setErrorCareerintrest('Career Interest is required.');
          return false;
        } else {
          setErrorCareerintrest('');
        }
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
        try {
          setIsLoading(true);
          setOpen(true);
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/save_collaborator_awards`, {
            method: 'post',
            body: JSON.stringify({ awards: awardBoxes, userId, highest_degree_you_earn, career_intrest, formid }),
          });
          // setShowAlert(true);
          if (response.status === 201) {
          
            setShouldNavigate(true);
          } else {
            console.error('Data upload failed:', response.statusText);
          }
        } catch (error) {
          setIsLoading(false);
          setOpen(false);
          console.error('Data upload failed:', error);
        }
      } else {
        // Increment the value state to move to the next tab
        setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
      }
    }
    else if(Type == 'admin'){
      if (value === 0) {
        // Move to the next tab logic (if any)
        setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
      }
      else if (value === 1 ) {
        window.location.href = `/admin_Collaborator_profileview?id=${encodeAlphanumeric(iddata)}`;
        // setShouldNavigate(true); // Assuming this triggers navigation to a different page
      }
    }
    };
    const handleBackButton = () => {
      if (value === 0) {
        if (Type === 'admin') {
          // If on the first tab and Type is admin, navigate to admin profile view
          navigate(`/admin_Collaborator_profileview?id=${encodeAlphanumeric(iddata)}`);
        } else {
          // If on the first tab and Type is not admin, navigate to "/complete_profile" route
          navigate('/complete_profile');
        }
      } else {
        // Decrement the value state to move to the previous tab
        setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
      }
    };
    // const handleBackButton = () => {
    //   if (value === 0) {
    //     // If on the first tab, navigate to "/complete_profile" route
    //     navigate('/complete_profile');
    //   } else {
    //     // Decrement the value state to move to the previous tab
    //     setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
    //   }
    // };
    const addAnotherAwardBox = () => {
    
      const lastRecommender = awardBoxes[awardBoxes.length - 1];
    
      // Check if the last recommender is not empty
      const isLastRecommenderEmpty = Object.values(lastRecommender).every(
        (value) => value === undefined || value === ''
      );
    
      if (!isLastRecommenderEmpty) {
        // Check for duplicates
        const isDuplicateRec = awardBoxes.slice(0, awardBoxes.length - 1).some((existingRecommender) => 
          Object.entries(existingRecommender).every(
            ([key, val]) => lastRecommender[key] === val
          )
        );
    
        if (!isDuplicateRec) {
          // Add a new empty recommender
          setAwardBoxes([...awardBoxes, {}]);
          setErrorawardBoxes('');
        } else {
          setErrorawardBoxes('Duplicate data. Please enter unique values for each award.');
        }
      } else {
        setErrorawardBoxes('Please complete all fields before adding another Honor.');
      }
    };
    const preprocessData = (data) => {
      return data.map((item) => {
        const processedItem = {};
        Object.keys(item).forEach((key) => {
          const processedKey = key.replace(/-\d+$/, ''); // Remove the index suffix
          processedItem[processedKey] = item[key];
        });
        return processedItem;
      });
    };
    // const addAnotherAwardBox = () => {
    //   setAwardBoxes([...awardBoxes, {}]);
    // };
    const handleChanges2 = (event, index) => {
      const { name,value } = event.target;
      const updatedawardBoxes = [...awardBoxes];
      let errorMessage = '';

        // Validation based on the field name
        if (name.includes('award-title-')) {
          // Allow only alphabets, spaces, and limit to 75 characters
          const sanitizedValue = value.replace(/[<>\\]/g, '').slice(0, 75);
          updatedawardBoxes[index][name] = sanitizedValue;

          // Check for specific error message
          if (sanitizedValue.length === 75) {
            errorMessage = 'Exceeded the limit of 75 characters for Title.';
          }
        } else if (name.includes('issuing-organization-')) {
          // Allow alphanumeric characters, spaces, periods, hyphens, and limit to 75 characters
          const sanitizedValue = value.replace(/[<>\\]/g, '').slice(0, 75);
          updatedawardBoxes[index][name] = sanitizedValue;

          // Check for specific error message
          if (sanitizedValue.length === 75) {
            errorMessage = 'Exceeded the limit of 75 characters for Issuing Organization.';
          }
        } else if (name.includes('grade-award-')) {
          // Allow alphanumeric characters and limit to 15 characters
          const sanitizedValue = value.replace(/[<>\\]/g, '').slice(0, 15);
          updatedawardBoxes[index][name] = sanitizedValue;

          // Check for specific error message
          if (sanitizedValue.length === 15) {
            errorMessage = 'Exceeded the limit of 15 characters for Grade Level.';
          }
        } else if (name.includes('award-description-')) {
          // Allow alphanumeric characters, spaces, periods, hyphens, and limit to 150 characters
         // const sanitizedValue = value.replace(/[^a-zA-Z0-9\s.-]/g, '').slice(0, 150);
         const words = value.split(/\s+/); // Split the input into words
          const sanitizedValue = words.slice(0, 15000).join(' '); 
          const wordCount = sanitizedValue.split(/\s+/).length;
          updatedawardBoxes[index][name] = sanitizedValue;

          // Check for specific error message
          if (wordCount=== 15000) {
            errorMessage = 'Exceeded the limit of 150 words for Award Description.';
          }
        }else{
          updatedawardBoxes[index][name] = value;
        }

      //updatedawardBoxes[index][name] = value;
      //alert(errorMessage);
      console.log(updatedawardBoxes,"uodated award boxes");
      setAwardBoxes(updatedawardBoxes);
      setErrorawardBoxes(errorMessage);
    };
    const handleDateChange = (date, fieldName, index) => {
      const updatedawardBoxes = [...awardBoxes];
      updatedawardBoxes[index][fieldName] = date; // Assuming 'fieldName' is the date field name
      console.log(updatedawardBoxes);
      setAwardBoxes(updatedawardBoxes);
    };

    useEffect(() => {
      if (shouldNavigate) {
        const navigateTimer = setTimeout(() => {
          // Navigate to the desired page after 2 seconds
          // setShowAlert(false);
          setIsLoading(true);
          setOpen(true);
          navigate('/complete_profile');
        }, 3000);
  
        // Clean up the timer to avoid memory leaks
        return () => clearTimeout(navigateTimer);
      }
    }, [shouldNavigate]);

    const handleCareerInterestChange = (event) => {
      const value = event.target.value;
    
      // Allow only alphabets, spaces, periods, hyphens, and limit to 150 characters
      const words = value.split(/\s+/); // Split the input into words
          const sanitizedValue = words.slice(0, 15000).join(' '); 
          const wordCount = sanitizedValue.split(/\s+/).length;
      //const sanitizedValue = value.replace(/[^a-zA-Z\s.-]/g, '').slice(0, 150);
    
      // Update the state with the sanitized value
      setFormData({ ...formData, career_intrest: sanitizedValue });
    
      // Check for specific error message
      if (wordCount === 15000) {
        setErrorCareerintrest('Exceeded the limit of 150 words for Career Interest.');
      } else {
        setErrorCareerintrest(''); // Clear the error if within the limit
      }
    };
    const deleteCourse2 = (indexToDelete) => {
      const updatedCourses = awardBoxes.filter((_, index) => index !== indexToDelete);
      setAwardBoxes(updatedCourses);
    };

  return (
    <div>
     {Type === 'admin' ? <Topbar2 /> : <Tobar />}
    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{ background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "20px" }}
      >
              <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
          <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
        
  
        <Box className="Test_BoxDiv Test_paddingBox2">
        <Box className="Collaborator_Honors_form" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value}  aria-label="basic tabs example"
            sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-between', // This adds space between the tabs
                },
                ...(isSmallScreen && {
                  '& .css-heg063-MuiTabs-flexContainer': {
                    overflow: 'scroll',
                    // Add other styles you want to apply to .css-heg063-MuiTabs-flexContainer
                  }, }),
              }}>
              <Tab label="HONORS" className="Collaborator_honors_tab1" sx={{textAlign:"center",width:"100%"}} />
              <Tab label="FUTURE PLANS" className="Collaborator_honors_tab2" sx={{textAlign:"center",width:"100%"}} />
             
        
            </Tabs>
          </Box>
          <div  className='Collaborator_tab_toppadding' role="tabpanel"  hidden={value !== 0}>
        
                {awardBoxes.map((box, index) => (
           

                  <Box key={index} className="Test_paddingBox2" sx={{mt:2}}>
                    <Box className="duplicate_box">
                      <div className="Test_FlexRow">
                        <TextField
                          id={`award-title-${index}`}
                          name={`award-title-${index}`}
                          label="Title *"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={box[`award-title-${index}`] || box['award-title'] || ''}
                          onChange={(event) => handleChanges2(event, index)}
                          disabled={Type === 'admin'}
                        />
                        <TextField
                          id={`issuing-organization-${index}`}
                          name={`issuing-organization-${index}`}
                          label="Issuing Organization"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={box[`issuing-organization-${index}`] || box['issuing-organization']|| ''}

                          onChange={(event) => handleChanges2(event, index)}
                          disabled={Type === 'admin'}
                        />
                      </div>
                      <div className="Test_FlexRow_honors">
                      {/* {console.log('box.startDate:', box.startDate)} */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id={`start-date-${index}`}
                            name={`start-date-${index}`}
                            label="Issue date *"
                            size="small"
                            // value={box[`start-date-${index}`] || ''}
                            value={
                              box[`start-date-${index}`]
                                ? dayjs(box[`start-date-${index}`])
                                : null
                            }
                            sx={{
                              mt: 2,
                              width: '100%',
                              '& .MuiInputBase-root': { height: '2.6rem' },
                            }}
                            onChange={(date) =>
                              handleDateChange(date, `start-date-${index}`, index)}
                              maxDate={dayjs()}
                              disabled={Type === 'admin'}
                          />
                        </LocalizationProvider>
                        <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                          <InputLabel id={`level-label-${index}`}>
                            Level(s) of Recognition *
                          </InputLabel>
                          <Select
                           disabled={Type === 'admin'}
                            labelId={`level-label-${index}`}
                            id={`level-select-${index}`}
                            name={`level-${index}`}
                            label="Level(s) of Recognition *"
                            value={box[`level-${index}`] || box['level'] || ''}
                            onChange={(event) => handleChanges2(event, index)}
                          >
                            <MenuItem value={'Local'}>Local</MenuItem>
                            <MenuItem value={'District'}>District</MenuItem>
                            <MenuItem value={'School'}>School</MenuItem>
                            <MenuItem value={'State/Regional'}>State/Regional</MenuItem>
                            <MenuItem value={'National'}>National</MenuItem>
                            <MenuItem value={'International'}>International</MenuItem>
                            {/* <MenuItem value={'Grade 11 - 12'}>Grade 11 - 12</MenuItem> */}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="Test_paddingDiv">
                      
                         <TextField
                           id={`grade-award-${index}`}
                           name={`grade-award-${index}`}
                          label="Grade Level *"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={box[`grade-award-${index}`] || box['grade-award']|| ''}
                          onChange={(event) => handleChanges2(event, index)}
                          disabled={Type === 'admin'}
                        />
                      </div>
                      <div className="Test_paddingDiv">
                        <TextField
                          id={`award-description-${index}`}
                          name={`award-description-${index}`}
                          label="Award Description"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={box[`award-description-${index}`] || box['award-description']  || ''}
                          onChange={(event) => handleChanges2(event, index)}
                          disabled={Type === 'admin'}
                          
                        />
                          {index !== 0 && (
        <DeleteOutlinedIcon className='Mui_edit_icon' onClick={() => deleteCourse2(index)}  sx={{color:'#D32F2F',padding:0}}/>
           )}
                      </div>
                    </Box>
                  </Box>
                ))}
                {ErrorawardBoxes && (
    <FormHelperText sx={{color:"#f44336"}}>{ErrorawardBoxes}</FormHelperText>
  )}
                {/* <div className="Test_paddingDiv">
                  <Button
                  sx={{boxShadow:"none"}}
                    variant="contained"
                    color="inherit"
                    size="large"
                    fullWidth
                    onClick={addAnotherAwardBox}
                  >
                    + ADD ANOTHER HONOR
                  </Button>
                </div> */}
              </div>
          <div  className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 1}>
          <Box className="Test_paddingBox" >
  
  <div className='Test_FlexRow_special'>
  
  <FormControl fullWidth size="small"   error={Boolean(ErrorHighestdegreeyouearn)} sx={{marginTop:"20px"}}>
    {console.log(formData.highest_degree_you_earn,"formData.highest_degree_you_earn")}
        <InputLabel id="highest_degree_you_earn">Highest degree you intend to earn *</InputLabel>
        <Select
          labelId="highest_degree_you_earn"
          id="highest_degree_you_earn"
          name="highest_degree_you_earn"
          value={formData.highest_degree_you_earn || ''} 
          label="Highest degree you intend to earn *"
          onChange={handleHighestDegreeChange}
          error={Boolean(ErrorHighestdegreeyouearn)}
          helperText={ErrorHighestdegreeyouearn}
          disabled={Type === 'admin'}
        >
          <MenuItem value={'Certificate'}>Certificate</MenuItem>
          <MenuItem value={'Diploma Associate'}>Diploma Associate</MenuItem>
          <MenuItem value={'Bachelor'}>Bachelors</MenuItem>
          <MenuItem value={'Post-bachelor'}>Post-bachelors</MenuItem>
          <MenuItem value={'Masters'}>Masters</MenuItem>
          <MenuItem value={'Post-masters'}>Post-masters</MenuItem>
          <MenuItem value={'Doctoral'}>Doctoral</MenuItem>
          <MenuItem value={'Post-Doctoral'}>Post-Doctoral</MenuItem>
        </Select>
        {ErrorHighestdegreeyouearn && (
    <FormHelperText sx={{color:"#f44336"}}>{ErrorHighestdegreeyouearn}</FormHelperText>
  )}
      </FormControl>
  
  
          
    <TextField  disabled={Type === 'admin'}  id="outlined-multiline-static"  multiline label="Career Interest *" name="career_intrest"      onChange={handleCareerInterestChange} variant="outlined" error={Boolean(ErrorCareerintrest)} value={formData.career_intrest} helperText={ErrorCareerintrest}  fullWidth size="small"   minRows={6}/>
  
</div>


  </Box>
 
          </div>
          
          <div className='Test_buttonDiv'>
        <Button variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:"none"}} onClick={handleBackButton} >Back</Button>
        <Button variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}} onClick={moveToNextTab}  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    value === 1 && Type === 'admin' ? "CLOSE" : (value === 1 ? "SAVE" : "NEXT")
                  )}</Button>
      </div>
         </Box>
        </Box>
        {/* {showAlert && (
        <Alert
          severity="success"
          sx={{
            position: 'absolute',
            bottom: 0, // Place the Alert at the bottom
            left: 0,
            right: 0,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Your data was successfully saved! Redirecting...
        </Alert>
      )} */}
      </Grid>
    </Grid>
  </div>
  )
}

export default Honours