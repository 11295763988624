import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link,useNavigate } from 'react-router-dom';
import './collaborator.css'
import backgroundimage from "../../assets/img/bg-img.png"
import profilebg1 from "../../assets/img/profilebg.png"
import profile1 from '../../assets/img/profile.jpg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tobar from './Tobar';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import mainlogo from '../../assets/img/mainwelcome.png'
import Collaborator_home from './collaborator_home';
import Messages from './Messages';
import Collaborator_students from './Collaborator_students';
import TextField from '@mui/material/TextField';
import Chatpic from '../../assets/img/_Avatar_.png';
import Chatpic1 from '../../assets/img/_Avatar_1.png';
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import SearchStudents from './SearchStudents';
import Collaborator_messages_request from './Collaborator_messages_request';
import bgimagecover from '../../assets/img/bgcover.svg';
import StudentNotification from '../Students/StudentNotification';
import CollaboratorNotification from './CollaboratorNotification';

const Profileview = () => {

  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }

  const [activeComponent, setActiveComponent] = useState('collaborator_home');

  const [selectedMessage, setSelectedMessage] = useState(null); // Track the selected message
  const [messages, setMessages] = useState([]);
  const [collaboratorschatusers, setcollaboratorschatusers] = useState([]);



  const [screenSizeLessThan680, setScreenSizeLessThan680] = useState(window.innerWidth > 680);

  useEffect(() => {
    function handleResize() {
      const isLessThan680 = window.innerWidth > 680;
      setScreenSizeLessThan680(isLessThan680);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Fetch messages from Laravel API
    //fetchMessagesFromApi();

    // Fetch all users from the API when the component mounts
    fetchUsersFromApi();
    
  }, [activeComponent]);

  const fetchUsersFromApi = () => {
    // Fetch all users from the API
    // Replace 'api/users' with your actual API endpoint for users
    const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
    fetch(`${apiUrl}/get_profileformessage`, {
      method: 'post',
      body: JSON.stringify({ userId }), // Send the entire formData object as the body
      }) 
      .then((response) => response.json())
      .then((data) => {
        console.log(data.collaborators); 
        //setMessages(data.collaborators);
        setcollaboratorschatusers(data.collaborators);
        console.log(data.collaborators,"messages profiles");
      })
      .catch((error) => {
        console.error("Error fetching users: ", error);
      });
  };
  
  const navigate = useNavigate();
  
  const userData = JSON.parse(localStorage.getItem('userData'));
  if (!userData) {
    navigate(`/login`);
    return null; 
  }
  const joinDate = new Date(userData.created_at);
  const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
  const profile1url = userData.avatarImage;
  const laravelUrl =profile1url? profile1url.replace('/public/', '/storage/app/public/'):null;
  const laravelUrlfinal =laravelUrl? laravelUrl.replace('/public/storage/', '/public/'):null;
  console.log(laravelUrlfinal,"user image"); 
  const backgroundurl = userData.background_image;

      let backgroundurlimagefinal; // Initialize the variable

      if (backgroundurl) {
        const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/');
        backgroundurlimagefinal = backgroundurlimage.replace('/public/storage/', '/public/');
      } else {
        // Handle the case where backgroundurl is empty or null
        backgroundurlimagefinal = ''; // Set it to an empty string or any other appropriate default value
      }

  const handleItemClick = (message) => {
    // Update the badge number to 0 when a message is clicked
    message.badgenumber = 0;
    console.log(message, "new id message");
    setSelectedMessage(message);
  };









 

  

  return (
    <div >
 <Tobar/>
   
   <Grid container 
   gap={4}
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Hidden_collaborator2'
   sx={{
    padding:"20px",
    
     backgroundImage: `url(${backgroundimage})`,
     backgroundRepeat: 'no-repeat',
     backgroundColor: (t) =>
       t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
     backgroundSize: 'cover',
     backgroundPosition: 'center',     // Center the image horizontally and vertically
     backgroundAttachment: 'fixed', 
     position: 'relative',
   }} >
   <Grid className='main_menu_collaborator'  sx={{background: "rgba(255, 255, 255, 0.80)",
  backdropfilter: "blur(25px)",display:"flex",flexDirection:"row",justifyContent:"space-between"}} xs={12}sm={12}>
     <Link
                className={`Profile_view_Button ${activeComponent === 'collaborator_home' ? 'active' : ''}`}
                to={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('collaborator_home')}
              ><HomeOutlinedIcon/> </Link>
               <Link
                className={`Profile_view_Button ${activeComponent === 'students' ? 'active' : ''}`}
                href={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('students')}
              >
                <PeopleAltOutlinedIcon/>
              </Link>
              <Link
                className={`Profile_view_Button ${activeComponent === 'Collaboratormessagesrequest' ? 'active' : ''}`}
                to={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('Collaboratormessagesrequest')}
              >
 <MailOutlineOutlinedIcon />
              </Link>
              <Link
                className={`Profile_view_Button ${activeComponent === 'notifications' ? 'active' : ''}`}
                href={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('notifications')}
              >     
 <NotificationsActiveOutlinedIcon />
 </Link>
 {/* <Link
                className={`Profile_view_Button ${activeComponent === 'search_students' ? 'active' : ''}`}
                href={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('search_students')}
              >
                <ScreenSearchDesktopOutlinedIcon />
              </Link> */}
  
   </Grid>
   {(screenSizeLessThan680 || activeComponent === 'collaborator_home') && (
    <Grid item
    className='Hidden_collaborator3'
   rowGap={3}
     lg={2}
     xl={2}
     xs={12} 
     sm={3.5}
   
     md={2} sx={{backgroundColor:"transparent", display:"flex" ,flexDirection:"column",  width: '200px', // Adjust the width as needed
     position: 'sticky',
     top: 0,
     height: '100vh', // Full viewport height
      }} >
<Grid item  xs={false} 
  sx={{background: "rgba(255, 255, 255, 0.80)",
  backdropfilter: "blur(25px)",height:"50%",padding:"4px",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
    <Box className="profile-view-bg" sx={{      
        backgroundImage:  backgroundurlimagefinal ? `url(${backgroundurlimagefinal})` : `url(${bgimagecover})`,
        // backgroundColor: backgroundurlimagefinal ? 'transparent' : '#D9D9D9',
  }}>
         <Avatar
         className='Avatat_collaborator'
            alt="Cindy Baker"
            src={laravelUrlfinal} // Make sure profile1 contains the correct image path
            sx={{
              border: '2px solid white', // Optional border
              width: "10vh", // Adjust the Avatar's width
              height: '10vh', // Adjust the Avatar's height
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              top:"70%",
             
            }}
          />
     </Box>
     <div style={{padding:"5px"}}></div>
<Box className="Profile-view" >
    <typography className='Profile_view_text'>{userData.legal_name}</typography>
    <typography className='Profile_view_location2'><LocationOnIcon sx={{height:"15px",width:"15px"}} />{userData.city}</typography>
    <typography className='Profile_view_date'><CalendarMonthIcon sx={{height:"15px",width:"15px"}}/>joined {formattedJoinDate}</typography>
    <Link to={'/complete_profile'}>
    <Button variant="outlined" ><typography className='Button_one'>view Profile</typography></Button>
    </Link>
</Box>

</Grid>

<Grid xs={0} sm={0}   className='Hidden_collaborator'
            item    
            sx={{ background: "rgba(255, 255, 255, 0.80)",
            backdropfilter: "blur(25px)", height: "50%", padding: "4px",display:"flex" }}
          >
            <Box className="profile_view_Button_div">
              <Link
                className={`Profile_view_Button ${activeComponent === 'collaborator_home' ? 'active' : ''}`}
                to={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('collaborator_home')}
              >
                <HomeOutlinedIcon/>
                Home
              </Link>
              <Link
                className={`Profile_view_Button ${activeComponent === 'students' ? 'active' : ''}`}
                href={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('students')}
              >
                <PeopleAltOutlinedIcon/>
                Collaborator
              </Link>
              <Link
                className={`Profile_view_Button ${activeComponent === 'Collaboratormessagesrequest' ? 'active' : ''}`}
                to={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('Collaboratormessagesrequest')}
              >
                <MailOutlineOutlinedIcon />
                Messaging
              </Link>
              <Link
                className={`Profile_view_Button ${activeComponent === 'notifications' ? 'active' : ''}`}
                href={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('notifications')}
              >
                <NotificationsActiveOutlinedIcon />
                Notifications
              </Link>
              {/* <Link
                className={`Profile_view_Button ${activeComponent === 'search_students' ? 'active' : ''}`}
                href={'#'}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveComponent('search_students')}
              >
                <ScreenSearchDesktopOutlinedIcon />
                Search Students
              </Link> */}
            </Box>
          </Grid>

    </Grid>

)}


    <Grid
    
          item
         
          lg={activeComponent === 'collaborator_home' ? 9.5 : 7.2}  // Set different values for lg based on the activeComponent
  xl={activeComponent === 'collaborator_home' ? 9.7 : 7.3}  // Set different values for xl based on the activeComponent
  xs={activeComponent === 'collaborator_home' ?  12:12}   // Set different values for xs based on the activeComponent
  sm={activeComponent === 'collaborator_home' ? 6.5 : 4}   // Set different values for sm based on the activeComponent
  md={activeComponent === 'collaborator_home' ? 9.4 :7.1} 
          sx={{
            flex: 1,
overflowY: 'auto', // Allow it to expand to fill available space
          }}
         
        >
          {activeComponent === 'collaborator_home' && <Collaborator_home/>}
          {activeComponent === 'collaboratorschatusers' && selectedMessage && (
            <Messages sender1={selectedMessage} />
          )}

          {activeComponent === 'students' && <Collaborator_students />}
          {activeComponent === 'search_students' && <SearchStudents />}
          {activeComponent === 'notifications' && <CollaboratorNotification/>}
          {activeComponent === 'Collaboratormessagesrequest' && <Collaborator_messages_request/>}
        </Grid>      
        {activeComponent === 'collaborator_home' ? (
          null
     
) : 
<>
{(screenSizeLessThan680 || activeComponent === 'Collaboratormessagesrequest') && (
 <Grid item
rowGap={3}
  lg={2}
  xl={2.2}
  xs={12}
  sm={3}
  md={2} sx={{backgroundColor:"transparent",display:"flex",flexDirection:"column",width: '200px', // Adjust the width as needed
  position: 'sticky',
  top: 0,
  height: '100vh', // Full viewport height
   }}
   className='Messaging_collaborator_grid'
    >

<Grid item 
className='Messages_search_div'
 sm={12}
sx={{backgroundColor:"white",height:"100%",padding:"30px",overflowX: 'auto',}}>
 <Box sx={{display:'flex',flexDirection:'column',paddingRight:'10px'}}>
     <typography className='message_Search_bartext'>Messaging</typography>
     <TextField
id="outlined-basic"
label="Search Chats"
name="Search Chats"
variant="outlined"
fullWidth
size="small"
InputLabelProps={{
 sx: {
   fontSize: '14px', // Set the desired font size for the label
 },
}}
/>

   

  </Box>
<Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', cursor: 'pointer' }}>
{collaboratorschatusers && collaboratorschatusers.map((message, index) => (
 <div
   key={message.id}
   className={activeComponent === 'collaboratorschatusers' && selectedMessage?.id === message.id ? 'active' : ''}
   onClick={() => {
     setActiveComponent('collaboratorschatusers');
     setSelectedMessage({ ...message, badgenumber: 0 }); // Set the selected message when clicked

     handleItemClick(message)
   }}
 >
   <CardHeader
     sx={{
       padding: '20px 0px 0px 0px',
     }}
     avatar={
       <Badge
         badgeContent={message.badgenumber}
         color="primary"
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'right',
         }}
       >
         <Avatar
           alt={message.sender}
           src={message.senderpic}
           sx={{
             width: '40px',
             height: '40px',
             borderRadius: '20px',
           }}
         />
       </Badge>
     }
     title={
       <typography
        className="message_text_secondary"
       >
         {index === 0 ? message.sender + ' *' : message.sender} 
       </typography>
     }
     subheader={
       <span
         style={{
           fontSize: '12px',
         }}
       >
         {message.content}
       </span>
     }
   />
 </div>
))}
</Box>
</Grid>


 </Grid>
)}
</> 
 }
   </Grid>
   </div>
  )
}

export default Profileview