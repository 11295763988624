import React from 'react';
import Headermain from '../components/header/header';
import Grid from '@mui/material/Grid';
import MainImage from '../assets/img/HomemainImage.png';
import { Button, Typography } from '@mui/material';
import '../App.css';
import HarvardImage from '../assets/img/Harvard.png';
import GeorgiaImage from '../assets/img/georgia.png';
import PennImage from '../assets/img/penn.png';
import StandfordImage from '../assets/img/standford.png';
import TrustedImage from '../assets/img/trustedbytop.png';
import StarImage from '../assets/img/Star1.svg';
import Icon1 from '../assets/img/icons1.svg';

import TrackApplicationImage from '../assets/img/trackImage.png'
import TrackApplicationImage2 from '../assets/img/trackimage2.png'
import App1 from '../assets/img/Harvard.png'
import App2 from '../assets/img/standford.png'
import App3 from '../assets/img/georgia.png'
import App4 from '../assets/img/penn.png'
import App5 from '../assets/img/uninew.png'
import CommentImage from '../assets/img/updatedpic.png'
import Quote from '../assets/img/quoteImage.png'
import FooterMain from '../components/footer/footer';
import Profile from '../assets/img/ibrahim.png'
import Profile2 from '../assets/img/emir.png'
import Questions from '../components/Questions/Questions';
import Icon2 from '../assets/img/icons2.svg'
import Icon3 from '../assets/img/icons3.svg'
import Icon4 from '../assets/img/icons4.svg'
import { Link } from 'react-router-dom';

const Homepage = () => {
  return (
    <>
    
    
    {/* <Grid container component="main" lg={12} xl={12} xs={12} sm={12} md={12} sx={{display:'flex',justifyContent:'center',alignItems:"flex-start"}}> */}
   
      {/* <Grid
        container
        item
        lg={12}
        xl={12}
        xs={false}
        sm={12}
        md={12}
        sx={{
          height: '100vh',
          backgroundImage: `url(${MainImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          alignItems: 'flex-start', // Center vertically
          justifyContent: 'center', // Center horizontally
          flexDirection: 'column', // Stack children vertically
          
        }}
      >
        <Headermain />
        <div className='mainText'>Make an<br />Educated Decision</div>
        
      
  <Button sx={{ backgroundColor: '#006CB7', color: 'white',marginLeft:'90px' }}>Free Demo</Button>



      </Grid> */}
     
      <Grid
  container
  component="main"
  sx={{
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.80) 19.27%, rgba(0, 0, 0, 0.00) 100%), url(${MainImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
   
    // justifyContent: 'flex-start', 
  }}
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
  <div className='Home_one'>
    <div className='Home_two'>
      <Headermain />
    </div>
  </div>

  <div className='Home-three'>
    <div className='Home_four'>
    <Grid
  item
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
  className="Homemain_one" // Add the class name "Homemain_one"
>
        <typography className='mainText' style={{ fontFamily: 'Circe' }}>Apply to<br />College the<br />Smart way</typography>
        <typography className='mainText2'>We are an online database that connects<br />college students and alumni to high<br />school students and college applicants.</typography>
        <Link to={`/welcome`} style={{ textDecoration: "none" }}>
        <Button className="Homebutton_one"> 
        Get Started
        
</Button>
</Link>

      </Grid>
    </div>
  </div>
</Grid>


<div className='Home_five'>
   
      <div className='Home_six'>
      <div className='Home_seven'>
      <div className='Home_eigth'>
        <typography className='trustedImageText'>Collaborators from Top Universities</typography>
        {/* <img src={TrustedImage} alt='Trusted' className='trustedImage'/> */}
        <img src={HarvardImage} alt='Harvard' className='trustedImage'/>
       <img src={GeorgiaImage} alt='Georgia' className='trustedImage'/>
        <img src={PennImage} alt='Penn' className='trustedImage'/>
        <img src={StandfordImage} alt='Standford' className='trustedImage'/>
      </div>
      <div>
        
      </div>
   
      



      
      <div className='Home_fourteen'>
       
       
      
<div style={{display:"flex",}}>
<div className='trackApplicationLeft' style={{ display: 'flex',flexWrap:"nowrap"}}>
        
        <div className='feature_maindiv'>
          <div className='featuresMain1'>
            <typography className='featuresMain1Text_1'>Students</typography>
          </div>
          <div  className='trackApplicationLeftTextMain'>
            <typography>Perfect<br/>your college<br/>applications</typography>
          </div>
          <div className='trackApplicationLeftTextSecondary'>
            <typography >Our student portal allows you to research colleges, find collaborators from the colleges you are applying to, view their profiles and essays, and message them.</typography>
          </div>
        </div>
      
    <Link  to="/signup?type=student"  className='feature_maindiv_Link'  >
      <Button variant='contained' className='feature_maindiv_Button'>JOIN AS STUDENT </Button>
      </Link>
        
      </div>
</div>
  
   



 
  <div className='trackApplicationRight'>
    <img src={TrackApplicationImage} className='trackApplicationRight'/>
  </div>
</div>



       
      
      <div className='Home_fifteen' >
      
        <div className='trackApplicationRight'>
            <img src={TrackApplicationImage2} className='trackApplicationRight'/>
        </div>
        <div className='trackApplicationLeft'>
            <div  className='feature_maindiv'>
        <div className='featuresMain1'>
            <typography  className='featuresMain1Text'>Collaborators</typography>
        </div>
        <div className='trackApplicationLeftTextMain2'>
        <typography >Earn money from your college applications</typography>
        </div>
        <div className='trackApplicationLeftTextSecondary'>
        <typography  >Our collaborator portal allows you to create a profile, connect with students, message others, and track your earnings.</typography>
        </div>
        </div>
        <Link  to="/signup?type=collaborator" className='feature_maindiv_Link'>
      <Button variant='contained' className='Home_fifteen_Button'>Join as collaborator</Button>
      </Link>
        
       
        </div>
      </div>


      <div className='featuresMain' >
        <div className='featuresMain1'>
            <typography  className='featuresMain1Text'>SCHOOLS</typography>
        </div>
        <div className='featuresMain21'>
            <typography className='featuresMain2Text'>Give your students the edge in <br/>
college admissions</typography>
        </div>
        <div  className='featuresMain3'>
            <typography className='featuresMain3Text'>We offer schools the ability to partner with us to give their students access to all of our college application resources.</typography>
        </div>
      </div>
      <div className='services'>
        <div className='services1'>
            <div className='servicesImage'>
                <img src={Icon1} alt='Icon1'/>
            </div>
            <div className='sevicesText'>
                <typography className='sevicesTextMain'>Improve School Rating</typography>
                <typography className='sevicesTextSecondary'>Get a higher college readiness score with more student applicants and admits.</typography>
            </div>
            {/* <div className='Home_ten'>
           <a href='#' className='linkText'>Learn More</a>
           <img src={ArrowImage} alt='Arrow'/>
            </div> */}
        </div>
        <div className='services2'>
        <div className='servicesImage'>
                <img src={Icon2} alt='Icon1'/>
            </div>
            <div  className='sevicesText'>
                <typography className='sevicesTextMain'>Help your Students</typography>
                <typography className='sevicesTextSecondary'>Take the stress and difficulty away from their college application process.</typography>
            </div>
            {/* <div className='Home_eleven'>
           <a href='#' className='linkText'>Learn More</a>
           <img src={ArrowImage} alt='Arrow'/>
            </div> */}
        </div>
        <div className='services3'>
        <div className='servicesImage'>
                <img src={Icon3} alt='Icon1'/>
            </div>
            <div  className='sevicesText'>
                <typography className='sevicesTextMain'>Track Student Progress</typography>
                <typography className='sevicesTextSecondary'>Use our advanced analytics to track student’s application progress.</typography>
            </div>
            {/* <div className='Home_twelve'>
           <a href='#' className='linkText'>Learn More</a>
           <img src={ArrowImage} alt='Arrow'/>
            </div> */}
        </div>
        <div className='services4'>
        <div className='servicesImage'>
                <img src={Icon4} alt='Icon1'/>
            </div>
            <div  className='sevicesText'>
                <typography className='sevicesTextMain'>Flexible Plans</typography>
                <typography className='sevicesTextSecondary'>ApplyUpp offers tailored plans to help your school’s specific needs.</typography>
            </div>
            {/* <div className='Home_thirteen'>
           <a href='#' className='linkText'>Learn More</a>
           <img src={ArrowImage} alt='Arrow'/>
            </div> */}
        </div>
      </div>


      <div className='featuresMain'>
        <div className='featuresMain1'>
            <typography  className='featuresMain1Text'>Colleges</typography>
        </div>
        <div className='featuresMain2'>
            <typography className='featuresMain2Text2'>Find the best colleges for you</typography>
        </div>
      
      </div>
     
      <div className='appIcon' >
        <img src={App1} className='appIconImage'/>
        <img src={App3}  className='appIconImage'/>
        <img src={App4}  className='appIconImage'/>
        <img src={App2}  className='appIconImage'/>
       
        
        <img src={App5}  className='appIconImage'/>
        
        
      </div>
      <div className='featuresMainTwo' >
      <Link  to="/signup?type=student"  className='feature_maindiv_Link'  >
         <Button variant='contained' sx={{boxShadow:"none",width:"190px"}}>Explore Colleges</Button>
   </Link>
      </div>
      <div className='CommentContainer'>
        <div className='CommentContainerLeft'>
            <div className='CommentContainerLeftTextMain'>
                <typography>I have had a great experience with ApplyUpp. Creating my profile was very easy, and since then I have been able to help college applicants and earn passive income!</typography>
            </div>
            <div className='CommentContainerLeft-Child'>
                <div>
                    <img src={Quote} alt='Quote'/>
                </div>
                <div style={{padding:'10px'}}>
                    <div>
                    <typography className='CommentContainerLeft-Child-mainText'>Fernando Gonazalez</typography>
                    </div>
                    <div>
                    <typography className='CommentContainerLeft-Child-secondaryText'>Freshman at the Midwestern State University,
Newman Smith Graduate</typography>
                    </div>
                </div>
             
            </div>
        </div>
        <div className='CommentContainerRight_div'>  <img src={CommentImage} alt='Image' className='CommentContainerRight'/></div>
      </div>
      <div className='risingStars'>
        <typography className='risingStarsText'>
        Loved by Students
        </typography>
      </div>
      <div className='risingStarsContainer' >
        <div className='risingStarsContainerLeft'>
        <div className='StarImagee'>
        <img src={StarImage} alt='StarImage'/>
            
        </div>
        <div className='Starimagetext'>
            <typography>As a senior in High School I was extremely nervous about applying to college. Thankfully I was able to find ApplyUpp, helping me research colleges and view real profiles which helped me fill out my own application.Thanks ApplyUpp!”</typography>
        </div>
        <div className='Profilecontainer'>
            <div>
                <img src={Profile}/>
            </div>
            <div className='Home_sixteen'>
                <typography className='CommentContainerLeft-Child-mainText'>Ibrahim Syed</typography>
                <typography className='CommentContainerLeft-Child-secondaryText'>Senior at the Brighter Horizons Academy</typography>

            </div>
        </div>
            
        </div>
        <div className='risingStarsContainerRight'>
        <div className='StarImagee'>
        <img src={StarImage} alt='StarImage'/>
            
        </div>
        <div className='Starimagetext'>
            <typography>ApplyUpp helped clarify what the college application process is all about. My junior year has been quite stressful with the SAT and dual credit classes, so thankfully college applications are one less thing to worry about!</typography>
        </div>
        <div className='Profilecontainer'>
            <div>
                <img src={Profile2}/>
            </div>
            <div className='Home_seventeen'>
                <typography className='CommentContainerLeft-Child-mainText'>Emir Hadzic</typography>
                <typography className='CommentContainerLeft-Child-secondaryText'>Junior at the IANT Quranic Academy</typography>

            </div>
        </div>
        </div>
      </div>
  <div className='Home-eighteen'>
      {/* <Questions /> */}
      <div className='students'>
        <div className='students1'>
            <div className='studentsmainText'>Our policy on privacy and plagiarism</div>
       
        </div>
        <div className='Homepage_Text_div' style={{display:"flex", justifyContent:"space-between",marginTop:"30px"}} >
        <div className='students2'>
            <div className='studentssecondaryText'>ApplyUpp has a zero-tolerance policy for plagiarism. We believe academic integrity is essential to the success of our users and thus closely monitor any potential instances of plagiarism. Profiles are meant to inspire applicants, not for applicants to copy.</div>
         
        </div>
        <div className='vertical-divider'></div> 
        <div className='students3'>
        <div  className='studentssecondaryText'>At ApplyUpp, we respect your privacy and are committed to protecting your personal information. None of your information will be shared with third parties without your consent. Your profile is only meant to help college applicants and earn you money.</div>
        </div>
        </div>

      </div>
      </div>
      <div className='Home-eighteen'>
      
      </div>


      
      
      </div>
    </div>
    </div>
    <FooterMain/>
    </>
  );
}

export default Homepage;
