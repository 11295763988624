import React, { useState,useRef,useEffect    } from 'react';
import Grid from '@mui/material/Grid';

import Topbar from '../Topbar';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography, Tabs, Tab,Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import Autocomplete from "@mui/material/Autocomplete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import '../../collaborator/Profile_add/profile.css'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation,useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tobar from '../../collaborator/Tobar';
import { Topbar as Topbar2 } from '../../Admin/Topbar';
// import { useParams } from 'react-router-dom';
const Collaborator_college_form = () => {
   
      
      const urlParams = new URLSearchParams(window.location.search);
      const urlType = urlParams.get('type');
      const id = urlParams.get('id');
      const userid = urlParams.get('userid');
      function encodeAlphanumeric(userid) {
        const encoded = btoa(userid); // Using btoa to Base64 encode the ID
        // You can further process the encoded string if needed
        return encoded;   
      } 
      console.log('ID:', id);
      console.log('UserID:', userid);
    const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const isSmallScreen = useMediaQuery('(max-width:680px)');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={urlType === 'collaborator' ? '/profileview' : (urlType === 'admin' ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userid)}` : '/Student')}
     
    >
      Profile
    </Link>,
 
    <Typography key="3" color="text.primary">
    Colleges applied for
    </Typography>
  ];

  const [collegeformid, setcollegeformid] = useState('');
  const [formid, setformid] = useState('');
    const [selectedOption, setSelectedOption] = useState('yes'); // To store the selected option
    const [selectedOption2, setSelectedOption2] = useState(''); // To store the selected option
    const [collegeName, setCollegeName] = useState([]);
  const [highSchoolLocation, setHighSchoolLocation] = useState('');
  const [collegeNameError, setCollegeNameError] = useState('');
  const [highSchoolLocationError, setHighSchoolLocationError] = useState('');
  const [selectedMajor, setSelectedMajor] = useState([]);
  const [admissionplan, setAdmissionPlan] = useState('');
  const [admissionplanError, setadmissionplanError] = useState('');
  const [selectedMajorError, setSelectedMajorError] = useState('');
  const [startterm, setStartTerm] = useState('');
  const [starttermError, setstarttermError] = useState('');

  const [coursetype, setCourseType] = useState('');
  const [coursetypeError, setcoursetypeError] = useState('');

  const [supplementryeassy, setSupplementryEassy] = useState('');
  const [supplementryeassyError, setsupplementryeassyError] = useState('');

  const [aidtype, setAidType] = useState('');
  const [aidtypeError, setaidtypeError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [AidError, setAidError] = React.useState('');
  const [durationofaid, setDurationOfAid] = useState('');
  const [durationofaidError, setdurationofaidError] = useState('');
  const [socholarshipError, setsocholarshipError] = useState('');
  const [aidpackageperunitError, setaidpackageperunitError] = useState('');
  const [RecommerdsError, setecommerdsError] = useState('');
  const [DecisionoptionError, setDecisionoptionError] = useState('');
  const [yourinterestError, setyourinterestError] = useState('');
  // const [showAlert, setShowAlert] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [CollegeFormData, setCollegeFormData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setsearchText] = useState('');
  const [formData, setFormData] = useState({
    collegeName: '',
    highSchoolLocation: '',
    major: '',
    admission_plan: '',
    start_term: '',
    course_type: '',
    supplementary_eassy: '',
    aid_type: '',
    socholarship_name: '',
    duration_of_aid: '',
    aid_package_per_unit: '',
    recommendersData: [],
    financial_aid:[],
    decisionOptions:'',
    your_interest: '',
    userId:'',
    formid:'',
  });
  const [collaborators, setcollaborators] = useState([]);
  useEffect(() => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      fetch(`${apiUrl}/get_all_collaborators_data_profiles`, {
          method: 'post',
          body: JSON.stringify({ userId }), // Send the entire formData object as the body
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
              throw new Error('Request failed');
          }
      })
      .then(data => {
          
          console.log(data); // Log the awardsData
          setcollaborators(data.collaborators);
         // alert("here");
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
  }, []);
  useEffect(() => {
  
     
      const searchParams = new URLSearchParams(location.search);
      const selectedCollegeId = searchParams.get('id'); //change this const to form id 
      setformid(selectedCollegeId);
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
      console.log('College ID:', selectedCollegeId); 

      if(selectedCollegeId !== ''){
        try {
        fetch(`${apiUrl}/get_colleges_form_data`, {   // chgange this url 
          method: 'post',
          body: JSON.stringify({ selectedCollegeId }), // pass the form id const here 
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
              throw new Error('Request failed');
          }
      })
      .then(data => {
        const collegeData = data.CollegeData[0];
        console.log(collegeData,"collegeData")
       
const financialAidArray = JSON.parse(collegeData.financial_aid || '[]');

const hasFinancialAid = Array.isArray(financialAidArray) && financialAidArray.length > 0;

        collegeData.duration_of_aid ||
        collegeData.aid_package_per_unit ||
        collegeData.socholarship_name ||
        collegeData.highSchoolLocation||
        collegeData.aid_type;

      setSelectedOption(hasFinancialAid ? 'yes' : 'no');
const parsedMajor = JSON.parse(collegeData.major || '[]');
const collgesNames = JSON.parse(collegeData.collegeName || '[]');
const financial_aiddata = JSON.parse(collegeData.financial_aid || '[]');
setScholarships(financial_aiddata);
setSelectedMajor(parsedMajor);
setCollegeName(collgesNames);
if (collegeData.decisionOptions === "Accepted") {
  setSelectedOption2("Accepted");

}
else if (collegeData.decisionOptions === "Attended") {
  setSelectedOption2("Attended");}
   else if (collegeData.decisionOptions === "Rejected") {
  setSelectedOption2("Rejected");
} else if (collegeData.decisionOptions === "Waitlisted") {
  setSelectedOption2("Waitlisted");
} else if (collegeData.decisionOptions === "Deferred") {
  setSelectedOption2("Deferred");
}
const parsedRecommendersData = JSON.parse(collegeData.recommendersData || '[]');

// Create an array to hold the prefilled recommenders
const prefilledRecommenders = [];

// Loop through the parsedRecommendersData and extract values
for (let i = 0; i < parsedRecommendersData.length; i++) {
  const recommenderData = parsedRecommendersData[i];
  const nameKey = `recommenders_name${i}`;
  const descriptionKey = `recommenders_description${i}`;
 
  const prefilledRecommender = {
    [nameKey]: recommenderData[nameKey] || '', // Use the dynamic key to get the name
    [descriptionKey]: recommenderData[descriptionKey] || '', // Use the dynamic key to get the description
  };
console.log(prefilledRecommenders,"prefilled recomenders"); 
  prefilledRecommenders.push(prefilledRecommender);
}
console.log(prefilledRecommenders,"prefilled recomenders"); 
// Set the state with the prefilled recommenders
setRecommenders(prefilledRecommenders);

const parsedfinancial_aid = JSON.parse(collegeData.financial_aid || '[]');

const prefilledfinancial_aids = [];

// Loop through the parsedRecommendersData and extract values
for (let i = 0; i < parsedfinancial_aid.length; i++) {
  const parsedfinancial_aidData = parsedfinancial_aid[i];
  const aid_type = `aid_type${i}`;
  const socholarship_name = `socholarship_name${i}`;
  const duration_of_aid = `duration_of_aid${i}`;
  const aid_package_per_unit = `aid_package_per_unit${i}`;
 
  const prefilledfinancial_aid = {
    [aid_type]: parsedfinancial_aidData[aid_type] || '', // Use the dynamic key to get the name
    [socholarship_name]: parsedfinancial_aidData[socholarship_name] || '', // Use the dynamic key to get the description
    [duration_of_aid]: parsedfinancial_aidData[duration_of_aid] || '', // Use the dynamic key to get the description
    [aid_package_per_unit]: parsedfinancial_aidData[aid_package_per_unit] || '', // Use the dynamic key to get the description
  };
 
prefilledfinancial_aids.push(prefilledfinancial_aid);
}
console.log(prefilledfinancial_aids,"prefilled scholarships");
setScholarships(prefilledfinancial_aids);

        setCollegeFormData(data.CollegeData);  // change this to data.formdata 
          console.log(data, "collegesdata"); // Log the awardsData
          setFormData({
            collegeName: collegeData.collegeName || '',
            highSchoolLocation: collegeData.highSchoolLocation || '',
            major: collegeData.major || '',
            admission_plan: collegeData.admission_plan || '',
            start_term: collegeData.start_term || '',
            course_type: collegeData.course_type || '',
            supplementary_eassy: collegeData.supplementary_eassy || '',
            aid_type: collegeData.aid_type || '',
            socholarship_name: collegeData.socholarship_name || '',
            duration_of_aid: collegeData.duration_of_aid || '',
            aid_package_per_unit: collegeData.aid_package_per_unit || '',
            recommendersData: JSON.parse(collegeData.recommendersData) || [],
            decisionOptions: collegeData.decisionOptions || '',
            your_interest: collegeData.your_interest || '',
            userId: userId || '',
            formid:selectedCollegeId || '',
          });
         
          console.log(formData,"form data from api ")
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
      }

      
  }, []);    
  
  

  
  
    const [isFieldsVisible, setIsFieldsVisible] = useState(true);
  
    
     
      const getRadioStyle = (value) => {
        // Define colors based on the selected value
        const colorMap = {
          Accepted: 'green !important',
          Rejected: 'red !important',
          Attended:'blue !important',
          Waitlisted: 'orange !important',
          Deferred: 'purple !important',
        };
    
        return {
          color: selectedOption2 === value ? colorMap[value] : colorMap[value],
        };
      };
   
    const top100Films = [
        // { title: "Computer Science" },
        // { title: "Biological sciences" },
        // { title: "Mathematics" },
        // { title: "Chemistry" },
        // { title: "Psychology" },
        // { title: "Accounting" },
        // { title: "Political science" },
        // { title: "Engineering" },
        // { title: "Anthropology" },
        // { title: "Economics" },
        // { title: "Marketing" },
        // { title: "Physics" },
        // { title: "Biochemistry" },
        // { title: "Mechanical Engineering" },
        // { title: "Philosophy" },
        // { title: "Chemical Engineering" },
        // { title: "Biomedical engineering" },
        // { title: "Business administration" },
        // { title: "Communications" },
        // { title: "Sociology" },
        // { title: "Electrical engineering" },
        // { title: "Computer Engineering" },
        // { title: "Primary education" },
        // { title: "Criminal justice" },
        // { title: "Neither" },
        { title: "Accounting" },
        { title: "Actuarial Science" },
        { title: "Aerospace Engineering" },
        { title: "African Studies" },
        { title: "Agricultural Engineering" },
        { title: "Agricultural Science" },
        { title: "American Studies" },
        { title: "Ancient Studies" },
        { title: "Animal Science" },
        { title: "Anthropology" },
        { title: "Applied Mathematics" },
        { title: "Arabic" },
        { title: "Architecture" },
        { title: "Art" },
        { title: "Art Education" },
        { title: "Art History" },
        { title: "Asian Studies" },
        { title: "Astronomy" },
        { title: "Astrophysics" },
        { title: "Athletic Training" },
        { title: "Biochemistry" },
        { title: "Biology" },
        { title: "Biomedical Engineering" },
        { title: "Biophysics" },
        { title: "Business Administration" },
        { title: "Business Analytics" },
        { title: "Business Information Systems" },
        { title: "Business Management" },
        { title: "Business Marketing" },
        { title: "Canadian Studies" },
        { title: "Cardiac Science" },
        { title: "Cartography and Geographic Information Science" },
        { title: "Cell and Molecular Biology" },
        { title: "Chemical Engineering" },
        { title: "Chemistry" },
        { title: "Child and Adolescent Development" },
        { title: "Chinese" },
        { title: "Civil Engineering" },
        { title: "Classical Studies" },
        { title: "Clinical Laboratory Science" },
        { title: "Cognitive Science" },
        { title: "Communication Studies" },
        { title: "Computer Engineering" },
        { title: "Computer Science" },
        { title: "Construction Engineering Management" },
        { title: "Criminal Justice" },
        { title: "Criminology" },
        { title: "Culinary Arts and Management" },
        { title: "Dance" },
        { title: "Data Science" },
        { title: "Dental Hygiene" },
        { title: "Dentistry" },
        { title: "Developmental Biology" },
        { title: "Dietetics and Nutrition" },
        { title: "Digital Media" },
        { title: "Drama" },
        { title: "Early Childhood Education" },
        { title: "Economics" },
        { title: "Education" },
        { title: "Educational Psychology" },
        { title: "Electrical Engineering" },
        { title: "Engineering Physics" },
        { title: "English" },
        { title: "English Language Arts" },
        { title: "Entomology" },
        { title: "Environmental Engineering" },
        { title: "Environmental Science" },
        { title: "European Studies" },
        { title: "Exercise Science" },
        { title: "Fashion Design" },
        { title: "Finance" },
        { title: "Film and Media" },
        { title: "Financial Planning" },
        { title: "Food and Nutrition" },
        { title: "Food Science and Technology" },
        { title: "Foreign Languages" },
        { title: "Forestry" },
        { title: "French" },
        { title: "Geography" },
        { title: "Geology" },
        { title: "German" },
        { title: "Graphic Design" },
        { title: "Greek" },
        { title: "Health Informatics" },
        { title: "Health Sciences" },
        { title: "Health Services Administration" },
        { title: "History" },
        { title: "Hospitality Management" },
        { title: "Human Resource Management" },
        { title: "Human Services" },
        { title: "Humanities" },
        { title: "Ibero-American Studies" },
        { title: "Information Systems" },
        { title: "Interior Design" },
        { title: "International Business" },
        { title: "International Relations" },
        { title: "Italian" },
        { title: "Japanese" },
        { title: "Journalism" },
        { title: "Kinesiology" },
        { title: "Korean" },
        { title: "Landscape Architecture" },
        { title: "Latin" },
        { title: "Law" },
        { title: "Linguistics" },
        { title: "Management" },
        { title: "Marine Biology" },
        { title: "Marketing" },
        { title: "Mathematics" },
        { title: "Mechanical Engineering" },
        { title: "Medical Technology" },
        { title: "Microbiology" },
        { title: "Middle Eastern Studies" },
        { title: "Music" },
        { title: "Music Education" },
        { title: "Music Performance" },
        { title: "Nursing" },
        { title: "Occupational Therapy" },
        { title: "Operations Management" },
        { title: "Optometry" },
        { title: "Organizational Behavior" },
        { title: "Paramedicine" },
        { title: "Parks, Recreation and Tourism Management" },
        { title: "Persian" },
        { title: "Petroleum Engineering" },
        { title: "Pharmacy" },
        { title: "Philosophy" },
        { title: "Physics" },
        { title: "Physiology" },
        { title: "Political Science" },
        { title: "Pre-Law" },
        { title: "Psychology" },
        { title: "Public Health" },
        { title: "Radiologic Technology" },
        { title: "Religious Studies" },
        { title: "Respiratory Therapy" },
        { title: "Robotics" },
        { title: "Russian" },
        { title: "Social Work" },
        { title: "Sociology" },
        { title: "Spanish" },
        { title: "Speech Pathology" },
        { title: "Statistics" },
        { title: "Sustainable Development" },
        { title: "Teaching English to Speakers of Other Languages (TESOL)" },
        { title: "Theater" },
        { title: "Tourism" },
        { title: "Veterinary Medicine" },
        { title: "Women’s Studies" },
        { title: "Writing" },
        { title: "Exploratory" },
        { title: "General Business" },
        { title: "Undeclared" }

      ];
      const states = [
        { code: "AL", name: "Alabama" },
        { code: "AK", name: "Alaska" },
        { code: "AZ", name: "Arizona" },
        { code: "AR", name: "Arkansas" },
        { code: "CA", name: "California" },
        { code: "CO", name: "Colorado" },
        { code: "CT", name: "Connecticut" },
        { code: "DE", name: "Delaware" },
        { code: "FL", name: "Florida" },
        { code: "GA", name: "Georgia" },
        { code: "HI", name: "Hawaii" },
        { code: "ID", name: "Idaho" },
        { code: "IL", name: "Illinois" },
        { code: "IN", name: "Indiana" },
        { code: "IA", name: "Iowa" },
        { code: "KS", name: "Kansas" },
        { code: "KY", name: "Kentucky" },
        { code: "LA", name: "Louisiana" },
        { code: "ME", name: "Maine" },
        { code: "MD", name: "Maryland" },
        { code: "MA", name: "Massachusetts" },
        { code: "MI", name: "Michigan" },
        { code: "MN", name: "Minnesota" },
        { code: "MS", name: "Mississippi" },
        { code: "MO", name: "Missouri" },
        { code: "MT", name: "Montana" },
        { code: "NE", name: "Nebraska" },
        { code: "NV", name: "Nevada" },
        { code: "NH", name: "New Hampshire" },
        { code: "NJ", name: "New Jersey" },
        { code: "NM", name: "New Mexico" },
        { code: "NY", name: "New York" },
        { code: "NC", name: "North Carolina" },
        { code: "ND", name: "North Dakota" },
        { code: "OH", name: "Ohio" },
        { code: "OK", name: "Oklahoma" },
        { code: "OR", name: "Oregon" },
        { code: "PA", name: "Pennsylvania" },
        { code: "RI", name: "Rhode Island" },
        { code: "SC", name: "South Carolina" },
        { code: "SD", name: "South Dakota" },
        { code: "TN", name: "Tennessee" },
        { code: "TX", name: "Texas" },
        { code: "UT", name: "Utah" },
        { code: "VT", name: "Vermont" },
        { code: "VA", name: "Virginia" },
        { code: "WA", name: "Washington" },
        { code: "WV", name: "West Virginia" },
        { code: "WI", name: "Wisconsin" },
        { code: "WY", name: "Wyoming" },
        { code: "PR", name: "Puerto Rico" },
        { code: "GU", name: "Guam" },
        { code: "VI", name: "U.S. Virgin Islands" },
        { code: "AS", name: "American Samoa" },
        { code: "MP", name: "Northern Mariana Islands" },
        { code: "INTL", name: "International" }
      ];
      
    const [age, setAge] = React.useState('');

    // const handleChanges = (event) => {
    //   setAge(event.target.value);
    // };
  const [value, setValue] = React.useState(0);


  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

 
  
  // Initialize navigate

const moveToNextTab = (userid) => {
  if (value === 5 && urlType === 'student' ) {
    window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}`;
    // setShouldNavigate(true); // Assuming this triggers navigation to a different page
  } else if (value === 5 && urlType === 'collaborator' )  {
    window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}`;
    // setShouldNavigate(true); // Assuming this triggers navigation to a different page
  }
  else if (value === 5 && urlType === 'admin' )  {
    window.location.href = `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userid)}`;
    // setShouldNavigate(true); // Assuming this triggers navigation to a different page
  }
  else {
    setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
  }
};
  
  const handleBackButton = (userid) => {
    if (value === 0 && urlType === 'student') {
      // If on the first tab, navigate to "/complete_profile" route
    
      
      
      window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}`;
    } else if (value === 0 && urlType === 'collaborator' )  {
      window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}`;
      // setShouldNavigate(true); // Assuming this triggers navigation to a different page
    }
    else if (value === 0 && urlType === 'admin' )  {
      window.location.href = `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userid)}`;
      // setShouldNavigate(true); // Assuming this triggers navigation to a different page
    } else {
      // Decrement the value state to move to the previous tab
      setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
    }
  };
  const [scholarships, setScholarships] = useState([{  }]);

  // const addScholarship = () => {
  //   setScholarships([...scholarships,{  } ]);
  // };


 

 
  const [recommenders, setRecommenders] = useState([
    {  }, // Initial set of fields
  ]);

 

 
  
  // const addRecommender = () => {
  //   setRecommenders([...recommenders, {  }]);
  // };
  const getRecommendersData = () => {
    // Map the recommenders state to get the entered values
    return recommenders.map((recommender) => ({
      name: recommender.recommenders_name,
      description: recommender.recommenders_description,
    }));
  };
  
  useEffect(() => {
    if (shouldNavigate) {
      const navigateTimer = setTimeout(() => {
        // Navigate to the desired page after 2 seconds
        // setShowAlert(false);
        
        navigate('/Student');
      },);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(navigateTimer);
    }
  }, [shouldNavigate]);
 

  
  

  const filteredOptions = top100Films.filter(
    (option) => !selectedMajor.some((selected) => selected.title === option.title)
  );
  
  
 
  return (
    <div>
    {/* <Topbar /> */}
    {urlType === 'student' ? <Topbar /> : (urlType === 'admin' ? <Topbar2 /> : <Tobar />)}

    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{  padding: "20px",background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)",
         }}
      >
     
        <Stack spacing={2}>
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
    {/* <p>College ID: {collegeId}</p> */}
  </Stack>
      
    
    <Box className="Test_BoxDiv"  >
      <Box className="collaborator_colleges_maindiv" sx={{ width: 'auto' ,display:"flex",flexDirection:"column",justifyContent:"space-between",}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
          <Tabs value={value} aria-label="basic tabs example"  
       >
          <Tab label="COLLEGE INFO"  />
      <Tab label="GENERAL" />
      <Tab label="FINANCIAL AID" />
      <Tab label="RECOMMENDERS" />
      <Tab label="STATUS" />
      <Tab label="ADDITIONAL QUESTIONS" />
          
          </Tabs>
        </Box>
        <Box className="main-div" >

       
        <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 0} >
        <div role="tabpanel" hidden={value !== 0} style={{  display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Box className="Test_paddingBox" style={{display:"flex",flexDirection:"column",flexWrap:"nowrap",alignSelf:"stretch",justifyContent:"space-between"}} >
<div className='Test_FlexRow'>
<Autocomplete
       fullWidth
    id="search-term-colleges"
    options={options}
    getOptionLabel={(option) => option.title || ''}
    value={collegeName}
    disabled
   
    renderInput={(params) => (
      <TextField
        {...params}
        label="College Name"
        name="college_name"
        fullWidth
        size="small"
        error={Boolean(collegeNameError)}
        helperText={collegeNameError}
      />
    )}
    noOptionsText="Search College"
  />
   {/*<TextField id="outlined-basic" label="College Name" name="college_name" variant="outlined" error={Boolean(collegeNameError)} helperText={collegeNameError} fullWidth size="small"   value={formData.collegeName} onChange={(e) => setFormData({ ...formData, collegeName: e.target.value })}  />*/}
  
  <FormControl fullWidth size="small"    error={Boolean(highSchoolLocationError)}>
      <InputLabel id="demo-simple-select-label">College Location</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="high_school_location"
        value={formData.highSchoolLocation} // Set the value from formData
        label="College Location"

        disabled
        
      >
        {states.map((stateName, index) => (
        <MenuItem key={index} value={stateName.code}>
          {stateName.name}
        </MenuItem>
      ))}
      </Select>
 
    </FormControl>
    
</div>


</Box>

</div>
        </div>
        <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 1}  >
        <Box >
          <div  className='Test_DivColumn'>

         
<div className='Test_FlexRow1122'>
<Autocomplete
size="small"
sx={{ mb: 2 }}
fullWidth
multiple
id="tags-outlined"
disabled
options={filteredOptions} // Make sure top100Films has the same structure as collegeData.major
getOptionLabel={(option) => option.title} // Assuming option.title exists in top100Films
filterSelectedOptions={true}
value={selectedMajor} // Set the selected values based on collegeData.major

renderInput={(params) => (
  <TextField
    {...params}
    label="Major"
    name="major"
    placeholder="option"
    error={Boolean(selectedMajorError)}
    helperText={selectedMajorError}
    disabled
  />
)}
/>


  <FormControl fullWidth size="small" sx={{mb:2}}  error={Boolean(admissionplanError)}>
      <InputLabel id="demo-simple-select-label" >Admission plan</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="admission_plan"
        value={formData.admission_plan}
        label="Admission plan"
        disabled
      >
         <MenuItem value={"Early Action"}>Early Action</MenuItem>
          <MenuItem value={"Early Decision"}>Early Decision</MenuItem>
        <MenuItem value={"Regular"}>Regular</MenuItem>
        <MenuItem value={"Rolling"}>Rolling</MenuItem>
      </Select>

    </FormControl>

</div>
<div className='Test_FlexRow'>
<FormControl fullWidth size="small"  error={Boolean(starttermError)}>
      <InputLabel id="demo-simple-select-label">Start term</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="start_term"
        value={formData.start_term}
        label="Start term"
        disabled
      >
        <MenuItem value={"Fall"}>Fall</MenuItem>
        <MenuItem value={"Winter"}>Winter</MenuItem>
        <MenuItem value={"Spring"}>Spring</MenuItem>
        <MenuItem value={"Summer"}>Summer</MenuItem>
      </Select>
   
    </FormControl>

  <FormControl fullWidth size="small" error={Boolean(coursetypeError)}>
      <InputLabel id="demo-simple-select-label">Type</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="course_type"
        value={formData.course_type}
        label="Type"
        disabled
      >
        <MenuItem value={"Full-time"}>Full-time</MenuItem>
        <MenuItem value={"Part-time"}>Part-time</MenuItem>
        <MenuItem value={"Transfer"}>Transfer</MenuItem>
      </Select>
    
    </FormControl>
  
</div>
<div className='Test_paddingDiv'>
<TextField id="outlined-basic"   sx={{
    '&': {
      userSelect: urlType === 'collaborator' || urlType === 'student' ? 'none' : 'auto',
      pointerEvents: 'none',
    },
  }} disabled label="Supplementary essay" name="supplementary_eassy" variant="outlined" fullWidth   minRows={4}  multiline size="small" value={formData. supplementary_eassy}   />
</div>

</div>

</Box>

        </div>
        <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 2 }style={{paddingTop:"20px"}} >
        <Box >
          <Box className='Test_paddingBox2'>
              <Typography>Did this college offer any financial aid?</Typography>
              <div >
    <RadioGroup
      aria-label="Options"
      name="options"
      value={selectedOption}
      disabled 
      sx={{display:"flex",flexDirection:"row"}}
    >
      <FormControlLabel
        value="yes"
        control={<Radio />}
        label="Yes"
       
      />
      <FormControlLabel
        value="no"
        control={<Radio />}
        label="No"
      />
    </RadioGroup>

  </div> 
          </Box>
          {scholarships.map((scholarship, index) => (
               <div key={index} style={{paddingTop:index !== 0 ?"50px":""}}>
          {isFieldsVisible && (
              <>
      <div className='Test_FlexRow2'>
      
        <FormControl  className='Collaborator_aid_pkg' sx={{width:"48.5%",mb:3}} size="small" >
          <InputLabel id="demo-simple-select-label${index}">Aid/Scholarship</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select${index}"
           
            name={`aid_type${index}`}
           
            value={scholarship[`aid_type${index}`] || ''}
            label="Aid/Scholarship"
            disabled 
          >
            <MenuItem value={"Aid"}>Aid</MenuItem>
            <MenuItem value={"Scholarship"}>Scholarship</MenuItem>
            {/* <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
        {/* {aidtypeError && (
  <FormHelperText>{aidtypeError}</FormHelperText> )} */}
    {index !== 0 && (
      <DeleteOutlinedIcon className='Mui_edit_icon' disabled   sx={{color:'#D32F2F',padding:0}}/>
         )}
      </div>
          <div className='Test_FlexRow'>
          <TextField id="outlined-basic${index}" disabled label="Aid/Scholarship name" name={`socholarship_name${index}`}  variant="outlined"  fullWidth size="small" value={scholarship[`socholarship_name${index}`] || ''}    />
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label${index}">Duration</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select${index}"
              
              name={`duration_of_aid${index}`}
             
              value={scholarship[`duration_of_aid${index}`] || ''}
              label="Duration"
              disabled
            >
              <MenuItem value={30}>Semesterly</MenuItem>
              <MenuItem value={40}>Yearly</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          {/* {durationofaidError && (
  <FormHelperText>{durationofaidError}</FormHelperText> )} */}
        </div>
        <TextField className='Collaborator_Total_pkg' id="outlined-basic${index}" label="Total package $" name={`aid_package_per_unit${index}`}  variant="outlined"   value={scholarship[`aid_package_per_unit${index}`] || ''} disabled sx={{width:"48.5%",marginTop: 3}} size="small" />
        </> 
    )}
    
     </div>
    ))}
{/* <div className='Test_paddingDiv'>
      <Button sx={{display:isFieldsVisible?"":"none"}}
        variant="contained"
        color="inherit"
        size="large"
        fullWidth
        disabled
      >
        + ADD ANOTHER AID      
      </Button>
      {AidError && (
  <FormHelperText  sx={{color:"#f44336"}}>{AidError}</FormHelperText>
)}
    </div> */}
</Box>

        </div>
       
        <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 3}>
        <Box className='Test_paddingBox2' >
        <div>
    {recommenders.map((recommender, index) => (
      <div key={index} className='Test_FlexRow_recomenders'>
        <TextField
        sx={{mb:2}}
          id={`name-${index}`}
          name={`recommenders_name${index}`}
          label="Name"
          variant="outlined"
          fullWidth
          size="small"
          value={recommender[`recommenders_name${index}`] || ''}
          disabled
          
        />
        <FormControl fullWidth size="small"  sx={{mb:2}}>
          <InputLabel  id={`description-label-${index}`}>Recommender</InputLabel>
          <Select
            labelId={`description-label-${index}`}
            id={`description-${index}`}
            name={`recommenders_description${index}`}
            value={recommender[`recommenders_description${index}`]?.toString() || ''}  
            label="Recommender"
            disabled
           
          >
            <MenuItem value="Teacher">Teacher</MenuItem>
            <MenuItem value="Parent">Parent</MenuItem>
            <MenuItem value="Adviser">Adviser</MenuItem>
          
            <MenuItem value="Other Recommender">Other Recommender</MenuItem>
    
          </Select>
        </FormControl>
      </div>
    ))}

    {/* <div className='Test_paddingDiv'>
      <Button
        variant="contained"
        color="inherit"
        size="large"
        fullWidth
        onClick={addRecommender}
      >
        + ADD ANOTHER RECOMMENDER       
      </Button>
      {RecommerdsError && (
  <FormHelperText sx={{color:"#f44336"}}>{RecommerdsError}</FormHelperText>
)}
    </div> */}
  </div>


</Box>

  </div>
  <div  className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 4} >
  <Box className='Test_paddingBox'>
<div className='Test_DivColumn_center'>
<Typography>Did you get admitted into this college?</Typography> 
<div className='Test_paddingDiv'>
      
      <RadioGroup
      clas
        aria-label="Decision Options"
        name="decisionOptions"
        value={selectedOption2}
        
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <FormControlLabel
          value="Accepted"
          control={<Radio sx={getRadioStyle('Accepted')}/>}
          label={<span className='Colleges_status' style={{ color: 'green',marginRight:"40px" }}>Accepted</span>} 
        />
         <FormControlLabel
            value="Attended"
            control={<Radio sx={getRadioStyle('Attended')}/>}
            label={<span className='Colleges_status' style={{ color: 'blue',marginRight:"40px" }}>Attended</span>} 
          />
        <FormControlLabel
          value="Rejected"
          control={<Radio sx={getRadioStyle('Rejected')}  />}
          label={<span className='Colleges_status'  style={{ color: 'red',marginRight:"40px" }}>Rejected</span>}
        />
        <FormControlLabel
          value="Waitlisted"
          control={<Radio sx={getRadioStyle('Waitlisted')} />}
          label={<span className='Colleges_status' style={{ color: 'orange',marginRight:"40px" }}>Waitlisted</span>}
        />
        <FormControlLabel
          value="Deferred"
          control={<Radio  sx={getRadioStyle('Deferred')} />}
          label={<span className='Colleges_status'  style={{ color: 'purple',marginRight:"40px" }}>Deferred</span>}
        />
      </RadioGroup>
      {DecisionoptionError && (
  <FormHelperText sx={{color:"#f44336"}}>{DecisionoptionError}</FormHelperText>
)}
    </div>
</div>


</Box>
 

  </div>
  <div  className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 5} >
    <Box className='Test_paddingBox2' >
    <TextField id="outlined-basic"   sx={{
    '&': {
      userSelect: urlType === 'collaborator' || urlType === 'student' ? 'none' : 'auto',
      pointerEvents: 'none',
    },
  }} disabled label="Response" name="your_interest" error={Boolean(yourinterestError)} helperText={yourinterestError} variant="outlined" fullWidth  value={formData.your_interest}      minRows={12}  multiline size="small" />
 
    </Box>
 
  </div>
  </Box>
  <div className='Test_buttonDiv'>
      <Button variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:"none"}}  onClick={() => handleBackButton(userid)} >Back</Button>
      {/* {collaborators.map((content) => ( */}
      <Button      variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}}  onClick={() => moveToNextTab(userid)}  disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  // Display the circular loading icon
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  // Display the "SUBMIT" text
                  value === 5 ? "CLOSE" : "NEXT"
                )}</Button>
      {/* ))} */}
    </div>
    
</Box>

</Box>

   

</Grid>
       

 
    </Grid>
    
  </div>
  )
}

export default Collaborator_college_form