import { Grid } from '@mui/material'
import React,{useState,useEffect}from 'react';
import Topbar from './Topbar'
import backgroundimage from '../../assets/img/bg-img.png'
import Tick from '../../assets/img/fill-tick.png'
import './Students.css'
import Tick2 from '../../assets/img/tickorange.svg';
import CircularProgress from '@mui/material/CircularProgress';


// const pricingPlans = [
//     {
//       name: 'Monthly',
//       description: 'Billed Monthly',
//       price: 9.99,
//       features: ['2 team members', '1,000 Contacts & Companies', '5 Campaign Workflows','5 Nodes Per Campaign','2 Automation Rules (Triggers)'],
//       buttonText: 'Get Started Now',
//       backgroundColor: '#006CB7',
//       planid :'price_1O5490AsIPOfuwS4gOZdYWxP'
//     },
//     {
//       name: 'Semesterly',
//       description: 'Billed every 6 months',
//       price: 34.99,
//       features: ['10 team members', '2,500 Contacts & Companies', '5 Campaign Workflows','10 Nodes Per Campaign','5 Automation Rules (Triggers)'],
//       buttonText: 'Get Started Now',
//       backgroundColor: '#EF6C00',
//       planid :'price_1O54ATAsIPOfuwS4dWaHlEFb'
//     },
//     {
//       name: '4 Years',
//       description: 'Billed Annually',
//       price: 99.99,
//       features: ['Unlimited team members', '5,000 Contacts & Companies', '25 Campaign Workflows','20 Nodes Per Campaign','15 Automation Rules (Triggers)'],
//       buttonText: 'Get Started Now',
//       backgroundColor: '#006CB7',
//       planid :'price_1O54CZAsIPOfuwS4MBkOzdEs'
//     },
//   ];
const pricingPlans = [
  {
    recomders:"Best for Transfer Students", 
    name: 'Monthly',
    description: 'Billed Monthly',
    price: 9.99,
    Actualprice:14.99,
    // features: ['2 team members', '1,000 Contacts & Companies', '5 Campaign Workflows','5 Nodes Per Campaign','2 Automation Rules (Triggers)'],
    buttonText: 'Get Started Now',
    features: ['College Information', 'Student Profiles', 'Admission Essays','Test Scores','Messaging'],
    backgroundColor: '#006CB7',
    planid :'price_1OSZieAsIPOfuwS4mjEPnSeQ',
    image:Tick
  },
  {
    recomders:"Recommended", 
    name: 'Semesterly',
    description: 'Billed every 6 months',
    price: 34.99,
    Actualprice:49.99,
    // features: ['10 team members', '2,500 Contacts & Companies', '5 Campaign Workflows','10 Nodes Per Campaign','5 Automation Rules (Triggers)'],
    features: ['College Information', 'Student Profiles', 'Admission Essays','Test Scores','Messaging'],
    buttonText: 'Get Started Now',
    backgroundColor: '#EF6C00',
    planid :'price_1OSZiWAsIPOfuwS4QugJj7aP',
    image:Tick2
  },
  {
    recomders:"Best for Freshman and Sophomores", 
    name: '4 Years',
    description: 'One-time purchase',
    price: 149.99,
    Actualprice:249.99,
    // features: ['Unlimited team members', '5,000 Contacts & Companies', '25 Campaign Workflows','20 Nodes Per Campaign','15 Automation Rules (Triggers)'],
    features: ['College Information', 'Student Profiles', 'Admission Essays','Test Scores','Messaging'],
    buttonText: 'Get Started Now',
    backgroundColor: '#006CB7',
    planid :'price_1OXL59AsIPOfuwS42OalgPaQ',
    image:Tick
  },
];

  
  function PricingGridItem({ name,plan, handlePlanSelection}) {
    //const { plan } = props;
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClick = async () => {
      setIsLoading(true);
      await handlePlanSelection(name,plan);
      setIsLoading(false);
    };

    return (
      <Grid
     container
      xs={12}
      sm={12}
      md={4}
      lg={4}
      xl={4}
      sx={{
        maxWidth:"1920px",
       
      }}>
  <div  className='Pricing_uper_div' style={{background: plan.recomders === "Recommended" ? "rgba(239, 108, 0, 0.20)": "rgba(0, 108, 183, 0.20)",padding:"8px 14px 20px 14px",width:"82%",marginBottom:"-10px", borderRadius: "16px 16px 0 0",textAlign:"center"}}>
<typography className="ValidityTextecondaryGrid12" style={{color:plan.backgroundColor}}>{plan.recomders}</typography>
</div> 
   
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="pricing_one" // Add the class name "pricing_one"
  sx={{backgroundColor:"#FFF"}}
      >
       
        {/* <Grid
           item
           xs={12}
           sm={12}
           md={12}
           lg={12}
           xl={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <typography className="paymentValidityText">{plan.name}</typography>
          
          <typography className="ValidityTextecondaryGrid1" style={{color:plan.backgroundColor}}>
            {plan.description}
          </typography>
         
          <p>
            <typography className="paymentPrice">
              <sub className="top-sub">$</sub>
              {plan.price}
            </typography>
          </p>
        </Grid> */}
        <Grid
  item
  xs={12}
  sm={12}
  md={12}
  lg={12}
  xl={12}
  className="pricing_two" // Add the class name "pricing_two"
>
 
        
        <typography className="paymentValidityText">{plan.name}</typography>
    
        <typography className="ValidityTextecondaryGrid1" style={{color:plan.backgroundColor}}>
          {plan.description}
        </typography>
       
       
          <typography className="paymentPrice">
            <sub className="top-sub">$</sub>
            {plan.price}
          </typography>
       
        <typography className="actualpaymentPrice">
          <s>
            ${plan.Actualprice}
          </s>
        </typography>
      </Grid>
        {/* <Grid>
          <typography className="planstitle">This plan includes:</typography>
          {plan.features.map((feature, index) => (
            <Grid
              item
              key={index}
              sx={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}
            >
              <img
                src={Tick}
                className="priceImage"
          
                alt="Tick icon"
              />
              <typography className="plansDec">{feature}</typography>
            </Grid>
          ))}
        </Grid> */}
         <Grid>
        <typography className="planstitle">This plan includes:</typography>
        {plan.features.map((feature, index) => (
       <Grid
       item
       key={index}
       className="pricing_three" // Add the class name "pricing_three"
     >
            <img
                src={plan.image}
              className="priceImage"
        
              alt="Tick icon"
            />
            <typography className="plansDec">{feature}</typography>
          </Grid>
        ))}
      </Grid>
        <div className='Pricing_one'>
        <Grid
          item
          className='pricing_four'
          sx={{
           
          
            
            backgroundColor: plan.backgroundColor,
          
         
          }}
        >
        <div>
  {isLoading ? (
    <CircularProgress size={24} color="inherit" />
  ) : (
    <a href="" className="buttonText" onClick={() => {
      handlePlanSelection(name,plan);
      handleClick();
    }}>
      {plan.buttonText}
    </a>
  )}
</div>

        </Grid>
        </div>
        {/* <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          <typography className="creditText">
            No credit card required.
          </typography>
        </Grid> */}
      
      </Grid>
      </Grid>
    );
  }

  

const StudentPricing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handlePlanSelection = async (name,plan) => {
    
    try {
      setIsLoading(true);
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
      console.log("Plan Name:", name);
      console.log("Plan ID:", plan);
      event.preventDefault();
      const response = await fetch(`${apiUrl}/createCheckoutSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ planId: plan.planid, userId, planname: name.name }),
      });
     
      if (response.ok) {
        setIsLoading(false);
        const data = await response.json();
        console.log(data, 'Checkout Session created');
        // Redirect to the Stripe checkout page using the session ID
        window.location.href = data.checkout_url; 
      } else {
        setIsLoading(false);
        console.error('Request failed');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Data fetch failed:', error);
    }
  };
  
   
  return (
    <>
     <Topbar/>
     <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >


 
      
<Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "15px 0px 15px 0px",display:'flex',flexDirection:'column',alignItems:'center'}}
      >
 
       
        <Grid
          item
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{
            // paddingTop: '150px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // paddingBottom: '30px',
          }}
        >
          
          <Grid
            item
            sx={{
              display: 'flex',
            //   paddingBottom: '20px',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <typography className='Plan_main'>
              Choose the plan that's right for you
            </typography>
           
          </Grid>
        
      </Grid>
      <div className='Pricing_four'>
        <div className='Pricing_five'>
      <Grid
        container
        className="pricingmain_five" 
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          
        }}
      >
        {pricingPlans.map((plan, index) => (
         <PricingGridItem key={index} name={plan.name} plan={plan} handlePlanSelection={handlePlanSelection}/>
        ))}
      </Grid>
      </div>
      </div>
 
      </Grid>
      </Grid>
      
     

      
    </>
  )
}

export default StudentPricing
