import React,{useState} from 'react'
import Grid from '@mui/system/Unstable_Grid/Grid';
import Headermain from '../components/header/header';
import Questions from '../components/Questions/Questions';
import FooterMain from '../components/footer/footer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ_s = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleAccordionChange = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedItem(option);
  };

  // Define your accordion data here
  const accordionData = [
    {
      title: 'How do I use ApplyUpp to get accepted into college?',
      options: ['ApplyUpp recommends you handle the college application phase in multiple steps. You should first research the process, including looking at different University profiles to see where you would best fit. Then, you should start brainstorming for your application, listing ideas you see in collaborator profiles and valuable experiences you have had. After that, you can start working on your application, gathering all of your documentation and completing your application similarly to your favorite collaborators. You should then review your entire application, ensuring all parts are submitted and asking for feedback/revisions from collaborators. Finally, you are ready to submit your applications and upload them onto ApplyUpp.'],
    },
    {
      title: 'What does ApplyUpp do about plagiarism?',
      options: ['ApplyUpp takes plagiarism very seriously. We have a thorough system to ensure that all data on our site is original and will not be plagiarized by any college applicants. These processes help us maintain a plagiarism-free platform for everyone involved in the college application progress.'],
    },
    {
      title: 'How do I know if profiles are authentic?',
      options: ['ApplyUpp personally verifies all applications in our system to ensure they are authentic. All key details are reviewed, and the collaborator is notified in case of any potential discrepancies. This manual review system helps us ensure that all applications are authentic and that prospects will only come across accurate information.'],
    },
    {
      title: 'How will I get paid as a collaborator?',
      options: ['ApplyUpp will pay you based on how much engagement your profile gets from prospects. Engagement is measured through analytics like how many people have viewed your profile, how long they stay on it, and what parts of your profile they view, among others. You will be able to track all of this through your dashboard.'],
    },
   
    {
      title: 'Is my information secure?',
      options: ['ApplyUpp prioritizes the security of your information. We ensure that all data is securely stored within our system and safe from third parties. ApplyUpp employs high-importance security measures to safeguard your personal, educational, and financial data. Our website and payment processing are encrypted, and we follow the best practices to protect your privacy and information.'],
    },
   
    {
      title: 'How does the referral system work?',
      options: ['ApplyUpp has a referral system in place to reward both Collaborators and Prospects. Collaborators can earn a $5(not confirmed) reward for each college student who builds a profile using their unique collaborator invite link. On the other hand, prospects can also earn a $5(not confirmed) reward for each college applicant who subscribes to the site using their unique prospect invite link.'],
    },
    // Add more items as needed
  ];
  return (
    <>
   
   <Grid
  container
  component="main"
  className="faq_one" // Add the class name "faq_one"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
<Grid
  container
  component="main"
  className="faq_two" // Add the class name "faq_two"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
       <div className='FAQ_one'>
    <div className='FAQ_two'>
        <Headermain/>
     </div>
     </div>
     <Grid
  item
  lg={10}
  xl={10}
  xs={10}
  sm={10}
  md={10}
  className="faq_three" // Add the class name "faq_three"
>
<Grid item className="faq_four">
                <typography  className='companyTitle'>Frequently Asked Questions</typography>
                <typography  className='companyDescription'>Have a question? See if we already answered it</typography>
            </Grid>
        </Grid>
    </Grid>
    {/* <Questions/> */}
    <div className='FAQ_three'>
    <div className='FAQ_four'>
    <Grid
  container
  component="main"
  className="faq_five" // Add the class name "faq_five"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
      <Grid container spacing={2} sx={{boxShadow:"none"}}>
      {accordionData.map((item, index) => (
        <Grid item xs={12} sm={12} key={index} sx={{boxShadow:"none"}} >
          <Accordion
            expanded={selectedItem === item.title}
            onChange={() => handleAccordionChange(item.title)}
            sx={{boxShadow:"none",padding:"16px 16px",
            backgroundColor:"#F3F3F3"}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ borderRadius:'10px',padding:"0px",backgroundColor:"#F3F3F3"}}
            >
              <typography>{item.title}</typography>
            </AccordionSummary>

            <AccordionDetails sx={{backgroundColor:"#F3F3F3",padding:"0px"}}>
              <div>
                {item.options.map((option, optionIndex) => (
                  <typography
                    key={optionIndex}
                    
                    onClick={() => handleOptionClick(option)}
                   
                  >
                    {option}
                  </typography>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
      {/* <div>
        <p>Selected Item: {selectedItem}</p>
      </div> */}
    </Grid>
    </div>
    </div>
 
        </Grid>
           <FooterMain/>
           </>
  )
}

export default FAQ_s