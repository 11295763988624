import React,{useState,useEffect}  from 'react'
import Grid from '@mui/material/Grid';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography,InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import { useLocation,useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';

import './Student_profile.css'
import Topbar from '../Topbar';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Topbar as Topbar2 } from '../../Admin/Topbar';


const Student_testing = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const urlParams = new URLSearchParams(window.location.search);
  const urlType = urlParams.get('type');
  const userid = urlParams.get('userid');
  function encodeAlphanumeric(userid) {
    const encoded = btoa(userid); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  } 
    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
    const location = useLocation();
    const navigate = useNavigate();
    const breadcrumbs = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href={urlType === 'admin' ? `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}` : `/student_complete_profile`}
       
      >
        Profile
      </Link>,
   
      <Typography key="3" color="text.primary">
        Testing
      </Typography>
    ];
    const [value, setValue] = React.useState(0);
    const [test, setTest] = useState([
    
      {  }
    ]);
    const [ErrorTest,setErrorTest] = useState('');
    const [open, setOpen] = React.useState(false);
    // const [showAlert, setShowAlert] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [formid, setformid] = useState('');
    const [isLoading, setIsLoading] = React.useState(false);
   const[testingdata,setTestingdata]=useState('')
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [age, setAge] = React.useState('');
  
    const handleChanges = (event) => {
      setAge(event.target.value);
    };
  
    const [formData, setFormData] = useState([]);
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
  const selectedTestingId = searchParams.get('id');
  setformid(selectedTestingId);
  const apiUrl = process.env.REACT_APP_API_URL;
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.id;
  console.log('Testing ID:', selectedTestingId);
  
    if (selectedTestingId !== '') {
      try {
        fetch(`${apiUrl}/get_Student_testing_form_data`, {
          method: 'post',
          body: JSON.stringify({ formid: selectedTestingId }),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error('Request failed');
            }
          })
          .then((data) => {
            const testingdata = data.formdata[0];
            setTestingdata(data.formdata);
            console.log(data, "setTestingdata");
  
            // Parse the testdata array from the API response
            const parsedTestData = JSON.parse(testingdata.testdata || '[]');
  
            // Organize the parsed data into an array of objects
            const formDataArray = parsedTestData.map((item, index) => ({
              testname: item[`testname-${index}`] || '',
              startDate: dayjs(item[`start-date-${index}`]).format('MM/DD/YYYY') || '',
              attempts: item[`attempts-${index}`] || '',
              score: item[`score-${index}`] || '',
             
            }));
            
            setTest(parsedTestData);
            // Update the formData state with the organized data
            setFormData(formDataArray);
           console.log(formDataArray,"formDataArray")
           const testNames = formDataArray.map(item => item.testname);
  console.log(testNames,"testNames");
  
          })
          .catch((error) => {
            console.error('Data fetch failed:', error);
          });
      } catch (error) {
        console.error('Data upload failed:', error);
      }
    }
  }, []);
  // const addTest = () => {
  //   setTest([...test, { testname: '', startDate: null, attempts: '', score: '' }]);
  // };
  // const addTest = () => {
  //   const lastRecommender = test[test.length - 1];
  
  //   // Check if the last recommender is not empty
  //   const isLastRecommenderEmpty = Object.values(lastRecommender).every(
  //     (value) => value === undefined || value === ''
  //   );
  
  //   if (!isLastRecommenderEmpty) {
  //     // Check for duplicates
  //     const isDuplicateRec = test.slice(0, test.length - 1).some((existingRecommender) =>
  //       Object.keys(existingRecommender).every((key) =>
  //         JSON.stringify(existingRecommender[key]) === JSON.stringify(lastRecommender[key])
  //       )
  //     );
  
  //     if (!isDuplicateRec) {
  //       // Add a new empty recommender
  //       setTest([...test, {}]);
  //       setErrorTest('');
  //     } else {
  //       setErrorTest('Duplicate data. Please enter unique values for each award.');
  //     }
  //   } else {
  //     setErrorTest('Please fill in all fields before adding another rewards.');
  //   }
  // };
  const handleTestSelectChange = (event, fieldName, index) => {
    
    const { name, value } = event.target;
    const updatedTest = [...test];
    let error = '';

    // Apply validation based on the field name
    if (name.includes('attempts-')) {
      // Allow only numeric input with up to 2 digits
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 2);
      updatedTest[index][fieldName] = sanitizedValue;
      if (sanitizedValue !== value) {
        error = 'Invalid input. Please enter only numeric values with up to 2 digits.';
      }
    } else if (name.includes('score-')) {
      // Allow only numeric input with up to 3 digits
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 4);
      updatedTest[index][fieldName] = sanitizedValue;
      if (sanitizedValue !== value) {
        error = 'Invalid input. Please enter only numeric values with up to 4 digits.';
      }
    } else {
      // For other fields, update the state directly without validation
      updatedTest[index][fieldName] = value;
    }

    console.log(updatedTest);
    setTest(updatedTest);
    setErrorTest(error);
  // updatedTest[index][fieldName] = value; // Use the 'fieldName' argument to update the specific field
  // console.log(updatedTest);
  // setTest(updatedTest);
};
  // const handleTestSelectChange = (event, fieldName, index) => {
  //   const { name, value } = event.target; // Get the name and value from the event
  //   const updatedTest = [...test];
  //   updatedTest[index][fieldName] = value; // Use the 'fieldName' argument to update the specific field
  //   console.log(updatedTest);
  //   setTest(updatedTest);
  // };
  const handleDateChange = (date, fieldName, index) => {
    const updatedTest = [...test];
    updatedTest[index][fieldName] = date; // Assuming 'fieldName' is the date field name
    console.log(updatedTest);
    setTest(updatedTest);
  };
  
  
    
    const handleSubmit = async () => {
      
      if (urlType !== 'admin') {
      const testData = test;
      const processedtestData = preprocessData(testData);
      
    const isDuplicate = processedtestData.some((current, index) =>
    processedtestData.slice(index + 1).some((other) => {
        const currentValues = Object.values(current).sort().join('');
        const otherValues = Object.values(other).sort().join('');
        return currentValues === otherValues;
      })
    );
  console.log(testData,"testData");
  
  if (isDuplicate) {
    // Awards contain duplicates
    setErrorTest('Duplicate data. Please enter unique values for each award box field.');
    return false;
  }
    console.log(testData,"test data ");
    const hasEmptyFields = testData.some((testItem,index ) => {
      return !(
        testItem[`testname-${index}`] &&
        testItem[`attempts-${index}`] &&
        testItem[`start-date-${index}`] &&
        testItem[`score-${index}`]
      );
    });
    if (hasEmptyFields) {
      setErrorTest('All fields are mandatory. Please fill in all fields for each test.');
      return false;
    } else {
      setErrorTest('');
    }
      console.log(testData,"test data ");
      if (testData.some((test) => 'testname-0' in test || 'attempts-0' in test)) {
        setErrorTest('');
      } else {
        setErrorTest('Please add at least one test');
        return false;
      }
      
          const formattedTestData = test.map((testItem, index) => {
            const startDate = testItem[`start-date-${index}`];
            return { 
              [`testname-${index}`]: testItem[`testname-${index}`],
              [`start-date-${index}`]: startDate instanceof Date ? startDate.toISOString() : startDate,
              [`attempts-${index}`]: testItem[`attempts-${index}`],
              [`score-${index}`]: testItem[`score-${index}`],
            };
          });
          
     
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;
        
      try {
        setIsLoading(true);
        setOpen(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        if(formid!== ''){
          // alert(formid);
          formData.formid = formid; 
        }
        const response = await fetch(`${apiUrl}/save_student_testing`, {
          method: 'post',
          body: JSON.stringify({ tests: formattedTestData, userId ,formid}), // Send the entire formData object as the body
        });
        // setShowAlert(true);
        if (response.status === 201) {
          navigate(`/student_complete_profile`)
              setShouldNavigate(true);
        } else {
          console.error('Data upload failed:', response.statusText);
        }
      } catch (error) {
        setIsLoading(false);
        setOpen(false);
        console.error('Data upload failed:', error);
      }
    }
    else if(urlType == 'admin'){
      window.location.href = `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}`;
    }
    };
    useEffect(() => {
      if (shouldNavigate) {
        const navigateTimer = setTimeout(() => {
          // Navigate to the desired page after 2 seconds
          // setShowAlert(false);
          setIsLoading(true);
          setOpen(true);
          navigate('/student_complete_profile');
        }, 3000);
  
        // Clean up the timer to avoid memory leaks
        return () => clearTimeout(navigateTimer);
      }
    }, [shouldNavigate]);
    const preprocessData = (data) => {
      return data.map((item) => {
        const processedItem = {};
        Object.keys(item).forEach((key) => {
          const processedKey = key.replace(/-\d+$/, ''); // Remove the index suffix
          processedItem[processedKey] = item[key];
        });
        return processedItem;
      });
    };
    return (
      <div>
      {/* <Topbar /> */}
    {urlType === 'admin' ? <Topbar2 /> :  <Topbar />}

      <Grid
        container
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{
          padding: "20px",
          minHeight: "92vh",
          backgroundImage: `url(${backgroundimage})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <Grid
          item
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{ background: "rgba(255, 255, 255, 0.80)",
          backdropfilter: "blur(25px)", padding: "20px" }}
        >
                <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
         <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
          
      
          <Box className="Test_BoxDiv">
          <Box className="Student_testing_from" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <div role="tabpanel" hidden={value !== 0}>
            
            <Box className="Test_paddingBox" >
               
            {test.map((testItem, index) => (     
             
               
    <div className='Collaborator_activities_frorm' key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 20, justifyContent: "center", paddingTop: 20 }}>
         {console.log('date formate :', testItem[`start-date-${index}`] )} 
     
      <FormControl fullWidth size="small" sx={{ mb: 2 }}>
        <InputLabel id={`demo-simple-select-label-${index}`}>Select Test *</InputLabel>
        <Select
          labelId={`demo-simple-select-label-${index}`}
          id={`demo-simple-select-${index}`}
          name={`testname-${index}`}
          label="Select Test *"
          value={testItem[`testname-${index}`] || '' } // Use the correct key
          onChange={(e) => handleTestSelectChange(e, `testname-${index}`, index)} // Pass the field name
          disabled={urlType=== 'admin'}
          
        >
          <MenuItem value="SAT">SAT</MenuItem>
          <MenuItem value="ACT">ACT</MenuItem>
          <MenuItem value="AP">AP</MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker id={`start-date-${index}`} value={
      testItem[`start-date-${index}`]
        ? dayjs(testItem[`start-date-${index}`])
        : null
    }   name={`start-date-${index}`} label="Date *" size="small" sx={{ mb: 2, width: "100%", "& .MuiInputBase-root": { height: "2.6rem" } }}  maxDate={dayjs()}   onChange={(date) =>
              handleDateChange(date, `start-date-${index}`, index) // Pass the index and name to handleDateChange
            }disabled={urlType=== 'admin'} />
      </LocalizationProvider>
      <div className='Test_FlexRow_special2'>
        <TextField disabled={urlType=== 'admin'} id={`attempts-${index}`} name={`attempts-${index}`} label="No. of Attempts *" variant="outlined"   value={testItem[`attempts-${index}`] || ''} fullWidth size="small"   onChange={(event) => handleTestSelectChange(event, `attempts-${index}`, index)}/>
        <TextField disabled={urlType=== 'admin'} id={`score-${index}`} name={`score-${index}`} label="Highest Score *" variant="outlined" value={testItem[`score-${index}`] || ''}fullWidth size="small" onChange={(event) => handleTestSelectChange(event, `score-${index}`, index)} />
      </div>
    </div>
  
  
  
  ))}
    </Box>
    {ErrorTest && (
    <FormHelperText sx={{color:"#d32f2f"}}    >{ErrorTest}</FormHelperText>
  )}
    {/* <div className="Test_paddingDiv">
  <Button variant="contained" color="inherit" size="large"fullWidth onClick={addTest}>+ ADD ANOTHER TEST</Button>
  </div> */}
  
            </div>
            <div className='Test_buttonDiv'>
    <Link
        underline="hover"
        key="1"
        color="inherit"
        // href={`/student_complete_profile`} 
        href={urlType === 'admin' ? `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}` : `/student_complete_profile`}
       
      >
      <Button className='Student_Button_activities' variant="contained" color="inherit" size="large" sx={{width:"180px",  boxShadow:"none"}}>Back</Button></Link>
      {/* <Link
        underline="hover"
        key="1"
        color="inherit"
        href={`/student_complete_profile`} 
       
      > */}
      <Button className='Student_Button_activities' variant="contained" color="primary" size="large" sx={{width:"180px",  boxShadow:"none"}} onClick={handleSubmit}  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    urlType === 'admin' ? 'CLOSE' : 'SAVE'
                  )}</Button>
                  {/* </Link> */}
    </div>
           </Box>
           
          </Box>
          {/* {showAlert && (
          <Alert
            severity="success"
            sx={{
              position: 'absolute',
              bottom: 0, // Place the Alert at the bottom
              left: 0,
              right: 0,
            }}
          >
            <AlertTitle>Success</AlertTitle>
            Your data was successfully saved! Redirecting...
          </Alert>
        )} */}
        </Grid>
      </Grid>
    </div>
    )
  }
export default Student_testing
