import { Box, Grid } from '@mui/material'
import React from 'react'

export const Admin_searchcollaborator = () => {
  return (
    <div 
    className='Admin_collaborator_Container'
    >
       <Grid container  className='Home_collaboraotor_view'
       lg={12}
       xl={12}
       xs={12}
       sm={12}
       md={12}
   
      >
        <Box>
          <typography>Search Collaborators</typography>
        </Box>
      

       </Grid>
      
    </div>
  )
}
