import { ThemeProvider } from '@material-ui/styles'
import { Box, Button, CircularProgress, Grid, Paper, TextField, Typography, createTheme } from '@mui/material'
import React, { useState } from 'react';
import './admin.css'
import MainLogo from '../../assets/img/logo.png'
import Line from '../../assets/img/Vector_line.png'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Admin_login = () => {
  const defaultTheme = createTheme();


  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("");
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [loginError, setLoginError] = React.useState(null);
  const [loginSuccess, setLoginSuccess] = React.useState(null);
  const [firstText, setFirstText] = useState("");
  const [secondtext, setSecondText] = useState("");
  const [bottomText, setBottomText] = useState("");
 
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    // type: "admin",
  });

  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    console.log(formData, "this is form data");
  };
  






  const handleSubmit = async (event) => {
    event.preventDefault();
    // formData.type = 'admin';
    console.log(formData,"Formdata from api")
    setPasswordError(null);
    setEmailError(null);
    setLoginError(null);
    setLoginSuccess(null);
    try {
      setIsLoading(true);
      const apiUrl = process.env.REACT_APP_API_URL;
  
      const response = await fetch(`${apiUrl}/admin/login`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });
      console.log(response);
  
      let data;
      if (response.ok) {
        data = await response.json();
        console.log(data);
        if (response.status ===  200) { // Corrected status code
          localStorage.setItem('accessToken', data.access_token);
          localStorage.setItem("userData", JSON.stringify(data.user));
          setLoginSuccess(data.message);
          setIsLoading(false);
          navigate(`/admin-dashboard`);
          return;
        }
      } else {
        data = await response.json();
        setIsLoading(false);
        if (data.error) {
          if (data.error.email) {
            setEmailError(data.error.email[0]);
          }
          if (data.error.password) {
            setPasswordError(data.error.password[0]);
          }
        }
        if (response.status === 401 || response.status === 404) {


          setLoginError("Unauthorized: Invalid credentials");
          return; // Exit the function early since error is set
        }
      }
    } catch (error) {
      console.error("Login failed:", error);
      setIsLoading(false);
      // Handle network errors or other exceptions here
    }
  };
  
  return (
   <ThemeProvider theme={defaultTheme}>
    <Grid container 
    component="main"
    className='admin_main_container_Login'
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    
    >
<Grid 
item
xs={false}
sm={4}
md={5.5}
lg={4}
xl={4}
className="admin_Login_Grid"
>
<Box></Box>
<Box className="admin_Login_text_area">
  <div>
    <img src={MainLogo} alt='MainLogo' className="admin_Login_logo_img"/>
  </div>
</Box>
<Box className="admin_Login_Bottom_box">
  <div className="admin_Login_Bottom_div">
    <img src={Line}></img>
    <typography className="admin_Login_Bottom_text">Administrator</typography>
  </div>
</Box>
</Grid>
<Grid 
item
lg={8}
xl={8}
xs={12}
sm={12}
md={6.5}
component={Paper}
elevation={6}
square
className="admin_Login_Textfield_grid"
>
  <Box className="admin_Login_Textfiled_Box">
    <Grid item
    lg={11} xl={11} xs={10} sm={10} md={11}
    >
      <Box 
      component="form"
      noValidate
      onSubmit={handleSubmit}
      className="admin_Login_Form_Textfield">
      <div className="admin_Login_header_text">
        <typography className="admin_Login_Welcome">Welcome back</typography>
        <typography className="admin_Login_Welcome_text">Good to see you again!</typography>
      </div>
      {loginError && <div className="error-message">{loginError}</div>}
              {loginSuccess && (
                <div className="success-message">{loginSuccess}</div>
              )}
      <TextField
       size="small"
       margin="normal"
       required
       fullWidth
       id="email"
       label="Email Address"
       name="email"
       autoComplete="email"
       onChange={handleChange}
       error={Boolean(emailError)}
                helperText={emailError}
       autoFocus/>
       <TextField
       size="small"
       margin="normal"
       required
       fullWidth
       name="password"
       label="Password"
       type="password"
       id="password"
       onChange={handleChange}
       error={Boolean(passwordError)}
                helperText={passwordError}
       autoComplete="current-password"
       />
   
  <Button
    type="submit"
    fullWidth
    variant="contained"
    className="admin_Login_button"
    style={{ boxShadow: 'none' }}
                disabled={isLoading} 
  >
    {isLoading ? (
      <CircularProgress size={24} color="inherit" />
    ) : (
      "LOGIN"
    )}
  </Button>

      </Box></Grid>
  </Box>
</Grid>

    </Grid>
   </ThemeProvider>
  )
}
