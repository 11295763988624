import React,{useState,useEffect}from 'react';
import {
  Box,
  Button,
  Avatar,
  Grid,
  typography,
  Divider,
} from "@mui/material";
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import Chatpic from '../../assets/img/_Avatar_.png';
import Chatpic1 from '../../assets/img/_Avatar_1.png';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './collaborator.css'
import Messages from './Messages';
import mainlogo from '../../assets/img/mainwelcome.png'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json'
import animationData2 from '../../assets/message.json'

import CircularProgress from '@mui/material/CircularProgress';
const students = [
  {
    name: 'Howie',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Alice',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'John',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Howie',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Bob',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Mia',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
];

function Collaborator_students() {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }

  // const [options, setOptions] = useState({
  //   loop: true,
  //   autoplay: false,
  //   animationData: null,// Default animation
  // });
  const [loadinguser, setLoadinguser] = useState(true); 
  const [defaultOptions2, setDefaultOptions]=useState()
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [collaborators, setcollaborators] = useState([]);
  const [sections, setsections] = useState([]);
  const [messagerequesterror, setmessagerequesterror] = useState([]);
  const [messagerequeststatus, setmessagerequeststatus] = useState('');
  const [messagerequestacceptrehecterror, setmessagerequestacceptrehecterror] = useState(''); 
  const shouldShowBorder = (index) => (index + 1) % 3 !== 0;
  const handleButtonClick = () => {
    setShowMessages(true);
  };


  useEffect(() => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      fetch(`${apiUrl}/get_all_collaborator_message_request`, {
          method: 'post',
          body: JSON.stringify({ userId }), // Send the entire formData object as the body
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
           
              throw new Error('Request failed');
          }
      })
      .then(data => {
          
          console.log(data , 'Messages requests'); // Log the awardsData 
          setsections(data.collaborators)  
          setLoadinguser(false);  
          //setcollaborators(data.collaborators);
         // alert("here");
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
  }, [messagerequeststatus]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      fetch(`${apiUrl}/get_all_collaborators_data_profiles`, {
          method: 'post',
          body: JSON.stringify({ userId }), // Send the entire formData object as the body
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
              throw new Error('Request failed');
          }
      })
      .then(data => {
          
          console.log(data,"This is data"); // Log the awardsData
          setcollaborators(data.collaborators);
          
         // alert("here");
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
  };


  function sendMessageRequest(collaboratorId) {
    try {
      setLoading(true); // Set loading to true before making the request

      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      fetch(`${apiUrl}/collaborator_message_request`, {
        method: 'post',
        body: JSON.stringify({ userId, collaboratorId }),
      })
        .then(response => {
          if (response.status === 201) {
            return response.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then(data => {
          console.log("message request", data.message);
          setmessagerequesterror(data.message);
        })
        .catch(error => {
          console.error('Data fetch failed:', error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after the request is completed
          setOpen(true);
          fetchData();
        });
    } catch (error) {
      console.error('Data upload failed:', error);
    }
  }

  function acceptmessagerequest(requestid,sendfrom,sendto) {
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    
  
   
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;

          fetch(`${apiUrl}/acceptmessagerequest`, {
              method: 'post',
              body: JSON.stringify({ requestid, sendfrom, sendto }), 
          })
          .then(response => {
              if (response.status === 201) {
                  return response.json(); 
              } else {
                  throw new Error('Request failed');
              }
          })
          .then(data => {
             
            setmessagerequeststatus("Accepted");
            setmessagerequestacceptrehecterror(data.message);
        
            
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
  }

  function rejectmessagerequest(requestid,sendfrom,sendto) { 
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    
  
   
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;

          fetch(`${apiUrl}/rejectmessagerequest`, {
              method: 'post',
              body: JSON.stringify({ requestid, sendfrom, sendto }), 
          })
          .then(response => {
              if (response.status === 201) {
                  return response.json(); 
              } else {
                  throw new Error('Request failed');
              }
          })
          .then(data => {
            setmessagerequeststatus("Rejected");
            setmessagerequestacceptrehecterror(data.message);
            
             
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
  }
  
  const handleClose = () => {
    setOpen(false);

  };
   
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  }
  const handleButtonClick2 = (content) => {
 
      window.location.href = `/studentProfile?id=${encodeAlphanumeric(content.id)}`;

  };
  return (
    <>
     {showMessages ? (
        <Messages /> // Replace the view with the Messages component when showMessages is true
      ) : (
      <Grid container  lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12} className='collaborator_view_mainGrid' rowGap={3} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" ,height:'100%'}}>
        <Grid
        className='Collaborator_student_maingrid'
          item
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundColor: "white",
            display: 'flex',
            flexDirection: 'column',
            padding: "30px",
            flex: '20%',
          }} 
        >
          {/* Correct the component name to typography */}
          <typography className='message_text'>Messages Requests</typography>
          {messagerequestacceptrehecterror && (
                <div className="success-message">{messagerequestacceptrehecterror}</div>
              )}
                  {loadinguser ? (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>
) : (
              <div>

              
          {sections.length === 0 ? ( // Check if there are no message requests
             <Grid className='Collaborator_Norequestdiv' rowGap={3} item lg={12} xl={12} xs={12} sm={12} md={12} sx={{ backgroundColor: "white", padding: "30px" }}>
             <Box className="profile_view_mainDiv" >
             <img src={mainlogo} alt="Logo"  className="profile-view-main"/>
     <typography className="Profile_view_welcome">When you receive a Message Request, it will appear here</typography>
    
   
     </Box>
     
             </Grid>
          ) : (
            sections.map((section, index) => (
      <Box
        key={index}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
       <CardHeader
avatar={
<Badge color="success">
<Avatar aria-label="recipe" src={section.avatarSrc} />
</Badge>
}
title={
<typography variant="h6" sx={{ fontSize: '18px' }}>
{section.title}
</typography>
}
subheader={
<typography variant="body2" sx={{ fontSize: '14px' }}>
{section.subheader}
</typography>
}
/>

        <Box>
          <Button onClick={() => rejectmessagerequest(section.requestid, section.sendfrom, section.sendto)} variant="text"  size="small">
            {section.buttonLabel}
          </Button>
          <Button  onClick={() => acceptmessagerequest(section.requestid, section.sendfrom, section.sendto)} variant="text"  color="primary" size="small">
            Accept
          </Button>
        </Box>
      </Box>
     ))
     )}
     </div>
)}
        </Grid>
      
        <Grid className='Collaborator_students_viewdiv' rowGap={3} item lg={12} xl={12} xs={12} sm={12} md={12} sx={{ backgroundColor: "white", padding: "30px" }}>
          {/* Correct the component name to typography */}
          <typography className='message_text'>Suggested Collaborators</typography>
          <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie    options={{
            loop: true,
            autoplay: true,
            animationData: messagerequesterror === 'Your request is already sent.' ? animationData2 : animationData,
          }}
           height={150} width={150} />
         
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              {messagerequesterror === 'Your request is already sent.' ? 
            null
           : (
            <typography className="Success">Success</typography>
          )}
           
            <typography className='Submitted_text2'> {messagerequesterror && (
                <div className="success-message">{messagerequesterror}</div>
              )}</typography>
         
        
          </div>
          <DialogActions sx={{display:"flex",justifyContent:"center"}}>
          <Button onClick={handleClose}>Ok</Button>
          
       </DialogActions>
          </DialogContent>
          
        </Dialog>
          <Box className="Collaborators_views" sx={{ display: "grid", justifyContent: "center", rowGap:"94px",columnGap:"32px", alignItems: "center", gridTemplateColumns: "repeat(3, 3fr)",paddingTop:'60px' ,justifyItems:"center"}}>
            {collaborators.map((content, index) => (
              
              <>
             
            <div style={{display:"flex",justifyContent:"space-between",gap:"27%",alignItems:"center"}}>

            
              <Box
                key={index}
                sx={{
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: "column",
                  justifyItems: "center",
                  width:"180px"
                  
                }}
              >
                
                <Avatar aria-label="recipe" src={content.image}  sx={{width:"60px",height:"60px"}}/>
                {/* Correct the component name to typography */}
                <typography className='message_text_secondary'>
                {content.legal_name.split(' ').length > 4
    ? `${content.legal_name.split(' ').slice(0,4 ).join(' ')} ...`
    : content.legal_name}
                </typography>
                <Box sx={{ display: "flex", alignItems: "center" ,marginBottom:"10px"}}>
                  <LocationOnIcon sx={{color:"#58595B",width:"16px",height:"16px",gap:"4px"}} /> 
                  {/* Correct the component name to typography */}
                  <typography className='message_text_secondary2'> {content.city}</typography>
                </Box>
                {/* Correct the component name to typography */}
                <typography className='message_text_secondary21'>GPA - {content.gpsscore}/{content.gpaOutof}</typography>
               {/* <Chip label="Applying" color="success" size="large" variant="outlined" />*/}
                {/* Correct the component name to typography */}
                
                {content.collgename !== 'College TBA' && content.decisionOptions === 'Attended' ? (
        <typography className='message_text_secondary3'>
          {content.collgename.split(' ').length > 7
            ? `${content.collgename.split(' ').slice(0, 7).join(' ')} ...`
            : content.collgename}
        </typography>
      ) : (
        <typography className='message_text_secondary3'>
          College TBA
        </typography>
      )}
                <Button
              variant="outlined"
              sx={{width:"179px"}}
              color="primary"
              size="small"
              onClick={() => sendMessageRequest(content.id)}
            >
              {content.messageRequest === 'yes' ? 'Request Sent' : 'Message'}
            </Button>
            {/* <Button variant="outlined" onClick={() => handleButtonClick2(content)} sx={{ marginTop: "20px",width:"179px",boxShadow:"none" }}  size="small">
  View Profile
</Button> */}
              </Box>
              <Box className="collaborator_views_divider" sx={{ borderRight: shouldShowBorder(index) ? '1px solid #ccc' : 'none', height: '150px',marginTop:"20px"}}/> 
              </div>
              </>
            ))}
          </Box>
        </Grid>
      </Grid>
      )
              }
      
    </>
  );
  
};


export default Collaborator_students;