import React,{useState,useEffect}from 'react';
import {
  Box,
  Button,
  Avatar,
  Grid,
  typography,
} from "@mui/material";
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import Chatpic from '../../assets/img/_Avatar_.png';
import Chatpic1 from '../../assets/img/_Avatar_1.png';
import Chip from '@mui/material/Chip';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './collaborator.css'
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json'
import animationData2 from '../../assets/message.json'
import MenuItem from '@mui/material/MenuItem';
import Messages from '../collaborator/Messages';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
const cities = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Puerto Rico",
  "Guam",
  "U.S. Virgin Islands",
  "American Samoa",
  "Northern Mariana Islands"
  ];
  const states = [
    "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Puerto Rico",
  "Guam",
  "U.S. Virgin Islands",
  "American Samoa",
  "Northern Mariana Islands",
  "International"
  ];
  const students = [
    {
      name: 'Howie',
      location: 'Los Angeles, United States',
      avatarSrc: Chatpic,
      score: "GPA - 3.7",
      university: "University of California"
    },
    {
      name: 'Alice',
      location: 'Los Angeles, United States',
      avatarSrc: Chatpic,
      score: "GPA - 3.7",
      university: "University of California"
    },
    {
      name: 'John',
      location: 'Los Angeles, United States',
      avatarSrc: Chatpic,
      score: "GPA - 3.7",
      university: "University of California"
    },
    {
      name: 'Howie',
      location: 'Los Angeles, United States',
      avatarSrc: Chatpic,
      score: "GPA - 3.7",
      university: "University of California"
    },
    {
      name: 'Bob',
      location: 'Los Angeles, United States',
      avatarSrc: Chatpic,
      score: "GPA - 3.7",
      university: "University of California"
    },
    {
      name: 'Mia',
      location: 'Los Angeles, United States',
      avatarSrc: Chatpic,
      score: "GPA - 3.7",
      university: "University of California"
    },
  ];
  const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];
  const Major = [
    {
      value: 'Maths',
      label: 'Maths',
    },
    {
      value: 'Computer',
      label: 'Computer',
    },
    {
      value: 'Biology',
      label: 'Biology',
    },
    {
      value: 'Chemistry',
      label: 'Chemistry',
    },
  ];
  // const Colleges = [
  //   {
  //     value: 'oxford',
  //     label: 'Oxford University',
  //   },
  //   {
  //     value: 'standford',
  //     label: 'Standford University',
  //   },
  //   {
  //     value: 'harvard',
  //     label: 'Harvard University',
  //   },
  //   {
  //     value: 'georgia',
  //     label: 'Georgia University',
  //   },
  // ];
  const GPA = [
    {
      value: 'weighted',
      label: 'Weighted(1-4)',
    },
    {
      value: 'unweighted',
      label: 'UnWeighted(1-4)',
    },
   
  ];
  const sat = [
    {
      value: 'act',
      label: 'ACT Score(1-36)',
    },
    {
      value: 'sat',
      label: 'SAT Score(1-36)',
    },
   
  ];
  const essay = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
   
  ];

const SearchStudents = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
    const [showMessages, setShowMessages] = useState(false);
    const [collaborators, setcollaborators] = useState([]);
    const [students, setstudents] = useState([]);
    const [students2, setstudents2] = useState([]);
    const [loadinguser, setLoadinguser] = useState(true); 
    const [sections, setsections] = useState([]);
    const [messagerequesterror, setmessagerequesterror] = useState([]);
    const [messagerequeststatus, setmessagerequeststatus] = useState('');
    const [messagerequestacceptrehecterror, setmessagerequestacceptrehecterror] = useState(''); 
    const [searchTerm, setSearchTerm] = useState('');
    const [menuSelection, setMenuSelection] = useState();
    const [majorSelection, setMajorSelection] = useState();
    const [collegeSelection, setCollegeSelection] = useState([]);
    const [citySelection, setcitySelection] = useState('');
    const [gpaSelection, setGpaSelection] = useState('');
    const [satSelection, setSatSelection] = useState('');
    const [essaySelection, setEssaySelection] = useState('');
    const [Colleges, setColleges] = useState([]);
    const [searchText, setsearchText] = useState('');
    const [options, setOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(true);
  const toggleSearchVisibility = () => {
    setSearchVisible(!isSearchVisible); // Toggle the visibility state
  };
    const shouldShowBorder = (index) => (index + 1) % 3 !== 0;
    
    const handleButtonClick = () => {
      setShowMessages(true);
    };
  
  
    useEffect(() => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
  
        fetch(`${apiUrl}/get_all_collaborator_message_request`, {
            method: 'post',
            body: JSON.stringify({ userId }), // Send the entire formData object as the body
        })
        .then(response => {
            if (response.status === 200) {
                return response.json(); // Convert the response to JSON
            } else {
             
                throw new Error('Request failed');
            }
        })
        .then(data => {
            
            console.log(data , 'Messages requests'); // Log the awardsData 
            setsections(data.collaborators)    
            //setcollaborators(data.collaborators);
           // alert("here");
        })
        .catch(error => {
            console.error('Data fetch failed:', error);
        });
    } catch (error) {
        console.error('Data upload failed:', error);
    }
    }, [messagerequeststatus]);
  
    useEffect(() => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
  
        fetch(`${apiUrl}/get_all_students_data_profiles`, {
            method: 'post',
            body: JSON.stringify({ userId }), // Send the entire formData object as the body
        })
        .then(response => {
            if (response.status === 200) {
                return response.json(); // Convert the response to JSON
            } else {
                throw new Error('Request failed');
            }
        })
        .then(data => {
            
            console.log(data,"students data"); // Log the awardsData
            setstudents(data.collaborators);
            setLoadinguser(false);
           // alert("here");
        })
        .catch(error => {
            console.error('Data fetch failed:', error);
        });
    } catch (error) {
        console.error('Data upload failed:', error);
    }
    }, []);
  
  
    function sendMessageRequest(collaboratorId) {
      // You can pass the collaborator's ID or any other identifier to identify the recipient.
      // You can also add additional data to the request, such as the message content.
      
    
     
          try {
            setLoading(true); // Set loading to true before making the request
            const apiUrl = process.env.REACT_APP_API_URL;
            const userData = JSON.parse(localStorage.getItem('userData'));
            const userId = userData.id;
           
            fetch(`${apiUrl}/collaborator_message_request`, {
                method: 'post',
                body: JSON.stringify({ userId, collaboratorId }), 
            })
            .then(response => {
                if (response.status === 201) {
                    return response.json(); 
                } else {
                    throw new Error('Request failed');
                }
            })
            .then(data => {
               
                console.log(data.message); 
                setmessagerequesterror(data.message);
                // setTimeout(() => {
                //   setmessagerequesterror(''); 
                // }, 5000); 
              
            })
            .catch(error => {
                console.error('Data fetch failed:', error);
            })
            .finally(() => {
              setLoading(false); // Set loading to false after the request is completed
              setOpen(true);
              fetchCollaborators();
            });
        } catch (error) {
            console.error('Data upload failed:', error);
        }
    }
  
    function acceptmessagerequest(requestid,sendfrom,sendto) {
      // You can pass the collaborator's ID or any other identifier to identify the recipient.
      // You can also add additional data to the request, such as the message content.
      
    
     
          try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const userData = JSON.parse(localStorage.getItem('userData'));
            const userId = userData.id;
  
            fetch(`${apiUrl}/acceptmessagerequest`, {
                method: 'post',
                body: JSON.stringify({ requestid, sendfrom, sendto }), 
            })
            .then(response => {
                if (response.status === 201) {
                    return response.json(); 
                } else {
                    throw new Error('Request failed');
                }
            })
            .then(data => {
               
              setmessagerequeststatus("Accepted");
              setmessagerequestacceptrehecterror(data.message);
              setTimeout(() => {
                setmessagerequestacceptrehecterror(''); 
              }, 5000); 
              
            })
            .catch(error => {
                console.error('Data fetch failed:', error);
            });
        } catch (error) {
            console.error('Data upload failed:', error);
        }
    }
    function encodeAlphanumeric(id) {
      const encoded = btoa(id); // Using btoa to Base64 encode the ID
      // You can further process the encoded string if needed
      return encoded;
    } 
    function rejectmessagerequest(requestid,sendfrom,sendto) { 
      // You can pass the collaborator's ID or any other identifier to identify the recipient.
      // You can also add additional data to the request, such as the message content.
      
    
     
          try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const userData = JSON.parse(localStorage.getItem('userData'));
            const userId = userData.id;
  
            fetch(`${apiUrl}/rejectmessagerequest`, {
                method: 'post',
                body: JSON.stringify({ requestid, sendfrom, sendto }), 
            })
            .then(response => {
                if (response.status === 201) {
                    return response.json(); 
                } else {
                    throw new Error('Request failed');
                }
            })
            .then(data => {
              setmessagerequeststatus("Rejected");
              setmessagerequestacceptrehecterror(data.message);
              setTimeout(() => {
                setmessagerequestacceptrehecterror(''); 
              }, 5000);
               
            })
            .catch(error => {
                console.error('Data fetch failed:', error);
            });
        } catch (error) {
            console.error('Data upload failed:', error);
        }
    }

    const handleClearSelection = () => {
      setcitySelection('');
    };
  
    const handleMenuChangecity = (e) => {
      const city = e.target.value;
    
      setcitySelection(city);
      
      // Now, you can fetch collaborators with the new menuSelection value here
      fetchCollaborators();
    };
    const handleMenuChangeCollege = (e) => {
      const college = e.target.value;
    
      setCollegeSelection(college);
      
      // Now, you can fetch collaborators with the new menuSelection value here
      fetchCollaborators();
    };
    const handleMenuChangeMajor = (e) => {
      const major = e.target.value;
    
      setMajorSelection(major);
      
      // Now, you can fetch collaborators with the new menuSelection value here
      fetchCollaborators();
    };
    const handleMenuChangegpa = (e) => {
      const gpa = e.target.value;
    
      setGpaSelection(gpa);
      
      // Now, you can fetch collaborators with the new menuSelection value here
      fetchCollaborators();
    };
    const handleMenuChangesat = (e) => {
      const sat = e.target.value;
    
      setSatSelection(sat);
      
      // Now, you can fetch collaborators with the new menuSelection value here
      fetchCollaborators();
    };
    const handleMenuChangeessay = (e) => {
      const essay = e.target.value;
    
      setEssaySelection(essay);
      
      // Now, you can fetch collaborators with the new menuSelection value here
      fetchCollaborators();
    };
  
  
    const fetchCollaborators = () => {
      // Update this URL with your actual API endpoint and include search and filter parameters
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
  
      fetch(`${apiUrl}/get_search_students_for_collaboraters`, {
        method: 'post',
        body: JSON.stringify({ userId, searchTerm, menuSelection, citySelection,collegeSelection}), 
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error('Request failed');
          }
        })
        .then((data) => {
          console.log(data.collaborators,"students data 2");
          setstudents(data.collaborators);
          setLoadinguser(false);
        })
        .catch((error) => {
          console.error('Data fetch failed:', error);
        });
    };
  
    useEffect(() => {
      
      fetchCollaborators();
    }, [searchTerm, citySelection,collegeSelection,majorSelection,gpaSelection,satSelection,essaySelection]);


    const handleSearchColleges = (searchText) => {
      const apiKey = 'xjbOja8DPH9EuBj6HUj8EJaj'; // Replace with your API key
      const apiUrl = `https://api.collegeai.com/v1/api/autocomplete/colleges?api_key=${apiKey}&query=${searchText}`;
  
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data, "colleges Names");
          console.log(data,"colleges Names");
          const updatedColleges = data.collegeList.map((college) => ({
            value: college.name,
            label: college.name,
          }));
          setOptions(updatedColleges);
          if (data && data.collegeList) {
            setOptions(data.collegeList.map((college) => ({
              value: college.name,
              label: college.name,
            })));
          }
        })
        .catch((error) => {
          console.error('Error fetching colleges:', error); 
        });
    };
    
    const handleClose = () => {
      setOpen(false);
  
    };

    const clear_filters = () => {
      // Assuming you have state variables for your filters
      setSearchTerm(''); // Clear the search term
      
      setcitySelection('');
     
      // Clear the category filter
      // Clear other filter states as needed
    };
  
    return (
   
        <Grid className='collaborator_view_mainGrid'  container rowGap={3} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }} >
          <Grid
          className='Search_student_maindiv'
            item
            lg={12}
            xl={12}
            xs={12}
            sm={12}
            md={12}
            sx={{
              backgroundColor: "white",
              display: 'flex',
              flexDirection: 'column',
              padding: "30px" ,
              flex: '20%',
            }} 
          >
            <div className='Test_paddingDiv_search'>
              <div style={{display:"flex",justifyContent:"space-between" }}>
                <div style={{width:"100%",gap:"10px",display:"flex"}}>
                  <TextField  label="Search"   id="search" size='small'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} sx={{width:"80%"}} />
              <TuneSharpIcon sx={{color:"#C4C4C4",height:"1.5em",width:"1.5em",cursor:'pointer'}}   onClick={toggleSearchVisibility}/>
                <Button variant='outlined' onClick={clear_filters}>Clear</Button>
                </div>
   
              </div>
              {isSearchVisible && (<>
       <Divider className="DividerCustom" variant="inset" /> 
      
       <div className='Search_bar_maindiv' style={{display:"flex", flexDirection:"row",gap:"20px"}}>
      {/* <Autocomplete
         fullWidth
      id="search-term-colleges"
      options={options}
      getOptionLabel={(option) => option.label || ''}
      value={collegeSelection}
      onChange={(event, newValue) => {
        setCollegeSelection(newValue);
        fetchCollaborators();
      }}
      onInputChange={(_, newInputValue) => {
        handleSearchColleges(newInputValue);
        
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Colleges"
          fullWidth
          size="small"
        />
      )}
      noOptionsText="Search College"
    /> */}
      
     
        
          <TextField
            fullWidth
            id="city"
            select
            label="High school origin"
            value={citySelection}
            onChange={handleMenuChangecity} 
          size='small'
          InputProps={{
            endAdornment: citySelection && (
              <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClearSelection} />
            ),
          }}
            
          >
            {cities.map((cityName, index) => (
              <MenuItem key={index} value={cityName}>
                {cityName}
              </MenuItem>
            ))}
          </TextField>
      </div> 
      </>
        )}
      {/* <div style={{display:"flex", flexDirection:"row",gap:"20px",marginTop:"20px"}}>
      <TextField
      fullWidth
            id="outlined-select-currency4"
            select
            label="GPA"
            value={gpaSelection}
            onChange={handleMenuChangegpa} 
            size='small'
          
            
          >
            {GPA.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            id="outlined-select-currency5"
            select
            label="SAT/ACT"
            value={satSelection}
            onChange={handleMenuChangesat} 
          size='small'
           
          >
            {sat.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            id="outlined-select-currency6"
            select
            label="Essay"
            value={essaySelection}
            onChange={handleMenuChangeessay} 
          size='small'
            
          >
            {essay.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
      </div> */}
      </div>
          </Grid>
  
          <Grid  className='Search_student_2nddiv'  rowGap={3} item lg={12} xl={12} xs={12} sm={12} md={12} sx={{ backgroundColor: "white", padding: "30px",minHeight:"83vh" }}>
            {/* Correct the component name to typography */}
            <typography className='message_text'>Search Results</typography>
                  <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie    options={{
            loop: true,
            autoplay: true,
            animationData: messagerequesterror === 'Your request is already sent.' ? animationData2 : animationData,
          }}
           height={150} width={150} />
         
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              {messagerequesterror === 'Your request is already sent.' ? 
            null
           : (
            <typography className="Success">Success</typography>
          )}
           
            <typography className='Submitted_text2'> {messagerequesterror && (
                <div className="success-message">{messagerequesterror}</div>
              )}</typography>
         
        
          </div>
          <DialogActions sx={{display:"flex",justifyContent:"center"}}>
          <Button onClick={handleClose}>Ok</Button>
          
       </DialogActions>
          </DialogContent>
          
        </Dialog>

        {loadinguser ? (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>
) : (
            <Box className="collaborator_search_view" sx={{ display: "grid", justifyContent: "center", rowGap:"94px",columnGap:"32px", alignItems: "center", gridTemplateColumns: "repeat(3, 3fr)",paddingTop:'60px' ,justifyItems:"center" }}>
            {
  students.length === 0 ? (
    <>
        <div></div>
    <div>
    <typography>No student found</typography></div>
    </>

  ) : (students.map((content, index) => (
                
                <>
                  <div style={{display:"flex",justifyContent:"space-between",gap:"27%",alignItems:"center"}}>
                <Box
                  key={index}
                  sx={{
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: "column",
                    justifyItems: "center",
                    width:"180px"
                  }}
                >
                  <Avatar aria-label="recipe" src={content.image}  sx={{width:"60px",height:"60px"}} />
                  {/* Correct the component name to typography */}
                  <typography className='message_text_secondary'>
                  {content.legal_name.split(' ').length > 4
    ? `${content.legal_name.split(' ').slice(0, 4).join(' ')} ...`
    : content.legal_name}</typography>
                  <Box sx={{ display: "flex", alignItems: "center" ,marginBottom:"10px"}}>
                    <LocationOnOutlinedIcon sx={{color:"#58595B",width:"16px",height:"16px",gap:"4px"}} /> 
                       {/* Correct the component name to typography */}
                    <typography className='message_text_secondary2'>{content.city}</typography>
                  </Box>
                  {/* Correct the component name to typography */}
                  <typography className='message_text_secondary21'>GPA - {content.gpsscore}/{content.gpaOutOf}</typography>
                 {/* <Chip label="Applying" color="success" size="large" variant="outlined" />*/}
                  {/* Correct the component name to typography */}
                  
                  <typography className='message_text_secondary3'>
                  {content.highschoolname && content.highschoolname.split(' ').length > 7
    ? `${content.highschoolname.split(' ').slice(0, 7).join(' ')} ...`
    : content.highschoolname}   
    {/* {content.highschoolname} */}
</typography>
                  <Button
                variant="outlined"
                sx={{width:"179px",boxShadow:"none"}}
                fullWidth
                color="primary"
                size="small"
                onClick={() => sendMessageRequest(content.id)}
              >
               {content.messageRequest === 'yes' ? 'Request Sent' : 'Message'}
              </Button>
              <Link to={`/studentProfile?id=${encodeAlphanumeric(content.id)}&type=collaborator`}  style={{ textDecoration: 'none' }}> 
            <Button variant="outlined"  sx={{marginTop:"20px",width:"179px"}}
              size="small">
        View Profile
      </Button></Link>
                </Box>
                <Box className="collaborator_search_view_divider" sx={{ borderRight: shouldShowBorder(index) ? '1px solid #ccc' : 'none', height: '150px',marginTop:"20px"}}/> 
                </div>
                </>
              ))  )
            }
            </Box>
            )}
          </Grid>
        </Grid>
        )
          
        
    
    
  };

export default SearchStudents
