import { Divider, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import React,{useEffect, useState} from 'react';
import topbarimg from '../../assets/img/topbar.png';
import topbarlogo1 from '../../assets/img/topbarlogo.png';
import profile1 from '../../assets/img/profile.jpg';

import Avatar from '@mui/material/Avatar';
import { Link,useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      gap: 1, // Adjust the gap for small screens
    },
  },
  headerText: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust the font size for small screens
    },
  },
}));
const Topbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userType, setUserType] = useState(null);

    const handleAvatarClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSignOut = () => {
      // Clear user data from localStorage
      localStorage.removeItem('userData');
      
      // Navigate to the login page
      navigate(`/welcome`);
    };
  
    useEffect(() => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
  
      try {
        fetch(`${apiUrl}/getuserdetailsforeditpage`, {
          method: 'post',
          body: JSON.stringify({ userId: userId }),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
             
            } else {
              throw new Error('Request failed');
            }
          })
          .then((data) => {
            console.log(data,"this is data from topbar");
           
            // Assuming 'membership' is the key for the membership value in the response data from your table
            const membershipValue = data.user.membership || 0;
            setMembership(membershipValue);
          
            
             console.log(data.user.membership,"this is membership value");
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
          });
      } catch (error) {
        // Handle fetch-related errors here
        console.error(error);
      }
      function encodeAlphanumeric(id) {
        const encoded = btoa(id); // Using btoa to Base64 encode the ID
        // You can further process the encoded string if needed
        return encoded;
      } 
    }, []);
    
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate(`/login`);
      return null; 
    }
    const joinDate = new Date(userData.created_at);
    const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
    const profile1url = userData.avatarImage;
    const laravelUrl = profile1url?profile1url.replace('/public/', '/storage/app/public/'):null;
    const laravelUrlfinal =laravelUrl? laravelUrl.replace('/public/storage/', '/public/'):null;
    console.log(laravelUrlfinal,"user image"); 


    const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        height: '8vh',
        backgroundImage: `url(${topbarimg})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      {/* Nested Grid for the logo and Avatar */}
      <Grid
        container
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: 'space-between', // Align items horizontally
          alignItems: 'center', // Align items vertically
          padding: '0 20px', // Add padding to separate the elements from the edges
        }}
      >
        {/* Logo on the left */}
        <Grid item lg={6} xl={6} xs={6} sm={6} md={6}  sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        '@media (max-width: 600px)': {
          gap: 1, // Adjust the gap for small screens
        },
      }}>
          <Link  to={`/Student`} sx={{textDecoration:"none"}}>
      <img src={topbarlogo1} alt="Logo" style={{ width: 'auto', height: '25px' }} />
      </Link>
      <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }} />
      <typography className="Topbar_header_text">{userData.type ==='student' && <typography>Student</typography>} {userData.type ==='collaborator' && <typography>Collaborator</typography>}</typography>
    </Grid>
        
        {/* Avatar on the right */}
        <Grid
          item
          lg={6}
          xl={6}
          xs={6}
          sm={6}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end', // Align to the right
            alignItems:"center",
            gap:"10px"
          }}
        >
            <typography className="Student_Topbar_header_text" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%',display: isSmallScreen ? 'none' : 'block', }}>{userData.legal_name}</typography>
            <Avatar
        alt="Cindy Baker"
        src={laravelUrlfinal}
        sx={{
          border: '2px solid white',
          width: '30px',
          height: '30px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: 'pointer', // Add cursor style to indicate it's clickable
        }}
        onClick={handleAvatarClick}
      />
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        {/* Add more menu items as needed */}
      </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Topbar
