import { Box, Button, Divider, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import mainlogo from '../../assets/img/mainwelcome.png'
import './collaborator.css'
import InsightsIcon from '@mui/icons-material/Insights';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const Collaborator_home = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

  if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
  }
  const userData = JSON.parse(localStorage.getItem('userData')); 
  const[analytics, setAnalytics]= useState([])



  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
       

        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;

        const response = await fetch(`${apiUrl}/collaborator/analytics/${userId}`, {
          method: 'GET',
         
         
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched Data:', data);
          // Handle the fetched data as needed
          const analytics=data.analytics
          console.log(analytics,"This is analytics")
          setAnalytics(analytics);
        } else {
          console.error('Error fetching data:', response.statusText);
          // Handle error scenario
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error scenario
      }
    };

    fetchData(); // Call the fetchData function when the component mounts

    // Clean-up function (optional)
    return () => {
      // Perform any clean-up actions if needed
    };
  }, []); 

  return (
    <div style={{flexWrap: "nowrap" ,height:'100%', display: 'flex', flexDirection: 'column',gap:'25px' }}>
    {/* <div rowGap={3} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" ,height:'100%'}}> */}

        <Grid container  
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          style={{
            background: "rgba(255, 255, 255, 0.80)",
            backdropFilter: "blur(25px)",
            height: '100%', // Set the height to 100% of the parent container
            padding:'30px',
            flex: '20%',
            display:'flex'
            
          }}
        >
          <Box sx={{display:'flex',flexDirection:'column',gap:'20px',justifyContent:'space-between',width:'100%'}} className='analytics_Div' >
            <div>
              <typography className='progress_main_Grid_Text'>Analytics</typography>
            </div>
            <Box sx={{display:'flex',flexDirection:'row',gap:'30px',justifyContent:'space-between','@media (max-width: 600px)': {
          flexDirection: 'column',
        },}}>
              <Box className='progress_main_Grid2' >
                <InsightsIcon className='progress_main_Grid_Avatar' />
                <Box className='progress_main_Grid2_Text'>
                  <typography className='progress_main_Grid2_Text_Main'>{analytics.profile_views}</typography>
                  <typography className='progress_main_Grid2_Text_Secondary'>Profile views</typography>
                </Box>
              
              </Box>
              <Divider orientation="vertical" className="Admin_DividerCustom4" />
              <Box className='progress_main_Grid2' >
                <MarkEmailReadIcon className='progress_main_Grid_Avatar' sx={{color:'#EF6C00'}} />
                <Box className='progress_main_Grid2_Text'>
                  <typography className='progress_main_Grid2_Text_Main'>{analytics.total_messages}</typography>
                  <typography className='progress_main_Grid2_Text_Secondary'>Messages</typography>
                </Box>
              </Box>
              <Divider orientation="vertical" className="Admin_DividerCustom4" />
              <Box className='progress_main_Grid2' >
                <ContentPasteSearchIcon className='progress_main_Grid_Avatar' sx={{color:'#2E7D32'}}/>
                <Box className='progress_main_Grid2_Text'>
                  <typography className='progress_main_Grid2_Text_Main'>{analytics.total_search}</typography>
                  <typography className='progress_main_Grid2_Text_Secondary'>Search Appearance</typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      {/* </div> */}

      <div style={{ flex: '80%', overflow: 'hidden' }}>
        <Grid container className='Home_collaboraotor_view'
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          style={{
            background: "rgba(255, 255, 255, 0.80)",
            backdropFilter: "blur(25px)",
            height: '100%', // Set the height to 100% of the parent container
          }}
        >
          <Box className="profile_view_mainDiv" >
            <img src={mainlogo} alt="Logo"  className="profile-view-main"/>
            <typography className="Profile_view_welcome">Welcome, {userData.legal_name}!</typography>
            <typography className="Profile_view_desc2">Complete your profile to start earning.</typography>
            <Link to={`/complete_profile`} sx={{textDecoration:"none",}}><Button variant='outlined' >Complete your Profile</Button></Link>
          </Box>
        </Grid>
      </div>
    </div>
  )
}

export default Collaborator_home
