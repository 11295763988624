import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Box, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip, TextField, Autocomplete, Button } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import AvatarImage from '../../assets/img/_Avatar_1.png'
import { Link } from 'react-router-dom';

function createData(avatarUrl,name, email, college,  status) {
  return { avatarUrl,name, email, college,  status, };
}
const generateAvatarUrl = (imagePath) => {
  if (imagePath) {
    const laravelUrl = imagePath.replace('/public/', '/storage/app/public/');
    const laravelUrlfinal = laravelUrl.replace('/public/storage/', '/public/');
    return laravelUrlfinal;
  }
  return ''; // Set a default image or handle the case when the image path is not available
};
export const Admin_collaborator = () => {

  const [switchStates, setSwitchStates] = useState({});
 

    const statuses = [
    "accepted",
    "rejected",
    "pending",
    "Not Requested"
    
      ];
      const cities =[
        "Florida State University",
        "Indiana University   Bloomington",
        "University of Arizona",
        "Texas State University",
        "University of Illinois at Chicago",
        "Grand Valley State University",
        "University of North Carolina Wilmington",
        "Tennessee Technological University",
        "Rutgers University   Newark",
        "Tarleton State University",
      ]
 

  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);
  // const [collegeSelection, setcollegeSelection] = useState('');
  const [status, setstatus] = useState('');
  const [status2, setstatus2] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [collegeSelection, setcollegeSelection] = useState([]);
  const [collaborators2, setcollaborators2] = useState([]);
  const autocompleteRef = useRef(null);

  // const [collegeSelection, setcollegeSelection] = useState('');
  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const rows =[
    createData(AvatarImage,'Frozen yoghurt', 'D@gmail.com', 'College Name', "Accepted", 4.0),
    createData(2,'Ice cream sandwich', 'D@gmail.com', 'College Name', "Rejected", 4.3),
    createData(3,'Eclair', 'D@gmail.com', 'College Name', "Accepted", 6.0),
    createData(4,'Cupcake', 'D@gmail.com', 'College Name', "Pending", 4.3),
    createData(5,'Gingerbread', 'D@gmail.com', 'College Name', "Rejected", 3.9),
    createData(6,'Gingerbread', 'D@gmail.com1111', 'College Name', "Accepted", 3.9),
  ];


  const [options, setOptions] = useState([]);
  console.log(options,"This is options"); 
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState(null);
  const handleSearchColleges = (searchText) => {
    const apiKey = 'xjbOja8DPH9EuBj6HUj8EJaj'; // Replace with your API key
    const apiUrl = `https://api.collegeai.com/v1/api/autocomplete/colleges?api_key=${apiKey}&query=${searchText}`;
  
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data, "colleges Names");
        const updatedColleges = data.collegeList.map((college) => ({
          title: college.name,
          state: college.state
        }));
        setOptions(updatedColleges);
        console.log(updatedColleges, "These are colleges"); // Corrected logging
      })
      .catch((error) => {
        console.error('Error fetching colleges:', error); 
      });
  };
  

  const handleClearSelection = () => {
    setcollegeSelection('');
    setSearchText('');
    setOptions([]);
  };
  const handleClearSelection2 = () => {
    setstatus('');
  };
  // const handleMenuChangecity = (e) => {
  //   const value = e.target.value;
  //   setSearchText(value);
  
  //   setcollegeSelection(e.target.value);
  //   handleSearchColleges(value);
  //   // Now, you can fetch collaborators with the new menuSelection value here
  //   fetchCollaborators();
  // };
 
  const fetchCollaborators = () => {
    // Update this URL with your actual API endpoint and include search and filter parameters
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;
    // const apiUrl = process.env.REACT_APP_API_URL;
    const storedAccessToken = localStorage.getItem('accessToken');

    if (!storedAccessToken) {
      console.error('Access token not found');
      window.location.href = '/admin-login';
      return;
    }

    setAccessToken(storedAccessToken);
    fetch(`${apiUrl}/admin/get_search_colloborators`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${storedAccessToken}`,
      },
      body: JSON.stringify({searchTerm, status,collegeSelection}), 
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((data) => {
        console.log(data.collaborators , "Collaborator 2");
        setCollaborators(data.collaborators);
      })
      .catch((error) => {
        console.error('Data fetch failed:', error);
      });
  };

  useEffect(() => {
    console.log('Fetching collaborators with status:', status);
    fetchCollaborators();
}, [searchTerm, status, collegeSelection]);
  const handleMenuChangestatus = (e) => {
    const status = e.target.value;
    console.log('Selected Status:', status);
    setstatus(status);
    // Now, you can fetch collaborators with the new status selection here
    // fetchCollaborators();
  };
  const handleMenuChangecity = (selectedValue) => {
    
    // const collegeSelection = e.target.value;
    console.log('Selected Status college:', selectedValue);
    setcollegeSelection(selectedValue);
    // fetchCollaborators();
    // handleSearchColleges(event.target.value);
  };
  const handleMenuChangestatus2 = (e) => {
    console.log('handleMenuChangestatus2 called');
    const status2 = e.target.value;
    console.log('Selected Status2:', status2);
    setstatus2(status2);
    fetchCollaborators();
  };
  const [collaborators, setCollaborators] = useState([]);
  const [storedAccessToken, setAccessToken] = useState('');
  const [collaboratorlaravelUrlfinal, setCollaboratorlaravelUrlfinal] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const storedAccessToken = localStorage.getItem('accessToken');

            if (!storedAccessToken) {
                console.error('Access token not found');
                window.location.href = '/admin-login';
                return;
            }

            setAccessToken(storedAccessToken);

            const response = await fetch(`${apiUrl}/admin/get-collaborator`, {
                method: 'GET', // Use 'GET' since it's a read operation
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${storedAccessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data = await response.json();
            console.log(data.collaborators, 'This is data');
            setCollaborators(data.collaborators); // Assuming you have a state setter for collaborators
            
            const profile1url = collaborators[1]?.avatarImage;
        const laravelUrl = generateAvatarUrl(profile1url);
        setCollaboratorlaravelUrlfinal(laravelUrl);
      } catch (error) {
            console.error('Data fetch failed:', error);
        }
    };

    fetchData();
}, []);

  
  const handleSwitchChange = async (userId, isEnabled) => {
    try {
      // Fetch the current status of the collaborator
      const collaboratorIndex = collaborators.findIndex((c) => c.id === userId);
  
      if (collaboratorIndex === -1) {
        console.error('Collaborator not found');
        return;
      }
  
      const updatedCollaborators = [...collaborators];
      const updatedCollaborator = { ...updatedCollaborators[collaboratorIndex] };
  
      const apiUrl = isEnabled
        ? `${process.env.REACT_APP_API_URL}/admin/disable/collaborator/${userId}`
        : `${process.env.REACT_APP_API_URL}/admin/enable/collaborator/${userId}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedAccessToken}`,
        },
      });
  
      console.log(response);
  
      if (!response.ok) {
        throw new Error('API request failed');
      }
  
      const data = await response.json();
      console.log(data);
  
      updatedCollaborator.status = isEnabled ? 0 : 1;
      updatedCollaborators[collaboratorIndex] = updatedCollaborator;
  
      // Update the state with the modified collaborators
      setCollaborators(updatedCollaborators);
  
    } catch (error) {
      console.error('Error:', error);
      // Handle errors if needed
    }
  };
  


  const handleSwitchChange2 = async (userId, isEnabled) => {
    try {
      // Fetch the current status of the collaborator
      const collaboratorIndex = collaborators.findIndex((c) => c.id === userId);
  
      if (collaboratorIndex === -1) {
        console.error('Collaborator not found');
        return;
      }
  
      const updatedCollaborators = [...collaborators];
      const updatedCollaborator = { ...updatedCollaborators[collaboratorIndex] };
  
      const apiUrl = isEnabled
        ? `${process.env.REACT_APP_API_URL}/admin/enable/collaborator/${userId}`
        : `${process.env.REACT_APP_API_URL}/admin/disable/collaborator/${userId}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedAccessToken}`,
        },
      });
  
      console.log(response);
  
      if (!response.ok) {
        throw new Error('API request failed');
      }
  
      const data = await response.json();
      console.log(data);
  
      updatedCollaborator.status = isEnabled ? 1 : 0;
      updatedCollaborators[collaboratorIndex] = updatedCollaborator;
  
      // Update the state with the modified collaborators
      setCollaborators(updatedCollaborators);
  
    } catch (error) {
      console.error('Error:', error);
      // Handle errors if needed
    }
  };
  
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 


  const clear_filters = () => {
    // Assuming you have state variables for your filters
    setSearchTerm(''); // Clear the search term
    setstatus(''); // Clear the category filter
    setstatus2(''); // Clear the category filter
    setcollegeSelection(''); // Clear the selected college
   
    // Clear the category filter
    // Clear other filter states as needed
  };

  return (
    <div className='Admin_collaborator_Container'>
      <Grid container className='Home_collaborator_view' lg={12} xl={12} xs={12} sm={12} md={12}>
        <Box className="admin_textfield_Box_Main">
          <typography className='admin_collaborator_main_Text'>Collaborators</typography>
          <Box className="admin_textfield_Box">
        <TextField  label="Name,Email,etc..."  
               value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}   id="search" size='small' className='Admin_textfield1' />
        <TextField
            
            id="status"
            select
            label="Status"
            value={status}
            onChange={handleMenuChangestatus} 
          size='small'
          InputProps={{
            endAdornment: status && (
              <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClearSelection2} />
            ),
          }}
          className='Admin_textfield2'
         
       
            
          >
            {statuses.map((cityName, index) => (
              <MenuItem key={index} value={cityName}>
                {cityName}
              </MenuItem>
            ))}
          </TextField>
         
          <div className='TextField_div'> {/* This div takes up remaining 25% */}
    <Autocomplete
      fullWidth
      className='Admin_textfield2'
      id="search-term-colleges"
      options={options}
      getOptionLabel={(option) => option.title || ''}
      onInputChange={(_, newInputValue) => {
        handleSearchColleges(newInputValue);
      }}
      onChange={(_, selectedOption) => {
        handleMenuChangecity(selectedOption?.title || '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="College Name *"
          name="college_name"
          size="small"
          value={collegeSelection}
          inputRef={autocompleteRef}
          className='Admin_textfield2'
        />
      )}
      noOptionsText="Search College"
    />
  </div>
<Button variant='outlined' onClick={clear_filters}>Clear</Button>
        </Box>
        </Box>
       
                   
        

        <TableContainer component={Paper} sx={{ backgroundColor: "transparent" ,boxShadow:'none'}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell ><typogaphy className='table_main_Text'>Name</typogaphy></TableCell>
                <TableCell ><typogaphy className='table_main_Text'>Email</typogaphy></TableCell>
                <TableCell ><typogaphy className='table_main_Text'>College</typogaphy></TableCell>
                <TableCell ><typogaphy className='table_main_Text'>Status</typogaphy></TableCell>
                <TableCell ><typogaphy className='table_main_Text'>Action</typogaphy></TableCell>
                {/* <TableCell className='table_main_Text'>Email</TableCell> */}
                {/* <TableCell className='table_main_Text'>College</TableCell> */}
                {/* <TableCell className='table_main_Text'>Status</TableCell> */}
                {/* <TableCell className='table_main_Text'>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {collaborators.slice(pg * rpg, pg * rpg + rpg).map((row, index) => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                  <Link style={{ textDecoration: 'none' }} to={`/admin_Collaborator_profileview?id=${encodeAlphanumeric(row.id)}`}>
  <Box display="flex" alignItems="center">
    <Avatar alt={row.name} src={generateAvatarUrl(row.avatarImage)} className='Admin_main_Avatar'/>
    <typography className='table_secondary_Text'>{row.legal_name}</typography>
  </Box>
</Link>

</TableCell>


                  <TableCell><typogaphy className='table_secondary_Text'>{row.email}</typogaphy> </TableCell>
                  <TableCell>
  <typography className='table_secondary_Text'>
    {Array.isArray(row.collgename) ? (
      row.collgename.map((college, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />} {/* Add line break except for the first line */}
          {college}
        </React.Fragment>
      ))
    ) : (
      "College not found"
    )}
  </typography>
</TableCell>
                  <TableCell>
                  <Chip
  label={row.profile_status || "Not Requested"
  }
  color={
    row.profile_status === 'accepted'
      ? 'success'
      : row.profile_status === 'rejected'
      ? 'error' // Use 'error' for red color, or any other color you prefer
      : row.profile_status === 'pending'
      ? 'warning' // Use 'warning' for yellow color
      : 'primary'
  }
/>




                  </TableCell>
                  <TableCell>
  {row.status === 1 ? (
    <>
      <Switch
        label="Label"
        defaultChecked={row.status === 1}
        color="success"
        onChange={() => handleSwitchChange(row.id, !switchStates[row.id])}
      />
      {'Enabled'}
    </>
  ) : (
    <>
      <Switch
        label="Label"
        defaultChecked={row.status === 1}
        color="success"
        onChange={() => handleSwitchChange2(row.id, !switchStates[row.id])}
      />
      {'Disabled'}
    </>
  )}
</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
          rowsPerPageOptions={[10, 20, 30,40,50]}
          component="div"
          count={collaborators.length}
          rowsPerPage={rpg}
          page={pg}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          
        />
        </TableContainer>
       
      </Grid>
    </div>
  );
};
