import React,{useState,useEffect}from 'react';
import {
  Box,
  Button,
  Avatar,
  Grid,
  typography,
} from "@mui/material";
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import Chatpic from '../../assets/img/_Avatar_.png';
import Chatpic1 from '../../assets/img/_Avatar_1.png';
import Chip from '@mui/material/Chip';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './Students.css'
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Premium from '../../assets/img/Rectangle.png'

import Messages from '../collaborator/Messages';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json'
import animationData2 from '../../assets/message.json'
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const cities = [
  "Alabama",
"Alaska",
"Arizona",
"Arkansas",
"California",
"Colorado",
"Connecticut",
"Delaware",
"Florida",
"Georgia",
"Hawaii",
"Idaho",
"Illinois",
"Indiana",
"Iowa",
"Kansas",
"Kentucky",
"Louisiana",
"Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming",
"Puerto Rico",
"Guam",
"U.S. Virgin Islands",
"American Samoa",
"Northern Mariana Islands"
];
const states = [
  "Alabama",
"Alaska",
"Arizona",
"Arkansas",
"California",
"Colorado",
"Connecticut",
"Delaware",
"Florida",
"Georgia",
"Hawaii",
"Idaho",
"Illinois",
"Indiana",
"Iowa",
"Kansas",
"Kentucky",
"Louisiana",
"Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming",
"Puerto Rico",
"Guam",
"U.S. Virgin Islands",
"American Samoa",
"Northern Mariana Islands",
"International"
];

const students = [
  {
    name: 'Howie',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Alice',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'John',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Howie',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Bob',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
  {
    name: 'Mia',
    location: 'Los Angeles, United States',
    avatarSrc: Chatpic,
    score: "GPA - 3.7",
    university: "University of California"
  },
];
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];
const Major = [
  {
    value: 'Maths',
    label: 'Maths',
  },
  {
    value: 'Computer',
    label: 'Computer',
  },
  {
    value: 'Biology',
    label: 'Biology',
  },
  {
    value: 'Chemistry',
    label: 'Chemistry',
  },
];
const Colleges = [
  {
    value: 'oxford',
    label: 'Oxford University',
  },
  {
    value: 'standford',
    label: 'Standford University',
  },
  {
    value: 'harvard',
    label: 'Harvard University',
  },
  {
    value: 'georgia',
    label: 'Georgia University',
  },
];
const GPA = [
  {
    value: 'weighted',
    label: 'Weighted(1-4)',
  },
  {
    value: 'unweighted',
    label: 'UnWeighted(1-4)',
  },
 
];
const sat = [
  {
    value: 'act',
    label: 'ACT Score(1-36)',
  },
  {
    value: 'sat',
    label: 'SAT Score(1-36)',
  },
 
];
const essay = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
 
];
const SearchCollaborator = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const [showMessages, setShowMessages] = useState(false);
  const [collaborators, setcollaborators] = useState([]);
  const [collaborators2, setcollaborators2] = useState([]);

  const [sections, setsections] = useState([]);
  const [messagerequesterror, setmessagerequesterror] = useState([]);
  const [messagerequeststatus, setmessagerequeststatus] = useState('');
  const shouldShowBorder = (index) => (index + 1) % 3 !== 0;
  const [messagerequestacceptrehecterror, setmessagerequestacceptrehecterror] = useState(''); 
  const [searchTerm, setSearchTerm] = useState('');
  const [menuSelection, setMenuSelection] = useState();
  const [majorSelection, setMajorSelection] = useState('');

  const [easySelection, setEasySelection] = useState('');

  const [collegeSelection, setCollegeSelection] = useState([]);
  const [citySelection, setcitySelection] = useState('');
  const [gpaSelection, setGpaSelection] = useState('');
  const [satSelection, setSatSelection] = useState('');
  const [essaySelection, setEssaySelection] = useState('');
  const [membership, setMembership] = useState();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  
  const [isSearchVisible, setSearchVisible] = useState(true);
  const toggleSearchVisibility = () => {
    setSearchVisible(!isSearchVisible); // Toggle the visibility state
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  
  const handleButtonClick = () => {
    setShowMessages(true);
  };


  useEffect(() => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      fetch(`${apiUrl}/get_all_collaborator_message_request`, {
          method: 'post',
          body: JSON.stringify({ userId }), // Send the entire formData object as the body
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
           
              throw new Error('Request failed');
          }
      })
      .then(data => {
          
          console.log(data , 'Messages requests'); // Log the awardsData 
          setsections(data.collaborators)    
          //setcollaborators(data.collaborators);
         // alert("here");
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
  }, [messagerequeststatus]);

  useEffect(() => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      fetch(`${apiUrl}/get_all_collaborators_data_profiles`, {
          method: 'post',
          body: JSON.stringify({ userId }), // Send the entire formData object as the body
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
              throw new Error('Request failed');
          }
      })
      .then(data => {
          
          console.log(data, "data"); // Log the awardsData
          setcollaborators(data.collaborators);
         // alert("here");
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
  }, []);


  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;

    try {
      fetch(`${apiUrl}/getuserdetailsforeditpage`, {
        method: 'post',
        body: JSON.stringify({ userId: userId }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
           
          } else {
            throw new Error('Request failed');
          }
        })
        .then((data) => {
          console.log(data,"this is data");
         
          // Assuming 'membership' is the key for the membership value in the response data from your table
          const membershipValue = data.user.membership ;
          setMembership(membershipValue);
           console.log(data.user.membership,"this is membership value");
        })
        .catch((error) => {
          // Handle the error here
          console.error(error);
        });
    } catch (error) {
      // Handle fetch-related errors here
      console.error(error);
    }
    function encodeAlphanumeric(id) {
      const encoded = btoa(id); // Using btoa to Base64 encode the ID
      // You can further process the encoded string if needed
      return encoded;
    } 
  }, []);

  

  function sendMessageRequest(collaboratorId) {
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    if (membership === null) {
      handleClickOpen();
    } else if (membership === 1 || membership === 0) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;

        fetch(`${apiUrl}/collaborator_message_request`, {
            method: 'post',
            body: JSON.stringify({ userId, collaboratorId }), 
        })
        .then(response => {
            if (response.status === 201) {
                return response.json(); 
            } else {
                throw new Error('Request failed');
            }
        })
        .then(data => {
           
            console.log(data.message); 
            setmessagerequesterror(data.message);
          
          
        })
        .catch(error => {
            console.error('Data fetch failed:', error);
        })
        .finally(() =>{
          setLoading(false);
          setOpen2(true);
          fetchCollaborators();
        })
    } catch (error) {
        console.error('Data upload failed:', error);
    }
    }
  
   
       
  }
  // function sendMessageRequest(collaboratorId) {
  //   // You can pass the collaborator's ID or any other identifier to identify the recipient.
  //   // You can also add additional data to the request, such as the message content.
    
  
   
  //       try {
  //         setLoading(true); // Set loading to true before making the request
  //         const apiUrl = process.env.REACT_APP_API_URL;
  //         const userData = JSON.parse(localStorage.getItem('userData'));
  //         const userId = userData.id;
         
  //         fetch(`${apiUrl}/collaborator_message_request`, {
  //             method: 'post',
  //             body: JSON.stringify({ userId, collaboratorId }), 
  //         })
  //         .then(response => {
  //             if (response.status === 201) {
  //                 return response.json(); 
  //             } else {
  //                 throw new Error('Request failed');
  //             }
  //         })
  //         .then(data => {
             
  //             console.log(data.message); 
  //             setmessagerequesterror(data.message);
  //             // setTimeout(() => {
  //             //   setmessagerequesterror(''); 
  //             // }, 5000); 
            
  //         })
  //         .catch(error => {
  //             console.error('Data fetch failed:', error);
  //         })
  //         .finally(() => {
  //           setLoading(false); // Set loading to false after the request is completed
  //           setOpen(true);
  //         });
  //     } catch (error) {
  //         console.error('Data upload failed:', error);
  //     }
  // }


  function acceptmessagerequest(requestid,sendfrom,sendto) {
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    
  
   
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;

          fetch(`${apiUrl}/acceptmessagerequest`, {
              method: 'post',
              body: JSON.stringify({ requestid, sendfrom, sendto }), 
          })
          .then(response => {
              if (response.status === 201) {
                  return response.json(); 
              } else {
                  throw new Error('Request failed');
              }
          })
          .then(data => {
             
            setmessagerequeststatus("Accepted");
            setmessagerequestacceptrehecterror(data.message);
            setTimeout(() => {
              setmessagerequestacceptrehecterror(''); 
            }, 5000); 
            
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
  }

  function rejectmessagerequest(requestid,sendfrom,sendto) { 
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    
  
   
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;

          fetch(`${apiUrl}/rejectmessagerequest`, {
              method: 'post',
              body: JSON.stringify({ requestid, sendfrom, sendto }), 
          })
          .then(response => {
              if (response.status === 201) {
                  return response.json(); 
              } else {
                  throw new Error('Request failed');
              }
          })
          .then(data => {
            setmessagerequeststatus("Rejected");
            setmessagerequestacceptrehecterror(data.message);
            setTimeout(() => {
              setmessagerequestacceptrehecterror(''); 
            }, 5000);
             
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
  }

  const handlenavigate = () => {
    // You can check the membership value here and navigate accordingly
    if (membership === 1) {
      alert("Hello user");
      // Navigate to the appropriate route
      // Example: history.push('/premium-profile');
    } else {
      // Handle the case where membership is not 1 (e.g., show a message)
      alert('You do not have a premium profile.');
    }
  };
  const handleClearSelection = () => {
    setcitySelection('');
  };

  const handleMenuChangecity = (e) => {
    const city = e.target.value;
  
    setcitySelection(city);
    
    // Now, you can fetch collaborators with the new menuSelection value here
    fetchCollaborators();
  };
  const handleMenuChangeCollege = (e) => {
    const college = e.target.value;
  
    setCollegeSelection(college);
    
    // Now, you can fetch collaborators with the new menuSelection value here
    fetchCollaborators();
  };
  const handleMenuChangeMajor = (e) => {

    const major = e.target.value;
    setMajorSelection(major);
    
    // Now, you can fetch collaborators with the new menuSelection value here
    fetchCollaborators();
  };
  const handleMenuChangegpa = (e) => {
    const gpa = e.target.value;
  
    setGpaSelection(gpa);
    
    // Now, you can fetch collaborators with the new menuSelection value here
    fetchCollaborators();
  };
  const handleMenuChangesat = (e) => {
    const sat = e.target.value;
  
    setSatSelection(sat);
    
    // Now, you can fetch collaborators with the new menuSelection value here
    fetchCollaborators();
  };
  const handleMenuChangeessay = (e) => {
    const essay = e.target.value;
  
    setEssaySelection(essay);
    
    // Now, you can fetch collaborators with the new menuSelection value here
    fetchCollaborators();
  };
  const handleEasySelectionChange = (event) => {
    setEasySelection(event.target.value);
    fetchCollaborators();
  };

  const fetchCollaborators = () => {
    // Update this URL with your actual API endpoint and include search and filter parameters
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;

    fetch(`${apiUrl}/get_search_colloborators_for_student`, {
      method: 'post',
      body: JSON.stringify({ userId, searchTerm, majorSelection, citySelection,collegeSelection,easySelection}), 
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Request failed');
        }
      })
      .then((data) => {
        console.log(data.collaborators , "Collaborator 2");
        setcollaborators(data.collaborators);
      })
      .catch((error) => {
        console.error('Data fetch failed:', error);
      });
  };

  useEffect(() => {
    
    fetchCollaborators();
  }, [searchTerm, citySelection,collegeSelection,majorSelection,gpaSelection,satSelection,easySelection]);

  const handleSearchColleges = (searchText) => {
    const apiKey = 'xjbOja8DPH9EuBj6HUj8EJaj'; // Replace with your API key
    const apiUrl = `https://api.collegeai.com/v1/api/autocomplete/colleges?api_key=${apiKey}&query=${searchText}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data, "colleges Names");
        console.log(data,"colleges Names");
        const updatedColleges = data.collegeList.map((college) => ({
          value: college.name,
          label: college.name,
        }));
        setOptions(updatedColleges);
        if (data && data.collegeList) {
          setOptions(data.collegeList.map((college) => ({
            value: college.name,
            label: college.name,
          })));
        }
      })
      .catch((error) => {
        console.error('Error fetching colleges:', error); 
      });
  };
  
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  } 
  const callApiAndNavigate = (content) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;
  
    fetch(`${apiUrl}/profile/count/${content.id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Assuming data contains necessary information for navigation
        console.log(data,"This is data of count");
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };
  
  const handleButtonClick2 = (content) => {
    if (membership === null) {
      handleClickOpen();
    } else if (membership === 1) {
      callApiAndNavigate(content);
      window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(content.id)}`;
    }
     else if (membership === 0) {
      callApiAndNavigate(content);
      window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(content.id)}`;
    }
  };
  const clear_filters = () => {
    // Assuming you have state variables for your filters
    setSearchTerm(''); // Clear the search term
    setCollegeSelection('');
    setcitySelection('');
    setEasySelection('');
    setMajorSelection('');

   
    // Clear the category filter
    // Clear other filter states as needed
  };
  const [selectedMajor, setSelectedMajor] = useState([]);
   const top100Films = [
          // { title: "Computer Science" },
          // { title: "Biological sciences" },
          // { title: "Mathematics" },
          // { title: "Chemistry" },
          // { title: "Psychology" },
          // { title: "Accounting" },
          // { title: "Political science" },
          // { title: "Engineering" },
          // { title: "Anthropology" },
          // { title: "Economics" },
          // { title: "Marketing" },
          // { title: "Physics" },
          // { title: "Biochemistry" },
          // { title: "Mechanical Engineering" },
          // { title: "Philosophy" },
          // { title: "Chemical Engineering" },
          // { title: "Biomedical engineering" },
          // { title: "Business administration" },
          // { title: "Communications" },
          // { title: "Sociology" },
          // { title: "Electrical engineering" },
          // { title: "Computer Engineering" },
          // { title: "Primary education" },
          // { title: "Criminal justice" },
          // { title: "Neither" },
          { title: "Accounting" },
          { title: "Actuarial Science" },
          { title: "African American Studies" },
         { title: "African Studies" },
          { title: "Agricultural Business" },
          { title: "Agricultural Engineering" },
          { title: "Agricultural Science" },
          { title: "American Studies" },
          { title: "Animal Science" },
          { title: "Anthropology" },
          { title: "Applied Mathematics" },
          { title: "Architectural Design" },
          { title: "Architecture" },
          { title: "Art" },
          { title: "Art Education" },
          { title: "Art History" },
          { title: "Asian Studies" },
          { title: "Astronomy" },
          { title: "Astrophysics" },
          { title: "Athletic Training" },
          { title: "Biochemistry" },
          { title: "Biology" },
          { title: "Biomedical Engineering" },
          { title: "Biophysics" },
          { title: "Business Administration" },
          { title: "Business Analytics" },
          { title: "Business Information Systems" },
          { title: "Business Management" },
          { title: "Business Marketing" },
          { title: "Calculus" },
          { title: "Canadian Studies" },
          { title: "Cardiac Science" },
          { title: "Cartography and Geographic Information Science" },
          { title: "Cell and Molecular Biology" },
          { title: "Chemical Engineering" },
          { title: "Chemistry" },
          { title: "Child and Adolescent Development" },
          { title: "Chinese" },
          { title: "Civil Engineering" },
          { title: "Classical Studies" },
          { title: "Clinical Laboratory Science" },
          { title: "Cognitive Science" },
          { title: "Communication Studies" },
          { title: "Computer Engineering" },
          { title: "Computer Science" },
          { title: "Construction Engineering Management" },
          { title: "Culinary Arts and Management" },
          { title: "Dance" },
          { title: "Data Science" },
          { title: "Dental Hygiene" },
          { title: "Dentistry" },
          { title: "Developmental Biology" },
          { title: "Dietetics and Nutrition" },
          { title: "Digital Media" },
          { title: "Drama" },
          { title: "Early Childhood Education" },
          { title: "Economics" },
          { title: "Education" },
          { title: "Educational Psychology" },
          { title: "Electrical Engineering" },
          { title: "Engineering" },
          { title: "Engineering Physics" },
          { title: "English" },
          { title: "English Language Arts" },
          { title: "Entomology" },
          { title: "Entrepreneurship" },
          { title: "Environmental Engineering" },
          { title: "Environmental Science" },
          { title: "European Studies" },
          { title: "Exercise Science" },
          { title: "Exploratory" },
          { title: "Fashion Design" },
          { title: "Finance" },
          { title: "Film and Media" },
          { title: "Financial Planning" },
          { title: "Food and Nutrition" },
          { title: "Food Science and Technology" },
          { title: "Foreign Languages" },
          { title: "Forestry" },
          { title: "French" },
          { title: "Gender Studies" },
          { title: "General Business" },
          { title: "General Engineering" },
          { title: "Geography" },
          { title: "Geology" },
          { title: "German" },
          { title: "Graphic Design" },
          { title: "Greek" },
          { title: "Healthcare Management" },
          { title: "Healthcare Studies" },
          { title: "Health Informatics" },
          { title: "Health Sciences" },
          { title: "Health Services Administration" },
          { title: "History" },
          { title: "Hospitality Management" },
          { title: "Human Evolutionary Biology" },
          { title: "Human Resource Management" },
          { title: "Human Services" },
          { title: "Humanities" },
          { title: "Ibero-American Studies" },
          { title: "Information Systems" },
          { title: "Information Technology" },
          { title: "Interior Design" },
          { title: "International Business" },
          { title: "International Relations" },
          { title: "Italian" },
          { title: "Japanese" },
          { title: "Journalism" },
          { title: "Kinesiology" },
          { title: "Korean" },
          { title: "Landscape Architecture" },
          { title: "Latin" },
          { title: "Law" },
          { title: "Linguistics" },
          { title: "Management" },
          { title: "Marine Biology" },
          { title: "Marketing" },
          { title: "Mathematics" },
          { title: "Mechanical Engineering" },
          { title: "Medical Technology" },
          { title: "Microbiology" },
          { title: "Middle Eastern Studies" },
          { title: "Music" },
          { title: "Music Education" },
          { title: "Music Performance" },
          { title: "Neuroscience" },
          { title: "Neruology" },
          { title: "Nursing" },
          { title: "Nutrition" },
          { title: "Occupational Therapy" },
          { title: "Operations Management" },
          { title: "Optometry" },
          { title: "Organizational Behavior" },
          { title: "Other" },
          { title: "Paramedicine" },
          { title: "Parks, Recreation and Tourism Management" },
          { title: "Persian" },
          { title: "Petroleum Engineering" },
          { title: "Pharmacy" },
          { title: "Philosophy" },
          { title: "Physics" },
          { title: "Physiology" },
          { title: "Political Science" },
          { title: "Pre-Law" },
          { title: "Pre-Med" },
          { title: "Psychology" },
          { title: "Public Health" },
          { title: "Radiologic Technology" },
          { title: "Real Estate" },
          { title: "Religious Studies" },
          { title: "Respiratory Therapy" },
          { title: "Robotics" },
          { title: "Russian" },
          { title: "Social Work" },
          { title: "Sociology" },
          { title: "Spanish" },
          { title: "Speech Pathology" },
          { title: "Statistics" },
          { title: "Sustainable Development" },
          { title: "Teaching English to Speakers of Other Languages (TESOL)" },
          { title: "Theater" },
          { title: "Tourism" },
          { title: "Undeclared" },
          { title: "Veterinary Medicine" },
          { title: "Web Development" },
          { title: "Wildlife Conservation" },
          { title: "Women’s Studies" },
          { title: "Writing" },
          { title: "Zoology" }
          
        ];
  const filteredOptions = top100Films.filter(
    (option) => !selectedMajor.some((selected) => selected.title === option.title)
  );
  return (
 
      <Grid className='student_search_collaboartor_maindiv' container rowGap={3} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" ,height:'100%'}} >
        <Grid
        className="student_search_collaborator"
          item
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundColor: "white",
            display: 'flex',
            flexDirection: 'column',
            padding: "30px",
            flex: '20%',
          }} 
        >
                   <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Box sx={{ display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',textAlign:'center',width:'210px'}}>
        <div>
    
    <img src={Premium} alt='Icon1'/>
    </div>
     
   <div style={{paddingBottom:'20px',paddingTop:'20px',display:'flex',flexDirection:'column'}}>
        <typography className='Premium_text'>Go Premium</typography>
  
        <typography className='Premium_text'>Explore Colleges</typography>
        <typography className='Premium_text'>View real applications</typography>
        <typography className='Premium_text'>Read admission essays</typography>
        <typography className='Premium_text'>Message Collaborators</typography>
        </div>
        <div style={{width:'100%'}}>
            <Link to='/Student_pricing'>
        <Button
        variant="outlined"
        color="warning"
        size="small"
        fullWidth
      >
      UPGRADE TO PREMIUM
      </Button>
      </Link>
        </div>
   

  </Box>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
          <div className='Test_paddingDiv_search'>
            <div style={{display:"flex",justifyContent:"space-between" }}>
              <div style={{width:"100%",gap:"10px",display:"flex"}}>
                <TextField  label="Search"   id="search" 
          size='small'
               
               value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} sx={{width:"80%"}} />
            <TuneSharpIcon sx={{color:"#C4C4C4",height:"1.5em",width:"1.5em",cursor:'pointer'}} onClick={toggleSearchVisibility}/>
            <Button variant='outlined' onClick={clear_filters}>Clear</Button>
              </div>
     
    {/* <Button variant="contained" color="primary" sx={{width:"140px",boxShadow:"none"}} size="small" onClick={fetchCollaborators}>Search</Button> */}
            </div>
            {isSearchVisible && (<>
            <Divider className="DividerCustom" variant="inset" /> 
       <div className='student_search_barsdiv' style={{display:"flex", flexDirection:"row",gap:"20px"}}>
      <Autocomplete
         fullWidth
      id="search-term-colleges"
      options={options}
      getOptionLabel={(option) => option.label || ''}
      value={collegeSelection}
      onChange={(event, newValue) => {
        setCollegeSelection(newValue);
        fetchCollaborators();
      }}
      onInputChange={(_, newInputValue) => {
        handleSearchColleges(newInputValue);
        
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Colleges applied to"
          fullWidth
          size="small"
        />
      )}
      noOptionsText="Search College"
    />
      
     
          {/* <TextField
            fullWidth
            id="outlined-select-currency2"
            select
            label="Major"
            value={majorSelection}
            size='small'
         
          onChange={handleMenuChangeMajor} 
           
          >
            {Major.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            fullWidth
            id="city"
            select
            label="Origin"
            value={citySelection}
            onChange={handleMenuChangecity} 
          size='small'
          InputProps={{
            endAdornment: citySelection && (
              <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClearSelection} />
            ),
          }}
            
          >
            {cities.map((cityName, index) => (
              <MenuItem key={index} value={cityName}>
                {cityName}
              </MenuItem>
            ))}
          </TextField>
      </div> 
      <div className='student_search_barsdiv' style={{display:"flex", flexDirection:"row",gap:"20px"}}>
      <Autocomplete
  size="small"
  sx={{ mb: 2,marginTop:"20px" }}
  fullWidth
  // value={majorSelection}
  id="tags-outlined"
  options={filteredOptions} // Make sure top100Films has the same structure as collegeData.major
    getOptionLabel={(option) => option.title} // Assuming option.title exists in top100Films
    filterSelectedOptions={true}
  onChange={(event, newValue) => {
    setMajorSelection(newValue);
    fetchCollaborators();
  }}
 
  renderInput={(params) => (
    <TextField
      {...params}
      label="Major"
      fullWidth
      size="small"
 
    />
  )}
/>

<FormControl fullWidth size="small" sx={{ mb: 2,marginTop:"20px" }}
>
        <InputLabel id="demo-simple-select-label">Essay</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="easySelection"
        
          label="Essay  "
      value={easySelection}
      onChange={handleEasySelectionChange}
        >
          <MenuItem value="included">Included</MenuItem>
          <MenuItem value="Not_Included">Not Included</MenuItem>
          
        </Select>
     
      </FormControl>
      </div>
      </>
        )}
    {/* <Divider className="DividerCustom" variant="inset" /> */}
    {/* <div style={{display:"flex", flexDirection:"row",gap:"20px"}}>
    <TextField
    fullWidth
          id="outlined-select-currency1"
          select
          size='small'

          label="College"
          value={collegeSelection}
          onChange={handleMenuChangeCollege} 
        
          
        >
          {Colleges.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size='small'

          fullWidth
          id="outlined-select-currency2"
          select
          label="Major"
          value={majorSelection}
       
        onChange={handleMenuChangeMajor} 
         
        >
          {Major.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size='small'

          fullWidth
          id="city"
          select
          label="High school origin"
          value={citySelection}
          onChange={handleMenuChangecity} 
          
        >
          {cities.map((cityName, index) => (
            <MenuItem key={index} value={cityName}>
              {cityName}
            </MenuItem>
          ))}
        </TextField>
    </div>
    <div style={{display:"flex", flexDirection:"row",gap:"20px",marginTop:"20px"}}>
    <TextField
          size='small'

    fullWidth
          id="outlined-select-currency4"
          select
          label="GPA"
          value={gpaSelection}
          onChange={handleMenuChangegpa} 
        
          
        >
          {GPA.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size='small'

          fullWidth
          id="outlined-select-currency5"
          select
          label="SAT/ACT"
          value={satSelection}
          onChange={handleMenuChangesat} 
         
        >
          {sat.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size='small'

          fullWidth
          id="outlined-select-currency6"
          select
          label="Essay"
          value={essaySelection}
          onChange={handleMenuChangeessay} 
          
        >
          {essay.map((option) => (
               <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
    </div> */} 
    </div>
        </Grid>

        <Grid className='Student_suggested_collaborator_div' rowGap={3} item lg={12} xl={12} xs={12} sm={12} md={12} sx={{ backgroundColor: "white", padding: "30px" }}>
          {/* Correct the component name to typography */}
          <typography className='message_text'>Suggested Collaborators</typography>
          <Dialog
          open={open2}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie    options={{
            loop: true,
            autoplay: true,
            animationData: messagerequesterror === 'Your request is already sent.' ? animationData2 : animationData,
          }}
           height={150} width={150} />
         
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              {messagerequesterror === 'Your request is already sent.' ? 
            null
           : (
            <typography className="Success">Success</typography>
          )}
           
            <typography className='Submitted_text2'> {messagerequesterror && (
                <div className="success-message">{messagerequesterror}</div>
              )}</typography>
         
        
          </div>
          <DialogActions sx={{display:"flex",justifyContent:"center"}}>
          <Button onClick={handleClose2}>Ok</Button>
          
       </DialogActions>
          </DialogContent>
          
        </Dialog>
          <Box className="Student_search_collaborators" sx={{ display: "grid", justifyContent: "center", rowGap:"94px",columnGap:"32px", alignItems: "center", gridTemplateColumns: "repeat(3, 3fr)",paddingTop:'60px' ,justifyItems:"center" }}>
            {collaborators.map((content, index) => (
              <>
               <div style={{display:"flex",justifyContent:"space-between",gap:"27%",alignItems:"center"}}>
              <Box
                key={index}
                sx={{
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: "column",
                  justifyItems: "center",
                  width:"180px"
                }}
              >
                <Avatar aria-label="recipe" src={content.image}  sx={{width:"60px",height:"60px"}} />
                {/* Correct the component name to typography */}
                {/* <Link to={`/Collaborator_Profile?id=${encodeAlphanumeric(content.id)}`}  style={{ textDecoration: 'none',textAlign:'center' }}>  */}
                <typography className='Student_message_text_secondary'>
                {content.legal_name.split(' ').length > 4
    ? `${content.legal_name.split(' ').slice(0, 4).join(' ')} ...`
    : content.legal_name}</typography>
                {/* </Link> */}
                <Box sx={{ display: "flex", alignItems: "center" ,marginBottom:"10px"}}>
                  <LocationOnOutlinedIcon sx={{color:"#58595B",width:"16px",height:"16px",gap:"4px"}}/> 
                  {/* Correct the component name to typography */}
                  <typography className='Student_message_text_secondary2'>{content.city}</typography>
                </Box>
                {/* Correct the component name to typography */}
                <typography className='Student_message_text_secondary21'>GPA - {content.gpsscore}/{content.gpaOutof}</typography>
                 {/*<Chip label="Applying" color="success" size="large" variant="outlined" />*/}
                {/* Correct the component name to typography */}
               
              
        <typography className='message_text_secondary3'>
          {content.collgename.split(' ').length > 7
            ? `${content.collgename.split(' ').slice(0, 7).join(' ')} ...`
            : content.collgename}
            {/* {content.collgename} */}
        </typography>
     
                <Button
              variant="outlined"
              sx={{width:"179px",boxShadow:"none"}}
             
              color="primary" 
              size="small"
              onClick={() => sendMessageRequest(content.id)}
            >
             {content.messageRequest === 'yes' ? 'Request Sent' : 'Message'}
            </Button>
           
            {/* <Button variant="outlined" onClick={handleClickOpen} sx={{marginTop:"20px"}}>
        View Profile
      </Button> */}
    <Button variant="outlined" onClick={() => handleButtonClick2(content)} sx={{ marginTop: "20px",width:"179px",boxShadow:"none" }}  size="small">
  View Profile
</Button>
              </Box>
              <Box className="Student_search_collaborators_divider"  sx={{ borderRight: shouldShowBorder(index) ? '1px solid #ccc' : 'none', height: '155px',marginTop:"-34px"}}/> 
              
              
              </div>
              </>
            ))}
          </Box>
          
        </Grid>
      </Grid>
      )
        
      
  
  
};

export default SearchCollaborator

