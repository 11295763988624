import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  TextField,
  Button,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import AvatarImage from "../../assets/img/_Avatar_1.png";
import { Link } from "react-router-dom";

function createData(avatarUrl, name, email, college, status, subscription) {
  return { avatarUrl, name, email, college, status, subscription };
}

export const Admin_student = () => {
  const [switchState, setSwitchState] = useState(false);
  const cities = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
  ];
  const statuses = ["paid", "Not Paid","Admin-authorized"];

  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);
  const [citySelection, setcitySelection] = useState("");
  const [payment_status, setpayment_status] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const rows = [
    createData(
      AvatarImage,
      "Frozen yoghurt",
      "D@gmail.com",
      "College Name",
      "Paid",
      "Monthly",
      4.0
    ),
    createData(
      2,
      "Ice cream sandwich",
      "D@gmail.com",
      "College Name",
      "Not Paid",
      "Monthly",
      4.3
    ),
    createData(
      3,
      "Eclair",
      "D@gmail.com",
      "College Name",
      "Paid",
      "Monthly",
      6.0
    ),
    createData(
      4,
      "Cupcake",
      "D@gmail.com",
      "College Name",
      "Paid",
      "Semesterly",
      4.3
    ),
    createData(
      5,
      "Gingerbread",
      "D@gmail.com",
      "College Name",
      "Not Paid",
      "Monthly",
      3.9
    ),
    createData(
      6,
      "Gingerbread",
      "D@gmail.com1111",
      "College Name",
      "Paid",
      "Yearly",
      3.9
    ),
  ];

  const handleClearSelection = () => {
    setcitySelection("");
  };
  const handleClearSelection2 = () => {
    setpayment_status("");
  };
  const handleMenuChangecity = (e) => {
    const city = e.target.value;

    setcitySelection(city);

    // Now, you can fetch students with the new menuSelection value here
  };
  const handleMenuChangestatus = (e) => {
    const city = e.target.value;

    setpayment_status(city);
    console.log(city,"This is value")

    // Now, you can fetch students with the new menuSelection value here
  };
  const [students, setStudents] = useState([]);
  const [students2, setStudents2] = useState([]);
  const [storedAccessToken, setAccessToken] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const storedAccessToken = localStorage.getItem("accessToken");

        if (!storedAccessToken) {
          console.error("Access token not found");
          window.location.href = "/admin-login";
          return;
        }

        setAccessToken(storedAccessToken);

        const response = await fetch(`${apiUrl}/admin/get-students`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedAccessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        console.log(data.students, "This is data");
        setStudents(data.students);
        const profile1url = students[1]?.avatarImage;
        const laravelUrl = generateAvatarUrl(profile1url);
        setCollaboratorlaravelUrlfinal(laravelUrl);
      } catch (error) {
        console.error("Data fetch failed:", error);
      }
    };

    fetchData();
  }, []);

  const [switchStates, setSwitchStates] = useState({});
  const [switchStates2, setSwitchStates2] = useState({});

  const handleSwitchChange = async (userId, isEnabled) => {
    try {
      // Fetch the current status of the collaborator
      const studentIndex = students.findIndex((c) => c.id === userId);

      if (studentIndex === -1) {
        console.error("Collaborator not found");
        return;
      }

      const updatedStudents = [...students];
      const updatedStudent = { ...updatedStudents[studentIndex] };

      const apiUrl = isEnabled
        ? `${process.env.REACT_APP_API_URL}/admin/disable/student/${userId}`
        : `${process.env.REACT_APP_API_URL}/admin/enable/student/${userId}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedAccessToken}`,
        },
      });

      console.log(response);

      if (!response.ok) {
        throw new Error("API request failed");
      }

      const data = await response.json();
      console.log(data);
      fetchStudents();
      updatedStudent.status = isEnabled ? 0 : 1;
      updatedStudents[studentIndex] = updatedStudent;

      // Update the state with the modified students
      setStudents(updatedStudents);
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if needed
    }
  };
  // const handleSwitchChange4 = async (userId, isEnabled) => {
  //   try {
  //     // Fetch the current status of the collaborator
  //     const studentIndex = students.findIndex((c) => c.id === userId);

  //     if (studentIndex === -1) {
  //       console.error('Collaborator not found');
  //       return;
  //     }

  //     const updatedStudents = [...students];
  //     const updatedStudent = { ...updatedStudents[studentIndex] };

  //     const apiUrl = isEnabled
  //     ? `${process.env.REACT_APP_API_URL}/admin/un_paid/student/${userId}`
  //     : `${process.env.REACT_APP_API_URL}/admin/paid/student/${userId}`;

  //     const response = await fetch(apiUrl, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${storedAccessToken}`,
  //       },
  //     });

  //     console.log(response);

  //     if (!response.ok) {
  //       throw new Error('API request failed');
  //     }

  //     const data = await response.json();
  //     console.log(data);

  //     updatedStudent.status = isEnabled ? 0 : 1;
  //     updatedStudents[studentIndex] = updatedStudent;

  //     // Update the state with the modified students
  //     setStudents(updatedStudents);

  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle errors if needed
  //   }
  // };

  const generateAvatarUrl = (imagePath) => {
    if (imagePath) {
      const laravelUrl = imagePath.replace("/public/", "/storage/app/public/");
      const laravelUrlfinal = laravelUrl.replace(
        "/public/storage/",
        "/public/"
      );
      return laravelUrlfinal;
    }
    return ""; // Set a default image or handle the case when the image path is not available
  };
  const handleSwitchChange2 = async (userId, isEnabled) => {
    try {
      // Fetch the current status of the collaborator
      const studentIndex = students.findIndex((c) => c.id === userId);

      if (studentIndex === -1) {
        console.error("Collaborator not found");
        return;
      }

      const updatedStudents = [...students];
      const updatedStudent = { ...updatedStudents[studentIndex] };

      const apiUrl = isEnabled
        ? `${process.env.REACT_APP_API_URL}/admin/enable/student/${userId}`
        : `${process.env.REACT_APP_API_URL}/admin/disable/student/${userId}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedAccessToken}`,
        },
      });

      console.log(response);

      if (!response.ok) {
        throw new Error("API request failed");
      }

      const data = await response.json();
      console.log(data);
      fetchStudents();
      updatedStudent.status = isEnabled ? 1 : 0;
      updatedStudents[studentIndex] = updatedStudent;

      // Update the state with the modified students
      setStudents(updatedStudents);
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if needed
    }
  };
  // const handleSwitchChange3 = async (userId, isEnabled) => {
  //   try {
  //     // Fetch the current status of the collaborator
  //     const studentIndex = students.findIndex((c) => c.id === userId);

  //     if (studentIndex === -1) {
  //       console.error('Collaborator not found');
  //       return;
  //     }

  //     const updatedStudents = [...students];
  //     const updatedStudent = { ...updatedStudents[studentIndex] };

  //     const apiUrl = isEnabled
  //       ? `${process.env.REACT_APP_API_URL}/admin/paid/student/${userId}`
  //       : `${process.env.REACT_APP_API_URL}/admin/un_paid/student/${userId}`;

  //     const response = await fetch(apiUrl, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${storedAccessToken}`,
  //       },
  //     });

  //     console.log(response);

  //     if (!response.ok) {
  //       throw new Error('API request failed');
  //     }

  //     const data = await response.json();
  //     console.log(data,"Ths is data");

  //     updatedStudent.status = isEnabled ? 1 : 0;
  //     updatedStudents[studentIndex] = updatedStudent;

  //     // Update the state with the modified students
  //     setStudents(updatedStudents);

  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle errors if needed
  //   }
  // };
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  }

  const clear_filters = () => {
    // Assuming you have state variables for your filters
    setSearchTerm("");
    // setstatus('');
    // setstatus2('');
    // setcollegeSelection('');
    setpayment_status("");
    // Clear the category filter
    // Clear other filter states as needed
  };

  const handleSwitchChange4 = async (userId, isEnabled) => {
    try {
      const apiUrl = isEnabled
        ? `${process.env.REACT_APP_API_URL}/admin/un_paid/student/${userId}`
        : `${process.env.REACT_APP_API_URL}/admin/paid/student/${userId}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedAccessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("API request failed");
      }

      const data = await response.json();
      console.log(data, "Data4");

      // Update the status locally based on the response
      const updatedStudents = students.map((student) => {
        if (student.id === userId) {
          return {
            ...student,
            status: isEnabled ? 0 : 1, // Update status based on toggle state
          };
        }
        return student;
      });

      // Update the state with the modified students
      setStudents2(updatedStudents);
      fetchStudents();
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if needed
    }
  };

  const handleSwitchChange3 = async (userId, isEnabled) => {
    try {
      const apiUrl = isEnabled
        ? `${process.env.REACT_APP_API_URL}/admin/paid/student/${userId}`
        : `${process.env.REACT_APP_API_URL}/admin/un_paid/student/${userId}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedAccessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("API request failed");
      }

      const data = await response.json();
      console.log(data, "Data3");
      fetchStudents();
      // Update the status locally based on the response
      const updatedStudents = students.map((student) => {
        if (student.id === userId) {
          return {
            ...student,
            status: isEnabled ? 1 : 0, // Update status based on toggle state
          };
        }
        return student;
      });

      // Update the state with the modified students
      setStudents2(updatedStudents);
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if needed
    }
  };

  const fetchStudents = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const storedAccessToken = localStorage.getItem("accessToken");

      if (!storedAccessToken) {
        console.error("Access token not found");
        window.location.href = "/admin-login";
        return;
      }

      const response = await fetch(`${apiUrl}/admin/get_search_student`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({ searchTerm, payment_status }),
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const data = await response.json();
      console.log(data.students, "Filtered Students");
      setStudents(data.students); // Update students state with filtered data
    } catch (error) {
      console.error("Data fetch failed:", error);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, [searchTerm, payment_status]);

  return (
    <div className="Admin_collaborator_Container">
      <Grid
        container
        className="Home_collaborator_view"
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
      >
        <Box className="admin_textfield_Box_Main">
          <typography className="admin_collaborator_main_Text">
            Students
          </typography>
          <Box className="admin_textfield_Box">
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              label="Name,Email,etc..."
              id="search"
              size="small"
              className="Admin_textfield1"
            />
            <TextField
              id="status"
              select
              label="Payment Status"
              value={payment_status}
              onChange={handleMenuChangestatus}
              size="small"
              InputProps={{
                endAdornment: payment_status && (
                  <ClearIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleClearSelection2}
                  />
                ),
              }}
              className="Admin_textfield2"
            >
              {statuses.map((cityName, index) => (
                <MenuItem key={index} value={cityName}>
                  {cityName}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="outlined" onClick={clear_filters}>
              Clear
            </Button>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <typogaphy className="table_main_Text">Name</typogaphy>
                </TableCell>
                <TableCell>
                  <typogaphy className="table_main_Text">Email</typogaphy>
                </TableCell>
                <TableCell>
                  <typogaphy className="table_main_Text">
                    Interested in
                  </typogaphy>
                </TableCell>
                <TableCell>
                  <typogaphy className="table_main_Text">
                    Payment Status
                  </typogaphy>
                </TableCell>
                <TableCell>
                  <typogaphy className="table_main_Text">
                    Subscription
                  </typogaphy>
                </TableCell>
                <TableCell>
                  <typogaphy className="table_main_Text">
                    Membership Id
                  </typogaphy>
                </TableCell>
                <TableCell>
                  <typogaphy className="table_main_Text">Action</typogaphy>
                </TableCell>
                {/* <TableCell className='table_main_Text'>Email</TableCell> */}
                {/* <TableCell className='table_main_Text'>College</TableCell> */}
                {/* <TableCell className='table_main_Text'>Status</TableCell> */}
                {/* <TableCell className='table_main_Text'>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.slice(pg * rpg, pg * rpg + rpg).map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/admin_student_profileview?id=${encodeAlphanumeric(
                        row.id
                      )}`}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={generateAvatarUrl(row.avatarImage)}
                          className="Admin_main_Avatar"
                        />
                        <typogaphy className="table_secondary_Text">
                          {row.legal_name}
                        </typogaphy>
                      </Box>
                    </Link>
                  </TableCell>

                  <TableCell>
                    <typogaphy className="table_secondary_Text">
                      {row.email}
                    </typogaphy>{" "}
                  </TableCell>
                  <TableCell>
                    <typogaphy className="table_secondary_Text">
                      {row.collegeTitle || "College not found"}
                    </typogaphy>{" "}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        row.membership === 1
                          ? "Paid"
                          : row.membership === null
                          ? "Not Paid"
                          : row.membership === 0
                          ? "Not Paid"
                          : row.status
                      }
                      color={
                        row.membership === 1
                          ? "success" // Use 'error' for red color, or any other color you prefer
                          : row.membership === null
                          ? "warning" // Use 'warning' for yellow color
                          : "secondary"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <typogaphy className="table_secondary_Text">
                      {row.membership_type || "Null"}
                    </typogaphy>{" "}
                  </TableCell>
                  <TableCell>
                    {row.membership === 1 || row.membership === 0 ? (
                      <>
                        <Switch
                          label="Label"
                          defaultChecked={
                            row.membership === 1 || row.membership === 0
                          }
                          color="success"
                          onChange={() =>
                            handleSwitchChange4(row.id, !switchStates2[row.id])
                          }
                          disabled={row.membership === 1}
                        />
                        {"Enable"}
                      </>
                    ) : (
                      <>
                        <Switch
                          label="Label"
                          defaultChecked={
                            row.membership === 1 || row.membership === 0
                          }
                          color="success"
                          onChange={() =>
                            handleSwitchChange3(row.id, !switchStates2[row.id])
                          }
                          disabled={row.membership === 1}
                        />
                        {"Disable"}
                      </>
                    )}
                  </TableCell>

                  <TableCell>
                    {row.status === 1 ? (
                      <>
                        <Switch
                          label="Label"
                          defaultChecked={row.status === 1}
                          color="success"
                          onChange={() =>
                            handleSwitchChange(row.id, !switchStates[row.id])
                          }
                        />
                        {"Enable"}
                      </>
                    ) : (
                      <>
                        <Switch
                          label="Label"
                          defaultChecked={row.status === 1}
                          color="success"
                          onChange={() =>
                            handleSwitchChange2(row.id, !switchStates[row.id])
                          }
                        />
                        {"Disable"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={students.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </div>
  );
};
