import React,{useState, useEffect} from 'react'
import {
    Box,
    CardHeader,
    Button,
    Badge,
    Avatar,
    Grid,
    Paper
  } from "@mui/material";
  import mainlogo from '../../assets/img/mainwelcome.png'
const Messagerequest = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
    const [sections, setsections] = useState(null);
    const [messagerequesterror, setmessagerequesterror] = useState([]);
    const [messagerequeststatus, setmessagerequeststatus] = useState('');
    const [messagerequestacceptrehecterror, setmessagerequestacceptrehecterror] = useState(''); 
    
  function acceptmessagerequest(requestid,sendfrom,sendto) {
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    
  
   
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;

          fetch(`${apiUrl}/acceptmessagerequest`, {
              method: 'post',
              body: JSON.stringify({ requestid, sendfrom, sendto }), 
          })
          .then(response => {
              if (response.status === 201) {
                  return response.json(); 
              } else {
                  throw new Error('Request failed');
              }
          })
          .then(data => {
             
            setmessagerequeststatus("Accepted");
            setmessagerequestacceptrehecterror(data.message);
            setTimeout(() => {
              setmessagerequestacceptrehecterror(''); 
            }, 5000); 
            
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
  }
  
  function rejectmessagerequest(requestid,sendfrom,sendto) { 
    // You can pass the collaborator's ID or any other identifier to identify the recipient.
    // You can also add additional data to the request, such as the message content.
    
  
   
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;

          fetch(`${apiUrl}/rejectmessagerequest`, {
              method: 'post',
              body: JSON.stringify({ requestid, sendfrom, sendto }), 
          })
          .then(response => {
              if (response.status === 201) {
                  return response.json(); 
              } else {
                  throw new Error('Request failed');
              }
          })
          .then(data => {
            setmessagerequeststatus("Rejected");
            setmessagerequestacceptrehecterror(data.message);
            setTimeout(() => {
              setmessagerequestacceptrehecterror(''); 
            }, 5000);
             
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
  }
  
    useEffect(() => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;
    
          fetch(`${apiUrl}/get_all_collaborator_message_request`, {
              method: 'post',
              body: JSON.stringify({ userId }), // Send the entire formData object as the body
          })
          .then(response => {
              if (response.status === 200) {
                  return response.json(); // Convert the response to JSON
              } else {
               
                  throw new Error('Request failed');
              }
          })
          .then(data => {
              
              console.log(data , 'Messages requests'); // Log the awardsData 
              setsections(data.collaborators || []);    
              console.log(sections,"section")
              //setcollaborators(data.collaborators);
             // alert("here");
          })
          .catch(error => {
              console.error('Data fetch failed:', error);
          });
      } catch (error) {
          console.error('Data upload failed:', error);
      }
      }, [messagerequeststatus]);
  return (
    <div style={{height:"100%"}}>
       <Grid container rowGap={3} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" ,height:'100%'}}>
            <Grid
            className='Student_messages_page_maindiv'
          item
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundColor: "white",
            display: 'flex',
            flexDirection: 'column',
            padding: "30px",
            flex: '20%',
          }} 
        >
         
          {/* Correct the component name to typography */}
          <typography className='message_text'>Message Requests</typography>
          {messagerequestacceptrehecterror && (
                <div className="success-message">{messagerequestacceptrehecterror}</div>
              )}
             {sections === null ? ( // Check for null (loading state)
          <></>
        ) :sections.length === 0 ? ( // Check if there are no message requests
             <Grid className='Student_Message_requestDiv' rowGap={3} item lg={12} xl={12} xs={12} sm={12} md={12} sx={{ backgroundColor: "white", padding: "30px" }}>
             <Box className="profile_view_mainDiv" >
             <img src={mainlogo} alt="Logo"  className="Student_profile-view-main"/>
     <typography className="Profile_view_welcome">When you receive a Message Request, it will appear here</typography>
    
   
     </Box>
     
             </Grid>
          ) : (
          sections.map((section, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
             <CardHeader
  avatar={
    <Badge color="success">
      <Avatar aria-label="recipe" src={section.avatarSrc} />
    </Badge>
  }
  title={
    <typography variant="h6" sx={{ fontSize: '18px' }}>
      {section.title}
    </typography>
  }
  subheader={
    <typography variant="body2" sx={{ fontSize: '14px' }}>
      {section.subheader}
    </typography>
  }
/>

              <Box>
                <Button onClick={() => rejectmessagerequest(section.requestid, section.sendfrom, section.sendto)} variant="text"  size="small">
                  {section.buttonLabel}
                </Button>
                <Button  onClick={() => acceptmessagerequest(section.requestid, section.sendfrom, section.sendto)} variant="text"  color="primary" size="small">
                  Accept
                </Button>
              </Box>
            </Box>
          ))
          )}
        </Grid>
        </Grid>
    </div>
  )
}

export default Messagerequest