import React, { useEffect, useState } from 'react'
import { Topbar } from './Topbar'
import { Avatar, Badge, Box, Button, CardHeader, Chip, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PropTypes from 'prop-types';
import collegeimage from '../../assets/img/college.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Highschoolimage from '../../assets/img/highschool.png';
import honoursimage from '../../assets/img/honorsnew.svg';
import testingimage from '../../assets/img/testing.png';
import sportimage from '../../assets/img/sports.png';
import viewsImage from '../../assets/img/InsightsIcon1.png'
import messagesImage from '../../assets/img/Insightsicon2.png'
import searchImage from '../../assets/img/insightsIcon3.png'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import InsightsIcon from '@mui/icons-material/Insights';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import profile1 from '../../assets/img/profile.jpg';
import SportsIcon from '../../assets/img/Admin_sports.png'
import CollegeIcon from '../../assets/img/Admin_College.png'
import Highschool_Icon from '../../assets/img/Admin_highschool.png'
import Honors_icon from '../../assets/img/Admin_honors.png'
import bgimagecover from '../../assets/img/bgcover.svg';
import { Link,useNavigate } from 'react-router-dom';
import Experiance_icon from '../../assets/img/experiance_icon.jpeg'

function CircularProgressWithLabel(props) { 
  
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex',}}>
      <CircularProgress variant="determinate" {...props}   size={120} // Adjust the size as needed
      thickness={5}  />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export const Admin_student_profileView = () => {
  const progress = 100;





  const [studentlaravelUrlfinal, setstudentlaravelUrlfinal] = useState(null);
  const [studentbackgroundurlimagefinal, setstudentbackgroundurlimagefinal] = useState(null);
  const [collaborators, setCollaborators] = useState([]);
  const [storedAccessToken, setAccessToken] = useState('');
  const [collegeId, setCollegeId] = useState('');
  const [Collegesdata, setCollegesdata] = useState([]);
  const [HighSchooldata, setHighSchooldata] = useState([]);
  const [Honoursdata, setHonoursdata] = useState([]); 
  const [testingdata,setTestingdata] = useState([]);
  const [Activitiesdata,setActivitiesdata] = useState([]);
  const [Experiencedata,setExperiencedata] = useState([]);
  const [Summarydata, setSummarydata]  = useState([]);
 const[userInfo, setUserImfo]= useState([])
 const [ collaboratorID, setCollaboratorID]=useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const storedAccessToken = localStorage.getItem('accessToken');
  
        if (!storedAccessToken) {
          console.error('Access token not found');
          window.location.href = '/admin-login';
          return;
        }
  
        setAccessToken(storedAccessToken);
  
        // Extract id from the URL query string
        const params = new URLSearchParams(window.location.search);
        const encodedId = params.get('id');
  
        if (!encodedId) {
          console.error('ID not found in the URL');
          return;
        }
  
        const decodedId = atob(encodedId); // Decode the id if it's encoded
  
        const response = await fetch(`${apiUrl}/admin/student/details/${decodedId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedAccessToken}`,
          },
        });
  
        console.log('API Response:', response);
  
        if (response.status === 200) {
          try {
            const data = await response.json();
            console.log('Fetched Data:', data);
  
            const collegesdata = data.user.college_data
            ;
            console.log(collegesdata,"This is college data");
            const highschooldata = data.user.students_high_school;
            // console.log(highschooldata,"This is college data");
            setCollaboratorID(decodedId)

            const hounoursdata = data.user.students_awards;
            // console.log(hounoursdata,"This is Hnors data");
            const testingdata = data.user.student_test_data;
            // console.log(testingdata,"This is testing data");
            const activitiesdata = data.user.student_experience_data
            ;
            const personalessay = data.user.student_summary
            ;
            const collaboratorExperience = data.user.studentworkExperiences;
  const userInfo=data.user.user_personal_data
  // console.log(userInfo,"userInfo")
  const profile1url = userInfo.avatarImage;
  const laravelUrl = profile1url.replace('/public/', '/storage/app/public/');
  const laravelUrlfinal = laravelUrl.replace('/public/storage/', '/public/');
  setstudentlaravelUrlfinal(laravelUrlfinal);
  const backgroundurl = userInfo.background_image;
               
  let backgroundurlimagefinal;

  if (backgroundurl) {
    const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/');
    backgroundurlimagefinal = backgroundurlimage.replace('/public/storage/', '/public/');
    setstudentbackgroundurlimagefinal(backgroundurlimagefinal);
  } else {
    backgroundurlimagefinal = '';
  }
  console.log(laravelUrlfinal,"user image"); 
            setCollegesdata(collegesdata);
            setHighSchooldata(highschooldata);
            setHonoursdata(hounoursdata);
            setTestingdata(testingdata);
            setActivitiesdata(activitiesdata);
            setSummarydata(personalessay);
            setExperiencedata(collaboratorExperience);
            setUserImfo(userInfo);
          } catch (jsonError) {
            console.error('Error parsing JSON:', jsonError);
          }
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('Data fetch failed:', error);
      }
    };
  
    fetchData();
  }, [collegeId]);
  const [selectedCollegeId, setSelectedCollegeId] = useState('');
  const [selectedHighschoolId, setSelectedHighschoolId] = useState('');
  const [selectedPersonalEssayId, setSelectedPersonalEssayId] = useState('');
  const [selectedTestingId, setSelectedTestingId] = useState('');
  const [selectedHonoursId, setSelectedHonoursId] = useState('');
  const [selectedActivityId, setSelectedActivityId] = useState('');
  const [selectedEperienceId, setSelectedEperienceId] = useState('');
  const handleEditClickActivities = (id) => {
    setSelectedActivityId(id); // Set the selected college id in the state
    console.log(setSelectedActivityId,"selectedTestingId")
  };
  const handleEditClickTesting = (id) => {
    ;
    setSelectedTestingId(id); // Set the selected college id in the state
    console.log(selectedTestingId,"selectedTestingId")
  };
  const handleEditClickExperience = (id) => {
    setSelectedEperienceId(id); // Set the selected college id in the state
    
  };
  const handleEditClickHonours = (id) => {
    setSelectedHonoursId(id); // Set the selected college id in the state
    console.log(setSelectedHonoursId,"selectedTestingId")
  };
  const handleEditClickHighschool = (id) => {
    setSelectedHighschoolId(id); // Set the selected college id in the state
    
  };
  const handleEditClickSummary = (id) => {
    setSelectedPersonalEssayId(id); // Set the selected college id in the state
    
  };
  // Function to handle clicking the edit icon
  const handleEditClick = (id) => {
    setSelectedCollegeId(id); // Set the selected college id in the state
    
  };
  const navigate = useNavigate();
  useEffect(() => {
  
    if(selectedCollegeId){
  
    
      navigate(`/Student_detail?id=${selectedCollegeId}&userid=${collaboratorID}&type=admin`);
    
 
    }
    else if(selectedHighschoolId){
    
        navigate(`/Highschool_detail?id=${selectedHighschoolId}&userid=${collaboratorID}&type=admin&view=student`);
      
    
    }
    else if(selectedPersonalEssayId){
      navigate(`/Summary_detail?id=${selectedPersonalEssayId}&userid=${collaboratorID}&type=admin&view=student`);
    }
    else if(selectedHonoursId){
      navigate(`/Honours_detail?id=${selectedHonoursId}&userid=${collaboratorID}&type=admin&view=student`);
    }
    else if(selectedTestingId){
      navigate(`/Testing_detail?id=${selectedTestingId}&userid=${collaboratorID}&type=admin&view=student`);
    }
    else if(selectedActivityId){
      navigate(`/Activities_detail?id=${selectedActivityId}&userid=${collaboratorID}&type=admin&view=student`);
    }
    else if(selectedEperienceId){
      navigate(`/Experience_detail?id=${selectedEperienceId}&userid=${collaboratorID}&type=admin&view=student`);

    }
  }, [selectedCollegeId,selectedHighschoolId,selectedPersonalEssayId,selectedTestingId,selectedHonoursId,selectedActivityId,selectedEperienceId,collaboratorID]);



  const states = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
    { code: "PR", name: "Puerto Rico" },
    { code: "GU", name: "Guam" },
    { code: "VI", name: "U.S. Virgin Islands" },
    { code: "AS", name: "American Samoa" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "INTL", name: "International" }
  ];


  const getStateFullName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.name : '';
  };
  return (
    <div><Topbar/>
    <Grid
    container
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    className='admin_collaborator_main_Grid' 
   
    >
      <Grid container

lg={8.8}
xl={9.7}
xs={12}
sm={12}

md={8.7} className='admin_collaborator_main_Grid2' 
 >
<Grid item
        xs={12}
        sm={12}
        xl={12}
        className='admin_collaborator_profile_main_Grid' 
        >
          {userInfo && (
<Box className='admin_collaborator_profile_main_Grid_Box'>
  <Box sx={{backgroundImage: studentbackgroundurlimagefinal ? `url(${studentbackgroundurlimagefinal})` : `url(${bgimagecover})`,}}className='admin_collaborator_profile_main_Grid_Background_Box'>

  </Box>
  <Box className='admin_collaborator_profile_information_Grid_Background_Box'>
   
    <Box className='admin_collaborator_profile_information'>
       <div>
    <Avatar  src={studentlaravelUrlfinal} className='avatar_Box'   sx={{
                border: '2px solid white',
                width: '80px',
                height: '80px',
                transform: "translate(-50%, -100%) !important",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
              }}>

    </Avatar>
    </div>
    <typography className='user_name_Text'>
    {userInfo.legal_name}
    </typography >
    <typography className='user_description_Text'>
    {userInfo.Headline || 'Applying to college the smart way'}
    </typography>
    <Box className='admin_collaborator_profile_information_Box'>
    {HighSchooldata &&  HighSchooldata.map((highschool, index) => {
       
      
    return (
      <React.Fragment key={index}>
        <typography className='college_name_Text'>
          <SchoolOutlinedIcon sx={{ height: "15px", width: "15px", marginRight: '10px' }} />
          {highschool.hight_school_name }
        </typography>
        {index < HighSchooldata.length - 1 && <Divider variant="inset" orientation="vertical" flexItem className='future-Divider' sx={{ mx: 3 }} />}
      </React.Fragment>
    );
  })}
  <Divider variant="inset" orientation="vertical" flexItem className='future-Divider' sx={{ mx: 3 }} />
  <Typography className='college_name_Text'>
    <CalendarMonthIcon sx={{ height: "15px", width: "15px", marginRight: '10px' }} />
    Joined {new Date(userInfo.created_at).toLocaleString('en-us', { month: 'short', year: 'numeric' })}
  </Typography>
</Box>

    <Box className='student_button_Admin'>
    <Button variant='contained' sx={{boxShadow:"none"}}>become collaborator</Button>
    {/* <SystemUpdateAltIcon sx={{height:"35px",width:"35px",color:'#006CB7'}}/> */}
    </Box>
    </Box>


  </Box>

</Box>
)}
</Grid>

    
      <Grid item
        xs={12}
        sm={12} 
        xl={12}
        className="admin_college_Grid">

<Box
    className="admin_college_Grid_Box">

   

  <typography className="college_Main_Text" >
  Colleges Interested In

</typography>

   
{Collegesdata &&  Collegesdata.map((college, index) => {
        const collegeNameData = JSON.parse(college.collegeName);
        const Collegemajor = JSON.parse(college.major);
        const collegeMajorData = typeof college.major === 'string' ? JSON.parse(college.major) : [];
        const majorTitles = Array.isArray(collegeMajorData)
          ? collegeMajorData.map((major) => major.title).join('  | ')
          : '';
        return (
          <>
      <div key={index}  className='admin_college_Grid_Div'>

    
      <div  className="admin_college_Grid_Div2">
        <div className="admin_college_Grid_Div3">
          <div>
            <Avatar className='admin_college-Avatar' src={CollegeIcon}></Avatar>
          </div>
          <div className="Admin_DivCustom">
            <div
            className="Admin_DivCustom3"
             
            >
              <div className="Admin_DivCustom4">
              <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClick(college.id);
  }}
>
              <typography className="Admin_TypographyCustom">{collegeNameData.title}</typography>
             
</Link>
              </div>
              
              <typography className="Admin_TypographyCustom2">{getStateFullName(college.highSchoolLocation)}</typography>

              {/* <typography className="Admin_Major_text" style={{color:'green',gap:'5px'}}  >
     <CalendarTodayIcon sx={{height:"15px",width:"15px"}}/><typography style={{marginRight:'15px'}}> Taken In: 2022-2024</typography>
    {Collegemajor.title}</typography> */}



                                        <Typography className="Admin_Major_text" style={{ display: 'flex', flexDirection: 'row' }} 
                                    >{majorTitles}</Typography>
                                 
            </div>

            <typography className="Admin_TypographyCustom2">Supplementary Essay</typography>
            <typography className="Admin_Supplementary_essay">
            {college.supplementary_eassy.length > 100
                ? `${college.supplementary_eassy.substring(0, 400)}...`
                : college.supplementary_eassy}
            </typography>
          </div>
        </div>
       
      </div>
      {/* <div className="Admin_DivCustom5" style={{ display: "flex", alignItems: "center" }}>
  <Chip
    
    sx={{ padding: "16px 8px" }}
    label='Accepted'
    color={
      "success"
   
    }
    size="small"
    variant="filled"
  />
 
</div> */}

</div>
      {index < Collegesdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
      </>
     
      );
    })}  


  </Box>

      </Grid>
      <Grid item
     xs={12}
     sm={12}
     xl={12}
   className="admin_highschool_Grid">
    <Box className="Admin_BoxCustom6">

   
  <Box className="Admin_BoxCustom7" >
    <typography className="college_Main_Text">High School Education</typography>
   
  </Box>
  {HighSchooldata &&  HighSchooldata.map((highschool, index) => {
       
       return (
 
        <>
      <div key={index} className='Admin_DivCustom_later2'  style={{width:"100%"}}>
        <div className="Admin_DivCustom6">

        
         <Avatar  src={Highschool_Icon}>

</Avatar>
<div  className="Admin_DivCustom7" style={{width:"100%"}}>

<div className="Admin_DivCustom8" style={{width:"100%"}}>
<Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHighschool(highschool.id);
  }}
>
        <typography className="Admin_TypographyCustom" >{highschool.hight_school_name }</typography></Link>
</div>
{/* <typography className="Admin_TypographyCustom2">Degree:{highschool.degree_high || "NO degree"} </typography> */}
        <div className="Admin_DivCustom9"><CalendarTodayIcon className='CalenderCustom' sx={{width:"15px",height:"15px",color:"#58595B"}}/>
        <typography className="Admin_date_highschool"> {new Date(highschool.start_date).toLocaleString('en-us', { year: 'numeric' })}</typography>
        <typography className="Admin_date_highschool">-  {new Date(highschool.end_date).toLocaleString('en-us', { year: 'numeric' })}</typography>
       
        </div>
     
        </div>
        </div>
        </div>
        {index < HighSchooldata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
        </>
       );
      })} 



  </Box>
   </Grid>
   <Grid item
     xs={12}
     sm={12} 
     xl={12}
     className="admin_honors_Grid">
      <Box className="Admin_BoxCustom9">
        <Box className="Admin_BoxCustom10">
          <typography  className="college_Main_Text">
            Honors
          </typography>
        </Box>
        {Honoursdata &&  Honoursdata.map((Honors, index) => {
        const awardsArray = JSON.parse(Honors.awards);
       return (
        <>
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="Admin_DivCustom10">
            <Avatar  src={Honors_icon} />
            <div className='Admin_honors_sub_Div'>
             
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHonours(Honors.id);
  }}
>    
            <typography variant="body1" className="Admin_TypographyCustom8">
                  {awardsArray[0]?.['award-title-0']}
                  </typography>
           </Link>
             
             
                  {/* <typography className="Admin_TypographyCustom2">
              {awardsArray[0]?.['issuing-organization-0']}
              </typography>
               */}
              {/* <div className="Admin_DivCustom9"><CalendarTodayIcon className='CalenderCustom' sx={{width:"15px",height:"15px",color:"#58595B"}}/>
              <typography className="Admin_date_highschool">Issued {new Date(awardsArray[0]?.['start-date-0']).toLocaleString('en-us', { month: 'short', year: 'numeric' })}</typography>
        
       
        </div> */}
        <Chip
 label={awardsArray[0]?.['level-0']}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px",marginTop:'15px', padding: "16px 8px"}}
/>
<typography className="Admin_Supplementary_essay">
    {awardsArray[0]?.['award-description-0']}
            </typography>
            </div>
          </div>
        </div>
         {index < Honoursdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
         </>
          );})} 
        {/* <Divider className="Admin_DividerCustom3" variant="inset" /> */}
        {/* <Box className='admin_future_Plans_Box'>
          <Box><typography className="Admin_TypographyCustom" >Future Plans</typography></Box>
        <Box className='Honors_Future'>
        {Honoursdata &&  Honoursdata.map((Honors, index) => {
          return(
            <>
            <div key={index} style={{display:'flex'}}>
          <Box className='Honors_sub_Future'>
            <typography className="Admin_TypographyCustom2">Highest degree intend to earn</typography>
            <typography className="college_Main_Text">{Honors.highest_degree_you_earn}</typography>
          </Box>
          <Divider variant="inset" orientation="vertical" flexItem  className='future-Divider' sx={{ mx: 5 }}/>
          <Box className='Honors_sub_Future'>
            <typography className="Admin_TypographyCustom2">Career Interest</typography>
            <typography className="college_Main_Text">{Honors.career_intrest}</typography>
          </Box>
          </div>
           {index < Honoursdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
           </>
          )
        })}
          </Box>
        </Box> */}
      </Box>
    </Grid>
    <Grid className="admin_testing_Grid" item
     xs={12}
     sm={12}
     xl={12}
     >
      <Box className="BoxCustom12">
        <Box className="BoxCustom13">
          <typography  className="college_Main_Text">
            Testing
          </typography>
        
        </Box>
        <div  style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}>
        {testingdata &&  testingdata.map((testing, index) => {
          const testDataArray = JSON.parse(testing.testdata);

          // Extracting the "testname" property from the first element
          const testname = testDataArray[0]?.['testname-0'];
          const attempts = testDataArray[0]?.['attempts-0'];
          const score = testDataArray[0]?.['score-0'];
          // const createdat = testDataArray[0]?.['created_at'];
       
       return (

        
          <Box key={index}  className="Admin_DivCustom100">
            {/* <Avatar className="Colleges_Avatar_img" src={testingimage} /> */}
            <div className="DivCustom17">
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickTesting(testing.id);
  }}
>
              <typography className="college_Main_Text">
               {testname}
              </typography></Link>
              <typography className="Admin_TypographyCustom2">
                Attempts: {attempts}
              </typography>
              <Box>
                <CalendarTodayIcon sx={{ width: '15px', height: '15px', color: '#58595B' }} />
                <typography className="Admin_date_highschool">{new Date(testing.created_at).toLocaleString('en-us', { year: 'numeric' })}</typography>
              </Box>
              <Chip
  label={`Highest Score: ${score}`}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px",padding: "16px 8px"}}
/>
            </div>
            <div>
          
          </div>
          {index % 2 === 0 && index < testingdata.length && (
 <Divider orientation="vertical" className="Admin_DividerCustom4" />
)}
          </Box>
           );})} 
           </div>
          {/* <div className="DivCustom18">
            <Divider orientation="vertical" className="DividerCustom4" />
            <div className="DivCustom19">
              <Typography variant="body1" className="Testing_Obtained_text">
                Highest Score
              </Typography>
              <Typography variant="body1">
                <span className="SpanCustom">sdf</span>
              </Typography>
            </div>
          </div> */}
   
      </Box>
    </Grid>
    <Grid item
     xs={12}
     sm={12}
     xl={12}
   className="admin_sports_Grid">
    <Box className="Admin_BoxCustom15" >

   
  <Box  className="Admin_BoxCustom16">
    <typography className="college_Main_Text">Activities</typography>
   
  </Box>
  {Activitiesdata &&  Activitiesdata.map((sports, index) => {
     const sportsDataArray = JSON.parse(sports.sports_activities);
     const sportName = sportsDataArray[0]?.['high-school-name-0'];
     const Joinedas = sportsDataArray[0]?.['participated-as0'];
     const level = sportsDataArray[0]?.['high-activities-select-0'];
     const description = sportsDataArray[0]?.['description-0'];

     return (
      <>
  <div key={index} className="Admin_DivCustom_later3">
          <div className="Admin_DivCustom10">
          <div>
            <Avatar  src={SportsIcon} />
            </div>
            <div className="Admin_DivCustom17">
              
            <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickActivities(sports.id);
  }}
>
              <typography className="college_Main_Text">
              {sportName}
              </typography></Link>
              <typography className="Admin_TypographyCustom2">
              {Joinedas}
              </typography>
              {/* <Box>
                <CalendarTodayIcon  sx={{ width: '15px', height: '15px', color: '#58595B' }} />
                <typography className="Admin_date_highschool">Date</typography>
              </Box> */}
              <Chip
  label={level}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px",padding: "16px 8px"}}
/>
<typography className="Admin_Supplementary_essay">
{description}
            </typography>
</div>
          {/* <Divider orientation="vertical" className="Admin_DividerCustom4" /> */}
            </div>
          </div>
         
        </div>
            
            {index < Activitiesdata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
            </>
  
            );})} 


  </Box>

   </Grid>
   <Grid item
   xs={12}
   sm={12}
   xl={12}
   className="admin_wokexperience_Grid">
    <Box className="Admin_BoxCustom15" >

   
  <Box  className="Admin_BoxCustom16">
    <typography className="college_Main_Text">Work Experience</typography>
  
  </Box>
  {/* <div className='Collaborator_completeprofile_activities' style={{display:"flex",justifyContent:"space-between"}}> */}
  {Experiencedata && Experiencedata.map((experience, index) => {
     const workDataArray = JSON.parse(experience.work_experience);
     const jobtitle = workDataArray[0]?.['Title-0'];
     const organization = workDataArray[0]?.['organization-name-0'];
     const jobType = workDataArray[0]?.['job-type-0'];
     const startDate = workDataArray[0]?.['start-date-0'];
     const endDate = workDataArray[0]?.['end-date-0'];
     const description = workDataArray[0]?.['job-description-0'];
     return (
      <>
      <div key={index}   style={{display:"flex",flexDirection:"row",gap:'15px'}}>
   
   <div>
   <Avatar  src={Experiance_icon} />
            </div>
        
     
<div style={{display:"flex",flexDirection:"column",gap:10}}>
  
<Link
 style={{ textDecoration: 'none' }}
to="#"

onClick={(e) => {
e.preventDefault();
handleEditClickExperience(experience.id);
}}
>
            <typography  className="college_Main_Text"> {jobtitle}</typography></Link>
            <typography style={{ fontWeight: '600', fontSize: '16px' }}>{organization}-{jobType}</typography>
            
            <Box>
           <CalendarTodayIcon sx={{height:"15px",width:"15px",color:"#2E7D32"}}/> <typography className="workDate">
      
      {new Date(startDate).toLocaleString('en-us', { month: 'short', year: 'numeric' })} -

      {!endDate ? (
  'Present' // or any text you want to display when `endDate` is not provided
) : (
  <>
    {new Date(endDate).toLocaleString('default', { month: 'short' })}{' '}
    {new Date(endDate).getFullYear()}
  </>
)}
</typography>
            </Box>
  <typography className="Admin_TypographyCustom2">Job Description:</typography>
<typography  className='Personal_text2'>{description}</typography>

            </div>
            {/* <Divider className="Admin_DividerCustom3" variant="inset" /> */}
        
          {/* <div className='volunteer_Hour_Div'>
            <typography className="Admin_TypographyCustom_Volunteer">Volunteer Hours</typography>
            <typography className="college_Main_Text">{experience.volunteer_hours}</typography>
            <typography className="Admin_TypographyCustom2">Per Week</typography>
          </div> */}
          </div>
          {index < Experiencedata.length - 1 && <Divider variant="middle"  sx={{ my: 5 }}/>}
       </>

       )
      })}



  </Box>
   </Grid>
   <Grid item
   xs={12}
   sm={12}
   xl={12}
   className="admin_essay_Grid">
    <Box className="Admin_BoxCustom20">

   
  

         <Box className="Admin_BoxCustom21">
    <typography className="college_Main_Text">Personal Essay</typography>
 
  </Box>
  {Summarydata && Summarydata.map((summary, index) => {
    
    return (
      <Link
      key={index}
      to="#"
      className='Text_decoration'
      onClick={(e) => {
        e.preventDefault();
        handleEditClickSummary(summary.id);
      }}
    >
        <typography key={index}className="Admin_TypographyCustom2">
         {summary.personal_eassay}
        </typography></Link>
    
    )
  })}
  </Box>
   </Grid>
      </Grid>
      {/* <Grid item lg={3}
    xl={2.3}
    xs={12}
    sm={12}
    md={3} 
    className='admin_collaborator_profile_Grid'
    >
       <Box className='progress_main_Grid' >
    <typography  className='progress_main_Grid_Text'>Complete your profile</typography>
   <Box className='progress_secondary_Grid'>
   <CircularProgressWithLabel value={progress}  color="success"  className='CircularProgressWithLabel' />
   <Box className='progress_sub_secondary_Grid'>
   <typography className='progress_sub_secondary_Grid_Text'><CheckCircleOutlineIcon sx={{color:"green"}}/>Personal information .  10%</typography>
   <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
        College applied to .  40%</typography>
   <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          High school education .  10%</typography>
   <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          Honors .  10%</typography>
   <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
         Testing .  10%</typography>
   <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
         Activities .  5%</typography>
          <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
         Work Experience .  5%</typography>
   <typography className='progress_sub_secondary_Grid_Text'>
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
        Personal Essay .  10%</typography>
   
  
  
        </Box>
        <Button 
  sx={{ boxShadow:"none",'&.Mui-disabled': {
    pointerEvents: 'visible', // Override the pointer-events property
  },
marginTop:'20px',


}}
   variant='outlined' color="error">REJECT PROFILE</Button>
   <Button 
  sx={{ boxShadow:"none",'&.Mui-disabled': {
    pointerEvents: 'visible', // Override the pointer-events property
  },}}
   variant='contained' color="success">ACCEPT PROFILE</Button>
    
   </Box>

   </Box>

      </Grid> */}
      <Grid item lg={3}
    xl={2.1}
    xs={12}
    sm={12}
    md={3} 
    className='admin_student_profile_Grid'
    >
       
       <Box sx={{display:'flex',flexDirection:'column',paddingRight:'10px'}}>
           <typography className='Admin_Student_message_text'>Related Students</typography>
           <TextField
     id="outlined-basic"
     label="Search Students"
   
     variant="outlined"
     fullWidth
     size="small"
     InputLabelProps={{
       sx: {
         fontSize: '14px', // Set the desired font size for the label
       },
     }}
   />
   
         
   
        </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', cursor: 'pointer' }}>
    
       
         <CardHeader
           sx={{
             padding: '20px 0px 0px 0px',
           }}
           avatar={
           
               <Avatar
                 alt={'Sender Name'}
                 src={profile1}
                 sx={{
                   width: '40px',
                   height: '40px',
                   borderRadius: '20px',
                 }}
               />
           
           }
           title={
            <typography
             className="Admin_message_text_secondary"
            >
            Hello
            </typography>
          }
           subheader={
             <span
               style={{
                 fontSize: '12px',
               }}
             >
               {"message.content"}
             </span>
           }
         />
      
     
   </Box>
   
       {/* <Box className='progress_main_Grid' >
    <typography className='progress_main_Grid_Text'>Profile Insights</typography>
    <Box className='progress_main_Grid2' >
      
   
      <InsightsIcon className='progress_main_Grid_Avatar' />

<Box className='progress_main_Grid2_Text'>
<typography className='progress_main_Grid2_Text_Main'>190</typography>
<typography className='progress_main_Grid2_Text_Secondary'>Profile views</typography>
</Box>
    </Box>
<Divider variant="middle" />
<Box className='progress_main_Grid2' >
      
     
        <MarkEmailReadIcon className='progress_main_Grid_Avatar' sx={{color:'#EF6C00'}} />
  
  <Box className='progress_main_Grid2_Text'>
  <typography className='progress_main_Grid2_Text_Main'>509</typography>
  <typography className='progress_main_Grid2_Text_Secondary'>Messages</typography>
  </Box>
      </Box>
      <Divider variant="middle" />
      <Box className='progress_main_Grid2' >
      
  
      <ContentPasteSearchIcon className='progress_main_Grid_Avatar' sx={{color:'#2E7D32'}}/>

<Box className='progress_main_Grid2_Text'>
<typography className='progress_main_Grid2_Text_Main'>1000</typography>
<typography className='progress_main_Grid2_Text_Secondary'>Search Appearance</typography>
</Box>
    </Box>


   </Box> */}

      </Grid>

    </Grid>
     </div>
  )
}
