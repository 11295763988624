import React from 'react'

import Grid from '@mui/material/Grid';
import { CssBaseline, Typography } from '@mui/material';
import Backgroundsrc from '../../assets/img/footerImage.png';
import Logo from '../../assets/img/FooterLogo.png'
import './footer.css';
import TiktokImage from '../../assets/img/tiktok.png'
import InstagramImage from '../../assets/img/instagram.png'
import LinkedinImage from '../../assets/img/Linkedin.png'
import samplePDF from '../../assets/terms policy/Service Agreements.pdf';
import samplePDF2 from '../../assets/terms policy/Privacy Policy.pdf';
import { Link } from 'react-router-dom';


const FooterMain = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
    
    <Grid container component="main" lg={12}
  
    xl={12}
    xs={12}
    sm={12}
    md={12}
    sx={{display:"flex", justifyContent:"center",flex:1}}>
      <Grid item 
      className='Footer_main_grid'
      lg={12}
  
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{ backgroundImage: `url(${Backgroundsrc})`,
      backgroundSize:'Cover',  // Adjust the background size as needed
      backgroundPosition: 'center',
      backgroundRepeat:'no-repeat' , // Adjust the background position as needed
      height: 'auto',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'50px',paddingBottom:'50px',textAlign:"center"}}>
        <Grid item>
          <typography className='FooterMainText'>Get started with ApplyUpp today</typography>
        </Grid>
        <Grid className='Footer_text_div' item sx={{width:'441px',textAlign:'center'}}>
          <typography className='FootersecondaryText'>Join high-earning collaborators and top students getting accepted into their dream schools</typography>
        </Grid>
        <Grid item 
        className='footer_button_Maindiv'
        sx={{display:"flex",margin:'10px'}}>
          <Grid className='Footer_Button_div' item sx={{backgroundColor:'white',width:'190px',height:'auto',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'4px',margin:'10px'}}>
            <Link className='Button1Text' to="/signup?type=collaborator">Collaborators</Link>
          </Grid>
          <Grid className='Footer_Button_div' item sx={{backgroundColor: 'rgba(255, 255, 255, 0.20)'
,width:'190px',height:'50px',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'4px',margin:'10px'}}>
  <Link className='Button2Text'  to="/signup?type=student">Students</Link>
          
          </Grid>
        </Grid>
      </Grid>
     

      <div className='Footer_Bottom_div' style={{  maxWidth: "1140px", paddingTop: '50px' ,width:"100%"}}>
    <div className='Footer_Padding_div' >
      <div className='FooterMain' style={{display:"flex",justifyContent:"center",marginTop:'81px'}}>
  <div className='FooterContainer' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',flex:1 }}>
    <div className='FooterImage'>
      <img src={Logo} alt="Logo" />
    </div>
    <div className='FooterProducts' style={{flex:"1 1 0%",display:"flex",justifyContent:"center"}}>
      <a href='/' className='Services'>Product</a>
      <a href='/Aboutus' className='Services'>Company</a>
      <a href='/Pricing' className='Services'>Plans</a>
    
      <a href='/Faqs' className='Services'>FAQ</a>
      <a href='/contact_us' className='Services'>Contact Us</a>
    </div>
    </div>
    <div className='FooterSocialImages'>
  <a href='https://www.tiktok.com/@applyupp?_t=8g0fdkUVPTm&_r=1' className='SocialImages'  target="_blank" rel="noopener noreferrer">
    <img src={TiktokImage} alt='TikTok' />
  </a>
  <a href='https://www.instagram.com/applyupp/?igshid=MzRlODBiNWFlZA%3D%3D' className='SocialImages'  target="_blank" rel="noopener noreferrer">
    <img src={InstagramImage} alt='Instagram' />
  </a>
  <a href='https://www.linkedin.com/company/applyupp/about/?viewAsMember=true' className='SocialImages'  target="_blank" rel="noopener noreferrer">
    <img src={LinkedinImage} alt='LinkedIn' />
  </a>
</div>

  </div>





<div className='FooterMain' style={{display:"flex",justifyContent:"center",marginTop:'71px',marginBottom:'40px'}}>
        

     
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent: 'space-between',flex:1 }}>
          <div>
          <typography className='CopyrightText'>Copyright © {currentYear} ApplyUpp. All Rights Reserved.</typography>
          </div>
        
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a href={samplePDF} target="_blank" rel="noopener noreferrer" className='Services'>Terms of Service</a>
            <a  href={samplePDF2} target="_blank" rel="noopener noreferrer" className='Services'>Privacy Policy</a>
          </div>
        </div>
        
     
</div>

</div>
</div>
</Grid>
</>       

   
  )
}

export default FooterMain;
