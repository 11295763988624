import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Topbar from '../Topbar';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography, Tabs, Tab,InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation,useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import Lottie from 'react-lottie';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import format from 'date-fns/format';
import animationData from '../../../assets/lottiefinal.json'
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import '../../collaborator/Profile_add/profile.css'
import Tobar from '../../collaborator/Tobar';
import { Topbar as Topbar2 } from '../../Admin/Topbar';
const Collaborator_highschool_form = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlType = urlParams.get('type');
      const id = urlParams.get('id');
      const userid = urlParams.get('userid');
      function encodeAlphanumeric(userid) {
        const encoded = btoa(userid); // Using btoa to Base64 encode the ID
        // You can further process the encoded string if needed
        return encoded;   
      } 
      console.log('ID:', id);
      console.log('UserID:', userid);
    const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const isSmallScreen = useMediaQuery('(max-width:680px)');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  const location = useLocation();

  const navigate = useNavigate();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      // href={urlType === 'collaborator'?'/profileview':`/Student`} 
      href={urlType === 'collaborator' ? '/profileview' : (urlType === 'admin' ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userid)}` : '/Student')}
    >
      Profile
    </Link>,
 
    <Typography key="3" color="text.primary">
     High school education
    </Typography>
  ];
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [courses, setCourses] = useState([{  }]);
    const [Errorcourses, setErrorcourses] = useState('');
    const [HighschoolName, SetHighschoolName ] = useState([]);
    const [ErrorHighschoolName,SetHighschoolNameError] = useState('');
    const [HighSchoolLocation, SetHighSchoolLocation] = useState([]);
    const [ErrorHighSchoolLocation, SetHighSchoolLocationError] = useState('');
    const [GpaScore, SetGpaScore] = useState([]);
    const [ErrorGpaScore, SetGpaScoreError] = useState('');
    const [GpaOutof, SetGpaoutof] = useState([]);
    const [ErrorGpaOutof,setGpaOutofError] = useState('');
    const [GpaType, SetGpaType] = useState([]);
    const [ErrorGpaType, SetGpaTypeError] = useState('');
    const [GradesEarninCourse, SetGradesEarninCourse] = useState([]);
    const [GradesEarninCourseError, SetGradesEarninCourseError] = useState([]);
    const [start_date, setStartDate] = useState('');
    const [start_dateError, setstart_date_Error] = useState('');
    const [end_date, setEndDate] = useState('');
    const [end_dateError, setend_dateError] = useState('');
    const [rankinclass, setrankinclass] = useState('');
    const [Errorrankinclass,setErrorrankinclass] = useState('');
    const [formid, setformid] = useState('');
    const [highschoolFormData, setHighschoolFormData] = useState([]);

    // const [showAlert, setShowAlert] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const [formData, setFormData] = useState({
      hight_school_name: '',
      high_school_location: '',
      start_date: '',
      end_date: '',
      gpa_score: '',
      gpa_out_of: '',
      gpa_type: '',
      rank_in_class: '',
      coursesData: '',
      degree_high:'',
      userId:'',
    formid:'',

    });
    useEffect(() => {
  
     
      const searchParams = new URLSearchParams(location.search);
      const selectedHighschoolId = searchParams.get('id'); //change this const to form id 
      setformid(selectedHighschoolId);
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
      console.log('Highschool ID:', selectedHighschoolId); 

      if(selectedHighschoolId !== ''){
        try {
        fetch(`${apiUrl}/get_high_school_form_data`, {   // chgange this url 
          method: 'post',
          body: JSON.stringify({ formid:selectedHighschoolId }), // pass the form id const here 
      })
      .then(response => {
          if (response.status === 200) {
              return response.json(); // Convert the response to JSON
          } else {
              throw new Error('Request failed');
          }
      })
      .then(data => {
        const highschool = data.formdata[0];
        const coursesData = JSON.parse(highschool.coursesData || '[]');
        // console.log('Course Titles:', coursesData.map(course => course.course_tittle0));
        // console.log('Start Date:', dayjs(highschool.start_date).format('MM/DD/YYYY'));
        // console.log('End Date:', dayjs(highschool.end_date).format('MM/DD/YYYY'));

        // const formateddate = format(new Date(highschool.end_date),'MM/dd/yyyy');
        // console.log(formateddate,"enddate")
        setCourses(coursesData);
        setHighschoolFormData(data.formdata);                     
          console.log(data, "highschooldata"); 
          setStartDate(dayjs(highschool.start_date));
            setEndDate(dayjs(highschool.end_date));
          setFormData({
            hight_school_name: highschool.hight_school_name || '',
            high_school_location: highschool.high_school_location || '',
            start_date: highschool.start_date || '',
            end_date:  highschool.end_date || '',
            gpa_score: highschool.gpa_score || '',
            gpa_out_of: highschool.gpa_out_of || '',
            gpa_type: highschool.gpa_type || '',
            rank_in_class: highschool.rank_in_class|| '',
            degree_high:highschool.degree_high||'',
            // coursesData: highschool.coursesData || '',
            userId: highschool.userId || '',
          });
       
      })
      .catch(error => {
          console.error('Data fetch failed:', error);
      });
  } catch (error) {
      console.error('Data upload failed:', error);
  }
      }

      
  }, []);    
    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    // };
    const [age, setAge] = React.useState('');

    const handleChanges = (event) => {
      const { name, value } = event.target;
      if (name === 'high_school_location') {
        SetHighSchoolLocation(value);
        SetHighSchoolLocationError('');
        // Clear the validation error if the user starts typing
        
      } else if (name === 'gpa_score') {
        SetGpaScore(value);
        SetGpaScoreError('');
        // Clear the validation error if the user starts typing
        
      } else if (name === 'gpa_out_of') {
        SetGpaoutof(value);
        setGpaOutofError('');
        // Clear the validation error if the user starts typing
        
      } else if (name === 'gpa_type') {
        SetGpaType(value);
        SetGpaTypeError('');
        // Clear the validation error if the user starts typing
        
      } else if (name === 'grades_earned_in_course') {
        SetGradesEarninCourse(value);
        SetGradesEarninCourseError('');
        // Clear the validation error if the user starts typing
        
      }
      

      
      setAge(event.target.value);
    };

    const moveToNextTab = (userid) => {
        if (value === 2 && urlType === 'student') {
          window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}`;
          // setShouldNavigate(true); // Assuming this triggers navigation to a different page
        } else if (value === 2 && urlType === 'collaborator' )  {
          window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}`;
          // setShouldNavigate(true); // Assuming this triggers navigation to a different page
        } else if (value === 2 && urlType === 'admin' )  {
          window.location.href = `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userid)}`;
          // setShouldNavigate(true); // Assuming this triggers navigation to a different page
        }
         else {
            // Increment the value state to move to the next tab
            setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
          }
      };
     // Initialize navigate
    // const moveToNextTab = async() => {
    //   if(value === 0){
    //     const hight_school_name = document.getElementsByName('hight_school_name')[0].value;
    //     const high_school_location = document.getElementsByName('high_school_location')[0].value;
    //     console.log(dayjs(end_date),"end_date");
    //     console.log(dayjs(start_date),"start_date");
    //     if (end_date && start_date && end_date <= start_date) {
    //       setend_dateError('End date should be after start  date.');
    //       return false;
    //     }
    //     if (hight_school_name.trim() === '' && high_school_location.trim() === '' && start_date === '' && end_date === '') {
    //       SetHighschoolNameError('High School Name is required.');
    //       SetHighSchoolLocationError('High School location is required.');
    //       setend_dateError("End date required");
    //       setstart_date_Error("Start date required");
    //       return false;
    //     }else{
          
    //       formData.hight_school_name = hight_school_name;
    //       formData.high_school_location = high_school_location;
    //       formData.start_date = start_date;
    //       formData.end_date = end_date;
    //     }
    //     if (hight_school_name.trim() === '' ) {
    //       SetHighschoolNameError('High School Name is required.');
    //       return false;
    //     }else{
    //       SetHighschoolName(hight_school_name);
    //       SetHighschoolNameError('');
    //     }
    //     if (high_school_location.trim() === '' ) {
    //       SetHighSchoolLocationError('High School location is required.');
    //       return false;
    //     }else{
    //       SetHighSchoolLocation(high_school_location);
    //       SetHighSchoolLocationError('');
    //     }

    //     if (start_date === '' ) {
    //       setend_dateError("End date required");
    //       return false;
    //     }else{
    //       setend_dateError("");
    //     }
    //     if (end_date === '' ) {
    //       setend_dateError("End date required");
    //       return false;
    //     }else{
    //       setend_dateError("");
    //     }
       

    //   }
    //   if(value === 1){
    //     const gpa_score = document.getElementsByName('gpa_score')[0].value;
    //     const gpa_out_of = document.getElementsByName('gpa_out_of')[0].value;
       
    //     // const roundedGpaScore = parseFloat(gpa_score);
    //     // const roundedGpaOutOf = parseFloat(gpa_out_of);
          
    //     //   if (roundedGpaScore > roundedGpaOutOf) {
    //     //     SetGpaScoreError('GPA field must be less then out of field');
    //     //     return false;
    //     //   }
    //     const gpa_type = document.getElementsByName('gpa_type')[0].value;
    //     const rank_in_class = document.getElementsByName('rank_in_class')[0].value;
    //     if (gpa_score.trim() === '' && gpa_out_of.trim() === '' && gpa_type === '' && rank_in_class === '') {
    //       SetGpaScoreError('Gpa Score required');
    //       setGpaOutofError('Gpa out of required');
    //       SetGpaTypeError('Gpa type required');
    //       setErrorrankinclass("Rank in class required");
    //       return false;
    //     }else{
         
    //       formData.gpa_score = gpa_score;
    //       formData.gpa_out_of = gpa_out_of;
    //       formData.gpa_type = gpa_type;
    //       formData.rank_in_class = rank_in_class;
    //     }
    //     if (gpa_score.trim() === '' ) {
    //       SetGpaScoreError('Gpa Score required');
          
    //       return false;
    //     }else{
    //       SetGpaScoreError('');
    //     }
    //     if ( gpa_out_of.trim() === '' ) {
         
    //       setGpaOutofError('Gpa out of required');
          
    //       return false;
    //     }else{
    //       setGpaOutofError('');
    //     }
    //     if ( gpa_type === '' ) {
         
    //       SetGpaTypeError('Gpa type required');

    //       return false;
    //     }else{
    //       SetGpaTypeError('');
    //     }
    //     if ( rank_in_class === '') {
         
    //       setErrorrankinclass("Rank in class required");
    //       return false;
    //     }else{
    //       setErrorrankinclass("");
    //     }
    //   }
    //   if (value === 2) {
    //     const coursesData = courses;
    //     const isDuplicate = coursesData.some((current, index) =>
    //       coursesData.slice(0, index).some((other) => {
    //         const currentKeys = Object.keys(current).sort();
    //         const otherKeys = Object.keys(other).sort();

    //         return (
    //           currentKeys.length === otherKeys.length &&
    //           currentKeys.every((key, i) => current[key] === other[otherKeys[i]]) &&
    //           current.course_title === other.course_title &&
    //           current.grades_earned_in_course === other.grades_earned_in_course
    //         );
    //       })
    //     );
        
    //       if (isDuplicate) {
    //         // Scholarships contain duplicates
    //         setErrorcourses('Duplicate data. Please enter unique values for each courses.');
    //         return false;
    //       }
    //     // if (coursesData.some(course => course.course_tittle0 || course.grades_earned_in_course0)) {
          
    //     //   setErrorcourses('');
          
    //     // } else {
    //     //   // No recommender has data
    //     //   setErrorcourses('Please add at least one course');
          
    //     //   return false;
    //     // }
    //     const hasEmptyField = coursesData.some((course, index) => {
    //       return !course[`course_tittle${index}`] || !course[`grades_earned_in_course${index}`]  ;
    //     });
      
    //     if (hasEmptyField) {
    //       setErrorcourses('All fields are mandatory');
    //       return false;
    //     }
    //     try {
    //       setIsLoading(true);
    //       setOpen(true);
        
    //       formData.coursesData = coursesData;
    //       // Make a POST request to the API endpoint
    //       const userData = JSON.parse(localStorage.getItem('userData'));
    //       const userId = userData.id;
          
       
    //       formData.userId = userId;
    //       const apiUrl = process.env.REACT_APP_API_URL;
    //       if(formid!== ''){
    //         // alert(formid);
    //         formData.formid = formid; 
    //       }
    //       const response = await fetch(`${apiUrl}/save_collaborator_highschool`, {
    //         method: 'post', 
    //         body: JSON.stringify(formData),
    //       }); 
    //       // setShowAlert(true);
    //       // Check the response status and handle it accordingly
    //       if (response.status === 201) {
           
    //         //setShowAlert(false);
    //         setShouldNavigate(true);
    //         //navigate('/complete_profile');     
    //       }
    //       if (response.status === 500) {
    //         //setShowAlert(false);
            
    //         //navigate('/complete_profile');     
    //       }
    //         // Registration successful, you can handle the response data here
           
    //     } catch (error) {
    //       setIsLoading(false);
    //       setOpen(false);
    //       // Handle network errors or other exceptions
    //       console.error('Registration failed:', error);
         
    //     }
        
    //     // If on the last tab, navigate to "/complete_profile" route
    //    // navigate('/complete_profile');
    //   } else {
    //     // Increment the value state to move to the next tab
    //     setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
    //   }
    // };
    const handleBackButton = (userid) => {
      if (value === 0 && urlType === 'student') {
        // If on the first tab, navigate to "/complete_profile" route
        window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}`
      }  else if (value === 0 && urlType === 'collaborator' )  {
        window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}`;
        // setShouldNavigate(true); // Assuming this triggers navigation to a different page
      }  else if (value === 0 && urlType === 'admin' )  {
        window.location.href = `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userid)}`;
        // setShouldNavigate(true); // Assuming this triggers navigation to a different page
      } else {
        // Decrement the value state to move to the previous tab
        setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
      }
    };
   
   
    // const addCourse = () => {
    //   setCourses([...courses, { course_title: '' }]);
    // };

   

    useEffect(() => {
      if (shouldNavigate) {
        const navigateTimer = setTimeout(() => {
        
          navigate('/Student');
        },);
  
        // Clean up the timer to avoid memory leaks
        return () => clearTimeout(navigateTimer);
      }
    }, [shouldNavigate]);

 
      const states = [
        "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
      "Puerto Rico",
      "Guam",
      "U.S. Virgin Islands",
      "American Samoa",
      "Northern Mariana Islands",
      "International"
      ];

 

  
      
      
  return (
    <div>
    {/* <Topbar /> */}
    {/* {urlType === 'student' ?  <Topbar /> : <Tobar/>} */}
    {urlType === 'student' ? <Topbar /> : (urlType === 'admin' ? <Topbar2 /> : <Tobar />)}
    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{ background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "20px" }}
      >
        
       <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
        
        <Box className="Test_BoxDiv Test_paddingBox2">
        <Box className="Collaborator_highschool_form" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value}  aria-label="basic tabs example"
              
            sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-between', // This adds space between the tabs
                },
                // ...(isSmallScreen && {
                //   '& .css-heg063-MuiTabs-flexContainer': {
                //     overflow: 'scroll',
                //     // Add other styles you want to apply to .css-heg063-MuiTabs-flexContainer
                //   }, }),
              }}>
              <Tab label="HIGH SCHOOL INFO" />
              <Tab label="GPA" />
              <Tab label="CURRENT COURSES" />
        
            </Tabs>
          </Box>
          <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 0}>
          <Box className='Test_paddingBox' >
  <div className='Test_FlexRow_highschool' >
    <TextField id="outlined-basic" label="High School Name" name="hight_school_name" error={Boolean(ErrorHighschoolName)} helperText={ErrorHighschoolName} variant="outlined" fullWidth size="small" sx={{mb:2}}   disabled value={formData.hight_school_name}/>
    <FormControl fullWidth size="small" sx={{mb:2}}   error={Boolean(ErrorHighSchoolLocation)}
      helperText={ErrorHighSchoolLocation} >
        <InputLabel id="demo-simple-select-label">High School Location</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="high_school_location"
          value={formData.high_school_location}
          label="high school location"
       disabled
          error={Boolean(ErrorHighSchoolLocation)}
        >
          {states.map((stateName, index) => (
          <MenuItem key={index} value={stateName}>
            {stateName}
          </MenuItem>
        ))}
        </Select>
  
      </FormControl>

  
  </div >
  {/* <FormControl fullWidth size="small" sx={{mb:2}} >
        <InputLabel id="demo-simple-select-label">Degree Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="degree_high"
          value={formData.degree_high}
          label="Degree Type"
          onChange={(e) => setFormData({ ...formData, degree_high: e.target.value })}
        >
          <MenuItem value={'AP'}>AP</MenuItem>
          <MenuItem value={'BI'}>IB</MenuItem>
        </Select>
      </FormControl> */}
   
  <div className='Test_FlexRow'>
  <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
  <LocalizationProvider dateAdapter={AdapterDayjs} >
  <DatePicker
    id="start-date"
    name="start_date"
    label={<span className="centered-label">start date</span>}
    size="small"
    sx={{
      width: '100%',
      '& .MuiInputLabel-root': {
        top: '-6px', // Center align the label
        color: start_dateError ? '#d32f2f' : '',
       
      },
      '& .MuiInputBase-root': {
        height: '2.6rem',
  
      },
    }}
    value={formData.start_date ? dayjs(formData.start_date) : null}
 disabled
    maxDate={dayjs()}
    
  />
  
</LocalizationProvider>
{start_dateError && (
    <FormHelperText sx={{color:"#d32f2f"}}>{start_dateError}</FormHelperText>
  )}
</div>
<div style={{display:"flex",flexDirection:"column",width:"100%"}}>
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    id="end-date"
    name="end_date"
    label="end date"
    size="small"
    disabled
    sx={{
      width: '100%',
      '& .MuiInputLabel-root': {
        top: '-6px', // Center align the label
        color: start_dateError ? '#d32f2f' : '',
      },
      '& .MuiInputBase-root': {
        height: '2.6rem',
      },
    }}
    value={formData.end_date ? dayjs(formData.end_date) : null}
  
    // maxDate={dayjs()}
  />
</LocalizationProvider>

  </div>
</div>


  </Box>
 

          </div>
          <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 1}>
          <Box className='Test_paddingBox' >
  <div className='Test_FlexRow21'>
  {/* <FormControl fullWidth size="small" sx={{mb:2}} >
        <InputLabel id="demo-simple-select-label">GPA score</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="gpa_score"
          value={GpaScore}
          label="GPA score"
          onChange={handleChanges}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
        {ErrorGpaScore && (
    <FormHelperText>{ErrorGpaScore}</FormHelperText>
  )}
      </FormControl> */}
       <TextField id="outlined-basic" label="GPA" name="gpa_score" variant="outlined" fullWidth size="small" sx={{mb:2}} disabled value={formData.gpa_score} error={Boolean(ErrorGpaScore)}   helperText={ErrorGpaScore}  
       inputProps={{
        inputMode: 'numeric',
      }}/>
    
    <FormControl fullWidth size="small" sx={{mb:2}}  error={Boolean(ErrorGpaOutof)} >
        <InputLabel id="demo-simple-select-label">Out of</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="gpa_out_of"
          value={formData.gpa_out_of}
          label="GPA Scale"
          disabled
          error={Boolean(ErrorGpaOutof)}
          helperText={ErrorGpaOutof}
       >
          <MenuItem value={4}>4.00</MenuItem>
          <MenuItem value={5}>5.00</MenuItem>
          <MenuItem value={6}>6.00</MenuItem>
          <MenuItem value={10}>10.00</MenuItem>
          <MenuItem value={100}>100.00</MenuItem>
         
        </Select>
   
      </FormControl>
   
  </div >
  <div className='Test_FlexRow'>
  
  <FormControl fullWidth size="small"  error={Boolean(ErrorGpaType)} >
        <InputLabel id="demo-simple-select-label">GPA Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="gpa_type"
          value={formData.gpa_type}
          label="GPA Type"
   disabled
          error={Boolean(ErrorGpaType)}
          helperText={ErrorGpaType}
      >
          <MenuItem value={"Weighted"}>Weighted</MenuItem>
          <MenuItem value={"Unweighted"}>Unweighted</MenuItem>
          {/* <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {ErrorGpaType && (
    <FormHelperText sx={{color:"#f44336"}}>{ErrorGpaType}</FormHelperText>
  )}
      </FormControl>
  
  
    <TextField id="outlined-basic" label="Class Rank" name="rank_in_class" error={Boolean(Errorrankinclass)} fullWidth  helperText={Errorrankinclass} variant="outlined"  size="small" value={formData.rank_in_class} disabled/>
  
</div>


  </Box>
 
          </div>
          <div className='Collaborator_tab_toppadding' role="tabpanel" hidden={value !== 2}>
          <Box className='Test_paddingBox2' >
  
 

{courses.map((course,index) => (
        <div key={course.id} className='Test_FlexRow1'>
          <TextField id={`outlined-basic-${index}`} label="Course Title" name={`course_tittle${index}`} variant="outlined" fullWidth size="small" disabled  value={course[`course_tittle${index}`] || ''}  />
          <FormControl fullWidth size="small">
            <InputLabel id={`demo-simple-select-labe-${index}`}>Earned Grades</InputLabel>
            <Select
              labelId={`demo-simple-select-label-${index}`}
              id={`grades_earned_in_course${index}`}
              name={`grades_earned_in_course${index}`}
              // value={formData.coursesData}
              value={course[`grades_earned_in_course${index}`] || course[formData.coursesData] || ''}
              label="Earned Grades"
          disabled
            >
              <MenuItem value={'A+'}>A+</MenuItem>
              <MenuItem value={'A'}>A</MenuItem>
              <MenuItem value={'A-'}>A-</MenuItem>
              <MenuItem value={'B+'}>B+</MenuItem>
              <MenuItem value={'B'}>B</MenuItem>
              <MenuItem value={'B-'}>B-</MenuItem>
              <MenuItem value={'C+'}>C+</MenuItem>
              <MenuItem value={'C'}>C</MenuItem>
              <MenuItem value={'C-'}>C-</MenuItem>
              <MenuItem value={'D+'}>D+</MenuItem>
              <MenuItem value={'D'}>D</MenuItem>
              <MenuItem value={'D-'}>D-</MenuItem>
              <MenuItem value={'F'}>F</MenuItem>
            </Select>
          </FormControl>
          {/* {index !== 0 && (
              <DeleteOutlinedIcon className='Mui_edit_icon' disabled sx={{color:'#D32F2F',padding:0}}/>
        )} */}
          {/* {index !== 0 && (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => deleteCourse(index)}
      >
        Delete
      </Button>
    )} */}

        </div>
      ))}
      {/* <div className='Test_paddingDiv'>
        <Button variant="contained" color="inherit" size="large" fullWidth >
          + ADD ANOTHER COURSE
        </Button>
        {Errorcourses && (
    <FormHelperText sx={{color:"#f44336"}}>{Errorcourses}</FormHelperText>
  )}
      </div> */}

  </Box>
          </div>
          <div className='Test_buttonDiv'>
        <Button variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:"none"}}  onClick={() => handleBackButton(userid)}  >Back</Button>
        <Button variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}} onClick={() => moveToNextTab(userid)}   disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    value === 2 ? "CLOSE" : "NEXT"
                  )}</Button>
      </div>
         </Box>
        </Box>
        {/* {showAlert && (
        <Alert
          severity="success"
          sx={{
            position: 'absolute',
            bottom: 0, // Place the Alert at the bottom
            left: 0,
            right: 0,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Your data was successfully saved! Redirecting...
        </Alert>
      )} */}
      </Grid>
    </Grid>
  </div>
  )
}

export default Collaborator_highschool_form