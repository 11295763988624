import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
///import { Link, useLocation ,useHistory   } from 'react-router-dom'; // Corrected import for useHistory
import { Link } from "react-router-dom";
import centerImage from "../../assets/img/loginbg.png";
import Google from "../../assets/img/Googelimge.png";
import Typography from "@mui/material/Typography";
import "./signup.css";
import studentLoginImage from "../../assets/img/studentlogin.png";
import Logo1 from "../../assets/img/logo.png";
import Line from "../../assets/img/Vector_line.png";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
const defaultTheme = createTheme();
import {useLocation} from "react-router-dom";
export default function Signup() {
  const navigate = useNavigate();
  const location2 = useLocation();
  //const history = useHistory(); // Corrected import and usage of useHistory

  const [formData, setFormData] = React.useState({
    // name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [registrationError, setRegistrationError] = React.useState(null);
  const [registrationSuccess, setRegistrationSuccess] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(null);
  const [firstText, setFirstText] = useState("");
  const [secondtext, setSecondText] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [signupText, setSignupText] = useState("");

  // Define a default image source
  let centerImageSrc = centerImage; // Default image

  // Check if the type is 'collaborator' or 'student' and set the image source and login text accordingly
  const location = window.location;
  const params = new URLSearchParams(location.search);
  const type = params.get("type");

  useEffect(() => {
    // Check if the type is 'collaborator' or 'student' and set the image source and login text accordingly
    if (type === "collaborator") {
      setFirstText("Earn money by");
      setSecondText("helping students");
      setBottomText("Collaborator");
      setSignupText("Get started to start earning")
    } else if (type === "student") {
      setFirstText("Apply to college");
      setSecondText("the smart way");
      setBottomText("Student");
      setSignupText("Get help with your College Applications")
    } else if (type === "null") {
      navigate("/welcome");
    } else if (type === "") {
      navigate("/welcome");
    }
  }, [type]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError(null);
    setPasswordError(null);
    setConfirmPasswordError(null);
    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }
    if (
      // !formData.name ||
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setConfirmPasswordError("Confirm password required");
      setPasswordError("password required");
      setEmailError("Email required");
      // setRegistrationError("All fields are required.");
      return;
    }
    formData.type = type;
    try {
      setIsLoading(true);
      // Make a POST request to the API endpoint
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/registerone`, {
        method: "post",

        body: JSON.stringify(formData),
      });

      // Check the response status and handle it accordingly
      if (response.status === 201) {
        // Registration successful, you can handle the response data here
        const data = await response.json();
        console.log("Registration successful");
        setRegistrationSuccess(data.message);

        navigate(`/signupprofile?type=${type}`);
        localStorage.setItem("formData", JSON.stringify(formData));
        //history.push(`/signupprofile?type=${type}`);
        // You can redirect the user to a success page or perform other actions
      } else if (response.status === 422) {
        // Handle other response statuses or errors
        const data = await response.json();
        console.error("Registration failed:", data);
        setIsLoading(false);
        if (data.error) {
          if (data.error.email) {
            setEmailError(data.error.email[0]);
            console.log(emailError ,"error")
          }
          if (data.error.password) {
            setPasswordError(data.error.password[0]);
          }
         
        }
      }
    } catch (error) {
      // Handle network errors or other exceptions
      //console.error('Registration failed:', error);
      setRegistrationError("Registration failed. Please try again later.");
    }
  };
  // const handleGoogleSignIn = async () => {
  //   try {
  //     // Load the Google API and initialize it
  //     await new Promise((resolve, reject) => {
  //       gapi.load("auth2", (error) => {
  //         if (error) {
  //           reject(error);
  //         } else {
  //           resolve();
  //         }
  //       });
  //     });
  
  //     const auth2 = await gapi.auth2.init({
  //       client_id: "880676432469-6hphhmsj3vd2frqrb4glsgitoehp7v9q.apps.googleusercontent.com",
  //     });
  
  //     // Sign in with Google
  //     const googleUser = await auth2.signIn();
  
  //     // Retrieve user information
  //     const profile = googleUser.getBasicProfile();
  //     const email = profile.getEmail();
  
  //     // Make a request to your backend API for Google authentication
  //     const apiUrl = process.env.REACT_APP_API_URL;  // Replace with your backend API URL
  //     const response = await fetch(`${apiUrl}/auth/google/callback`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // Add any additional headers if needed
  //       },
  //     });
  
  //     // Check the response status and handle it accordingly
  //     if (response.status === 200) {
  //       // Successful authentication, you can handle the response data here
  //       const data = await response.json();
  //       console.log("Authentication successful:", data);
  //     } else {
  //       // Handle other response statuses or errors
  //       const data = await response.json();
  //       console.error("Authentication failed:", data);
  //     }
  //   } catch (error) {
  //     // Handle errors during Google sign-in or API request
  //     console.error("Google sign-in or API request failed:", error);
  //   }
  // };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleGoogleSignIn = () => {
  //   // Make an HTTP request to your Laravel backend to get the Google Sign-In URL
  //   fetch(`${process.env.REACT_APP_API_URL}/auth/google?type=${type}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       // Open the Google Sign-In URL in a new tab
        
  //       window.open(data.url, '_blank');
  //     })
  //     .catch(error => {
  //       console.error('Error during Google Sign-In:', error);
  //     });
  // };
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [data, setData] = useState({});
  const [user, setUser] = useState(null);
  const [failMessage, setFailMessage] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Check if the URL contains the 'code' parameter
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    
      // If the URL contains the 'code' parameter, set isAuthenticated to true
      
// 
      // Fetch user data after authentication

      
      fetch(`${process.env.REACT_APP_API_URL}/auth/google/callback${location2.search}`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Data from server:', data);
          window.location.href = '/signup';
          if (data.status === 'success') {
            // Store user details in localStorage
            localStorage.setItem('userDetails', JSON.stringify(data.user));
            localStorage.setItem('accessToken', data.access_token);
            setLoading(false);
            setData(data);
            // Redirect to the appropriate dashboard or URL
            // window.location.href = data.redirect_url;
           
          } else {
            // Handle login failure
            console.error('Login failed:', data.message);
          }
        })
        .catch((error) => {
          console.error('Error during login:', error);
        });
   
  }, []);
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userDetailsParam = searchParams.get('userDetails');
    const failsParam = searchParams.get('fails');
    if (userDetailsParam) {
      try {
        // Parse the JSON string from the URL
        const userData = JSON.parse(userDetailsParam);

        // Set the entire userDetails object in localStorage
        localStorage.setItem('userData', JSON.stringify(userData));

        // Check the 'type' property
        if (userData.type === 'student') {
          // If 'type' is 'student', navigate to '/student'
          navigate('/student');
        } else if (userData.type === 'collaborator') {
          // If 'type' is 'collaborator', navigate to '/profileview'
          navigate('/profileview');
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }else if (failsParam) {
      // Handle failed login due to email already being registered for a different user type
      console.error('Login failed:', failsParam);
      setFailMessage(failsParam);
      // Display an appropriate message to the user or handle the failure as needed
    }
  }, [location.search]);

  const handleGoogleSignIn = () => {
    // Make an HTTP request to your Laravel backend to get the Google Sign-In URL
    fetch(`${process.env.REACT_APP_API_URL}/auth/google?type=${type}`)
      .then((response) => response.json())
      .then((data) => {
        // Open the Google Sign-In URL in a new tab
        setIsAuthenticated(true);
        window.open(data.url, '_blank');
        setUser(data);
        console.log(data,"data")
      })
      .catch((error) => {
        console.error('Error during Google Sign-In:', error);
      });
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        className="main_container_login"
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
      >
        <CssBaseline />
        <Grid
          item
          lg={4}
          xl={4}
          xs={false}
          sm={4}
          md={5.5}
          className="Login_Grid"
        >
          <Box></Box>
          <Box className="Login_text_area">
            <div>
              <img src={Logo1} alt="Logo1" className="Login_logo_img" />
              <div className="login_text_div">
                <typography className="Login_side_text">{firstText}</typography>
              </div>
              <div>
                <typography className="Login_side_text">
                  {secondtext}
                </typography>
              </div>
            </div>
          </Box>
          <Box className="Login_Bottom_box">
            <div className="Login_Bottom_div">
              <img src={Line} alt="Line" className="Login_Bottom_img" />
              <typography className="Login_Bottom_text">
                {bottomText}
              </typography>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          lg={8}
          xl={8}
          xs={12}
          sm={8}
          md={6.5}
          component={Paper}
          elevation={6}
          square
          className="Login_Textfield_grid"
        >
          <Box className="Login_Textfiled_Box">
            <Grid item lg={8} xl={8} xs={10} sm={10} md={10}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                className="Login_Form_Textfield"
              >
                <div className="Login_header_text">
                  <typography className="Login_Welcome">Sign Up</typography>
                  <typography className="Login_Welcome_text">
                  {signupText}
                  </typography>
                </div>
                <div className="Login_Google"  onClick={handleGoogleSignIn}>
                  <img
                    src={Google}
                    alt="Center Image"
                  
                  />
                  <Typography className="Login_google">
                    Sign Up with Google
                  </Typography>
                </div>
                <div style={{display:"flex",justifyContent:'center',marginTop:'10px'}}>
                {failMessage ? (
        <typography style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>{failMessage}</typography>
      ) : (
        
          <div></div>
         
          )}
          </div>
                <div className="Login_divider">
                  <div className="custom-divider" />
                  <Typography className="Login_ortext">or</Typography>
                  <div className="custom-divider" />
                </div>
                {registrationError && (
                  <div className="error-message">{registrationError}</div>
                )}
                {registrationSuccess && (
                  <div className="success-message">{registrationSuccess}</div>
                )}
                {/* <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Full Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={formData.name}
                  onChange={handleChange}
                  error={Boolean(nameError)}
                  helperText={nameError}
                /> */}
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(emailError)}
                  helperText={emailError}
                />
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleChange}
                  error={Boolean(passwordError)}
                  helperText={passwordError}
                />
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="current-password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={Boolean(confirmPasswordError)}
                  helperText={confirmPasswordError}
                />
                {/* <Link
                to={`/signupprofile?type=${type}`}
                style={{ textDecoration: 'none' }}
              > */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="Login_button"
                  style={{ boxShadow: 'none' }}
                  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    "SUBMIT"
                  )}
                </Button>
                {/* </Link> */}

                <Grid container className="Login_Textfield_Last_grid">
                  <Grid item>
                    <p className="Welcome__already">
                      Already have an account?{" "}
                      <Link
                        to={`/login?type=${type}`}
                        style={{ textDecoration: "none" }}
                      >
                        <typography className="Signup_text">login</typography>
                       
                      </Link>{" "}
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
