import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import { Box, Chip, Divider } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import dayjs from 'dayjs';
import Topbar from '../Students/Topbar'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tobar from '../collaborator/Tobar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Experience_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
  const [type, setType] = useState()
  const [view, setView] = useState();
  const [userId, setUserId] = useState();
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 
  const breadcrumbs = [
    <Link
      underline="hover"
      style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}
      key="1"
      color="inherit"
      href={
        type === 'admin' && view === 'student'
          ? `/admin_student_profileview?id=${encodeAlphanumeric(userId)}`
          : type === 'admin'
            ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
            : type === 'student' && view === 'student'
            ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}&type=student`
            : type === 'student'
              ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
              : type === 'collaborator' && view === 'student'
              ? `/studentProfile?id=${encodeAlphanumeric(userId)}&type=collaborator`
              : null
      } 
     
    >
       <ArrowBackIosIcon fontSize="meduim" />
     BACK TO PROFILE
    </Link>
 
   
  ];
  const [formData, setFormData] = useState({
    
volunteer_hours: '',
work_experience: [],
  
  });
 
useEffect(() => {
  
     
  const searchParams = new URLSearchParams(location.search);
  const selectedActivityId = searchParams.get('id'); //change this const to form id 
  const view = searchParams.get('view');
  const apiUrl = process.env.REACT_APP_API_URL;
  const userData = JSON.parse(localStorage.getItem('userData'));
  const type = searchParams.get('type');
  setType(type)
  setView(view)
  const urlParams = new URLSearchParams(window.location.search);
  const userid = urlParams.get('userid');

 
  if(selectedActivityId !== ''){
    const apiEndpoint = view === 'student' ? '/get_student_work_experiemce_form_data' : '/get_work_experience_form_data';
    try {
    fetch(`${apiUrl}${apiEndpoint}`, {   // chgange this url 
      method: 'post',
      body: JSON.stringify({ formid:selectedActivityId }), // pass the form id const here 
  })
  .then(response => {
      if (response.status === 200) {
          return response.json(); // Convert the response to JSON
      } else {
          throw new Error('Request failed');
      }
  })
  .then(data => {
    const personal_eassaydata = data.formdata[0];
   
             
      console.log(personal_eassaydata, "setPersonalEssayData"); 
      const fetchedUserId = personal_eassaydata.userId || '';
      setUserId(fetchedUserId);


  const ExperienceDataArray = JSON.parse(personal_eassaydata.work_experience

    || '[]').map((award, index) => ({
   title: award[`Title-${index}`] || '',
   organizationName: award[`organization-name-${index}`] || '',
   
   jobDescription: award[`job-description-${index}`] || '',
  
   description: award[`description-${index}`] || '',
   jobType: award[`job-type-${index}`] || '',
   options: award[`options-${index}`] || '',
   startDate: dayjs(award[`start-date-${index}`]).format('MM/DD/YYYY') || '',
   endDate: dayjs(award[`end-date-${index}`]).format('MM/DD/YYYY') || '',
 }));

 setFormData({
  volunteer_hours: personal_eassaydata.volunteer_hours || '',
work_experience: ExperienceDataArray,
 });


 console.log(ExperienceDataArray, "ExperienceDataArray");
})

  .catch(error => {
      console.error('Data fetch failed:', error);
  });
} catch (error) {
  console.error('Data upload failed:', error);
}
  }

  
}, []); 
  return (
    <div>
    {type === 'student' ? <Topbar /> : type === 'collaborator' ? <Tobar /> : <Topbar2 />}
     <Grid container 
gap="20px"
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Grid_Experience_Honors_detailpage'
>
<Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
<Grid
item
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Collaborator_Experience_info_detailpage'>
<Box className='Experience_info_Box1'>
<WorkOutlineOutlinedIcon className='College_info_Avatar' />
<typography className='College_heading'>
Work Experiences</typography>
</Box>
<Box className='Experience_info_main_Box'>
<Box className='Experience_info_Box2'>
  <Box className='Experience_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Role/title</typography>
    <typography className='College_name_secondary'>{formData.work_experience[0]?.title}</typography>
  </Box>
  <Box className='Experience_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Job Type</typography>
    <typography className='College_name_secondary'>{formData.work_experience[0]?.jobType}</typography>
  </Box>
  <Box className='Experience_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Company Name</typography>
    <typography className='College_name_secondary'>{formData.work_experience[0]?.organizationName}</typography>
  </Box>
</Box>
<Box className='Experience_info_Box2'>
  <Box className='Experience_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Duration</typography>
    <typography className='College_name_secondary'>{formData.work_experience[0]?.startDate} - 
    {formData.work_experience[0]?.options !== 'yes' ? (
        <>
          {formData.work_experience[0]?.endDate}
        </>
      ) : (
        'Present' // or any text you want to display when `options` is 'yes'
      )}
    </typography>
  </Box>
   
</Box>
<Box className='Experience_info_Box2'>
  <Box className='Experience_info_Box2_secondary11'>
    <typography className='College_heading_secondary'>Job Description</typography>
    <typography className='College_name_secondary'>{formData.work_experience[0]?.jobDescription} </typography>
  </Box>

</Box>

</Box>
<Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />
</Grid>




</Grid>
  </div>
  )
}

export default Experience_detailpage