import { Box, Grid,  Typography } from '@mui/material'
import React, { useState } from 'react'
import Logo from '../../assets/img/topbarlogo.png'
import { Link,useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      gap: 1, // Adjust the gap for small screens
    },
  },
  headerText: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust the font size for small screens
    },
  },
}));

export const Topbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userType, setUserType] = useState(null);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    // Iterate over all keys in localStorage and remove them
    Object.keys(localStorage).forEach(key => {
        localStorage.removeItem(key);
    });
    
    // Alternatively, you can simply clear the entire localStorage
    // localStorage.clear();
    
    // Navigate to the login page
    navigate(`/admin-login`);
};
  return (
   <Grid container
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='admin_topbar_Container'
   >
    <Grid item
     lg={12}
     xl={12}
     xs={12}
     sm={12}
     md={12}
     className='admin_topbar_subContainer'
    
    >
      <Link style={{textDecoration:'none'}} to={'/admin-dashboard'}>
        <img src={Logo} alt='Logo' className='admin_topbar_Logo'/>
        </Link>
  <Link  onClick={handleAvatarClick} style={{textDecoration:'none'}}>
        <typography className="admin_Topbar_header_text">Administrator</typography>
        </Link>
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        {/* Add more menu items as needed */}
      </Menu>
    </Grid>
   </Grid>
  )
}
