import { Grid, typography } from '@mui/material';
import React from 'react';
import Headermain from '../components/header/header';
import UniLogos from '../components/UniLogos/UniLogos';
import FooterMain from '../components/footer/footer';
import '../App.css';
import Tick from '../assets/img/tickicon.svg';
import Questions from '../components/Questions/Questions';
import Tick2 from '../assets/img/tickorange.svg';
import { Link } from 'react-router-dom';

const pricingPlans = [
  {
    recomders:"Best for Transfer Students", 

    name: 'Monthly',
    description: 'Billed Monthly',
    price: 9.99,
    Actualprice:14.99,
    features: ['College Information', 'Student Profiles', 'Admission Essays','Test Scores','Messaging'],
    buttonText: 'Get Started Now',
    backgroundColor: '#006CB7',
    image:Tick
  },
  {
    recomders:"Recommended", 
    name: 'Semesterly',
    description: 'Billed every 6 months',
    price: 34.99,
    Actualprice:49.99,
    features: ['College Information', 'Student Profiles', 'Admission Essays','Test Scores','Messaging'],
    buttonText: 'Get Started Now',
    backgroundColor: '#EF6C00',
    image:Tick2
  },
  {

    recomders:"Best for Freshman and Sophomores", 
    name: '4 Years',
    description: 'One-time purchase',
    price: 149.99,
    Actualprice:249.99,
    features: ['College Information', 'Student Profiles', 'Admission Essays','Test Scores','Messaging'],
    buttonText: 'Get Started Now',
    backgroundColor: '#006CB7',
    image:Tick
  },
];

function PricingGridItem(props) {
  const { plan } = props;

  return (
    <Grid
   container
    xs={12}
    sm={12}
    md={4}
    lg={4}
    xl={4}
    sx={{
      maxWidth:"1920px",
     
    }}>
<div className='Pricing_uper_div' style={{background: plan.recomders === "Recommended" ? "rgba(239, 108, 0, 0.20)": "rgba(0, 108, 183, 0.20)",padding:"8px 14px 20px 14px",width:"82%",marginBottom:"-10px", borderRadius: "16px 16px 0 0",textAlign:"center"}}>
<typography className="ValidityTextecondaryGrid12" style={{color:plan.backgroundColor}}>{plan.recomders}</typography>
</div> 
 
<Grid
  item
  xs={12}
  sm={12}
  md={12}
  lg={12}
  xl={12}
  className="pricing_one" // Add the class name "pricing_one"
  sx={{backgroundColor:"#FFF"}}
>

<Grid
  item
  xs={12}
  sm={12}
  md={12}
  lg={12}
  xl={12}
  className="pricing_two" // Add the class name "pricing_two"
>
 
        
        <typography className="paymentValidityText">{plan.name}</typography>
    
        <typography className="ValidityTextecondaryGrid1" style={{color:plan.backgroundColor}}>
          {plan.description}
        </typography>
       
       
          <typography className="paymentPrice">
            <sub className="top-sub">$</sub>
            {plan.price}
          </typography>
       
        <typography className="actualpaymentPrice">
          <s>
            ${plan.Actualprice}
          </s>
        </typography>
      </Grid>
      <Grid>
        <typography className="planstitle">This plan includes:</typography>
        {plan.features.map((feature, index) => (
       <Grid
       item
       key={index}
       className="pricing_three" // Add the class name "pricing_three"
     >
            <img
                src={plan.image}
              className="priceImage"
        
              alt="Tick icon"
            />
            <typography className="plansDec">{feature}</typography>
          </Grid>
        ))}
      </Grid>
      <Link  to="/signup?type=student"  style={{ textDecoration: 'none' }}>
      <div className='Pricing_one'>
   

    
      <Grid
        item
        className='pricing_four'
        sx={{
        
          backgroundColor: plan.backgroundColor,
        
       
        }}
      >
      <typography to="/signup?type=student"  className="buttonText">
          {plan.buttonText}</typography>
       
      </Grid>
      
      </div>
      </Link>
      <Grid
  item
  className="pricing_five" // Add the class name "pricing_five"
>
       
      </Grid>
    
    </Grid>
    </Grid>
  );
}

const Pricing = () => {
  return (
    <>
   


   <Grid
  container
  component="main"
  className="pricingmain_one" // Add the class name "pricingmain_one"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
      
<Grid
  container
  component="main"
  className="pricingmain_two" // Add the class name "pricingmain_two"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
        <div className='Pricing_two'>
    <div className='Pricing_three'>
          <Headermain />
       </div>
       </div>
       
       <Grid
  item
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
  className="pricingmain_three" // Add the class name "pricingmain_three"
>
<Grid
  item
  className="pricingmain_four" // Add the class name "pricingmain_four"
>
            <typography className="companyTitle">
            Choose the plan that is best for you
            </typography>
            <typography className="companyDescription">
            Unlock access to our entire site
            </typography>
          </Grid>
        </Grid>
      </Grid>
      <div className='Pricing_four'>
        <div className='Pricing_five'>
        <Grid
  container
  className="pricingmain_five" // Add the class name "pricingmain_five"
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
>
        {pricingPlans.map((plan, index) => (
          <PricingGridItem key={index} plan={plan} />
        ))}
      </Grid>
      </div>
      </div>
      <div className='Pricing_six'>
        {/* <div className='Pricing_seven'>
 
 <div className='Pricing_eigth'>
      <Questions />
     
      </div>
 </div> */}
 </div>
     
     
    </Grid>
      <FooterMain />
    </>
  );
};

export default Pricing;
