import React,{useState,useEffect} from 'react'
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper
} from "@mui/material";
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import Chatpic from '../../assets/img/_Avatar_.png';
import Chatpic1 from '../../assets/img/_Avatar_1.png';
import DoneIcon from '@mui/icons-material/Done';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './collaborator.css'


const Messages = ({ sender1,content }) => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  console.log(sender1,"message user details")
  const [input, setInput] = React.useState(""); 
  const [messages, setMessages] = useState([]);
  const handleSend = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
    if (input.trim() !== "") {
      // Prepare the message data to send to the Laravel API
      const newMessage = {
        text: input,
        sender: userData.legal_name, // You can customize the sender logic
        userId:userId,
        id : sender1.id
         // Add sender's name
      };
      

      // Send the message to the API
      // Replace 'api/messages' with your actual API endpoint for sending messages
      fetch(`${apiUrl}/save_collaborator_messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newMessage),
      })
        .then((response) => response.json())
        .then(() => {
          // Clear the input field and fetch updated messages
          setInput("");
          fetchMessagesFromApi();
        })
        .catch((error) => {
          console.error("Error sending message: ", error);
        });
    }
  };
  useEffect(() => {
    // Fetch messages from Laravel API
    //fetchMessagesFromApi();
  
    // Fetch all users from the API when the component mounts
    fetchMessagesFromApi();
    const intervalId = setInterval(fetchMessagesFromApi, 10000);  
    return () => clearInterval(intervalId);
  }, [sender1]);  

  const fetchMessagesFromApi = () => {
    // Fetch messages from the API
    // Replace 'api/messages' with your actual API endpoint for messages
    const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
      const reciever = sender1.id;
      console.log(reciever,"reciever id");
      fetch(`${apiUrl}/get_collaborator_messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId,reciever }),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.messages);
        setMessages(data.messages);
      })
      .catch((error) => {
        console.error("Error fetching messages: ", error);
      });
  };
  
  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
  
  
  
  return (
    <Box
    className="Messages_chatBox_maindiv"
    sx={{
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      
    }}
    >
    <Box className="Messages_box_main_div" sx={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"35px"}}>
    <CardHeader
    sx={{padding:"0px"}}
    avatar={
      <Badge color="success"> {/* Use Badge with badgeContent */}
      <Avatar className='Messages_box_Avatar' sx={{width:"60px",height:"60px"}}  aria-label="recipe"    src={sender1.senderpic}>
      
      </Avatar>
      </Badge>
    }
    title={ <typography className="Message_sender_name">{sender1.sender}</typography>}
    subheader={<typography className="Message_sender_detail">{sender1.content}</typography>}
    
    />
    {/* <Box sx={{display:"flex",gap:"10px"}}>
    
    
    <DoneIcon sx={{color:"grey.400"}}/>
    <StarBorderIcon sx={{color:"grey.400"}}/>
    <DeleteOutlineIcon sx={{color:"grey.400"}}/>
    </Box> */}
    </Box>
    <Divider variant="inset"  />
    <Box className="Messages_chat_div" sx={{ flexGrow: 1, overflow: "auto",padding:"35px" }}>
    {messages.map((message) => (
      <Message key={message.id} message={message.text} sender1={sender1} messagesender={message.sender} />
      ))}
      </Box>
      <Box sx={{ p: "35px", }}>
      <Grid container spacing={2}>
      <Grid item xs={10} sm={10}>
      <TextField size="small"  fullWidth placeholder="Type a message" value={input} id="filled-basic"  variant="filled" onChange={handleInputChange} onKeyPress={(e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  }}
  sx={{
    '& .MuiInputBase-input': {
      padding: '10px', // Set the desired padding here
    },
  }}
/>
      
      </Grid>
      <Grid item xs={2} sm={2} sx={{paddingLeft:"12px"}}>
      <Button
      size="large"
      fullWidth
      color="primary"
      variant="contained"
      // endIcon={<SendIcon />}
      onClick={handleSend}
      sx={{boxShadow:"none",height:"100%",
      '@media (max-width: 600px)': {
        size: "small",  // Add margin to the right on small screens
      },
    }}
      >
      Send
      </Button>
      </Grid>
      </Grid>
      </Box>
      </Box>
      );
    };
    
    const Message = ({ message,sender1,messagesender}) => {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const isBot = messagesender !== userData.legal_name;
      console.log(userData.name,"login user");
      console.log(messagesender,"message sender");
      console.log(isBot,"bot name");
      const profile1url = userData.avatarImage;
      const laravelUrl = profile1url.replace('/public/', '/storage/app/public/');
      const laravelUrlfinal = laravelUrl.replace('/public/storage/', '/public/');
      
      return (
        <Box
        sx={{
          display: "flex",
          justifyContent: isBot ? "flex-start" : "flex-end",
          mt:"40px",
          mb:"40px"
        }}
        >
        <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "flex-start",
          width:"70%"
        }}
        >
        <Avatar
        
        src={isBot ? sender1.senderpic : laravelUrlfinal }
        sx={{
          border: '2px solid white',
          width: '30px',
          height: '30px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: 'pointer', // Add cursor style to indicate it's clickable
        }}>
          {/* {isBot ? <img src={sender1.senderpic} alt="Bot Avatar" /> : <img src={laravelUrlfinal} alt="User Avatar" />} */}
        </Avatar>
        
        <Paper
        variant="outlined"
        sx={{
          p: "10px 10px 10px 14px",
          ml: isBot ? 1 : 0,
          mr: isBot ? 0 : 1,
          backgroundColor: isBot ? "grey.200": "rgba(0, 108, 183, 0.15)",
         border:"none"
        }}
        >
        <typography className="Message_users" style={{color:isBot ? "var(--text-secondary, rgba(0, 0, 0, 0.60))":" var(--material-theme-sys-light-primary-1, #006CB7)"}} variant="body1">{message}</typography>
        </Paper>
        </Box>
        </Box>
        );
      };
      
      
      
      export default Messages  
      
      
      