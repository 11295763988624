import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from './pages/welcome';
import Login from './pages/Login/login'; // Import your Login component
import Signup from './pages/Signup/signup'; // Import your Signup component
import Homepage from './pages/Homepage';
import Headermain from './components/header/header';
import FooterMain from './components/footer/footer';

import Signupprofile from './pages/Signup/Signupprofile';
import Profileview from './pages/collaborator/Profileview';
import CompleteProfile from './pages/collaborator/CompleteProfile';
import Aboutus from './pages/Aboutus';
import Pricing from './pages/Pricing';
import Testing from './pages/collaborator/Profile_add/Testing';
import Honours from './pages/collaborator/Profile_add/Honours';
import Activities from './pages/collaborator/Profile_add/Activities';
import Summary from './pages/collaborator/Profile_add/Summary';
import High_school from './pages/collaborator/Profile_add/High_school';
import Colleges_applied from './pages/collaborator/Profile_add/Colleges_applied';
import FAQ_s from './pages/FAQ_s';
import Contact_us from './pages/Contact_us';
import Profile_submitted from './pages/collaborator/Profile_submitted';
import Messages from './pages/collaborator/Messages';
import Collaborator_home from './pages/collaborator/collaborator_home';
import Collaborator_students from './pages/collaborator/Collaborator_students';
import StudentProfileview from './pages/Students/StudentProfileview';
import StudentHome from './pages/Students/StudentHome';
import StudentCompleteProfile from './pages/Students/StudentCompleteProfile';
import Student_college_applied_for from './pages/Students/Student_profile_add/Student_college_applied_for';
import Student_high_school from './pages/Students/Student_profile_add/Student_high_school';
import Student_honors from './pages/Students/Student_profile_add/Student_honors';
import Student_testing from './pages/Students/Student_profile_add/Student_testing';
import Student_activities from './pages/Students/Student_profile_add/Student_activities';
import Student_Summary from './pages/Students/Student_profile_add/Student_Summary';
import CollaboratorProfile from './pages/Students/CollaboratorProfile';
import CollaboratorProfileforstudent from './pages/collaborator/CollaboratorProfileforstudent';
import StudentPricing from './pages/Students/StudentPricing';
import Profile_edit from './pages/collaborator/Profile_add/Profile_edit';
import Student_profileedit from './pages/Students/Student_profile_add/Student_profileedit';
import Payment_success from './pages/Students/Payment_success';
import Forgot_password from './pages/Password/Forgot_password'
import Reset_password from './pages/Password/Reset_password';
import Student_work_experience from './pages/Students/Student_profile_add/Student_work_experience';
import Collaborator_experience from'./pages/collaborator/Profile_add/Collaborator_experience'
import Collaborator_college_form from './pages/Students/Collaboratorforms/Collaborator_college_form';
import Collaborator_highschool_form from './pages/Students/Collaboratorforms/Collaborator_highschool_form';
import StudentProfile from './pages/collaborator/StudentProfile';
import { Admin_login } from './pages/Admin/Admin_login';
import { Admin_dashborad } from './pages/Admin/Admin_dashborad';
import { Admin_Collaborator_profileview, Admin_profileview } from './pages/Admin/Admin_Collaborator_profileview';
import { Admin_student_profileView } from './pages/Admin/Admin_student_profileView';
import Collaborator_college_detailpage from './pages/Details_pages/Collaborator_college_detailpage';
import Activities_detailpage from './pages/Details_pages/Activities_detailpage';
import Testing_detailpage from './pages/Details_pages/Testing_detailpage';
import Experience_detailpage from './pages/Details_pages/Experience_detailpage';
import Highschool_detailpage from './pages/Details_pages/Highschool_detailpage';
import Summary_detailpage from './pages/Details_pages/Summary_detailpage';
import Student_college_detailpage from './pages/Details_pages/Student_college_detailpage';
import Honours_detailpage from './pages/Details_pages/Honours_detailpage';


function App() {
  return (

     <BrowserRouter>
     <Routes>
       
         <Route path="/welcome"  element={<Welcome/>} />
         <Route path="/login" element={<Login />} />
         <Route path="/signup" element={<Signup />} />
         <Route path="/signupprofile" element={<Signupprofile />} />
         <Route path="/" element={<Homepage />} />
         <Route path="/Headermain" element={<Headermain />} />
         <Route path="/FooterMain" element={<FooterMain />} />
        
         <Route path="/profileview" element={<Profileview/>} />
         <Route path="/complete_profile" element={<CompleteProfile/>} />
         <Route path="/Aboutus" element={<Aboutus/>} />
         <Route path="/college_applied_for" element={<Colleges_applied/>} />

         <Route path="/testing" element={<Testing/>} />
         <Route path="/honour" element={<Honours/>} />
         <Route path="/activities" element={<Activities />} />
         <Route path="/summary" element={<Summary />} />
         <Route path="/high_school" element={<High_school />} />
         <Route path="/Faqs" element={<FAQ_s/>} />
         <Route path="/contact_us" element={<Contact_us />} />

               <Route path="/Pricing" element={<Pricing/>} />
               <Route path="/Profile_submitted" element={<Profile_submitted/>} />
               <Route path="/messages" element={<Messages/>}/>
               <Route path="/collaborator_home" element={<Collaborator_home/>}/>
               <Route path="/collaborator_students" element={<Collaborator_students/>}/>
               <Route path="/Student" element={<StudentProfileview/>}/>
               <Route path="/student_home" element={<StudentHome/>}/>
               <Route path="/student_complete_profile" element={<StudentCompleteProfile/>}/>
               <Route path="/Student_colleges" element={<Student_college_applied_for/>}/>
               <Route path="/Student_high_school" element={<Student_high_school/>}/>
               <Route path="/Student_Honors" element={<Student_honors/>}/>
               <Route path="/Student_Testing" element={<Student_testing/>}/>
               <Route path="/Student_activities" element={<Student_activities/>}/>
               <Route path="/Student_summary" element={<Student_Summary/>}/>
               <Route path="/Collaborator_Profile" element={<CollaboratorProfile/>}/>
          


               <Route path="/Collaborator_Profile_for_student" element={<CollaboratorProfileforstudent/>}/>
               <Route path="/Collaborator_college_form" element={<Collaborator_college_form/>}/>
               <Route path="/Collaborator_highschool_form" element={<Collaborator_highschool_form/>}/>


               <Route path="/Student_pricing" element={<StudentPricing/>}/>
               <Route path="/profile_edit" element={<Profile_edit/>}/>
               <Route path="/student_profile_edit" element={<Student_profileedit/>}/>
               <Route path="/payment_success" element={<Payment_success/>}/>
               <Route path="/Forgot_password" element={<Forgot_password/>}/>
               <Route path="/Reset_password/:token" element={<Reset_password/>}/>
               <Route path="/Student_work_experience" element={<Student_work_experience/>}/>
               <Route path="/Collaborator_work_experience" element={<Collaborator_experience/>}/>
               <Route path="/studentProfile" element={<StudentProfile/>}/>
               <Route path='/admin-login' element={<Admin_login/>}/>
               <Route path="/admin-dashboard" element={<Admin_dashborad/>}/>
               <Route path="/admin_Collaborator_profileview" element={<Admin_Collaborator_profileview/>}/>
               <Route path="/Admin_student_profileView" element={<Admin_student_profileView/>}/>




               {/* <Route path="/complete_profile" element={<CompleteProfile/>}/> */}

               <Route path="/College_detail" element={<Collaborator_college_detailpage/>}/>
               <Route path="/Student_detail" element={<Student_college_detailpage/>}/>
               <Route path="/Highschool_detail" element={<Highschool_detailpage/>}/>
               <Route path="/Activities_detail" element={<Activities_detailpage/>}/>
               <Route path="/Honours_detail" element={<Honours_detailpage/>}/>
               <Route path="/Summary_detail" element={<Summary_detailpage/>}/>
               <Route path="/Experience_detail" element={<Experience_detailpage/>}/>
               <Route path="/Testing_detail" element={<Testing_detailpage/>}/>

      
     </Routes>
   </BrowserRouter>
  );
}

export default App;
