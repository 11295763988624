import { Grid, Typography } from '@mui/material'
import React from 'react'
import Headermain from '../components/header/header'
import '../App.css';
import AboutUsPic from '../assets/img/aboutnew.png';
import Image1 from '../assets/img/Human1.png';
import Image2 from '../assets/img/Human2.png';
import Image3 from '../assets/img/Human3.png';
import Image4 from '../assets/img/Human4.png';
import Arrow from '../assets/img/Shape.png';
import Logo1 from '../assets/img/Harvard.png';
import UniLogos from '../components/UniLogos/UniLogos';
import FooterMain from '../components/footer/footer';
import Divider from '@mui/material/Divider';



const Aboutus = () => {
  return (
    <>
     <Grid container component="main" lg={12} xl={12} xs={12} sm={12} md={12} className="aboutus-one">
     <Grid container component="main" className="aboutus_main" lg={12} xl={12} xs={12} sm={12} md={12}>

        <div className='Container_one'>
    <div className='Container_two'>
      <Headermain />
    </div>
  </div>
  <div className='Container_three'>
    <div className='Container_four'>
        <Grid item 
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        className='aboutus-two'
       
        >
         <Grid item className="aboutus_three">

                <typography  className='companyTitle'>About Our Company</typography>
                <typography  className='companyDescription2'>ApplyUpp started as a high school project in Carrollton, Texas. In our entrepreneurship class I was assigned with creating my own business idea and developing entrepreneurial skills through my project. This came at a time when I was stressing out about my college applications. I didn't have the adequate resources needed to take on the whole process, so I didn't know what schools to apply to, what to include in my application, and how to write good essays. All this stress and confusion made me procrastinate my applications until days before deadlines, and submit half-done applications that didn't accurately represent all of my experience. Looking back, this all could have been solved if I simply had a way to view past applications and be advised by applicants who went through the same process I did. Thus, my idea was to create a website that gave high school students all the application resources they needed.</typography>
            </Grid>
       

        </Grid>
        </div>
        <div 
        className='Container_five'
>
  <img
    src={AboutUsPic}
    className='Container_six'
    alt="About Us"
  />
</div>

        </div>
        </Grid>
        <div className='Container_seven'>
          <div className='Container_eigth'>
          <Grid item className="aboutus_fourth" lg={12} xl={12} xs={12} sm={12} md={12}>

            <typography className='AboutusDetail'>By the time I thought of the idea it was too late for my classmates and I to use, but it came at the perfect time for the future rounds of applicants. Since the COVID-19 pandemic struck, the college application process has become much more difficult. College applications are no longer matching into a school based on your SAT score and GPA. Instead, it is now about who you are as a person and where your experiences in high school have led you. This now offers high school students a much more level playing field regarding college admissions. The only problem is that it is much harder to capture your entire high school experience in an application than it is to show a few test scores. This is where ApplyUpp comes into place. College applicants can now view an unlimited amount of complete applications from past students. This allows high schoolers to know exactly how to start the application process, which schools they should apply to, how to format their application, and ultimately increase their chances of being admitted while removing all the stress and confusion. At the same time college students can give back to high schoolers who were in the same place they were while also earning passive income. We hope you’re willing to join us in changing the college application process for the better.</typography>

        </Grid>
        <div 
        className='Container_nine'
        
        >
            <Grid item className="aboutus_five" lg={12} xl={12} xs={12} sm={12} md={12}>

                <typography className='missionText'>Our mission is to help you perfect<br/>your college application</typography>
            </Grid>
          
            {/* <div
            className='Container-ten'
            >
               
               <div className='Container_eleven'>
               <Divider style={{width:'100%'}}/>
               <Grid item className="aboutus_six">

                    
                        <typography className='missionRates'>50M+</typography>
                        <typography className='missionNames'>Global Users</typography>
                    </Grid>
                    </div>
                    <div className='Container_twelve'>
                    <Divider style={{width:'100%'}}/>
                    <Grid item className="aboutus_seven">

                       
                        <typography className='missionRates'>$100M</typography>
                        <typography className='missionNames'>Capital Raised</typography>
                    </Grid>
                    </div>
                    <div className='Container-thirteen'>
                    <Divider style={{width:'100%'}}/>
                    <Grid item className="aboutus_seven">
                        <typography className='missionRates'>30%</typography>
                        <typography className='missionNames'>Upto Savings rate</typography>
                    </Grid>
                    </div>
                    <div className='Container_fourteen'>
                    <Divider style={{width:'100%'}}/>
                    <Grid item className="aboutus_seven">
                       
                        <typography className='missionRates'>60+</typography>
                        <typography className='missionNames'>Team Members</typography>
                    </Grid>
                    </div>
                    
                </div> */}
                
            
        </div>
 <Grid item className="aboutus_eight" lg={12} xl={12} xs={12} sm={12} md={12}>

  <Grid
    item
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom:'20px'
    }}
  >
    <Grid
      item
      sx={{
        paddingBottom:'12px'
       
       
      }}
    >
      <div className='Container_fifteen'>
      <typography className='featuresMain1Text'>our team</typography>
      </div>
    </Grid>
    <typography className='featuresMain2Text'>Meet our amazing team</typography>
    <typography className='dedicatedText'>
    We’re a dedicated group of innovative people.
    </typography>
  </Grid>
            <div 
            className='Container_sixteen'>
         <Grid item className="aboutus_nine">

                    <img src={Image1} className='HumanImage'/>
                    <typography className='HumanName'>Zad Ahmed</typography>
                    <typography  className='AboutusDetail'>Founder & CEO</typography>
                </Grid>
                <Grid item className="aboutus_nine">
                    <img src={Image2}  className='HumanImage'/>
                    <typography className='HumanName'>Howie Gherezghier</typography>
                    <typography  className='AboutusDetail'>Vice President of Sales</typography>
                </Grid>

                <Grid item className="aboutus_nine">
                    <img src={Image3}  className='HumanImage'/>
                    <typography className='HumanName'>Omar Abdullahi</typography>
                    <typography  className='AboutusDetail'>Marketing Intern</typography>
                </Grid>
                <Grid item className="aboutus_nine">
                    <img src={Image4}  className='HumanImage'/>
                    <typography className='HumanName'>Murtaza Islam</typography>
                    <typography  className='AboutusDetail'>System Analyst</typography>
                </Grid>
                </div>
            </Grid>
       
            <Grid item className="aboutus_ten" lg={12} xl={12} xs={12} sm={12} md={12}>

            <Grid>
                <typography className='studentsmainText'>Join our team</typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
            className='About_us_textdiv'
            sx={{paddingLeft:'20px'}}>
                <Grid sx={{paddingBottom:"30px"}}>
                <typography className='ExploreTextdec'>We believe it takes great people to make a great product. That’s why we hire not only the perfect professional fits, but people who embody our company values.</typography>
                </Grid>
                <Grid className="aboutus_eleven">

                    <a href='https://www.linkedin.com/company/applyupp/about/?viewAsMember=true' className='ExploreText'  target='_blank' rel='noopener noreferrer'>Explore openings</a>
                    <img style={{height:"fit-content"}} src={Arrow}/>
                </Grid>
            </Grid>
        </Grid>
       
       
       
        {/* <div className='Container_seventeen'>
 <UniLogos />
 </div> */}
        </div>
        </div>

        </Grid>
     
        <FooterMain/>
        </>
  )
}

export default Aboutus
