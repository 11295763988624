import React, { useState, useEffect, useRef  } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Grid, InputLabel } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import centerImage from "../../assets/img/loginbg.png";
import Google from "../../assets/img/Googelimge.png";
import "./signup.css";
import studentLoginImage from "../../assets/img/studentlogin.png";
import animationData from '../../assets/lottiefinal.json'; 
import Lottie from 'react-lottie';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import Alert from '@mui/material/Alert';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logo1 from "../../assets/img/logo.png";
import Line from "../../assets/img/Vector_line.png";
import MuiPhoneInput from "material-ui-phone-number";
// import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogContent";
import {  IconButton } from '@mui/material';
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber,parsePhoneNumber  } from "react-phone-number-input"; 
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import "react-phone-number-input/style.css";
import Cropper from "react-cropper";
import CloseIcon from '@mui/icons-material/Close';
import "cropperjs/dist/cropper.css";
import { getCroppedImg } from "./cropperUtils";
//import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
const defaultTheme = createTheme();

export default function Signupprofile() {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const [avatarImage, setAvatarImage] = useState(null);
  const [avatarImagefile, setAvatarImagefile] = useState();
  const [legalnameError, setLegalNameError] = React.useState(null);
  const [addressError, setAddressError] = React.useState(null);
  const [phoneError, setPhoneError] = React.useState(null);
  const [cityError, setCityError] = React.useState(null);
  const [ImageError, setImageError] = React.useState(null);
  const [registrationSuccess, setRegistrationSuccess] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [firstText, setFirstText] = useState("");
  const [secondtext, setSecondText] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [phoneValue, setPhoneValue] = useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorimageMessage, setErrorimageMessage] = useState('');
  const [isLoadingcrop, setisLoadingcrop] = React.useState(true);
  const [registrationError, setRegistrationError] = React.useState(null);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  

  const cropperRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  const cities = [
    "Aberdeen",
    "Abilene",
    "Aguada",
    "Aguas",
    "Aibonito",
    "Akron",
    "Albany",
    "Albuquerque",
    "Alexandria",
    "Allentown",
    "Alta",
    "Alto",
    "Amarillo",
    "Anaheim",
    "Anchorage",
    "Ann Arbor",
    "Antioch",
    "Apple Valley",
    "Appleton",
    "Arlington",
    "Arroyo",
    "Arvada",
    "Asheville",
    "Athens",
    "Atlanta",
    "Atlantic City",
    "Augusta",
    "Aurora",
    "Austin",
    "Baja",
    "Bakersfield",
    "Baltimore",
    "Barceloneta",
    "Barnstable",
    "Baton Rouge",
    "Bayamón",
    "Beaumont",
    "Bel Air",
    "Bellevue",
    "Berkeley",
    "Bethlehem",
    "Billings",
    "Birmingham",
    "Bloomington",
    "Boise",
    "Boise City",
    "Bonita Springs",
    "Boston",
    "Boulder",
    "Bradenton",
    "Bremerton",
    "Bridgeport",
    "Brighton",
    "Brownsville",
    "Bryan",
    "Buffalo",
    "Burbank",
    "Burlington",
    "Cambridge",
    "Camuy",
    "Canton",
    "Cape Coral",
    "Carolina",
    "Carrollton",
    "Cary",
    "Cathedral City",
    "Cayey",
    "Cedar Rapids",
    "Champaign",
    "Chandler",
    "Charleston",
    "Charlotte",
    "Chattanooga",
    "Chesapeake",
    "Chicago",
    "Chula Vista",
    "Ciales",
    "Cincinnati",
    "Clarke County",
    "Clarksville",
    "Clearwater",
    "Cleveland",
    "Coamo",
    "College Station",
    "Colorado Springs",
    "Columbia",
    "Columbus",
    "Concord",
    "Coral Springs",
    "Corona",
    "Corozal",
    "Corpus Christi",
    "Costa Mesa",
    "Dallas",
    "Daly City",
    "Danbury",
    "Davenport",
    "Davidson County",
    "Dayton",
    "Daytona Beach",
    "Deltona",
    "Denton",
    "Denver",
    "Des Moines",
    "Detroit",
    "Dorado",
    "Downey",
    "Duluth",
    "Durham",
    "Díaz",
    "El Monte",
    "El Paso",
    "Elizabeth",
    "Elk Grove",
    "Elkhart",
    "Erie",
    "Escondido",
    "Eugene",
    "Evansville",
    "Fairfield",
    "Fargo",
    "Fayetteville",
    "Fitchburg",
    "Flint",
    "Florida",
    "Fontana",
    "Fort Collins",
    "Fort Lauderdale",
    "Fort Smith",
    "Fort Walton Beach",
    "Fort Wayne",
    "Fort Worth",
    "Frederick",
    "Fremont",
    "Fresno",
    "Fullerton",
    "Gainesville",
    "Garden Grove",
    "Garland",
    "Gastonia",
    "Germán",
    "Gilbert",
    "Glendale",
    "Grand Prairie",
    "Grand Rapids",
    "Grayslake",
    "Green Bay",
    "GreenBay",
    "Greensboro",
    "Greenville",
    "Guayanilla",
    "Gulfport-Biloxi",
    "Gurabo",
    "Hagerstown",
    "Hampton",
    "Harlingen",
    "Harrisburg",
    "Hartford",
    "Hatillo",
    "Havre de Grace",
    "Hayward",
    "Hemet",
    "Henderson",
    "Hesperia",
    "Hialeah",
    "Hickory",
    "High Point",
    "Hollywood",
    "Honolulu",
    "Houma",
    "Houston",
    "Howell",
    "Humacao",
    "Huntington",
    "Huntington Beach",
    "Huntsville",
    "Independence",
    "Indianapolis",
    "Inglewood",
    "Irvine",
    "Irving",
    "Isabel",
    "Jackson",
    "Jacksonville",
    "Jayuya",
    "Jefferson",
    "Jersey City",
    "Johnson City",
    "Joliet",
    "Juan",
    "Kailua",
    "Kalamazoo",
    "Kaneohe",
    "Kansas City",
    "Kennewick",
    "Kenosha",
    "Killeen",
    "Kissimmee",
    "Knoxville",
    "Lacey",
    "Lafayette",
    "Lajas",
    "Lake Charles",
    "Lakeland",
    "Lakewood",
    "Lancaster",
    "Lansing",
    "Laredo",
    "Las",
    "Las",
    "Las Cruces",
    "Las Vegas",
    "Layton",
    "Leominster",
    "Lewisville",
    "Lexington",
    "Lincoln",
    "Little Rock",
    "Loiza",
    "Long Beach",
    "Lorain",
    "Lorenzo",
    "Los Angeles",
    "Louisville",
    "Lowell",
    "Lubbock",
    "Macon",
    "Madison",
    "Manatí",
    "Manchester",
    "Marina",
    "Marysville",
    "Maunabo",
    "McAllen",
    "McHenry",
    "Medford",
    "Melbourne",
    "Memphis",
    "Merced",
    "Mesa",
    "Mesquite",
    "Miami",
    "Milwaukee",
    "Minneapolis",
    "Miramar",
    "Mission Viejo",
    "Mobile",
    "Moca",
    "Modesto",
    "Monroe",
    "Monterey",
    "Montgomery",
    "Moreno Valley",
    "Murfreesboro",
    "Murrieta",
    "Muskegon",
    "Myrtle Beach",
    "Naguabo",
    "Naperville",
    "Naples",
    "Nashua",
    "Nashville",
    "New Bedford",
    "New Haven",
    "New London",
    "New Orleans",
    "New York",
    "New York City",
    "Newark",
    "Newburgh",
    "Newport News",
    "Norfolk",
    "Normal",
    "Norman",
    "North Charleston",
    "North Las Vegas",
    "North Port",
    "Norwalk",
    "Norwich",
    "Oakland",
    "Ocala",
    "Oceanside",
    "Odessa",
    "Ogden",
    "Oklahoma City",
    "Olathe",
    "Olympia",
    "Omaha",
    "Ontario",
    "Orange",
    "Orem",
    "Orlando",
    "Orocovis",
    "Overland Park",
    "Oxnard",
    "Palm Bay",
    "Palm Springs",
    "Palmdale",
    "Panama City",
    "Pasadena",
    "Paterson",
    "Pembroke Pines",
    "Pensacola",
    "Peoria",
    "Peñuelas",
    "Philadelphia",
    "Phoenix",
    "Pittsburgh",
    "Plano",
    "Pomona",
    "Pompano Beach",
    "Port Arthur",
    "Port Orange",
    "Port Saint Lucie",
    "Port St. Lucie",
    "Portland",
    "Portsmouth",
    "Poughkeepsie",
    "Providence",
    "Provo",
    "Pueblo",
    "Punta Gorda",
    "Quebradillas",
    "Racine",
    "Raleigh",
    "Rancho Cucamonga",
    "Reading",
    "Redding",
    "Reno",
    "Richland",
    "Richmond",
    "Richmond County",
    "Rio",
    "Riverside",
    "Roanoke",
    "Rochester",
    "Rockford",
    "Rojo",
    "Roseville",
    "Round Lake Beach",
    "Sabana",
    "Sacramento",
    "Saginaw",
    "Saint Louis",
    "Saint Paul",
    "Saint Petersburg",
    "Salem",
    "Salinas",
    "Salinas",
    "Salt Lake City",
    "San Antonio",
    "San Bernardino",
    "San Buenaventura",
    "San Diego",
    "San Francisco",
    "San Jose",
    "Santa Ana",
    "Santa Barbara",
    "Santa Clara",
    "Santa Clarita",
    "Santa Cruz",
    "Santa Maria",
    "Santa Rosa",
    "Sarasota",
    "Savannah",
    "Scottsdale",
    "Scranton",
    "Seaside",
    "Seattle",
    "Sebastian",
    "Sebastián",
    "Shreveport",
    "Simi Valley",
    "Sioux City",
    "Sioux Falls",
    "South Bend",
    "South Lyon",
    "Spartanburg",
    "Spokane",
    "Springdale",
    "Springfield",
    "St. Louis",
    "St. Paul",
    "St. Petersburg",
    "Stamford",
    "Sterling Heights",
    "Stockton",
    "Sunnyvale",
    "Syracuse",
    "Tacoma",
    "Tallahassee",
    "Tampa",
    "Temecula",
    "Tempe",
    "Thornton",
    "Thousand Oaks",
    "Toledo",
    "Topeka",
    "Torrance",
    "Trenton",
    "Tucson",
    "Tulsa",
    "Tuscaloosa",
    "Tyler",
    "Utica",
    "Vallejo",
    "Vancouver",
    "Vega",
    "Vega",
    "Vero Beach",
    "Victorville",
    "Vieques",
    "Virginia Beach",
    "Visalia",
    "Waco",
    "Warren",
    "Washington",
    "Waterbury",
    "Waterloo",
    "West Covina",
    "West Valley City",
    "Westminster",
    "Wichita",
    "Wilmington",
    "Winston",
    "Winter Haven",
    "Worcester",
    "Yabucoa",
    "Yakima",
    "Yauco",
    "Yonkers",
    "York",
    "Youngstown",
  ];
  const states = [
    "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Puerto Rico",
  "Guam",
  "U.S. Virgin Islands",
  "American Samoa",
  "Northern Mariana Islands",
  "International"
  ];
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");

  useEffect(() => {
    // Check if the type is 'collaborator' or 'student' and set the image source and login text accordingly
    if (type === "collaborator") {
      setFirstText("Earn money by");
      setSecondText("helping students");
      setBottomText("Collaborator");
    } else if (type === "student") {
      setFirstText("Apply to college");
      setSecondText("the smart way");
      setBottomText("Student");
    } else if (type === "null") {
      navigate("/welcome");
    } else if (type === "") {
      navigate("/welcome");
    }
  }, [type]);
  // Define a default image source
  let centerImageSrc = centerImage; // Default image

  // Check if the type is 'collaborator' or 'student' and set the image source and login text accordingly
  if (type === "collaborator") {
    centerImageSrc = centerImage; // Collaborator image
  } else if (type === "student") {
    centerImageSrc = centerImage; // Student image
  } else if (type === "null") {
    navigate("/welcome");
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const data = new FormData(event.currentTarget);
    const legalName = data.get('legal_name');
    const address = data.get('address');
  
    
   
   
    
    if (!age  || !legalName.trim() ||  !address.trim() ||  !avatarImagefile ) {
      // setCityError("Please enter your city.");
      setCityError("State required");
      setAddressError("Address required");
      setLegalNameError("Legal Name required");
     // setPhoneError("Phone number required");
      // setRegistrationError("All fields are required.");
      //setImageError("Please select an avatar image.");
      if (!avatarImagefile) {
        setImageError("Please select a profile picture.");
       
      }else{
        setImageError("");
      }
      if (!age) {
        setCityError("State required");
       
  
      }else{
        setCityError("");
      }
  
      if (!legalName.trim()) {
        setLegalNameError("Full name required");
        
  
      }else{
        setLegalNameError("");
      }
  
      if (!address.trim()) {
        setAddressError("Home address required");
        
  
      }else{
        setAddressError("");
      }
      if (!phoneValue) {
        
        setPhoneError('Please enter a valid phone number');
        
      }else{
        const isValidnumber = isValidPhoneNumber(phoneValue);
     
      if (!isValidnumber) {
        setPhoneError('Please enter a valid phone number');
        return;
      } else {
        setPhoneError('');
      }
        
      }

      return;
    }else{ 
      setCityError("");
      setAddressError("");
      setLegalNameError("");
      setRegistrationError("");
    }
   

    if (phoneValue) {
     
      const isValidnumber = isValidPhoneNumber(phoneValue);
     
      if (!isValidnumber) {
        setPhoneError('Please enter a valid phone number');
        return;
      } else {
        setPhoneError('');
      }
    }else{
      setPhoneError('Please enter a valid phone number');
      return;
    }

   
   
    data.append("city", age);
    data.append("number", phoneValue);
    data.append("avatarImage", avatarImagefile);
    const localStorageData = JSON.parse(localStorage.getItem("formData"));
    // if (localStorageData) {
    //   // Iterate over the properties in localStorageData and append them to the FormData object
    //   for (const key in localStorageData) {
    //     if (localStorageData.hasOwnProperty(key)) {
    //       data.append(key, localStorageData[key]);
    //     }
    //   }
    // }
    if (localStorageData) {
      // Iterate over the properties in localStorageData and append them to the FormData object
      for (const key in localStorageData) {
        if (Object.hasOwnProperty.call(localStorageData, key)) {
          data.append(key, localStorageData[key]);
        }
      }
    }
    const formData = {};
    for (const [key, value] of data.entries()) {
      formData[key] = value;
    }

    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

    try {
      setIsLoading(true);
      // Make a POST request to the API endpoint
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/registertwo`, {
        method: "post",
        body: data,
      });

      // Check the response status and handle it accordingly
      if (response.status === 201) {
        // Registration successful, you can handle the response data here
        const data = await response.json();
        setOpen(true);
        console.log("Registration successful");
        setRegistrationSuccess(data.message);
        setIsLoading(false);
        //history.push(`/signupprofile?type=${type}`);
        // You can redirect the user to a success page or perform other actions
      } else if (response.status === 422) {
        // Handle other response statuses or errors
        const data = await response.json();
        console.error("Registration failed:", data);
        setIsLoading(false); 
        if (data.error) {
          if (data.error.legal_name) {
            setLegalNameError(data.error.legal_name[0]);
          }
          if (data.error.address) {
            setAddressError(data.error.address[0]);
          }
          if (data.error.number) {
            setPhoneError(data.error.number[0]);
          }
        }
      }
    } catch (error) {
      setIsLoading(false); 
      // Handle network errors or other exceptions
      console.error("Registration failed:", error);
    }
  };

  const handleAvatarClick = () => {
    // Trigger a click event on the hidden file input
    document.getElementById("avatarInput").click();
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   console.log("File name:", file);
  //   console.log("File type:", file.type);
  //   console.log("File size:", file.size, "bytes");
  //   if (file) {
  //     // You can perform further processing here, e.g., upload the image to a server.
  //     // For now, we'll just set it as the avatar image.
  //     const imageUrl = URL.createObjectURL(file);
  //     setAvatarImage(imageUrl);
  //     setAvatarImagefile(file);
  //   }
  // };
  const [imageLoaded, setImageLoaded] = useState(true); 
  // useEffect(() => {
  //   // This will run after each render, including after the state has been updated
  //   setisLoadingcrop(true);
  //   alert(isLoadingcrop);
  // }, [isLoadingcrop]);
  
  

  const handlePhoneChange = (value) => {
    // const phoneNumberString = value.toString();
    // const phoneNumber = parsePhoneNumber(phoneNumberString);
    //   const isValid = isValidPhoneNumber(phoneNumber);
      
    //   if (!isValid) {
    //     setPhoneError('Please enter a valid phone number');
    //   } else {
    //     setPhoneError('');
    //     setPhoneValue(value);
    //   }
    setPhoneValue(value);

      
    
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = function (event) {
        const img = new Image();
  
        img.onload = function () {
          // Check the image dimensions
          if (img.width < 98 || img.height < 98) {
            setErrorimageMessage('Image dimensions should be greater than 98x98 pixels. Please select another image.');
            return; // Exit without proceeding
          }
  
      
        
  
          // Proceed with setting the image and opening the crop dialog
          setAvatarImagefile(file);
          const imageUrl = URL.createObjectURL(file);
          setAvatarImage(imageUrl);
          setImageLoaded(true);
          setIsCropDialogOpen(true);
          console.log('Image loaded successfully.');
        };
   // Use a state variable to track whether the image has finished loading
      
  
      // Create an image element
      // const image = new Image();
      // image.src = imageUrl;
  
      // // Set up a callback for the image onLoad event
      // image.onload = () => {
      //   setImageLoaded(true);
      // };
        img.src = event.target.result;
      };
  
      reader.readAsDataURL(file);
    }
  };
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //  // setisLoadingcrop(false);
  //   if (file) {
  //     if (file.size < 98 * 1024) {
  //       // File size is less than 98 KB (98 * 1024 bytes)
  //       alert('Image size should be greater than 98 KB. Please select another image.');
  //       return false; // Return false to prevent further actions
  //     }
  //     setAvatarImagefile(file);
  //     const imageUrl = URL.createObjectURL(file);
  //     setAvatarImage(imageUrl);
  //     setImageLoaded(true);
  //     setIsCropDialogOpen(true);
  //     console.log('Image loaded successfully.');

      
      
   
  //     // Use a state variable to track whether the image has finished loading
      
  
  //     // Create an image element
  //     // const image = new Image();
  //     // image.src = imageUrl;
  
  //     // // Set up a callback for the image onLoad event
  //     // image.onload = () => {
  //     //   setImageLoaded(true);
  //     // };
  //   }
  // };
  const handleCrop = async () => {
     console.log(imageLoaded,"crop code status ");
    // Get the cropped image data
    if (cropperRef.current && imageLoaded) {
      const imageElement = cropperRef.current;
      const cropper = imageElement.cropper;
  
      // Get the cropped data synchronously
      const croppedData = cropper.getData();
  
      // Get the cropped image asynchronously
      cropper.getCroppedCanvas().toBlob(async (croppedImageBlob) => {
        // Convert the blob to a data URL
        const croppedImage = URL.createObjectURL(croppedImageBlob);
  
        // Update the state with the cropped image
        setAvatarImagefile(croppedImageBlob);
        setAvatarImage(croppedImage);
        setIsCropDialogOpen(false);
  
        // Now you can send the croppedImage to your server or use it as needed
        console.log("Cropped Image Blob:", croppedImageBlob);
      });
    } else {
      setImageLoaded(true);
      console.log("Cropper not initialized or image not loaded");
    }
  };
  const handleCloseDialog = () => {
    setIsCropDialogOpen(false);
    setAvatarImage(null); // Reset the image state when closing the dialog
  };

  useEffect(() => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      
      // Assuming you have some way of obtaining the user data, either from context, props, or localStorage
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
  
      fetch(`${apiUrl}/get_all_profile_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      })
      .then(response => {
        if (response.ok) {
          return response.json(); // Convert the response to JSON
        } else {
          throw new Error('Request failed');
        }
      })
      .then(data => {
        // Handle the data from the successful response
        console.log('Profile data:', data);
        // TODO: Update state or perform other actions with the received data
      })
      .catch(error => {
        console.error('Error fetching profile data:', error.message);
        // TODO: Handle the error, show an error message, or perform other actions
      });
    } catch (error) {
      console.error('Error in useEffect:', error);
      // TODO: Handle unexpected errors in the useEffect hook
    }
  }, []); // Empty dependency array means this effect runs once after the initial render
  
  const [responseMessage, setResponseMessage] = useState(null);

  const handleResendClick = async () => {
    try {
       const apiUrl = process.env.REACT_APP_API_URL;
 
       const response = await fetch(`${apiUrl}/resend`, {
          method: "POST",
       });
 
       const responseData = await response.json();
 
       // Log more details
       console.log('Response status:', response.status);
       console.log('Response data:', responseData);
 
       if (response.ok) {
        console.log('Response is ok');
        setResponseMessage(responseData.message);
     } else {
        console.log('Response is not ok');
        console.log('Response status:', response.status);
        console.log('Response data:', responseData);
        setResponseMessage(responseData.error || 'Error occurred.');
     }
  } catch (error) {
     console.error('Error fetching API:', error);
     setResponseMessage('Error occurred. Please try again.');
  }
};
 
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        className="main_container_login"
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
      >
        <CssBaseline />
        <Grid
          item
          lg={4}
          xl={4}
          xs={false}
          sm={4}
          md={5.5}
          className="Login_Grid"
        >
          <Box></Box>
          <Box className="Login_text_area">
            <div>
              <img src={Logo1} alt="Logo1" className="Login_logo_img" />
              <div className="login_text_div">
                <typography className="Login_side_text">{firstText}</typography>
              </div>
              <div>
                <typography className="Login_side_text">
                  {secondtext}
                </typography>
              </div>
            </div>
          </Box>
          <Box className="Login_Bottom_box">
            <div className="Login_Bottom_div">
              <img src={Line} alt="Line" className="Login_Bottom_img" />
              <typography className="Login_Bottom_text">
                {bottomText}
              </typography>
            </div>
          </Box>
        </Grid>
        <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{display:"flex",justifyContent:"center",paddingBottom:"35px",paddingTop:"30px"}}>
            <Link to={`/login?type=${type}`} style={{ textDecoration: "none" }}>
              {" "}
              <Button  variant ="outlined"onClick={handleClose}>Back To Login</Button>
            </Link>
              {/* <Button  variant ="outlined"onClick={handleResendClick}>Resend</Button> */}
          </DialogActions>
        </Dialog>
        <Grid
          item
          lg={8}
          xl={8}
          xs={12}
          sm={8}
          md={6.5}
          component={Paper}
          elevation={4}
          className="Login_Textfield_grid"
        >
          <Box className="Login_Textfiled_Box">
            <Grid item lg={6.69} xl={8} xs={10} sm={10} md={10}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                className="Login_Form_Textfield"
              >
                <div className="Login_header_text">
                  <typography className="Login_Welcome">
                    Personal Information
                  </typography>
                  <typography className="Login_Welcome_text">
                    Enter your personal information below
                  </typography>
                            {avatarImage && (
                              <div>
                               <Dialog open={isCropDialogOpen} onClose={handleCloseDialog} >
                               <DialogTitle>Crop Image
                               <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              style={{ position: 'absolute', top: 5, right: 5 }}
            >
              <CloseIcon />
            </IconButton>
                               </DialogTitle>
                               <DialogContent>
                                 <Cropper
                                   ref={cropperRef}
                                   src={avatarImage}
                                   style={{ height: "80%", width: "80%", }}
                                   aspectRatio={1}
                                   guides={true}
                                   dragMode="none"
                                   zoomOnWheel={false}
                                 />
                                 <div style={{display:"flex",justifyContent:"center"}}>
                                 <Button variant="contained" sx={{marginTop:"16px",justifyContent:"center"}} onClick={handleCrop}>Crop Image</Button></div>
                               </DialogContent>
                             </Dialog>
                           </div>
                  // <div>
                  //   <Cropper
                  //     ref={cropperRef}
                  //     src={avatarImage}
                  //     style={{ height: 300, width: "100%" }}
                  //     aspectRatio={1}
                  //     guides={true}
                  //     dragMode="move"
                  //   />
                  //   <button type="button" onClick={handleCrop}>
                  //     Crop Image
                  //   </button>
                  // </div>
                )}
                  <div style={{ display: "inline-flex" ,cursor:'pointer'}}>
                  
                    <label htmlFor="avatarInput" >
                      <Avatar className="Signup_avatar_size">
                        {avatarImage ? (
                          <img
                          onLoad={() => setImageLoaded(true)}
                            src={avatarImage}
                            alt="Avatar"
                            className="Signup_Avatar_image"
                          />
                        ) : (
                          "+"
                        )}
                      </Avatar>
                    </label>
                    <input
                      type="file"
                      id="avatarInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      onClick={handleAvatarClick}
                    />
                  </div>

                  <typography className="Profile_upload_text">
                    upload Profile Photo
                  </typography>
                  {ImageError && (
                    <div className="error-message">{ImageError}</div>
                  )}
                </div>
                 {registrationError && (
                  <div className="error-message">{registrationError}</div>
                )}
                 {errorimageMessage && (
        <Alert severity="error" onClose={() => setErrorimageMessage('')}>
          {errorimageMessage}
        </Alert>
      )}
                <TextField
  fullWidth
  size="small"
  style={{ marginTop: 16, marginBottom: 8 }}
  required
  id="name"
  label="Legal Name"
  name="legal_name"
  autoComplete="name"
  autoFocus
  error={Boolean(legalnameError)}
  helperText={legalnameError}
/>

                <MuiPhoneInput
                  defaultCountry={"us"}
                  size="small"
                  fullWidth
                  variant="outlined"
                  //onChange={setPhoneValue}
                  onChange={handlePhoneChange}
                  style={{ marginTop: 16, marginBottom: 8 }}
                  error={Boolean(phoneError)}
                  helperText={phoneError}
                />
                {/* {phoneError && (
                  <div className="error-message">{phoneError}</div>
                )} */}
                <TextField
                  fullWidth
                  size="small"
                  margin="normal"
                  required
                  name="address"
                  label="Address"
                  type="adrress"
                  id="address"
                  autoComplete="address"
                  error={Boolean(addressError)}
                  helperText={addressError}
                  style={{ marginTop: 16, marginBottom: 8 }}
                />
                <TextField
                  size="small"
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  select
                  label="State"
                  value={age}
                  helperText={cityError}
                  onChange={handleChange}
                  error={cityError}
                  style={{ marginTop: 16, marginBottom: 8 }}
                >
                  {states.map((stateName, index) => (
                    <MenuItem key={index} value={stateName}>
                      {stateName}
                    </MenuItem>
                  ))}
                </TextField>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="Login_button"
                  style={{ boxShadow: "none" }}
                  disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? (
                      // Display the circular loading icon
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      // Display the "SUBMIT" text
                      "SUBMIT"
                    )}
                </Button>
                {/* </Link> */}
                <Grid container className="Login_Textfield_Last_grid">
                  <Grid item>
                    <p className="Welcome__already">
                      Already have an account?{" "}
                      <Link
                        to={`/login?type=${type}`}
                        style={{ textDecoration: "none" }}
                      >
                        <typography className="Signup_text">Login</typography>
                      </Link>{" "}
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
