import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Topbar from './Topbar';
import backgroundimage from '../../assets/img/bg-img.png';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json';
import { useLocation } from 'react-router-dom';
import './Students.css';

const pricingPlans = [
  {
    name: 'Monthly',
    description: 'Billed Monthly',
    price: 9.99,
    features: ['2 team members', '1,000 Contacts & Companies', '5 Campaign Workflows', '5 Nodes Per Campaign', '2 Automation Rules (Triggers)'],
    buttonText: 'Get Started Now',
    backgroundColor: '#006CB7',
    planid: 'price_1OSZieAsIPOfuwS4mjEPnSeQ',
  },
  {
    name: 'Semesterly',
    description: 'Billed every 6 months',
    price: 34.99,
    features: ['10 team members', '2,500 Contacts & Companies', '5 Campaign Workflows', '10 Nodes Per Campaign', '5 Automation Rules (Triggers)'],
    buttonText: 'Get Started Now',
    backgroundColor: '#EF6C00',
    planid: 'price_1OSZiWAsIPOfuwS4QugJj7aP',
  },
  {
    name: '4 Years',
    description: 'One-time purchase',
    price: 149.99,
    features: ['Unlimited team members', '5,000 Contacts & Companies', '25 Campaign Workflows', '20 Nodes Per Campaign', '15 Automation Rules (Triggers)'],
    buttonText: 'Get Started Now',
    backgroundColor: '#006CB7',
    planid: 'price_1OXL59AsIPOfuwS42OalgPaQ',
  },
];

const Payment_success = () => {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planIdFromURL = searchParams.get('planid'); // Assuming 'planid' is the parameter name in your URL

  const selectedPlan = pricingPlans.find((plan) => plan.planid === planIdFromURL);
  console.log('planIdFromURL:', planIdFromURL);

  // Get the plan name or a default value if not found
  const planName = selectedPlan ? selectedPlan.name : 'Unknown Plan';
  const planPaid = selectedPlan ? selectedPlan.price : 'Unknown Plan';
  console.log('planIdFromURL:', planIdFromURL);


  return (
    <div>
      <Topbar />
      <Grid
  container
  lg={12}
  xl={12}
  xs={12}
  sm={12}
  md={12}
  sx={{
    padding: '20px',
    minHeight: '100vh',
    backgroundImage: `url(${backgroundimage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: (t) =>
      t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  }}
>
  <Grid
    item
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    sx={{
      background: 'rgba(255, 255, 255, 0.80)',
      backdropFilter: 'blur(25px)',
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', maxWidth: '600px' }}>
      <Lottie options={defaultOptions} height={200} width={200} />
      <typography className="payment_text">Payment Confirmed</typography>
      <typography className="payment_desc">Your payment is successful, now you can take advantage of ApplyUpp Platform</typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <typography className="subscription_text">Subscription Plan</typography>
        <typography className="subscription_text">{planName}</typography>
      </div>
      <Divider variant="inset" style={{ margin: '16px 0', width: '100%' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <typography className="subscription_text">Payment method</typography>
        <typography className="subscription_text">Stripe</typography>
      </div>
      <Divider variant="inset" style={{ margin: '16px 0', width: '100%' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '40px' }}>
        <typography className="Amount_text">Amount Paid</typography>
        <typography className="Amount_text">{planPaid}</typography>
      </div>
      <Link to={`/Student`} sx={{ textDecoration: "none" }}>
        <Button variant="contained" sx={{ boxShadow: 'none', width: '100%'  }}>
          GO TO HOME PAGE
        </Button>
      </Link>
    </div>
  </Grid>
</Grid>

    </div>
  );
};

export default Payment_success;
