import React, { useState,useEffect  } from 'react'
import Grid from '@mui/material/Grid';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography, Tabs, Tab,InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useLocation,useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';

import './Student_profile.css'
import Topbar from '../Topbar';
import { Topbar as Topbar2 } from '../../Admin/Topbar';

const Student_work_experience= () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const urlParams = new URLSearchParams(window.location.search);
  const urlType = urlParams.get('type');
  const userid = urlParams.get('userid');
  function encodeAlphanumeric(userid) {
    const encoded = btoa(userid); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  }
    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const isSmallScreen = useMediaQuery('(max-width:680px)');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  const location = useLocation();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={urlType === 'admin' ? `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}` : `/student_complete_profile`}
     
    >
      Profile
    </Link>,
 
    <Typography key="3" color="text.primary">
    Work Experience
    </Typography>
  ];
  const [value, setValue] = React.useState(0);

    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    // };
    const [age, setAge] = React.useState('');
    const [awardBoxes, setAwardBoxes] = useState([{}]);
    const [TestBoxes, setTestBoxes] = useState([{}]);
    const [Erroractivities,setErroractivities] = useState('');
    const [Errorexperience,setErrorexperience] = useState('');
    const [Errorhours,setErrorhours] = useState('');
    // const [showAlert, setShowAlert] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [formid, setformid] = useState('');
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [formdata, setFormData]=useState({
      volunteer_hours: '',
    })
const[activityData,setActivityData]=useState('')
    const handleChanges = (event) => {
      setAge(event.target.value);
    };
    const [selectedValue, setSelectedValue] = useState('');

  // Handle radio button change event
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  // const handleVolunteerHoursChange = (event) => {
  //   const newValue = event.target.value;

  //   setFormData({ ...formdata, volunteer_hours: newValue });
  // };
  const handleVolunteerHoursChange = (event) => {
    const inputValue = event.target.value;

  // Allow only alphanumeric characters and limit to 10 characters
  const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 4);

  // Check if the sanitized value exceeds the limit
  if (sanitizedValue.length === 4) {
    setErrorhours('Exceeded the limit of 4 characters for Volunteer Hours.');
  } else {
    setErrorhours(''); // Clear the error if within the limit
  }

  // Update the state with the sanitized value
  setFormData({ ...formdata, volunteer_hours: sanitizedValue });
    // const newValue = event.target.value;

    // setFormData({ ...formdata, volunteer_hours: newValue });
  };
  useEffect(() => {
  
     
    const searchParams = new URLSearchParams(location.search);
    const selectedActivityId = searchParams.get('id'); //change this const to form id 
     setformid(selectedActivityId);
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;
    console.log('Activity Id:', formid); 

    if(selectedActivityId !== ''){
      try {
      fetch(`${apiUrl}/get_student_work_experiemce_form_data`, {   // chgange this url 
        method: 'post',
        body: JSON.stringify({ formid:selectedActivityId }), // pass the form id const here 
    })
    .then(response => {
        if (response.status === 200) {
            return response.json(); // Convert the response to JSON
        } else {
            throw new Error('Request failed');
        }
    })
    .then(data => {
      const personal_eassaydata = data.formdata[0];
     
      setActivityData(data.formdata);                     
        console.log(data, "setPersonalEssayData"); 
        setFormData({
          volunteer_hours: personal_eassaydata.volunteer_hours|| '',
         
        });
      
           // console.log(parsedTestData,"parsedTestData")
        // console.log(formDataArray,"formDataArray,AWards")
        const parsedExperienceData = JSON.parse(personal_eassaydata.work_experience|| '[]');
         const formExperienceData = parsedExperienceData.map((item, index) => ({
          'Title': item[`Title-${index}`] || '',
          'organization-name': item[`organization-name-${index}`] || '',
          'job-description': item[`job-description${index}`] || '',
          'description': item[`description-${index}`] || '',
          'job-type': item[`job-type-${index}`] || '',
          'options': item[`options-${index}`] || '',
          'start-date': dayjs(item[`start-date-${index}`]).format('MM/DD/YYYY') || '',
          'end-date': dayjs(item[`end-date-${index}`]).format('MM/DD/YYYY') || '',


        }));
        setTestBoxes(parsedExperienceData)
     console.log(formExperienceData,"parsedExperienceData")
    })
    .catch(error => {
        console.error('Data fetch failed:', error);
    });
} catch (error) {
    console.error('Data upload failed:', error);
}
    }

    
}, []); 
const preprocessData = (data) => {
  return data.map((item) => {
    const processedItem = {};
    Object.keys(item).forEach((key) => {
      const processedKey = key.replace(/-\d+$/, ''); // Remove the index suffix
      processedItem[processedKey] = item[key];
    });
    return processedItem;
  });
};
const preprocessAndValidateDates = (data) => {
  return data.map((experience, index) => {
    const startDate = new Date(experience[`start-date-${index}`]);
    const endDate = new Date(experience[`end-date-${index}`]);
   
    // Validate start date is less than end date
    if (startDate >= endDate) {
      
      setErrorexperience(`Invalid date range in experience box ${index + 1}. Please ensure the start date is before the end date.`);
      return null; // Invalid data, return null
    }else{
      setErrorexperience('');
      return Object.values(experience).sort().join('');
    }

    // Preprocess and sort values
    //return Object.values(experience).sort().join('');
  });
};

  const navigate = useNavigate(); // Initialize navigate
  const moveToNextTab = async () => {
    if (urlType !== 'admin') {
    if(value === 0){
      const experiencedata = TestBoxes;
      console.log(experiencedata);
      const hasEmptyField = experiencedata.some((experience, index) => {
        if(experience[`options-${index}`]==='yes'){
          return !experience[`Title-${index}`] || !experience[`job-type-${index}`] || !experience[`organization-name-${index}`] || !experience[`start-date-${index}`]  || !experience[`job-description-${index}`];
        }else{
          return !experience[`Title-${index}`] || !experience[`job-type-${index}`] || !experience[`options-${index}`] || !experience[`organization-name-${index}`] || !experience[`start-date-${index}`]  || !experience[`end-date-${index}`] || !experience[`job-description-${index}`];
        }
      });
      
      if (hasEmptyField) {
        setErrorexperience('All fields are mandatory');
        return false;
      }
      const isAnyFieldFilled = experiencedata.some((experience) =>
          Object.values(experience).some((value) => value !== undefined && value !== '')
        );
        
        if (isAnyFieldFilled) {
          // Check if all fields are filled
          const hasEmptyField = experiencedata.some((experience, index) => {
            if(experience[`options-${index}`]==='yes'){
              return !experience[`Title-${index}`] || !experience[`job-type-${index}`] || !experience[`organization-name-${index}`] || !experience[`start-date-${index}`]  || !experience[`job-description-${index}`] ;
              }else{
                return !experience[`Title-${index}`] || !experience[`job-type-${index}`] || !experience[`organization-name-${index}`] || !experience[`start-date-${index}`]  || !experience[`end-date-${index}`] || !experience[`job-description-${index}`] ;
              }
          });
          
          if (hasEmptyField) {
            setErrorexperience('All fields are mandatory');
            return false;
          }
          
          if(experiencedata[0][`options-0`] === 'no'){ 
          const processedexperiencedatadatecheck = preprocessAndValidateDates(experiencedata);

          if (processedexperiencedatadatecheck.includes(null)) {
            // Handle the case where an invalid date range is detected
            // (e.g., show an error message or prevent further processing)
            return false;
          }
        }

          const processedexperiencedata = preprocessData(experiencedata);

          const isDuplicatexperience = processedexperiencedata.some((current, index) =>
            processedexperiencedata.slice(index + 1).some((other) => {
              const currentValues = Object.values(current).sort().join('');
              const otherValues = Object.values(other).sort().join('');
              return currentValues === otherValues;
            })
          );
          if (isDuplicatexperience) {
      
              // Awards contain duplicates
              setErrorexperience('Duplicate data. Please enter unique values for each experience box field.');
              return false;
            }
        } else {
          // No fields are filled, no validation needed
          setErrorexperience('');
        }
       
      // if (experiencedata.some((experience) => 'start-date-0' in experience || 'end-date-0' in experience)) {
      //   setErrorexperience('');
      // } else {
      //   // No recommender has data
      //   setErrorexperience('Please add at least one Experience');
      //   return false;
      // }
      
      
    }

    if (value === 1) {
      const volunteer_hours = document.getElementsByName('volunteer_hours')[0].value;
        // if (volunteer_hours.trim() === '' ) {
        //   setErrorhours('Hours required');
          
        //   return false;
        // }else{
        //   setErrorhours('');
        // }
    
      try {
        setIsLoading(true);
        setOpen(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
        const response = await fetch(`${apiUrl}/save_student_work_experience`, {
          method: 'post',
          body: JSON.stringify({ TestBoxes,  userId, volunteer_hours, formid}), // Send the entire formData object as the body
        });
        // setShowAlert(true);
        if (response.status === 201) {
         
              setShouldNavigate(true);
        } else {
          console.error('Data upload failed:', response.statusText);
        }
      } catch (error) {
        setIsLoading(false);
        setOpen(false);
        console.error('Data upload failed:', error);
      }
      // If on the last tab, navigate to "/complete_profile" route
      //navigate('/complete_profile');
    } else {
      // Increment the value state to move to the next tab
      setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
    }
  }
  else if(urlType == 'admin'){
    if (value === 0) {
      // Move to the next tab logic (if any)
      setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
    }
    else if (value === 1 ) {
      window.location.href = `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}`;
      // setShouldNavigate(true); // Assuming this triggers navigation to a different page
    }
  }
  };
  const handleBackButton = () => {
    if (value === 0) {
      if (urlType === 'admin') {
        // If on the first tab and Type is admin, navigate to admin profile view
        navigate(`/Admin_student_profileView?id=${encodeAlphanumeric(userid)}`);
      } else {
        // If on the first tab and Type is not admin, navigate to "/complete_profile" route
        navigate('/student_complete_profile');
      }
    } else {
      // Decrement the value state to move to the previous tab
      setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
    }
  };
  // const handleBackButton = () => {
  //   if (value === 0) {
  //     // If on the first tab, navigate to "/complete_profile" route
  //     navigate('/student_complete_profile');
  //   } else {
  //     // Decrement the value state to move to the previous tab
  //     setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
  //   }
  // };
  const addAnotherAwardBox = () => {
    //setAwardBoxes([...awardBoxes, {}]);
    const lastAwardBox = awardBoxes[awardBoxes.length - 1];

    // Check if the last award box is not empty
    const isLastAwardBoxEmpty = Object.values(lastAwardBox).every(
      (value) => value === undefined || value === ''
    );
  
    if (!isLastAwardBoxEmpty) {
      // Add a new empty award box
      setAwardBoxes([...awardBoxes, {}]);
      setErroractivities('');
    } else {
      setErroractivities('Please fill in all fields before adding another award box.');
    }
    

  };
  // const addAnotherAwardBox = () => {
  //   setAwardBoxes([...awardBoxes, {}]);
  // };
  // const addAnotherTestBox = () => {
  //   setTestBoxes([...TestBoxes, {}]);
  // };
  const addAnotherTestBox = () => {
    // setTestBoxes([...TestBoxes, {}]);
    const lastTestBox = TestBoxes[TestBoxes.length - 1];
 
    // Check if the last test box is not empty
    const isLastTestBoxEmpty = Object.values(lastTestBox).every(
      (value) => value === undefined || value === ''
    );
  
    if (!isLastTestBoxEmpty) {
      // Add a new empty test box
      setTestBoxes([...TestBoxes, {}]);
      setErrorexperience('');
    } else {
     setErrorexperience('Please fill in all fields before adding another test box.');
    }
   };
   const handleActivitiesChange = (event, index) => {
    const { name, value } = event.target;
    const updactivities = [...awardBoxes];
    let highSchoolNameError = '';
    let descriptionError = '';

    if (name.includes("high-school-name")) {
      // Allow only alphabets, spaces, and limit to 50 characters
      const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      updactivities[index][name] = sanitizedValue;

      if (sanitizedValue.length === 50) {
        highSchoolNameError = 'Exceeded the limit of 50 characters for High School Name.';
      }
    } else if (name.includes("description")) {
      // Allow only alphabets, spaces, dots, hyphens, and limit to 150 characters
     // const sanitizedValue = value.replace(/[^a-zA-Z\s.-]/g, '').slice(0, 150);
     const words = value.split(/\s+/); // Split the input into words
      const sanitizedValue = words.slice(0, 150).join(' '); 
      const wordCount = sanitizedValue.split(/\s+/).length;
      updactivities[index][name] = sanitizedValue;

      if (wordCount === 150) {
        descriptionError = 'Exceeded the limit of 150 words for Description.';
      }
    } else {
      updactivities[index][name] = value;
    }
    setAwardBoxes(updactivities);
    setErroractivities(highSchoolNameError || descriptionError);
    // updactivities[index][name] = value;
    // console.log(updactivities);
    // setAwardBoxes(updactivities);
  };
  // const handleActivitiesChange = (event, index) => {
  //   const { name, value } = event.target;
  //   const updactivities = [...awardBoxes];
  //   updactivities[index][name] = value;
  //   console.log(updactivities);
  //   setAwardBoxes(updactivities);
  // };
  const handlexperienceChange = (event, index) => {
    const { name, value } = event.target;
    console.log('name:', name);
    console.log('value:', value);
    const updatedExperience = [...TestBoxes];
    let titleError = '';
    let organizationNameError = '';
    let jobDescriptionError = '';

    if (name.includes("Title-")) {
      // Allow only alphabets and spaces, and limit to 50 characters
      // const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '');
      // updatedExperience[index][name] = sanitizedValue;
      updatedExperience[index][name] = value;
      // if (sanitizedValue.length === 50) {
      //   titleError = 'Exceeded the limit of 50 characters for Role/Title.';
      // }
    } else if (name.includes("organization-name-")) {
      // Allow only alphabets, spaces, and limit to 75 characters
      // const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '').slice(0, 75);
      // updatedExperience[index][name] = sanitizedValue;
      updatedExperience[index][name] = value;

      // if (sanitizedValue.length === 75) {
      //   organizationNameError = 'Exceeded the limit of 75 characters for Organization Name.';
      // }
    } else if (name.includes("job-description-")) {
      // Allow alphabets, spaces, dots, hyphens, and limit to 150 characters
     // const sanitizedValue = value.replace(/[^a-zA-Z\s.-]/g, '').slice(0, 150);
     const words = value.split(/\s+/); // Split the input into words
      const sanitizedValue = words.slice(0, 15000).join(' '); 
      const wordCount = sanitizedValue.split(/\s+/).length;
      updatedExperience[index][name] = sanitizedValue;

      if (wordCount === 15000) {
        jobDescriptionError = 'Exceeded the limit of 150 words for Job Description.';
      }
    }else if (name.includes("options-")) {
      // Handle options like "options-0", "options-1", etc.
      updatedExperience[index][name] = value;
    } else {
      updatedExperience[index][name] = value;
    }
    setTestBoxes(updatedExperience);
    setErrorexperience(titleError || organizationNameError || jobDescriptionError);
    // const updateexperience = [...TestBoxes];
    // updateexperience[index][name] = value;
    // console.log(updateexperience);
    // setTestBoxes(updateexperience);
  };
  // const handlexperienceChange = (event, index) => {
  //   const { name, value } = event.target;
  //   const updateexperience = [...TestBoxes];
  //   updateexperience[index][name] = value;
  //   console.log(updateexperience);
  //   setTestBoxes(updateexperience);
  // };
  const handleDateChange = (date, fieldName, index) => {
    const updatedTest = [...TestBoxes];
    updatedTest[index][fieldName] = date; // Assuming 'fieldName' is the date field name
    console.log(updatedTest);
    setTestBoxes(updatedTest);
  };
  useEffect(() => {
    if (shouldNavigate) {
      const navigateTimer = setTimeout(() => {
        // Navigate to the desired page after 2 seconds
        // setShowAlert(false);
        setIsLoading(true);
        setOpen(true);
        navigate('/student_complete_profile');
      }, 3000);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(navigateTimer);
    }
  }, [shouldNavigate]);
  return (
    <div>
    {/* <Topbar /> */}
    {urlType === 'admin' ? <Topbar2 /> :  <Topbar />}

    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "20px" }}
      >
                     <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
            <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
        
        
     
        <Box className="Test_BoxDiv Test_paddingBox2">
        <Box className="student_activities_form" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} aria-label="basic tabs example"
            sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-between', // This adds space between the tabs
                },
                // ...(isSmallScreen && {
                //   '& .css-heg063-MuiTabs-flexContainer': {
                //     overflow: 'scroll',
                //     // Add other styles you want to apply to .css-heg063-MuiTabs-flexContainer
                //   },
                // }),
              }}>
              {/* <Tab label="SPORTS " /> */}
              <Tab label="WORK EXPERIENCES" />
              <Tab label="VOLUNTEER HOURS" />
        
            </Tabs>
          </Box>
         

<div role="tabpanel" hidden={value !== 0}>
  {TestBoxes.map((box, index) => (
    <Box key={index} className='Test_paddingBox2' sx={{ mt: 2 }}>
      <Box className="duplicate_box">
        <div className='Test_FlexRow_workExperience'>
          <TextField
          disabled={urlType=== 'admin'}
            id={`rank-in-class-${index}`}
            name={`Title-${index}`}
            label="Role/Title *"
            variant="outlined"
            fullWidth
            size="small"
            sx={{ mb: 2 }}
            value={box[`Title-${index}`] || box['Title'] || ''}
            onChange={(event) => handlexperienceChange(event, index)}
          />
          <FormControl fullWidth size="small" sx={{ mb: 2 }}>
            <InputLabel id={`out-of-label-${index}`}>Job Type *</InputLabel>
            <Select
             disabled={urlType=== 'admin'}
              labelId={`out-of-label-${index}`}
              id={`out-of-select-${index}`}
              name={`job-type-${index}`}
              label="Job Type *"
            value={box[`job-type-${index}`] || box['job-type'] || ''}

              onChange={(event) => handlexperienceChange(event, index)}
            >
              <MenuItem value={'Internship'}>Internship</MenuItem>
              <MenuItem value={'Community Service'}>Community Service (Volunteer)</MenuItem>
              <MenuItem value={'Self-employed'}>Self-employed</MenuItem>
              <MenuItem value={'Freelance'}>Freelance</MenuItem>
              <MenuItem value={'Contract'}>Contract</MenuItem>
              <MenuItem value={'Apprenticeship'}>Apprenticeship</MenuItem>
              <MenuItem value={'Seasonal'}>Seasonal</MenuItem>
              <MenuItem value={'Part Time'}>Part Time</MenuItem>
              <MenuItem value={'Full Time'}>Full Time</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className='Test_FlexRow'>
          <TextField
           disabled={urlType=== 'admin'}
            id={`rank-in-class-${index}`}
            name={`organization-name-${index}`}
            label="Organization Name *"
            variant="outlined"
            fullWidth
            size="small"
            value={box[`organization-name-${index}`] || box['organization-name'] || ''}

            onChange={(event) => handlexperienceChange(event, index)}
          />
         
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
             disabled={urlType=== 'admin'}
              id={`start-date-${index}`}
              name={`start-date-${index}`}
              label="Start date *"
              size="small"
              value={
                box[`start-date-${index}`]
                  ? dayjs(box[`start-date-${index}`]) // Treat the date as UTC
                  : null
              }
              
              sx={{
                width: "100%",
                '& .MuiInputLabel-root': {
                  top: '-6px', // Center align the label
                },
                '& .MuiInputBase-root': {
                  height: '2.5rem',
                },
              }}
              maxDate={dayjs()}
              onChange={(date) =>
                handleDateChange(date, `start-date-${index}`, index) // Pass the index and name to handleDateChange
              }
            />
          </LocalizationProvider>
        </div>
        <div className='Test_paddingDiv'>
        <div className='Test_paddingDiv'>
                <Typography>Currently working? *</Typography>
                <div>
                  <RadioGroup
                   disabled={urlType=== 'admin'}
                    aria-label={`Options-${index}`}
                    name={`options-${index}`}
                    value={box[`options-${index}`] || box['options'] || ''}
                    onChange={(event) => handlexperienceChange(event, index)}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel   disabled={urlType=== 'admin'} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel   disabled={urlType=== 'admin'} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
              </div>
              <div className='Test_paddingDiv'></div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
             disabled={urlType=== 'admin'}
              className='Activities_endDate'
              id={`end-date-${index}`}
              name={`end-date-${index}`}
              label="End date *"
              size="small"
              value={
                box[`end-date-${index}`]
                  ? dayjs(box[`end-date-${index}`])
                  : null
              }
              sx={{
                width: "48.6%",
                display: box[`options-${index}`] === 'yes' ? 'none' : 'block',
                '& .MuiInputLabel-root': {
                  top: '-6px', // Center align the label
                },
                '& .MuiInputBase-root': {
                  height: '2.5rem',
                },
              }}
              maxDate={dayjs()}
              onChange={(date) =>
                handleDateChange(date, `end-date-${index}`, index) // Pass the index and name to handleDateChange
              }
            />
          </LocalizationProvider>
      
        </div>
        <div className='Test_paddingDiv'>
          <TextField
           disabled={urlType=== 'admin'}
            id={`job-description-${index}`}
            name={`job-description-${index}`}
            label="Job Description *"
            variant="outlined"
            fullWidth
            minRows={3}
            multiline
            size="small"
            value={box[`job-description-${index}`] || box['description'] || ''}

            onChange={(event) => handlexperienceChange(event, index)}
          />
        </div>
      </Box>
    </Box>
  ))}
  {/* <div className='Test_paddingDiv'>
    <Button
      variant="contained"
      color="inherit"
      size="large"
      fullWidth
      onClick={addAnotherTestBox}
    >
      + ADD ANOTHER TEST
    </Button>
  </div> */}
  {Errorexperience && (
    <FormHelperText sx={{color:'#d32f2f'}}>{Errorexperience}</FormHelperText>
  )}
</div>

          <div role="tabpanel" hidden={value !== 1}>
          <Box className='Test_paddingBox2' >
  
  <div className='Test_FlexRow'>
  <TextField  disabled={urlType=== 'admin'} className='Activities_Voluntier_hour' id="outlined-basic" label="Volunteer Hours"   onChange={handleVolunteerHoursChange}  name="volunteer_hours" value={formdata.volunteer_hours} error={Boolean(Errorhours)} helperText={Errorhours} variant="outlined" sx={{width:"50%"}} size="small"/>
  
</div>



  </Box>
          </div>
          <div className='Test_buttonDiv2'>
    <Button variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:"none"}}  onClick={handleBackButton}>Back</Button>
  
    <Button variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}} onClick={moveToNextTab}   disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    
                    value === 1 && urlType === 'admin' ? "CLOSE" : (value === 1 ? "SAVE" : "NEXT")
                  )}</Button>
  </div>
         </Box>
        </Box>
        {/* {showAlert && (
        <Alert
          severity="success"
          sx={{
            position: 'absolute',
            bottom: 0, // Place the Alert at the bottom
            left: 0,
            right: 0,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Your data was successfully saved! Redirecting...
        </Alert>
      )} */}
      </Grid>
    </Grid>
  </div>
  )
}

export default Student_work_experience
