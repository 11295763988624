import React , { useState, useRef,useEffect  } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import './collaborator.css'
import backgroundimage from "../../assets/img/bg-img.png"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Tobar from './Tobar';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import PropTypes from 'prop-types'; // Import PropTypes
import { Link,useNavigate } from 'react-router-dom';
//import profile1 from '../../assets/img/profile.jpg';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Profile_submitted from './Profile_submitted';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import honoursimage from '../../assets/img/honorsnew.svg';
import Highschoolimage from '../../assets/img/highschool.png';
import collegeimage from '../../assets/img/college.png';
import sportimage from '../../assets/img/sports.png';
import testingimage from '../../assets/img/testing.png';
import bgimagecover from '../../assets/img/bgcover.svg';
import IosShareIcon from '@mui/icons-material/IosShare';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
// import { useNavigate } from 'react-router-dom';
import deletedata from '../../assets/img/delete.svg';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogContent";
import {  IconButton } from '@mui/material';
//import DialogTitle from '@mui/material/DialogTitle';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json'; 
import animationData2 from '../../assets/deletenew.json'; 
import "cropperjs/dist/cropper.css";
import { getCroppedImg } from "./../Signup/cropperUtils";
import Cropper from "react-cropper";
import CloseIcon from '@mui/icons-material/Close';
// import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Experiance_icon from '../../assets/img/experiance_icon.jpeg'
function CircularProgressWithLabel(props) { 
  
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex',}}>
        <CircularProgress variant="determinate" {...props}   size={120} // Adjust the size as needed
        thickness={5}  />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };
  
  const CompleteProfile = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData, // The Lottie JSON data
    };
    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: animationData2, // The Lottie JSON data 
    };
    const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }

    // const [progress, setProgress] = React.useState(10); 
    const [registrationSuccess, setRegistrationSuccess] = React.useState(null);
    const [profileuser, setProfileUser] = useState([]);
    const [Collegesdata, setCollegesdata] = useState([]);
    const [HighSchooldata, setHighSchooldata] = useState([]);
    const [Honoursdata, setHonoursdata] = useState([]); 
    const [Testingdata,setTestingdata] = useState([]);
    const [Activitiesdata,setActivitiesdata] = useState([]);
    const [Experiencedata,setExperiencedata] = useState([]);

    const [Summarydata, setSummarydata]  = useState([]);
    const [collegeId, setCollegeId] = useState('');
    const [showDeleteDataImage, setShowDeleteDataImage] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    //const [highschooldelete, sethighschooldelete] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
    const userData = JSON.parse(localStorage.getItem('userData'));
    console.log(userData,'This is User Data ')
    const joinDate = new Date(userData.created_at);
    const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
    const profile1url = userData.avatarImage;
    const laravelUrl =profile1url? profile1url.replace('/public/', '/storage/app/public/'):null;
    const laravelUrlfinal =laravelUrl? laravelUrl.replace('/public/storage/', '/public/'):null;

    const backgroundurl = userData.background_image;

let backgroundurlimagefinal; // Initialize the variable

if (backgroundurl) {
  const backgroundurlimage = backgroundurl? backgroundurl.replace('/public/', '/storage/app/public/'):null;
  backgroundurlimagefinal =backgroundurlimage? backgroundurlimage.replace('/public/storage/', '/public/'):null;
} else {
  // Handle the case where backgroundurl is empty or null
  backgroundurlimagefinal = ''; // Set it to an empty string or any other appropriate default value
}
    // Testingdata.forEach((item, index) => {
    //   const testData = JSON.parse(item.testdata);
    //   if (testData && testData.length > 0) {
    //     testData.forEach((test, testIndex) => {
    //       const testname = test[`testname-${testIndex}`];
    //       const startDate = new Date(test[`start-date-${testIndex}`]);
    //       const year = startDate.getFullYear();
    //       const attempts = test[`attempts-${testIndex}`];
    //       const score = test[`score-${testIndex}`];
    
    //       console.log(`Test ${testIndex + 1}:`);
    //       console.log(`Test Name: ${testname}`);
    //       console.log(`Year: ${year}`);
    //       console.log(`Attempts: ${attempts}`);
    //       console.log(`Score: ${score}`);
    //     });
    //   }
    // });
    
    
    // console.log(Testingdata,"Testingdata");
    // console.log(laravelUrlfinal,"user image");
    // console.log( Collegesdata,"colleges data")
    // console.log( HighSchooldata,"HighSchooldata")
    // console.log( Activitiesdata,"Activitiesdata")
    console.log( Experiencedata,"Experiencedata")
    // console.log(Testingdata,"Testing data")

        
    // if (Activitiesdata && Activitiesdata.length > 0) {
    //   const sportsActivities = JSON.parse(Activitiesdata[0].sports_activities); // Assuming there is only one item in Activitiesdata
    
    //   if (sportsActivities && sportsActivities.length > 0) {
    //     sportsActivities.forEach((activity, index) => {
    //       const highSchoolName = activity[`high-school-name-${index}`];
    //       const highActivitiesSelect = activity[`high-activities-select-${index}`];
    
    //       console.log(`High School Name: ${highSchoolName}`);
    //       console.log(`High Activities Select: ${highActivitiesSelect}`);
    //     });
    //   }
    // }
    
    // HighSchooldata.map((item, index) => {
    //   const endDateYear = new Date(item.end_date).getFullYear(); // Extract the year from end_date
    //   const startDateYear = new Date(item.start_date).getFullYear(); // Extract the year from start_date
    
    //   console.log(`High School Name: ${item.hight_school_name}`);
    //   console.log(`End Date Year: ${endDateYear}`);
    //   console.log(`Start Date Year: ${startDateYear}`);
      
    //   // Do whatever you want with this data
    //   // You can also store it in an array or state if needed
    // });
    
    // console.log(Summarydata,"summary data")
// Check if Honoursdata exists and has data
// if (Honoursdata && Honoursdata.length > 0) {
//   Honoursdata.forEach((item, index) => {
//     const awards = JSON.parse(item.awards);

//     if (awards && awards.length > 0) {
//       awards.forEach((award, awardIndex) => {
//         const awardTitle = award[`award-title-${awardIndex}`];
//         const level = award[`level-${awardIndex}`];
//         const awardDescription = award[`award-description-${awardIndex}`];

//         console.log(`Award Title ${awardIndex + 1}: ${awardTitle}`);
//         console.log(`Level ${awardIndex + 1}: ${level}`);
//         console.log(`Award Description ${awardIndex + 1}: ${awardDescription}`);
//       });
//     }
//   });
// }


    // Collegesdata.forEach((college, index) => {
    //   console.log(`College ${index + 1}:`);
    //   console.log(`Major: ${college.major}`);
    //   console.log(`Supplementary Essay: ${college.supplementary_eassy}`);
    //   console.log(`High School Location: ${college.highSchoolLocation}`);
    //   console.log(`College Name: ${college.collegeName}`);
     
    // });
    // React.useEffect(() => {
    //   const timer = setInterval(() => {
    //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    //   }, 800);
    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(true);
    const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
    const [profilefile, setprofilefile]= useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const cropperRef = useRef(null);
    // Function to handle image selection
    const handleImageSelection = async (event) => {
      
      const file = event.target.files[0];
      if (file) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          setSnackbarOpen2(true); // Open Snackbar2 if file size exceeds 2MB
          fileInputRef2.current.value = '';
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedImage(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      setImageLoaded(true);
      setIsCropDialogOpen(true);
      
    };

    const [showFullSummary, setShowFullSummary] = useState(false);

    const handleViewMore = () => {
      setShowFullSummary(!showFullSummary);
    };
    const handleCrop = async () => {
      console.log(imageLoaded,"crop code status ");
     // Get the cropped image data
     if (cropperRef.current && imageLoaded) {
       const imageElement = cropperRef.current;
       const cropper = imageElement.cropper;
   
       // Get the cropped data synchronously
       const croppedData = cropper.getData();
   
       // Get the cropped image asynchronously
       cropper.getCroppedCanvas().toBlob(async (croppedImageBlob) => {
         // Convert the blob to a data URL
         const croppedImage = URL.createObjectURL(croppedImageBlob);
   
         // Update the state with the cropped image
         setprofilefile(croppedImageBlob);
         setSelectedImage(croppedImage);
         setIsCropDialogOpen(false);
         try {
          // Make a POST request to the API endpoint
          const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
        
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('avatarImage', croppedImageBlob);
        console.log(formData, "form data for image update");
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/profileImagechange`, {
            method: 'post', 
            body: formData,
          });
        
          // Check the response status and handle it accordingly
          if (response.status === 200) {
            // Registration successful, you can handle the response data here
            const data = await response.json();
            localStorage.removeItem('userData');
            localStorage.setItem('userData', JSON.stringify(data.user));
            console.log(data,'user data');
            
              
            //history.push(`/signupprofile?type=${type}`);
            // You can redirect the user to a success page or perform other actions
          } else if(response.status === 422) {
            // Handle other response statuses or errors
            const data = await response.json();
            console.error('Registration failed:', data);
            
            
            
          }
        } catch (error) {
          // Handle network errors or other exceptions
          console.error('Registration failed:', error);
         
        }
   
         // Now you can send the croppedImage to your server or use it as needed
         console.log("Cropped Image Blob:", croppedImageBlob);
       });
     } else {
       setImageLoaded(true);
       console.log("Cropper not initialized or image not loaded");
     }
   }; 

    const handleCloseDialogcrop = () => {
      setIsCropDialogOpen(false);
      setSelectedImage(null);
    };
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [isSnackbarOpen2, setSnackbarOpen2] = useState(false);
    const [image, setImage] = useState(null); // State to store the uploaded image file
    const [showImageUploader, setShowImageUploader] = useState(false); // State to control the image uploader visibility
    const fileInputRef = useRef(null); // Ref to access the hidden file input
    const fileInputRef2 = useRef(null); 
    const handleImageChange = async () => {
      const file = fileInputRef.current.files[0]; 
   
      if (file) {
        // Check if the file size is greater than 5MB (5 * 1024 * 1024 bytes)
        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 3000);
          fileInputRef.current.value = '';
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
          setShowImageUploader(false); // Hide the image uploader after an image is uploaded
        };
        reader.readAsDataURL(file); 
      }
      try {
        // Make a POST request to the API endpoint
        const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;

      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('background_image', file);
      console.log(formData, "form data for image update");
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/profilebackgroundImagechange`, {
          method: 'post', 
          body: formData,
        });
  
        // Check the response status and handle it accordingly
        if (response.status === 200) {
          // Registration successful, you can handle the response data here
          const data = await response.json();
          localStorage.removeItem('userData');
          console.log('Registration successful');
          localStorage.setItem('userData', JSON.stringify(data.user));
          window.location.reload();
          //history.push(`/signupprofile?type=${type}`);
          // You can redirect the user to a success page or perform other actions
        } else if(response.status === 422) {
          // Handle other response statuses or errors
          const data = await response.json();
          console.error('Registration failed:', data);
          
          
          
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Registration failed:', error);
       
      }
      
    };
  
    const handleIconClick = async () => {
      // Trigger the hidden file input when the icon is clicked
      
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
      
    };

  
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
      
      
    };
    const handleOpen = () => {
      setOpen(true);

    };
    const handleOpen2 = () => {
      setOpen2(true);
      fetchData()
    };
    const handleClose2 = () => {
      setOpen2(false);
      
      
    };

    
  

  // useEffect(() => {
  //   try {
  //     const apiUrl = process.env.REACT_APP_API_URL;
  //     const userData = JSON.parse(localStorage.getItem('userData'));
  //     const userId = userData.id;

  //     fetch(`${apiUrl}/get_all_profile_data`, {
  //         method: 'post',
  //         body: JSON.stringify({ userId }), // Send the entire formData object as the body
  //     })
  //     .then(response => {
  //         if (response.status === 200) {
  //             return response.json(); // Convert the response to JSON
  //         } else {
  //             throw new Error('Request failed');
  //         }
  //     })
  //     .then(data => {
  //         const collegesdata = data.CollegesData;
  //         const highschooldata = data.HighschoolData;
  //         const hounoursdata = data.awardsData;
  //         const testingdata = data.TestingData;
  //         const activitiesdata = data.ExperiencesData;
  //         const personalessay = data.SummaryData;
  //         const collaboratorExperience=data.colaboratorworkexperience;
  //         const profileuser=data.user_data
  //         console.log(profileuser,"profile")
  //         setProfileUser(profileuser)
  //         setCollegesdata(collegesdata);
  //         setHighSchooldata(highschooldata);
  //         setHonoursdata(hounoursdata);
  //         setTestingdata(testingdata);
  //         setActivitiesdata(activitiesdata);
  //         setSummarydata(personalessay);
  //         setExperiencedata(collaboratorExperience)
  //         //console.log(collegesdata); // Log the awardsData
  //        // alert("here");
  //     })
  //     .catch(error => {
  //         console.error('Data fetch failed:', error);
  //     });
  // } catch (error) {
  //     console.error('Data upload failed:', error);
  // }
  // },  [collegeId]);
  const fetchData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData.id;
  
      const response = await fetch(`${apiUrl}/get_all_profile_data`, {
        method: 'post',
        body: JSON.stringify({ userId }),
      });
  
      if (response.status === 200) {
        const data = await response.json();
        const collegesdata = data.CollegesData;
        const highschooldata = data.HighschoolData;
        const hounoursdata = data.awardsData;
        const testingdata = data.TestingData;
        const activitiesdata = data.ExperiencesData;
        const personalessay = data.SummaryData;
        const collaboratorExperience = data.colaboratorworkexperience;
        const profileuser = data.user_data;
  
        console.log(profileuser, 'profile');
        setProfileUser(profileuser);
        setCollegesdata(collegesdata);
        setHighSchooldata(highschooldata);
        setHonoursdata(hounoursdata);
        setTestingdata(testingdata);
        setActivitiesdata(activitiesdata);
        setSummarydata(personalessay);
        setExperiencedata(collaboratorExperience);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error('Data fetch failed:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [collegeId]);
   // Initialize with null or a default value
   const [openClick, setOpenClick] = useState(false);
   const [deleteType, setDeleteType] = useState('');
   const [deleteId, setDeleteId] = useState(null);
 const[confirmationText,setConfirmationText ]=useState('')
   const handlecollegeDelete = (id, type) => {
     setDeleteType(type); // Set the delete type (college or high school)
     setDeleteId(id); // Set the selected college/high school id
     setOpenClick(true); // Open the dialog`
     if(type === 'college'){
      setConfirmationText('College');
     }
     else if (type === 'highschool') {
      setConfirmationText('High school');
     }
     else if (type === 'honours') {
      setConfirmationText('Honours');

     }
     else if (type === 'testing') {
      setConfirmationText('Testing');

     }
     else if (type === 'activity') {
      setConfirmationText('Activity');

     }
     else if (type === 'experience') {
      setConfirmationText('Experience');

     }
     else if (type === 'summary') {
      setConfirmationText('Personal Essay'); 

     }
   };
 
   const handleCloseDialog = () => {
     setOpenClick(false);
   };
   useEffect(() => {
    if (showDeleteDataImage) {
      const timeout = setTimeout(() => {
        handleCloseDialog();
        // lines to fix delete issue 
        setShowDeleteDataImage(false)
        setShowButtons(true)
      }, 2000); // Adjust the time in milliseconds (e.g., 3000 ms = 3 seconds)
      
      return () => {
    
        clearTimeout(timeout); // Clear the timeout if the component unmounts or showDeleteDataImage changes
      };
    }
  }, [showDeleteDataImage]);
   const handleDeleteConfirmation = async () => {
     try {
       // Make a POST request to the appropriate API endpoint based on deleteType
       const apiUrl = process.env.REACT_APP_API_URL;
       const userData = JSON.parse(localStorage.getItem('userData'));
       const userId = userData.id;
 
       let endpoint = '';
   

       if (deleteType === 'college') {
         endpoint = `${apiUrl}/del_college_appliedfor_form_data`;
        
       } else if (deleteType === 'highschool') {
         endpoint = `${apiUrl}/del_high_school_form_data`;
         
       }
       else if (deleteType === 'honours') {
        endpoint = `${apiUrl}/del_honours_form_data`;
        
      }
      else if (deleteType === 'testing') {
        endpoint = `${apiUrl}/del_testing_form_data`;
        
      }
      else if (deleteType === 'activity') {
        endpoint = `${apiUrl}/del_activities_form_data`;
        
      }
      else if (deleteType === 'experience') {
        endpoint = `${apiUrl}/del_work_experience_form_data`;
        
      }
      else if (deleteType === 'summary') {
        endpoint = `${apiUrl}/del_personal_form_form_data`;
        
      }
       if (endpoint) {
         const response = await fetch(endpoint, {
           method: 'post',
           body: JSON.stringify({ userId, formid: deleteId }),
         });
 
         if (response.status === 200) {
          setCollegeId(deleteId);
           const responseData = await response.json();
           const message = responseData.message;
           console.log(message);
           
           // Handle success or show a notification
         }
       }
     } catch (error) {
       // Handle network errors or other exceptions
       console.error('Delete failed:', error);
     }
 
    
    setShowDeleteDataImage(true)
    
    setShowButtons(false)
   };
    const navigate = useNavigate();
    const handleprofileView =()=>{
      navigate('/profile_edit')
    }
    const [selectedCollegeId, setSelectedCollegeId] = useState('');
    const [selectedHighschoolId, setSelectedHighschoolId] = useState('');
    const [selectedPersonalEssayId, setSelectedPersonalEssayId] = useState('');
    const [selectedTestingId, setSelectedTestingId] = useState('');
    const [selectedHonoursId, setSelectedHonoursId] = useState('');
    const [selectedActivityId, setSelectedActivityId] = useState('');
    const [selectedEperienceId, setSelectedEperienceId] = useState('');

    const handleEditClickActivities = (id) => {
      setSelectedActivityId(id); // Set the selected college id in the state
      console.log(setSelectedActivityId,"selectedTestingId")
    };
    const handleEditClickHonours = (id) => {
      setSelectedHonoursId(id); // Set the selected college id in the state
      console.log(setSelectedHonoursId,"selectedTestingId")
    };
    const handleEditClickTesting = (id) => {
      setSelectedTestingId(id); // Set the selected college id in the state
      console.log(selectedTestingId,"selectedTestingId")
    };
    const handleEditClickHighschool = (id) => {
      setSelectedHighschoolId(id); // Set the selected college id in the state
      
    };
    const handleEditClickSummary = (id) => {
      setSelectedPersonalEssayId(id); // Set the selected college id in the state
      
    };
    const handleEditClickExperience = (id) => {
      setSelectedEperienceId(id); // Set the selected college id in the state
      
    };
    // Function to handle clicking the edit icon
    const handleEditClick = (id) => {
      setSelectedCollegeId(id); // Set the selected college id in the state
      
    };
    useEffect(() => {
    
      if(selectedCollegeId){
      
       navigate(`/college_applied_for?id=${selectedCollegeId}`);
      }
      else if(selectedHighschoolId){
        navigate(`/high_school?id=${selectedHighschoolId}`);
      }
      else if(selectedPersonalEssayId){
        navigate(`/summary?id=${selectedPersonalEssayId}`);
      }
      else if(selectedTestingId){
        navigate(`/testing?id=${selectedTestingId}`);
      }
      else if(selectedHonoursId){
        navigate(`/honour?id=${selectedHonoursId}`);
      }
      else if(selectedActivityId){
        navigate(`/activities?id=${selectedActivityId}`);
      }
      else if(selectedEperienceId){
        navigate(`/Collaborator_work_experience?id=${selectedEperienceId}`);

      }
    }, [selectedCollegeId,selectedHighschoolId,selectedPersonalEssayId,selectedTestingId,selectedHonoursId,selectedActivityId,selectedEperienceId]);

  
    const calculateProgress = () => {
      let progress = 10;
      if (Collegesdata.length > 0) progress += 40;
      if (HighSchooldata.length > 0) progress += 40;
      if (Honoursdata.length > 0) progress += 0;
      if (Testingdata.length > 0) progress += 0;
      if (Activitiesdata.length > 0) progress += 10;
      if (Experiencedata.length > 0) progress += 0;
      if (Summarydata.length > 0) progress += 0;
      return progress;
    };
  
    const progress = calculateProgress();
    useEffect(() => {
      if (progress === 100 && (profileuser && profileuser.profile_status ===  null || profileuser.profile_status ===  "rejected")) {
        setIsButtonDisabled(false); // Enable the button when progress is 100%
      }
      else if((profileuser && profileuser.profile_status === "pending"  || profileuser.profile_status === "accepted")) {
        setIsButtonDisabled(true); // Disable the button otherwise
      }
       else {
        setIsButtonDisabled(true); // Disable the button otherwise
      }
    }, [progress, profileuser]);
    
    const states = [
      { code: "AL", name: "Alabama" },
      { code: "AK", name: "Alaska" },
      { code: "AZ", name: "Arizona" },
      { code: "AR", name: "Arkansas" },
      { code: "CA", name: "California" },
      { code: "CO", name: "Colorado" },
      { code: "CT", name: "Connecticut" },
      { code: "DE", name: "Delaware" },
      { code: "FL", name: "Florida" },
      { code: "GA", name: "Georgia" },
      { code: "HI", name: "Hawaii" },
      { code: "ID", name: "Idaho" },
      { code: "IL", name: "Illinois" },
      { code: "IN", name: "Indiana" },
      { code: "IA", name: "Iowa" },
      { code: "KS", name: "Kansas" },
      { code: "KY", name: "Kentucky" },
      { code: "LA", name: "Louisiana" },
      { code: "ME", name: "Maine" },
      { code: "MD", name: "Maryland" },
      { code: "MA", name: "Massachusetts" },
      { code: "MI", name: "Michigan" },
      { code: "MN", name: "Minnesota" },
      { code: "MS", name: "Mississippi" },
      { code: "MO", name: "Missouri" },
      { code: "MT", name: "Montana" },
      { code: "NE", name: "Nebraska" },
      { code: "NV", name: "Nevada" },
      { code: "NH", name: "New Hampshire" },
      { code: "NJ", name: "New Jersey" },
      { code: "NM", name: "New Mexico" },
      { code: "NY", name: "New York" },
      { code: "NC", name: "North Carolina" },
      { code: "ND", name: "North Dakota" },
      { code: "OH", name: "Ohio" },
      { code: "OK", name: "Oklahoma" },
      { code: "OR", name: "Oregon" },
      { code: "PA", name: "Pennsylvania" },
      { code: "RI", name: "Rhode Island" },
      { code: "SC", name: "South Carolina" },
      { code: "SD", name: "South Dakota" },
      { code: "TN", name: "Tennessee" },
      { code: "TX", name: "Texas" },
      { code: "UT", name: "Utah" },
      { code: "VT", name: "Vermont" },
      { code: "VA", name: "Virginia" },
      { code: "WA", name: "Washington" },
      { code: "WV", name: "West Virginia" },
      { code: "WI", name: "Wisconsin" },
      { code: "WY", name: "Wyoming" },
      { code: "PR", name: "Puerto Rico" },
      { code: "GU", name: "Guam" },
      { code: "VI", name: "U.S. Virgin Islands" },
      { code: "AS", name: "American Samoa" },
      { code: "MP", name: "Northern Mariana Islands" },
      { code: "INTL", name: "International" }
    ];

    const getStateFullName = (stateCode) => {
      const state = states.find((s) => s.code === stateCode);
      return state ? state.name : '';
    };
    function encodeAlphanumeric(id) {
      const encoded = btoa(id); // Using btoa to Base64 encode the ID
      // You can further process the encoded string if needed
      return encoded;
    } 










    const handleClick = () => {
      setIsLoading(true);
      handleRejectProfile();
    };





    const handleRejectProfile = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
    
        const response = await fetch(`${apiUrl}/collaborator/approval/request/${userId}?userId=${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        if (!response.ok) {
          throw new Error('Request failed');
        }
        fetchData();
        setIsLoading(false);
        handleOpen();
        console.log('Profile Send for approval');
      } catch (error) {
        console.error('Error requesting profile:', error);
      }
    };
    
    
    
    
    const handleButtonClickViewProfile = () => {
      const userid = JSON.parse(localStorage.getItem('userData'));
        window.location.href = `/Collaborator_Profile_for_student?id=${encodeAlphanumeric(userid.id)}`;
      
    };
    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };
    const handleCloseSnackbar2 = () => {
      setSnackbarOpen2(false);
    };
  
  return (
    <div >
    <Tobar/>
      
      <Grid container 
      gap="20px"
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      className='Grid_main_profile'
     >
        
   <Grid item

    lg={8.5}
    xl={9.7}
    xs={12}
    sm={12}
    md={8.5} 
    className="Grid_item_custom"
    >
          <Dialog
  open={open}
  keepMounted
  onClose={handleClose}
  aria-describedby="alert-dialog-slide-description"
  maxWidth="sm" // Set maximum width for the dialog
  fullWidth // Ensure the dialog takes full width
>
  <DialogContent className="Dialog_content">
    <Lottie options={defaultOptions} height={200} width={200} />

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%' }}>
      <Typography className="Success">Profile Submitted</Typography>
      <Typography className='Submitted_text22'>Dear {userData.legal_name}, your profile has been submitted and is being reviewed by our team.</Typography>
      <Typography className='Submitted_text2'>We will email you once your profile has been approved.</Typography>
      <div style={{ paddingBottom: '20px', width: '100%' }}>
        <Button variant="outlined" fullWidth onClick={handleClose}>OK</Button>
      </div>
    </div>
  </DialogContent>
</Dialog>
          <Dialog
  open={open2}
  keepMounted
  onClose={handleClose2}
  aria-describedby="alert-dialog-slide-description"
  maxWidth="sm" // Set maximum width for the dialog
  fullWidth // Ensure the dialog takes full width
>
  <DialogContent className="Dialog_content">
    <Lottie options={defaultOptions2} height={200} width={200} />

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%' }}>
      <Typography className="delete_confirm">Profile Rejected</Typography>
      <Typography className='Submitted_text22'>Dear {userData.legal_name}, your profile has been reviewed by our team and Rejected.</Typography>
      <Typography className='Submitted_text2'>{ profileuser.reasons}</Typography>
      <div style={{ paddingBottom: '20px', width: '100%' }}>
        <Button   onClick={handleClose2}   sx={{
            background: "var(--error, #D32F2F)",
            width: "45%",
            boxShadow: "none",
            "&:hover": {
              background: "var(--error, #D32F2F)",
              color: "white",
            },
          }}
          variant="contained">OK</Button>
      </div>
    </div>
  </DialogContent>
</Dialog>

        <Grid item
        xs={12}
        sm={12}
        className="GridItemCustom2"
   >
  

 <Box
 className="BoxCustom"
  
>
  <Box
  className="BoxCustom_main"
    sx={{
    
     width:"-webkit-fill-available",
      backgroundImage: backgroundurlimagefinal ? `url(${backgroundurlimagefinal})` : `url(${bgimagecover})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // backgroundColor: image ? 'transparent' : '#D9D9D9',
    
    }}
  >
      <div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000} // 3 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert severity="error" onClose={handleCloseSnackbar}>
          File size exceeds the limit of 5MB.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSnackbarOpen2}
        autoHideDuration={3000} // 3 seconds
        onClose={handleCloseSnackbar2}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert severity="error" onClose={handleCloseSnackbar2}>
          File size exceeds the limit of 2MB.
        </MuiAlert>
      </Snackbar>

      {/* Rest of your component */}
    </div>
    <label htmlFor="image-input" className="image-uploader">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        id="image-input"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        onClick={handleIconClick}
      />
      <CameraAltOutlinedIcon
        className="upload-icon"
      
      />
    </label>
  
  </Box>

  {selectedImage && (
                              <div>
                               <Dialog open={isCropDialogOpen} onClose={handleCloseDialogcrop} >
                               <DialogTitle>Crop Image
                               <IconButton
              aria-label="close"
              onClick={handleCloseDialogcrop}
              style={{ position: 'absolute', top: 5, right: 5 }}
            >
              <CloseIcon />
            </IconButton>
                               </DialogTitle>
                               <DialogContent>
                                 <Cropper
                                   ref={cropperRef}
                                   src={selectedImage}
                                   style={{ height: "80%", width: "80%", }}
                                   aspectRatio={1}
                                   guides={true}
                                   dragMode="none"
                                   zoomOnWheel={false}
                                 />
                                 <div style={{display:"flex",justifyContent:"center"}}>
                                 <Button variant="contained" sx={{marginTop:"16px",justifyContent:"center"}} onClick={handleCrop}>Crop Image</Button></div>
                               </DialogContent>
                             </Dialog>
                           </div>
                  // <div>
                  //   <Cropper
                  //     ref={cropperRef}
                  //     src={avatarImage}
                  //     style={{ height: 300, width: "100%" }}
                  //     aspectRatio={1}
                  //     guides={true}
                  //     dragMode="move"
                  //   />
                  //   <button type="button" onClick={handleCrop}>
                  //     Crop Image
                  //   </button>
                  // </div>
                )}
  <div>
      <input
        ref={fileInputRef2}
        type="file"
        accept="image/*"
        id="image-input2"
        onChange={handleImageSelection}
        style={{ display: 'none' }}
      />

      <div onClick={() => document.getElementById('image-input2').click()} style={{cursor:'pointer'}}>
      <Avatar
      className="AvatarCustom"
  alt="Cindy Baker"
  src={selectedImage || laravelUrlfinal}
 
/>

      </div>
   
    </div>
  
</Box>
<div className='Edit_icon_profile' >

<ModeEditOutlineOutlinedIcon sx={{color:'#58595B'}} onClick={handleprofileView}/>
</div>


   <Box className="profile_view_mainDiv2" >
 
   <typography className="Profile_view_welcome"> {userData.legal_name}</typography>
   <typography className="Profile_view_desc"> {userData.Headline || "Helping Students apply to college the smart way"}</typography>
  <Box className="Complete_profile">
  {Collegesdata.some(college => college.decisionOptions === "Attended") ? (
  <>
    {Collegesdata
      .filter(college => college.decisionOptions === "Attended")
      .map((college, index) => {
        const collegeNamecollaborator = JSON.parse(college.collegeName);
        const Collegenameadded = collegeNamecollaborator ? collegeNamecollaborator.title : "";

        return (
          <div key={index} className="university-name">
            <typography className='Profile_view_location2'>
              <SchoolOutlinedIcon sx={{ height: "15px", width: "15px" }} />
              {Collegenameadded}
            </typography>
            {index < Collegesdata.length - 1 && <Divider variant="middle" />}
          </div>
        );
      })}
  </>
) : (
  null
)}

    <typography className='Profile_view_date2'><CalendarMonthIcon sx={{height:"15px",width:"15px"}}/>joined {formattedJoinDate}</typography>
  </Box>
  <div style={{marginTop:"30px",display:"flex",gap:"20px"}}>
  <Button className='Collaboartor_viewas_student' onClick={() => handleButtonClickViewProfile()} variant='contained' sx={{boxShadow:"none"}}>VIEW AS STUDENT</Button>
  {/* <IosShareIcon sx={{height:"2.7em",width:"1.7em",color:"#006CB7",borderRadius:"4px",border:"0.75px solid #006CB7 ",fontSize:"0.8em",padding:"1px 6px"}}/> */}
  </div>
  
   </Box>
   </Grid>
   <Grid item xs={12}
    sm={12}
className="GridItemCustom_grid_circular"
   >

<Box
    className="BoxCustom2">
   <Box className="Profile-view" >
    <typography className="Profile_view_Percent">Complete your profile</typography>
   <CircularProgressWithLabel value={progress} color="success"   />
   <Box className="profile_view_Percet_container">
   
   <Button onClick={() => {
    handleOpen();
    handleClick();
}}
  sx={{ boxShadow:"none",'&.Mui-disabled': {
    pointerEvents: 'visible', // Override the pointer-events property
  },}}
   variant='contained' disabled={isButtonDisabled }  title={isButtonDisabled ? 'Fill up your profile for submission' : ''}>{isLoading ? (
    // Display the circular loading icon
    <CircularProgress size={24} color="inherit" />
  ) : (
    // Display the "SUBMIT" text
    "SUBMIT FOR APPROVAL"
  )}</Button>
    {profileuser && profileuser.profile_status !== null && (
  profileuser.profile_status === 'rejected' ? (
    <Chip 
      color="error" 
      size="small" 
      variant="filled"
      sx={{
        width:"fit-content",
        padding:'15px',
        margin:'15px',
        display:'flex',
        alignSelf:'center',
        cursor: 'pointer' // Change cursor to pointer to indicate clickable
      }}
      label="Rejected"
      onClick={handleOpen2} // Add onPress event handler
    />
  ) : (
    <Chip 
      color={
        profileuser.profile_status === "accepted" ? "success" :
        profileuser.profile_status === "pending" ? "warning" :
        "primary"
      }
      size="small" 
      variant="filled"
      sx={{
        width:"fit-content",
        padding:'15px',
        margin:'15px',
        display:'flex',
        alignSelf:'center'
      }}
      label={
        profileuser.profile_status === 'accepted'
        ? 'Accepted'
        : 'Pending'
      }
    />
  )
)}
   </Box>

   </Box>

      

</Box>
   </Grid>
<Grid item xs={12}
    sm={12}
className="GridItemCustom"
   >




    <Box
    className="BoxCustom2">

   
  <Box
  className="BoxCustom3">
  <typography className="Collaborator_colleges_appliedto" style={{ fontWeight: '600', fontSize: '20px' }}>
  Colleges Applied to
</typography>

    <Box
    className="BoxCustom4">
        {Collegesdata && Collegesdata.length > 0 ? (
     <Link to={`/college_applied_for`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link> 
        ):(null)}
    </Box>
  </Box>
  <Dialog
        open={openClick}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
       <div className='Collaborator_Delete_Div' style={{ padding: "30px", }}>
    {showDeleteDataImage ? (
      <DialogContent>
          <Lottie options={defaultOptions2} height={200} width={200}   sx={{
    '@media (max-width: 600px)': {
      height: 130,
      width: 130,
    },
  }}
/>

       
        <DialogContentText id="alert-dialog-description">
        <typography className="delete_confirm">Deleted !</typography>
        </DialogContentText>
      </DialogContent>
    ) : (
      <DialogContent>
        
          <div className='Collaborator_Delete_Div' style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
          <img src={deletedata} alt="deletedata" style={{ width: "80px", height: "80px" }} />
        </div>
        <typography className="delete_confirm">Confirm Deletion</typography>
        <DialogContentText  sx={{textAlign:"center"}} id="alert-dialog-description">
          Are you sure you want to delete the {confirmationText} data?
        </DialogContentText>
      </DialogContent>
    )}
    {showButtons && (
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{
            background: "var(--grey-300, #E0E0E0)",
            color: "black",
            width: "45%",
            boxShadow: "none",
            "&:hover": {
              background: "var(--grey-300, #E0E0E0)",
              color: "black",
            },
          }}
          variant="contained"
          onClick={handleCloseDialog}
          autoFocus
        >
          No, Cancel
        </Button>
        <Button
          sx={{
            background: "var(--error, #D32F2F)",
            width: "45%",
            boxShadow: "none",
            "&:hover": {
              background: "var(--error, #D32F2F)",
              color: "white",
            },
          }}
          variant="contained"
          onClick={handleDeleteConfirmation}
        >
          Yes, Delete
        </Button>
      </DialogActions>
    )}
  </div>
      </Dialog>
  {Collegesdata.length > 0 ? (
  // Render content related to the data
  
  Collegesdata.map((college, index) => {
    const majorData = JSON.parse(college.major)[0]; // Parse the JSON and access the first item
    const majorTitle = majorData ? majorData.title : ""; // Access the "title" property
    const collegeNamecollaborator = JSON.parse(college.collegeName); // Parse the JSON and access the first item
    const Collegenameadded = collegeNamecollaborator ? collegeNamecollaborator.title : ""; // Access the "title" property 
   
 
    return (
      <>
      <div className='DivCustom_later'>

    
      <div key={index} className="DivCustom">
        <div className="DivCustom2">
          <div>
            <Avatar className='Colleges_Avatar_img' src={collegeimage}></Avatar>
          </div>
          <div className="DivCustom">
            <div
            className="DivCustom3"
             
            >
              <div className="DivCustom4">
              <typography className="TypographyCustom">{Collegenameadded}</typography>
             

              </div>
              
              <typography className="TypographyCustom2">{getStateFullName(college.highSchoolLocation)}</typography>
              <typography className="Major_text"   style={{
    color: college.decisionOptions === "Accepted"
      ? "#2E7D32"
      : college.decisionOptions === "Rejected"
      ? "#D32F2F"
      : college.decisionOptions === "Attended"
      ? "#0000FF"
      : college.decisionOptions === "Waitlisted"
      ? "#EF6C00"
      : college.decisionOptions === "Deferred"
      ? "#9C27B0"
      : "default"
  }}>{majorTitle}</typography>
            </div>

            <typography className="TypographyCustom3">Supplementary Essay</typography>
            <typography className="Supplementary_essay">
              {college.supplementary_eassy.length > 100
                ? `${college.supplementary_eassy.substring(0, 400)}...`
                : college.supplementary_eassy}
            </typography>
          </div>
        </div>
       
      </div>
      <div className="DivCustom5" style={{ display: "flex", alignItems: "center" }}>
  <Chip
    className='Chip_div'
    sx={{ padding: "16px 8px" }}
    label={college.decisionOptions}
    color={
      college.decisionOptions === "Accepted" ? "success" :
      college.decisionOptions === "Attended" ? "primary" :
      college.decisionOptions === "Rejected" ? "error" :
      college.decisionOptions === "Waitlisted" ? "warning" :
      college.decisionOptions === "Deferred" ? "secondary" : "default"
    }
    size="small"
    variant="filled"
  />
  <Link
    className='Text_decoration'
    to="#"
    onClick={(e) => {
      e.preventDefault();
      handleEditClick(college.id);
    }}
    style={{ padding: 0, color: '#58595B' }}
  >
    <ModeEditOutlineOutlinedIcon className='Mui_edit_icon' />
  </Link>
  <Link
    to="#"
    className='Text_decoration'
    onClick={() => handlecollegeDelete(college.id, 'college')}
    style={{ padding: 0, color: '#D32F2F' }}
  >
    <DeleteOutlinedIcon className='Mui_edit_icon' />
  </Link>
</div>

</div>
       {index < Collegesdata.length - 1 && (
        // Add Divider only if it's not the last item in the array
        <Divider className="DividerCustom2" variant="inset" />
      )}
     </>
    );
  })
) : (
  // Render the default content when there is no data
  <Box className="BoxCustom5">
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} />Add the colleges you applied to
      </typography>
    </Box>
    <Link to={`/college_applied_for`} className='Text_decoration'>
      <Button variant="outlined"> + ADD College</Button>
    </Link>
  </Box>
)}

  </Box>
   </Grid>
 
   <Grid item
     xs={12}
     sm={12}
   className="GridItemCustom3">
    <Box className="BoxCustom6">

   
  <Box className="BoxCustom7" >
    <typography className="TypographyCustom4">High School Education</typography>
    <Box className="BoxCustom8">
    {HighSchooldata && HighSchooldata.length > 0 ? (
     <Link to={`/high_school`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/></Link>
        ):(null)}
 
    </Box>
  </Box>

  {HighSchooldata && HighSchooldata.length > 0 ? (
  // Render content related to the data
  HighSchooldata.map((item, index) => {
    // Render the data here as needed
    return (
      <>
    
      <div className='DivCustom_later2' key={index} style={{width:"100%"}}>
        <div className="DivCustom6">

        
         <Avatar  className='Colleges_Avatar_img' src={Highschoolimage}>

</Avatar>
<div  className="DivCustom7" style={{width:"100%"}}>

<div className="DivCustom8" style={{width:"100%"}}>
        <typography className="TypographyCustom5" > {item.hight_school_name}</typography>
</div>
{/* <typography className="degree_name">Degree: {item.degree_high}</typography> */}
        <div className="DivCustom9"><CalendarTodayIcon className='CalenderCustom' sx={{width:"15px",height:"15px",color:"#58595B"}}/>
        <typography className="date_highschool"> {new Date(item.start_date).getFullYear()}&nbsp;&nbsp;-</typography>
        <typography className="date_highschool">{new Date(item.end_date).getFullYear()}</typography>
       
        </div>
     
        </div>
        </div>
        <div className='DivCustom5 '>
        <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHighschool(item.id);
  }}
>
  <ModeEditOutlineOutlinedIcon className='Mui_edit_icon'  sx={{color:'#58595B',padding:0}}/>
</Link>
<Link
  to="#"
  className='Text_decoration'
  onClick={() => handlecollegeDelete(item.id, 'highschool')}
><DeleteOutlinedIcon className='Mui_edit_icon'  sx={{color:'#D32F2F',padding:0}}/></Link>
</div>
      </div>
         {index < HighSchooldata.length - 1 && (
          // Add Divider only if it's not the last item in the array
          <Divider className="DividerCustom2" variant="inset"/>
        )}
          </>
    );
  })
) : (
  // Render the "Add High School" button if no data is present
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} /> Add your high school education here.
    </typography>
    <Link to={`/high_school`} className='Text_decoration'>
      <Button variant="outlined"> + ADD HIGH SCHOOL</Button>
    </Link>
  </Box>
)}



  </Box>
   </Grid>
   <Grid item
   className="GridItemCustom4">
    <Box className="BoxCustom9">

   
  <Box className="BoxCustom10">
    <typography className="TypographyCustom7">Honors</typography>
    <Box className="BoxCustom11">
    {Honoursdata && Honoursdata.length > 0 ? (
     <Link to={`/honour`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/></Link>
        ):(null)}
   
    </Box>
  </Box>

  {Honoursdata && Honoursdata.length > 0 ? (
  // Render the awards/honours data
  Honoursdata.map((item, index) => {
    const awards = JSON.parse(item.awards);
    if (awards && awards.length > 0) {
      return awards.map((award, awardIndex) => (
        <>
        <div key={awardIndex} >
     <div style={{display:"flex",justifyContent:"space-between"}}>

    
           <div className="DivCustom10">
          <Avatar className='Colleges_Avatar_img' src={honoursimage}>

          </Avatar>
      
          <div className="DivCustom11">
          <div className="DivCustom12">
            <div className="DivCustom13">
          <typography className="TypographyCustom8"> {award[`award-title-${awardIndex}`]}</typography>
           
</div>
          <typography className="Honours_level">Level: {award[`level-${awardIndex}`]}</typography>
      </div>
      <typography className="Honours_desc">
  {award && award[`award-description-${awardIndex}`] && award[`award-description-${awardIndex}`].length > 400
    ? `${award[`award-description-${awardIndex}`].substring(0, 400)}...`
    : award && award[`award-description-${awardIndex}`]}
</typography>

          </div>
         
          </div>
          <div className='DivCustom5' style={{display:"flex",gap:"10px"}}>

         
          <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHonours(item.id);
  }}
>
  <ModeEditOutlineOutlinedIcon className='Mui_edit_icon'  sx={{color:'#58595B',padding:0}}/>
</Link>
<Link
  to="#"
  className='Text_decoration'
  onClick={() => handlecollegeDelete(item.id, 'honours')}
><DeleteOutlinedIcon className='Mui_edit_icon'  sx={{color:'#D32F2F',padding:0}}/></Link>
</div>
</div>
        </div>
         {index < Honoursdata.length - 1 && (
          // Add Divider only if it's not the last item in the array
          <Divider className="DividerCustom3" variant="inset" />
        )}
        </>
      ));
    }
    return null;
  })
) : (
  // Render the "Add Honours" button if no data is present
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} /> Add any honors you have received
    </typography>
    <Link to={`/honour`} className='Text_decoration'>
      <Button variant="outlined"> + ADD HONOR</Button>
    </Link>
  </Box>
)}

  </Box>
   </Grid>
   <Grid item
   className="GridItemCustom5"
   
  >
    <Box className="BoxCustom12" >

   
  <Box className="BoxCustom13">
    <typography className="TypographyCustom9">Testing</typography>
    <Box className="BoxCustom14">
    {Testingdata && Testingdata.length > 0 ? (
      <Link className="LinkCustom" to={`/testing`} ><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link>
        ):(null)}

    </Box>
  </Box>

  {Testingdata && Testingdata.length > 0 ? (
  // Render the testing data
  Testingdata.map((item, index) => {
    const testData = JSON.parse(item.testdata);
    return (
      <>
      
        {testData.map((test, testIndex) => (
          <>
          <div className='DivCustom_later3' key={testIndex}>
                       <div className="DivCustom10">
            {/* <div> */}
              {/* <div className="DivCustom16"> */}
            <Avatar className='Colleges_Avatar_img' src={testingimage}>

</Avatar>

<div className="DivCustom17">
            <typography className="TypographyCustom10"> {test[`testname-${testIndex}`]}</typography>
            <typography className="TypographyCustom11">Attempts: {test[`attempts-${testIndex}`]}</typography>
            <Box>
           <CalendarTodayIcon className="CalendarTodayIconCustom" sx={{width:"15px",height:"15px",color:"#58595B"}} /> <typography>  {new Date(test[`start-date-${testIndex}`]).toLocaleDateString()}</typography>
            </Box>
            
            </div>
            {/* </div> */}
            {/* </div> */}
         
            </div>
            <div  className="DivCustom18">
  <div className="DividerCustom4"></div> {/* Vertical divider */}
  <div className="DivCustom19">
    <typography className="Testing_Obtained_text">Highest Score</typography>
    <typography><span className='SpanCustom' >{test[`score-${testIndex}`]}</span></typography>
  </div>

</div>
            <div className='DivCustom5' style={{display:"flex",gap:"10px"}}>
            <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickTesting(item.id);
  }}
>
  <ModeEditOutlineOutlinedIcon className='Mui_edit_icon'  sx={{color:'#58595B',padding:0}}/>
</Link>
<Link
  to="#"
  className='Text_decoration'
  onClick={() => handlecollegeDelete(item.id, 'testing')}
><DeleteOutlinedIcon className='Mui_edit_icon'  sx={{color:'#D32F2F',padding:0}}/></Link>
</div>
          </div>
            {index < Testingdata.length - 1 && (
              // Add Divider only if it's not the last item in the array
              <Divider className="DividerCustom3" variant="inset" />
            )}
            </>
        ))}
  
      </>
    );
  })
) : (
  // Render the "Add Testing" button if no data is present
  <Box>
    <typography className="Complete_profile_desc">
      <InfoOutlinedIcon sx={{ color: "orange" }} /> Add your SAT/ACT/AP scores.
    </typography>
    <Link to={`/testing`} className='Text_decoration'>
      <Button variant="outlined"> + ADD TEST</Button>
    </Link>
  </Box>
)}



  </Box>
   </Grid>
   
   <Grid item
   className="GridItemCustom6">
    <Box className="BoxCustom15" >

   
  <Box  className="BoxCustom16">
    <typography className="TypographyCustom12">Activities</typography>
    <Box className="BoxCustom17">
    {Activitiesdata && Activitiesdata.length > 0 ? (
      <Link to={`/activities`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link>
        ):(null)}
  
    </Box>
  </Box>
  <div className='Collaborator_completeprofile_activities' style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}>
  {Activitiesdata && Activitiesdata.length > 0 ? (
  // Render the extra-curricular activities data
 
  Activitiesdata.map((activity, index) => {
    const sportsActivities = JSON.parse(activity.sports_activities);
   
    return (
      <>

        {sportsActivities.map((item, i) => (

          <div  className="DivCustom20" key={i}>
            <div className="DivCustom21">

        <div className="DivCustom22" >
             <div className="DivCustom23">
              <Avatar className='Colleges_Avatar_img' src={sportimage}>

</Avatar>

</div>
<div className="DivCustom23">
            <typography className="TypographyCustom13"> {item[`high-school-name-${i}`] ?? ''}</typography>
            <typography className='Personal_sports_text3'>{item[`participated-as${i}`] ?? ''}</typography>
            {/* <typography style={{ fontWeight: '600', fontSize: '16px' }}>Level {item[`high-activities-select-${i}`]}</typography> */}
            <Chip
  label={`${item[`high-activities-select-${i}`]} `}
  color="primary"
  size="small"
  variant="filled"
  sx={{width:"fit-content",marginBottom:"15px"}}
/>
<div>


<typography  className='Personal_text2'> {item[`description-${i}`] && (
    item[`description-${i}`].length > 10
      ? `${item[`description-${i}`].substring(0, 70)}...`
      : item[`description-${i}`]
  )}</typography>
</div>
            {/* <typography className="TypographyCustom14">Level {item[`high-activities-select-${i}`]}</typography> */}
            </div>
          
            </div>
            {/* <div style={{ borderLeft: "2px solid black", height: "100%" }}></div>   */}
            </div>
            <div className='DivCustom5'  style={{display:"flex",gap:"10px"}}>

           
            <Link
            className="LinkCustom2"
  to="#"
  
  onClick={(e) => {
    e.preventDefault();
    handleEditClickActivities(activity.id);
  }}
>
  <ModeEditOutlineOutlinedIcon className='Mui_edit_icon'  sx={{color:'#58595B',padding:0}}/>
</Link>
<Link
  to="#"
  className='Text_decoration'
  onClick={() => handlecollegeDelete(activity.id, 'activity')}
><DeleteOutlinedIcon className='Mui_edit_icon'  sx={{color:'#D32F2F',padding:0}}/></Link>
{index % 2 === 0 && index < Activitiesdata.length  && (
  <div className="DividerCustom4" style={{ marginRight: "20px", marginLeft: "20px" }}></div>
)}

</div>
          </div>
          
        ))}
      </>
    );
  })
) : (
  // Render the default content when there is no data
  <Box className="BoxCustom19">
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} /> Add any extracurricular activities you participated in
      </typography>
    </Box>
    <Link to={`/activities`} className='Text_decoration'>
      <Button variant="outlined"> + ADD ACTIVITY</Button>
    </Link>
  </Box>
)}

</div>


  </Box>
   </Grid>
    

   <Grid item
   className="GridItemCustom6">
    <Box className="BoxCustom15" >

   
  <Box  className="BoxCustom16">
    <typography className="TypographyCustom12">Work Experience</typography>
    <Box className="BoxCustom17">
    {Experiencedata && Experiencedata.length > 0 ? (
      <Link to={`/Collaborator_work_experience`} className='Text_decoration'><AddOutlinedIcon sx={{color:'#58595B'}}/> </Link>
        ):(null)}
  
    </Box>
  </Box>
  {/* <div className='Collaborator_completeprofile_activities' style={{display:"flex",justifyContent:"space-between"}}> */}
  {Experiencedata && Experiencedata.length > 0 ? (
  // Render the extra-curricular activities data
 
  Experiencedata.map((activity, index) => {
    const work_experience = JSON.parse(activity.work_experience);
   
    return (
      <>
      <div className='collaborator_view_experience_div' key={index} style={{display:"flex",justifyContent:"space-between",flexDirection:""}}>
        {work_experience.map((item, i) => (
          <div key={i} >
            <div style={{display:"flex",flexDirection:"row",gap:'15px'}}>
            <Avatar  src={Experiance_icon} />

        <div style={{display:"flex",flexDirection:"row",gap:10}}>
     
<div style={{display:"flex",flexDirection:"column",gap:10}}>
            <typography  className='Personal_text_main'> {item[`Title-${i}`]}</typography>
            <typography style={{ fontWeight: '600', fontSize: '16px' }}>{item[`organization-name-${i}`]} - {item[`job-type-${i}`]}</typography>
            
            <Box>
           <CalendarTodayIcon sx={{height:"15px",width:"15px",color:"#2E7D32"}}/> <typography className="workDate">
  {new Date(item[`start-date-${i}`]).toLocaleString('default', { month: 'short' })}{' '}
  {new Date(item[`start-date-${i}`]).getFullYear()} -{' '}
  
  {item[`options-${i}`] !== 'yes' ? (
  <>
    {new Date(item[`end-date-${i}`]).toLocaleString('default', { month: 'short' })}{' '}
    {new Date(item[`end-date-${i}`]).getFullYear()}
  </>
) : (
  'Present' // or any text you want to display when `item[`options-${i}`]` is 'no'
)}
</typography>
            </Box>
  <typography className="job_text">Job Description:</typography>
<typography  className='Personal_text2'>{item[`job-description-${i}`]}</typography>

            </div>
          
            </div>
        
            </div>
          
          </div>
        ))}
         <div className='DivCustom5'  style={{display:"flex",gap:"10px"}}>

           
<Link
className="LinkCustom2"
to="#"

onClick={(e) => {
e.preventDefault();
handleEditClickExperience(activity.id);
}}
>
<ModeEditOutlineOutlinedIcon className='Mui_edit_icon'  sx={{color:'#58595B',padding:0}}/>
</Link>
<Link
to="#"
className='Text_decoration'
onClick={() => handlecollegeDelete(activity.id, 'experience')}
><DeleteOutlinedIcon className='Mui_edit_icon'  sx={{color:'#D32F2F',padding:0}}/></Link>
{/* {index % 2 === 0 && index < Experiencedata.length  && (
<div className="DividerCustom4" style={{ marginRight: "20px", marginLeft: "20px" }}></div>
)} */}

</div>
      </div>
      {index < Experiencedata.length - 1 && (
          // Add Divider only if it's not the last item in the array
          <Divider className="DividerCustom2" variant="inset"/>
        )}
      </>
    );
  })
) : (
  // Render the default content when there is no data
  <Box className="BoxCustom19">
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} />Add any work experience you have
      </typography>
    </Box>
    <Link to={`/Collaborator_work_experience`} className='Text_decoration'>
      <Button variant="outlined"> + ADD EXPERIENCE</Button>
    </Link>
  </Box>
)}

{/* </div> */}


  </Box>
   </Grid>

   <Grid item
   className="GridItemCustom7">
    <Box className="BoxCustom20">

   
  

  {Summarydata.length > 0 ? (
  // Render the personal essay content
  <div >
   
    {Summarydata.map((summary, index) => (
      <div key={index} >
         <Box className="BoxCustom21">
    <typography className="TypographyCustom9">Personal Essay</typography>
    <div className='DivCustom5' style={{display:"flex",gap:"10px"}}>

    
    <Link
       
       to="#"
       className='Text_decoration'
       onClick={(e) => {
         e.preventDefault();
         handleEditClickSummary(summary.id);
       }}
     >
       <ModeEditOutlineOutlinedIcon className='Mui_edit_icon'  sx={{color:'#58595B'}}/>
     </Link> 
     <Link
  to="#"
  className='Text_decoration'
  onClick={() => handlecollegeDelete(summary.id, 'summary')}
><DeleteOutlinedIcon className='Mui_edit_icon'  sx={{color:'#D32F2F'}}/></Link>
</div>
  </Box>
  {summary.personal_eassay ? (
        <typography className="Summary_desc">
          {showFullSummary
            ? summary.personal_eassay
            : summary.personal_eassay.length > 100
            ? `${summary.personal_eassay.substring(0, 600)}...`
            : summary.personal_eassay}
          {summary.personal_eassay.length > 100 && (
            <span className="ViewMoreLink" onClick={handleViewMore}>
              {showFullSummary ? '  View Less' : '  View More'}
            </span>
          )}
        </typography>
      ) : (
        <typography className="NoSummaryMessage">No summary available</typography>
      )}
        {/* <typography className="Summary_desc">{summary.personal_eassay}</typography> */}
     
      </div> 
    ))}
  </div>
) : (
  // Render the default content when there is no data
  <Box className="BoxCustom22">
        <Box className="BoxCustom23">
    <typography className="TypographyCustom15">Personal Essay</typography></Box>
    <Box>
      <typography className="Complete_profile_desc">
        <InfoOutlinedIcon sx={{ color: "orange" }} />Add your personal essay here
      </typography>
    </Box>
    <Link to={`/summary`}className='Text_decoration'>
      <Button variant="outlined"> + ADD ESSAY</Button>
    </Link>
  </Box>
)}


  </Box>
   </Grid>
   </Grid>
   <Grid item 
    className="GridItemCustom8"
       lg={3.2}
       xl={2.13}
       xs={5.3}
       sm={5}
       md={3.2}
     >
     
   <Box className="Profile-view" >
    <typography className="Profile_view_Percent">Complete your profile</typography>
   <CircularProgressWithLabel value={progress} color="success"   />
   <Box className="profile_view_Percet_container">
   <typography className="Profile_view_Percent2"><CheckCircleOutlineIcon sx={{color:"green"}}/>Personal Information :  10%</typography>
   <typography className="Profile_view_Percent2">{Collegesdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Colleges Applied to :  40%</typography>
   <typography className="Profile_view_Percent2">{HighSchooldata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}High School Education :  40%</typography>
   <typography className="Profile_view_Percent2">{Honoursdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Honors : Recommended </typography>
   <typography className="Profile_view_Percent2">{Testingdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Testing : Recommended   </typography>
   <typography className="Profile_view_Percent2">{Activitiesdata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Activities :  10%</typography>
          <typography className="Profile_view_Percent2">{Experiencedata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Work Experience : Recommended   </typography>
   <typography className="Profile_view_Percent2"> {Summarydata.length > 0 ? (
            <CheckCircleOutlineIcon sx={{ color: "green" }} />
          ) : (
            <InfoOutlinedIcon sx={{ color: "orange" }} />
          )}Personal Essay : Recommended  </typography>
   
  
  
  
 
   {/* <Link  to={`/Profile_submitted`}> */}
   <Button onClick={() => {
    
    handleClick();
}}
  sx={{ boxShadow:"none",'&.Mui-disabled': {
    pointerEvents: 'visible', // Override the pointer-events property
  },}}
   variant='contained' disabled={isButtonDisabled }  title={isButtonDisabled ? 'Fill up your profile for submission' : ''}> {isLoading ? (
    // Display the circular loading icon
    <CircularProgress size={24} color="inherit" />
  ) : (
    // Display the "SUBMIT" text
    "SUBMIT FOR APPROVAL"
  )}</Button>
{profileuser && profileuser.profile_status !== null && (
  profileuser.profile_status === 'rejected' ? (
    <Chip 
      color="error" 
      size="small" 
      variant="filled"
      sx={{
        width:"fit-content",
        padding:'15px',
        margin:'15px',
        display:'flex',
        alignSelf:'center',
        cursor: 'pointer' // Change cursor to pointer to indicate clickable
      }}
      label="Rejected"
      onClick={handleOpen2} // Add onPress event handler
    />
  ) : (
    <Chip 
      color={
        profileuser.profile_status === "accepted" ? "success" :
        profileuser.profile_status === "pending" ? "warning" :
        "primary"
      }
      size="small" 
      variant="filled"
      sx={{
        width:"fit-content",
        padding:'15px',
        margin:'15px',
        display:'flex',
        alignSelf:'center'
      }}
      label={
        profileuser.profile_status === 'accepted'
        ? 'Accepted'
        : 'Pending'
      }
    />
  )
)}








      {/* <div className='backdropStyle' style={{ display: open ? 'block' : 'none',}} onClick={handleClose}>
        <Profile_submitted color="inherit" />
      </div> */}
   </Box>

   </Box>

       </Grid>



     


      </Grid>
    
      </div>
      
  )
}

export default CompleteProfile