import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import mainlogo from '../../assets/img/mainwelcome.png'

import './Students.css'

const StudentHome = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
      const userData = JSON.parse(localStorage.getItem('userData'));
  return (
    <div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center',background: "rgba(255, 255, 255, 0.80)",
    backdropfilter: "blur(25px)",}}>
    <Grid container className='Home_collaboraotor_view'
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    sx={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}
   >
    
      <Grid  lg={12}
    xl={12}
    xs={10}
    sm={6}
    md={12} sx={{display:"flex",flexDirection:"column",padding:"20px"}} className="Student_profile_view_mainDiv" >

<img src={mainlogo} alt="Logo"  className="Student_profile-view-main"/>

<typography className="Student_Profile_view_welcome">Welcome, {userData.legal_name}!</typography>
<typography className="Student_Profile_view_desc"> Congratulations on taking this first step <br />
      in the college application process!</typography>
<Link to={'/student_complete_profile'} sx={{textDecoration:"none"}}><Button variant='outlined' sx={{mt:6}}>Complete your Profile</Button></Link>
</Grid>

    </Grid>
   
 </div>
)
  
}

export default StudentHome
