import React,{useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import Topbar from '../Students/Topbar'
import { Box, Chip, Divider } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Collaborator_college_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
  const [type, setType] = useState()
  const [userId, setUserId] = useState();
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 
  const breadcrumbs = [
    <Link
      underline="hover"
      
      style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}
      key="1"
      color="inherit"
 
      href={
        type === 'student'
          ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
          : `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
      }
    >
       <ArrowBackIosIcon fontSize="meduim" />
     BACK TO PROFILE
    </Link>
 
    // <Typography key="3" color="text.primary">
    // Colleges applied for
    // </Typography>
  ];
    const [formData, setFormData] = useState({
        collegeState:'',
        collegeName: '',
        highSchoolLocation: '',
        major: [],
        financialAid: [],
        admissionPlan: '',
        startTerm: '',
        courseType: '',
        supplementaryEssay: '',
        decisionOptions: '',
        aidType: '',
        scholarshipName: '',
        durationOfAid: '',
        aidPackagePerUnit: '',
        recommendersData: [],
        yourInterest: '',
        userId: '',
        formId: '',
      });
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const userData = JSON.parse(localStorage.getItem('userData'));
            const userId = userData.id;
    
            const searchParams = new URLSearchParams(window.location.search);
            const selectedCollegeId = searchParams.get('id');
            const type = searchParams.get('type');
            setType(type)
            if (selectedCollegeId) {
              const response = await fetch(`${apiUrl}/get_colleges_form_data`, {
                method: 'post',
                body: JSON.stringify({ selectedCollegeId }),
              });
    
              if (response.status === 200) {
                const data = await response.json();
                const collegeData = data.CollegeData[0];
                console.log(collegeData,"collegeData")
                const collegeNameObj = JSON.parse(collegeData.collegeName || '{}');
                const collegestateObj = JSON.parse(collegeData.collegeName || '{}');
                const majorArray = JSON.parse(collegeData.major || '[]');
                const recommendersArray = JSON.parse(collegeData.recommendersData || '[]');
                const financialAidArray = JSON.parse(collegeData.financial_aid || '[]');
                const fetchedUserId = collegeData.userId || '';
                setUserId(fetchedUserId);
                setFormData({
                    collegeName: collegeNameObj.title || '',
                    collegeState: collegestateObj.state || '',

                  highSchoolLocation: collegeData.highSchoolLocation || '',
                  major: majorArray.map((major) => major.title),
                  financialAid: JSON.parse(collegeData.financial_aid || '[]'),
                  admissionPlan: collegeData.admission_plan || '',
                  startTerm: collegeData.start_term || '',
                  courseType: collegeData.course_type || '',
                  supplementaryEssay: collegeData.supplementary_eassy || '',
                  decisionOptions: collegeData.decisionOptions || '',
                
                  financialAid: financialAidArray.map((aid, index) => ({
                    aidType: aid[`aid_type${index}`] || '',
                    scholarshipName: aid[`socholarship_name${index}`] || '',
                    durationOfAid: aid[`duration_of_aid${index}`] || '',
                    aidPackagePerUnit: aid[`aid_package_per_unit${index}`] || '',
                  })),
                  recommendersData: recommendersArray.map((recommender, index) => ({
                    name: recommender[`recommenders_name${index}`] || '',
                    description: recommender[`recommenders_description${index}`] || '',
                  })),
                  yourInterest: collegeData.your_interest || '',
                  userId: userId || '',
                  formId: selectedCollegeId || '',
                });
              } else {
                throw new Error('Request failed');
              }
            }
          } catch (error) {
            console.error('Data fetch failed:', error);
          }
        };
    
        fetchData();
      }, []); // Add dependencies if needed
    
      const getMajorTitle = (majorArray) => {
        if (Array.isArray(majorArray) && majorArray.length > 0) {
          return majorArray[0].title || '';
        }
        return '';
      };

      const states = [
        { code: "AL", name: "Alabama" },
        { code: "AK", name: "Alaska" },
        { code: "AZ", name: "Arizona" },
        { code: "AR", name: "Arkansas" },
        { code: "CA", name: "California" },
        { code: "CO", name: "Colorado" },
        { code: "CT", name: "Connecticut" },
        { code: "DE", name: "Delaware" },
        { code: "FL", name: "Florida" },
        { code: "GA", name: "Georgia" },
        { code: "HI", name: "Hawaii" },
        { code: "ID", name: "Idaho" },
        { code: "IL", name: "Illinois" },
        { code: "IN", name: "Indiana" },
        { code: "IA", name: "Iowa" },
        { code: "KS", name: "Kansas" },
        { code: "KY", name: "Kentucky" },
        { code: "LA", name: "Louisiana" },
        { code: "ME", name: "Maine" },
        { code: "MD", name: "Maryland" },
        { code: "MA", name: "Massachusetts" },
        { code: "MI", name: "Michigan" },
        { code: "MN", name: "Minnesota" },
        { code: "MS", name: "Mississippi" },
        { code: "MO", name: "Missouri" },
        { code: "MT", name: "Montana" },
        { code: "NE", name: "Nebraska" },
        { code: "NV", name: "Nevada" },
        { code: "NH", name: "New Hampshire" },
        { code: "NJ", name: "New Jersey" },
        { code: "NM", name: "New Mexico" },
        { code: "NY", name: "New York" },
        { code: "NC", name: "North Carolina" },
        { code: "ND", name: "North Dakota" },
        { code: "OH", name: "Ohio" },
        { code: "OK", name: "Oklahoma" },
        { code: "OR", name: "Oregon" },
        { code: "PA", name: "Pennsylvania" },
        { code: "RI", name: "Rhode Island" },
        { code: "SC", name: "South Carolina" },
        { code: "SD", name: "South Dakota" },
        { code: "TN", name: "Tennessee" },
        { code: "TX", name: "Texas" },
        { code: "UT", name: "Utah" },
        { code: "VT", name: "Vermont" },
        { code: "VA", name: "Virginia" },
        { code: "WA", name: "Washington" },
        { code: "WV", name: "West Virginia" },
        { code: "WI", name: "Wisconsin" },
        { code: "WY", name: "Wyoming" },
        { code: "PR", name: "Puerto Rico" },
        { code: "GU", name: "Guam" },
        { code: "VI", name: "U.S. Virgin Islands" },
        { code: "AS", name: "American Samoa" },
        { code: "MP", name: "Northern Mariana Islands" },
        { code: "INTL", name: "International" }
      ];


      const getStateFullName = (stateCode) => {
        const state = states.find((s) => s.code === stateCode);
        return state ? state.name : '';
      };
  return (
    <div>
        {/* <Topbar2/> */}
        {type === 'student' ? <Topbar /> : <Topbar2 />}
         <Grid container 
    gap="20px"
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    className='Grid_Collaborator_college_detailpage'
   >
         <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_collegeinfo_detailpage'>
 <Box className='College_info_Box1'>
 <SchoolOutlinedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
    College information
  </typography>
 </Box>
    <Box className='College_info_Box2'>
      <Box className='College_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>College Name</typography>
        <typography className='College_name_secondary'>{formData.collegeName}</typography>
      </Box>
      <Box className='College_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>College Location</typography>
        <typography className='College_name_secondary'>{getStateFullName(formData.collegeState)}</typography>
      </Box>
      <Box className='College_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Admission Status</typography>
        <Chip
    
    sx={{ padding: "16px 8px",maxWidth:'fit-content' }}
    label={formData.decisionOptions}
    color={
      formData.decisionOptions === "Accepted" ? "success" :
      formData.decisionOptions === "Attended" ? "primary" :
      formData.decisionOptions === "Rejected" ? "error" :
      formData.decisionOptions === "Waitlisted" ? "warning" :
      formData.decisionOptions === "Deferred" ? "secondary" : "default"
    }
    size="small"
    variant="filled"
  />
      </Box>
    </Box>
  </Grid>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_generalinfo_detailpage'>
    <Box className='College_info_Box1'>
 <HelpOutlineIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  General
  </typography>
 </Box>
 <Box className='genral_info_main_Box'>
 <Box className='College_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Major</typography>
        <typography className='College_name_secondary'>{formData.major.join(', ')}</typography>
      </Box>
      <Box className='College_info_Box2'>
      <Box className='College_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Admission Plan</typography>
        <typography className='College_name_secondary'>{formData.admissionPlan}</typography>
      </Box>
      <Box className='College_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Start term</typography>
        <typography className='College_name_secondary'>{formData.startTerm}</typography>
      </Box>
      <Box className='College_info_Box2_secondary1'>
      <typography className='College_heading_secondary'>Type</typography>
        <typography className='College_name_secondary'>{formData.courseType}</typography>
      </Box>
    </Box>
    <Box className='College_info_Box2_secondary11' >
        <typography className='College_heading_secondary'>Supplementary essay</typography>
        <typography className='College_name_secondary'>{formData.supplementaryEssay} </typography>
      </Box>
      </Box>
  </Grid>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_additionalinfo_detailpage'>
 <Box className='additional_info_Box1'>
 <HelpCenterOutlinedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  Additional Information
  </typography>
 </Box>
 <Box className='additional_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Response</typography>
        <typography className='College_name_secondary'>{formData.yourInterest} </typography>
      </Box>
  </Grid>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_recommenderinfo_detailpage'>
 <Box className='Recommenders_info_Box1'>
 <Diversity1OutlinedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  Recommenders
  </typography>
 </Box>
 {formData.recommendersData.map((recommender, index) => (
  <>
 <Box key={index} className='recommender_info_Box2'>
      <Box className='recommender_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Name</typography>
        <typography className='College_name_secondary'>{recommender.name}</typography>
      </Box>
      <Box className='recommender_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Relationship</typography>
        <typography className='College_name_secondary'>{recommender.description}</typography>
      </Box>
      <Box className='recommender_info_Box2_secondary1'>
        
      </Box>
    </Box>
      {index < formData.recommendersData.length - 1 && <Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />}
      </>
     ))}
  </Grid>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_financialinfo_detailpage'>
 <Box className='Aid_info_Box1'>
 <PaidOutlinedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  Financial Aid
  </typography>
 </Box>
 {formData.financialAid.map((aid, index) => (
  <>
 <Box key={index} className='aid_info_main_Box'>
 <Box className='aid_info_Box2'>
      <Box className='aid_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Aid type</typography>
        <typography className='College_name_secondary'> {aid.aidType}</typography>
      </Box>
      <Box className='aid_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Scholarship name</typography>
        <typography className='College_name_secondary'>{aid.scholarshipName}</typography>
      </Box>
      <Box className='aid_info_Box2_secondary1'>
       
      </Box>
     
    </Box>
 <Box className='aid_info_Box2'>
      <Box className='aid_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Duration of aid/scholarship</typography>
        <typography className='College_name_secondary'>{aid.durationOfAid}</typography>
      </Box>
      <Box className='aid_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Financial Aid per unit (1 time, Annual, semesterly)</typography>
        <typography className='College_name_secondary'>${aid.aidPackagePerUnit}</typography>
      </Box>
      <Box className='aid_info_Box2_secondary1'>
      
      </Box>
     
    </Box>
    </Box>
     {index < formData.financialAid.length - 1 && <Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />}
     </>
 ))}
  </Grid>
   </Grid>
      </div>
  )
}

export default Collaborator_college_detailpage