import React,{useState,useEffect}  from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import { Box, Chip, Divider } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import dayjs from 'dayjs';
import Topbar from '../Students/Topbar'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tobar from '../collaborator/Tobar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Testing_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
    const checkuser = JSON.parse(localStorage.getItem('userData'));
    const urlParams = new URLSearchParams(window.location.search);
    const [formData, setFormData] = useState([]);
    const [view, setView] = useState();
    const [type, setType] = useState()
    const [userId, setUserId] = useState();
    function encodeAlphanumeric(id) {
      const encoded = btoa(id); // Using btoa to Base64 encode the ID
      // You can further process the encoded string if needed
      return encoded;
    } 
    const breadcrumbs = [
      <Link
        underline="hover"
        style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}     
           key="1"
        color="inherit"
        href={
          type === 'admin' && view === 'student'
            ? `/admin_student_profileview?id=${encodeAlphanumeric(userId)}`
            : type === 'admin'
              ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
              : type === 'student' && view === 'student'
              ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}&type=student`
              : type === 'student'
                ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
                : type === 'collaborator' && view === 'student'
              ? `/studentProfile?id=${encodeAlphanumeric(userId)}&type=collaborator`
              : null
        }
       
      >
        <ArrowBackIosIcon fontSize="meduim" />
       BACK TO PROFILE
      </Link>
   
     
    ];
    const [formid, setformid] = useState('');
   
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const selectedTestingId = searchParams.get('id');
      const view = searchParams.get('view');
      setView(view)
      setformid(selectedTestingId);
      const apiUrl = process.env.REACT_APP_API_URL;
      const userData = JSON.parse(localStorage.getItem('userData'));
      const type = searchParams.get('type');
      setType(type)
      if (selectedTestingId !== '') {
        const apiEndpoint = view === 'student' ? '/get_Student_testing_form_data' : '/get_testing_form_data';
        try {
          fetch(`${apiUrl}${apiEndpoint}`, {
            method: 'post',
            body: JSON.stringify({ formid: selectedTestingId }),
          })
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                throw new Error('Request failed');
              }
            })
            .then((data) => {
              const testingdata = data.formdata[0];
              
              console.log(testingdata, "setTestingdata");
              const fetchedUserId = testingdata.userId || '';
              setUserId(fetchedUserId);
              // Parse the testdata array from the API response
              const parsedTestData = JSON.parse(testingdata.testdata || '[]');
              // Organize the parsed data into an array of objects
              const formDataArray = parsedTestData.map((item, index) => ({
                testname: item[`testname-${index}`] || '',
                startDate: dayjs(item[`start-date-${index}`]).format('MM/DD/YYYY') || '',
                attempts: item[`attempts-${index}`] || '',
                score: item[`score-${index}`] || '',
               
              }));
              
             
              setFormData(formDataArray);
             console.log(formDataArray,"formDataArray")
             const testNames = formDataArray.map(item => item.testname);
    console.log(testNames,"testNames");
    
            })
            .catch((error) => {
              console.error('Data fetch failed:', error);
            });
        } catch (error) {
          console.error('Data upload failed:', error);
        }
      }
    }, []);
  return (
    <div>
    {type === 'student' ? <Topbar /> : type === 'collaborator' ? <Tobar />  :  <Topbar2 />}
     <Grid container 
gap="20px"
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Grid_Collaborator_Testing_detailpage'
>
<Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
<Grid
item
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Collaborator_Testing_info_detailpage'>
<Box className='Testing_info_Box1'>
<QuizOutlinedIcon className='College_info_Avatar' />
<typography className='College_heading'>
Testing</typography>
</Box>
{formData.map((test, index) => (
  <>
<Box key={index} className='Testing_info_main_Box'>
<Box className='Testing_info_Box2'>
  <Box className='Testing_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Test</typography>
    <typography className='College_name_secondary'>{test.testname}</typography>
  </Box>
  <Box className='Testing_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Date</typography>
    <typography className='College_name_secondary'>{test.startDate}</typography>
  </Box>
  <Box className='Testing_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>How many Attempts?</typography>
    <typography className='College_name_secondary'> {test.attempts}</typography>
  </Box>
</Box>
<Box className='Testing_info_Box2'>
  <Box className='Testing_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Highest Score</typography>
    <typography className='College_name_secondary'> {test.score}</typography>
  </Box>
 
</Box>
</Box>
<Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />
</>
  ))}
</Grid>




</Grid>
  </div>
  )
}

export default Testing_detailpage