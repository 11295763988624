import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Tabs, Tab, Button,Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation hook
import { useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/img/logo.png';
import AlternateLogoImage from '../../assets/img/logoupdate.png'; // Import the alternate logo image
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import './header.css'
const Headermain = () => {
  // Use the useLocation hook to get the current route
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleTabClick1 = (path) => {
    navigate(path);
    if (window.innerWidth < 680) {
      setDrawerOpen(false); // Close drawer on smaller screens after clicking a link
    }
  };
  const handleTabClick = () => {
    navigate('/'); // Replace '/' with the desired URL
  };
  const handleTabClick2 = () => {
    navigate('/Aboutus'); // Replace '/' with the desired URL
  };
  const handleTabClick3 = () => {
    navigate('/Pricing'); // Replace '/' with the desired URL
  };
  const handleTabClick4 = () => {
    navigate('/faqs'); // Replace '/' with the desired URL
  };
  const handleTabClick5 = () => {
    navigate('/contact_us'); // Replace '/' with the desired URL
  };
  const handleTabClick6 = () => {
    navigate('/welcome'); // Replace '/' with the desired URL
  };
  // Determine which image to display based on the route
  const logoImage = location.pathname === '/' ? AlternateLogoImage : LogoImage;

  return (
    <React.Fragment>
      <AppBar sx={{ backgroundColor: 'transparent', position: 'static' }} elevation={0}>
        <div className='Main_container' style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
          <div className='Main_logodiv'>
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
              <img src={logoImage} alt="Apply-app" />
            </Link>
          </div>
       
        
          <div className='Main_button' style={{ flex: 1, display: "flex", justifyContent: "center", }}>
            <Tabs  sx={{backgroundColor:"transparent"}}>
          
                <Tab label="Product" sx={{color:" #F5F5F5"}} style={{textTransform:"none" ,color:"#FFFF",fontSize:"16px"}} onClick={handleTabClick} />
                <Tab label="Company" sx={{color:" #F5F5F5"}}  style={{textTransform:"none",fontSize:"16px"}} onClick={handleTabClick2} />
                <Tab label="Plans" sx={{color:" #F5F5F5"}}  style={{textTransform:"none",fontSize:"16px"}} onClick={handleTabClick3} />
                <Tab label="FAQ" sx={{color:" #F5F5F5"}}  style={{textTransform:"none",fontSize:"16px"}}onClick={handleTabClick4}/>
                <Tab label="Contact Us" sx={{color:" #F5F5F5"}}  style={{ textTransform: "none",fontSize:"16px"}} onClick={handleTabClick5}  />
                <Tab label="Log In" sx={{color:" #F5F5F5"}}  style={{ textTransform: "none",fontSize:"16px"}} onClick={handleTabClick6}  />
              {/* <Tab label="Login" /> */}
            </Tabs>
          </div>

          <div className='Main_getstarted'>
            <Link to={`/welcome`} style={{ textDecoration: "none" }}>
              <Button size='large' sx={{ backgroundColor: 'white', color: '#006CB7',textTransform:'none', '&:hover': {
                color: 'white', boxShadow:'none',textTransform:'none'// Change text color to white on hover
              }, }} variant="contained">
                Get Started</Button>
            </Link>
          </div>
     
        <div className='Menu_icon'>
        <Toolbar>
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                 <ListOutlinedIcon/>
                </IconButton>
              </Toolbar>
        </div>
             
           
        </div>
      
    

      </AppBar>
      
      <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          variant="temporary"
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': { width: '60%', maxWidth: 300 },
          }}
        >
          <List>
         
            <ListItem button onClick={() => handleTabClick1('/')}>
              <ListItemText primary="Product" />
            </ListItem>
            <ListItem button onClick={() => handleTabClick1('/Aboutus')}>
              <ListItemText primary="Company" />
            </ListItem>
            <ListItem button onClick={() => handleTabClick1('/Pricing')}>
              <ListItemText primary="Plans" />
            </ListItem>
            <ListItem button onClick={() => handleTabClick1('/faqs')}>
              <ListItemText primary="FAQ" />
            </ListItem>
            <ListItem button onClick={() => handleTabClick1('/contact_us')}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button onClick={() => handleTabClick1('/welcome')}>
              <ListItemText primary="Log In" />
            </ListItem>
            <ListItem button onClick={() => handleTabClick1('/welcome')}>
              <ListItemText primary="Get Started" />
            </ListItem>
          </List>
        </Drawer>
    </React.Fragment>
  );
}

export default Headermain;
