import React, { useState,useRef,useEffect    } from 'react';
import Grid from '@mui/material/Grid';
import backgroundimage from '../../../assets/img/bg-img.png'
import { Box, Typography, Tabs, Tab } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from "@mui/material/Autocomplete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import './Student_profile.css'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation,useParams } from 'react-router-dom';
import Topbar from '../Topbar';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tobar from '../../collaborator/Tobar';
import { Topbar as Topbar2 } from '../../Admin/Topbar';

const Student_college_applied_for = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const urlParams = new URLSearchParams(window.location.search);
  const urlType = urlParams.get('type');
  const userid = urlParams.get('userid');
  function encodeAlphanumeric(userid) {
    const encoded = btoa(userid); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  } 
  console.log('urlType:', urlType);
    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const isSmallScreen = useMediaQuery('(max-width:680px)');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
    const location = useLocation();
    const navigate = useNavigate();
    const breadcrumbs = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href={urlType === 'student' ? '/student' : urlType === 'collaborator'?'/profileview' :urlType === 'admin'?`/Admin_student_profileView?id=${encodeAlphanumeric(userid)}` : '/student_complete_profile'}
       
      >
        Profile
      </Link>,
   
      <Typography key="3" color="text.primary">
      Colleges applying for
      </Typography>
    ];
    const [selectedMajorError, setSelectedMajorError] = useState('');
    const [collegeformid, setcollegeformid] = useState('');
    const [formid, setformid] = useState('');
      const [selectedOption, setSelectedOption] = useState(''); // To store the selected option
      const [selectedOption2, setSelectedOption2] = useState(''); // To store the selected option
      const [collegeName, setCollegeName] = useState('');
    const [highSchoolLocation, setHighSchoolLocation] = useState('');
    const [collegeNameError, setCollegeNameError] = useState('');
    const [highSchoolLocationError, setHighSchoolLocationError] = useState('');
    const [selectedMajor, setSelectedMajor] = useState([]);
    const [admissionplan, setAdmissionPlan] = useState('');
    const [admissionplanError, setadmissionplanError] = useState('');
  
    const [startterm, setStartTerm] = useState('');
    const [open, setOpen] = React.useState(false);
    const [starttermError, setstarttermError] = useState('');
  
    const [coursetype, setCourseType] = useState('');
    const [coursetypeError, setcoursetypeError] = useState('');
  
    const [supplementryeassy, setSupplementryEassy] = useState('');
    const [supplementryeassyError, setsupplementryeassyError] = useState('');
  
    const [aidtype, setAidType] = useState('');
    const [aidtypeError, setaidtypeError] = useState('');
    
    const [durationofaid, setDurationOfAid] = useState('');
    const [durationofaidError, setdurationofaidError] = useState('');
    const [socholarshipError, setsocholarshipError] = useState('');
    const [aidpackageperunitError, setaidpackageperunitError] = useState('');
    const [RecommerdsError, setecommerdsError] = useState('');
    const [DecisionoptionError, setDecisionoptionError] = useState('');
    const [yourinterestError, setyourinterestError] = useState('');
    // const [showAlert, setShowAlert] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [CollegeFormData, setCollegeFormData] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = useState([]);
    const [searchText, setsearchText] = useState('');
    const [formData, setFormData] = useState({
      collegeName: '',
      highSchoolLocation: '',
      major: '',
      admission_plan: '',
      start_term: '',
      course_type: '',
      supplementary_eassy: '',
     
      recommendersData: [],
      
      your_interest: '',
      userId:'',
      formid:'',
    });
    // const [urlType, setUrlType] = useState('');
   
    useEffect(() => {
    
      
      // Get the 'type' parameter value from the URL
    
        const searchParams = new URLSearchParams(location.search);
  
     
        const selectedCollegeId = searchParams.get('id'); //change this const to form id 
        setformid(selectedCollegeId);
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
        console.log('College ID:', selectedCollegeId); 
  
        if(selectedCollegeId !== ''){
          try {
          fetch(`${apiUrl}/get_student_colleges_form_data`, {   // chgange this url 
            method: 'post',
            body: JSON.stringify({ selectedCollegeId }), // pass the form id const here 
        })
        .then(response => {
            if (response.status === 200) {
                return response.json(); // Convert the response to JSON
            } else {
                throw new Error('Request failed');
            }
        })
        .then(data => {
          const collegeData = data.CollegeData[0];
          const hasData =
          collegeData.duration_of_aid ||
          collegeData.aid_package_per_unit ||
          collegeData.socholarship_name ||
          collegeData.aid_type;
  
        setSelectedOption(hasData ? 'yes' : 'no');
  const parsedMajor = JSON.parse(collegeData.major || '[]');
  const collgesNames = JSON.parse(collegeData.collegeName || '[]');
  setSelectedMajor(parsedMajor);
  setCollegeName(collgesNames);
  if (collegeData.decisionOptions === "Accepted") {
    setSelectedOption2("Accepted");
  } else if (collegeData.decisionOptions === "Rejected") {
    setSelectedOption2("Rejected");
  } else if (collegeData.decisionOptions === "Waitlisted") {
    setSelectedOption2("Waitlisted");
  } else if (collegeData.decisionOptions === "Deferred") {
    setSelectedOption2("Deferred");
  }
  const parsedRecommendersData = JSON.parse(collegeData.recommendersData || '[]');
    
  // Create an array to hold the prefilled recommenders
  const prefilledRecommenders = [];
  
  // Loop through the parsedRecommendersData and extract values
  for (let i = 0; i < parsedRecommendersData.length; i++) {
    const recommenderData = parsedRecommendersData[i];
    const nameKey = `recommenders_name${i}`;
    const descriptionKey = `recommenders_description${i}`;
   
    const prefilledRecommender = {
      [nameKey]: recommenderData[nameKey] || '', // Use the dynamic key to get the name
      [descriptionKey]: recommenderData[descriptionKey] || '', // Use the dynamic key to get the description
    };
  console.log(prefilledRecommenders,"prefilled recomenders"); 
    prefilledRecommenders.push(prefilledRecommender);
  }
  console.log(prefilledRecommenders,"prefilled recomenders"); 
  // Set the state with the prefilled recommenders
  setRecommenders(prefilledRecommenders);
  
          setCollegeFormData(data.CollegeData);  // change this to data.formdata 
            console.log(data, "collegesdata"); // Log the awardsData
            setFormData({
              collegeName: collegeData.collegeName || '',
              highSchoolLocation: collegeData.highSchoolLocation || '',
              major: collegeData.major || '',
              admission_plan: collegeData.admission_plan || '',
              start_term: collegeData.start_term || '',
              course_type: collegeData.course_type || '',
              supplementary_eassy: collegeData.supplementary_eassy || '',
             
              recommendersData: JSON.parse(collegeData.recommendersData) || [],
              your_interest: collegeData.your_interest || '',
              userId: userId || '',
              formid:selectedCollegeId || '',
            });
            console.log(prefilledRecommenders,"recommendersData")
        })
        .catch(error => {
            console.error('Data fetch failed:', error);
        });
    } catch (error) {
        console.error('Data upload failed:', error);
    }
        }
  
        
    },[location.search]);    
    
    
  
    
    
      const [isFieldsVisible, setIsFieldsVisible] = useState(true);
      const handleOptionChange = (event) => {
          setSelectedOption(event.target.value);
          // Update the visibility of fields based on the selection
          setIsFieldsVisible(event.target.value === 'yes');
        };
      
        const handleDecisionChange = (decision) => {
         setSelectedOption2(decision)
        setIsFieldsVisible(decision==='yes')
        };
        const cities = [
          "Aberdeen",
          "Abilene",
          "Aguada",
          "Aguas",
          "Aibonito",
          "Akron",
          "Albany",
          "Albuquerque",
          "Alexandria",
          "Allentown",
          "Alta",
          "Alto",
          "Amarillo",
          "Anaheim",
          "Anchorage",
          "Ann Arbor",
          "Antioch",
          "Apple Valley",
          "Appleton",
          "Arlington",
          "Arroyo",
          "Arvada",
          "Asheville",
          "Athens",
          "Atlanta",
          "Atlantic City",
          "Augusta",
          "Aurora",
          "Austin",
          "Baja",
          "Bakersfield",
          "Baltimore",
          "Barceloneta",
          "Barnstable",
          "Baton Rouge",
          "Bayamón",
          "Beaumont",
          "Bel Air",
          "Bellevue",
          "Berkeley",
          "Bethlehem",
          "Billings",
          "Birmingham",
          "Bloomington",
          "Boise",
          "Boise City",
          "Bonita Springs",
          "Boston",
          "Boulder",
          "Bradenton",
          "Bremerton",
          "Bridgeport",
          "Brighton",
          "Brownsville",
          "Bryan",
          "Buffalo",
          "Burbank",
          "Burlington",
          "Cambridge",
          "Camuy",
          "Canton",
          "Cape Coral",
          "Carolina",
          "Carrollton",
          "Cary",
          "Cathedral City",
          "Cayey",
          "Cedar Rapids",
          "Champaign",
          "Chandler",
          "Charleston",
          "Charlotte",
          "Chattanooga",
          "Chesapeake",
          "Chicago",
          "Chula Vista",
          "Ciales",
          "Cincinnati",
          "Clarke County",
          "Clarksville",
          "Clearwater",
          "Cleveland",
          "Coamo",
          "College Station",
          "Colorado Springs",
          "Columbia",
          "Columbus",
          "Concord",
          "Coral Springs",
          "Corona",
          "Corozal",
          "Corpus Christi",
          "Costa Mesa",
          "Dallas",
          "Daly City",
          "Danbury",
          "Davenport",
          "Davidson County",
          "Dayton",
          "Daytona Beach",
          "Deltona",
          "Denton",
          "Denver",
          "Des Moines",
          "Detroit",
          "Dorado",
          "Downey",
          "Duluth",
          "Durham",
          "Díaz",
          "El Monte",
          "El Paso",
          "Elizabeth",
          "Elk Grove",
          "Elkhart",
          "Erie",
          "Escondido",
          "Eugene",
          "Evansville",
          "Fairfield",
          "Fargo",
          "Fayetteville",
          "Fitchburg",
          "Flint",
          "Florida",
          "Fontana",
          "Fort Collins",
          "Fort Lauderdale",
          "Fort Smith",
          "Fort Walton Beach",
          "Fort Wayne",
          "Fort Worth",
          "Frederick",
          "Fremont",
          "Fresno",
          "Fullerton",
          "Gainesville",
          "Garden Grove",
          "Garland",
          "Gastonia",
          "Germán",
          "Gilbert",
          "Glendale",
          "Grand Prairie",
          "Grand Rapids",
          "Grayslake",
          "Green Bay",
          "GreenBay",
          "Greensboro",
          "Greenville",
          "Guayanilla",
          "Gulfport-Biloxi",
          "Gurabo",
          "Hagerstown",
          "Hampton",
          "Harlingen",
          "Harrisburg",
          "Hartford",
          "Hatillo",
          "Havre de Grace",
          "Hayward",
          "Hemet",
          "Henderson",
          "Hesperia",
          "Hialeah",
          "Hickory",
          "High Point",
          "Hollywood",
          "Honolulu",
          "Houma",
          "Houston",
          "Howell",
          "Humacao",
          "Huntington",
          "Huntington Beach",
          "Huntsville",
          "Independence",
          "Indianapolis",
          "Inglewood",
          "Irvine",
          "Irving",
          "Isabel",
          "Jackson",
          "Jacksonville",
          "Jayuya",
          "Jefferson",
          "Jersey City",
          "Johnson City",
          "Joliet",
          "Juan",
          "Kailua",
          "Kalamazoo",
          "Kaneohe",
          "Kansas City",
          "Kennewick",
          "Kenosha",
          "Killeen",
          "Kissimmee",
          "Knoxville",
          "Lacey",
          "Lafayette",
          "Lajas",
          "Lake Charles",
          "Lakeland",
          "Lakewood",
          "Lancaster",
          "Lansing",
          "Laredo",
          "Las",
          "Las",
          "Las Cruces",
          "Las Vegas",
          "Layton",
          "Leominster",
          "Lewisville",
          "Lexington",
          "Lincoln",
          "Little Rock",
          "Loiza",
          "Long Beach",
          "Lorain",
          "Lorenzo",
          "Los Angeles",
          "Louisville",
          "Lowell",
          "Lubbock",
          "Macon",
          "Madison",
          "Manatí",
          "Manchester",
          "Marina",
          "Marysville",
          "Maunabo",
          "McAllen",
          "McHenry",
          "Medford",
          "Melbourne",
          "Memphis",
          "Merced",
          "Mesa",
          "Mesquite",
          "Miami",
          "Milwaukee",
          "Minneapolis",
          "Miramar",
          "Mission Viejo",
          "Mobile",
          "Moca",
          "Modesto",
          "Monroe",
          "Monterey",
          "Montgomery",
          "Moreno Valley",
          "Murfreesboro",
          "Murrieta",
          "Muskegon",
          "Myrtle Beach",
          "Naguabo",
          "Naperville",
          "Naples",
          "Nashua",
          "Nashville",
          "New Bedford",
          "New Haven",
          "New London",
          "New Orleans",
          "New York",
          "New York City",
          "Newark",
          "Newburgh",
          "Newport News",
          "Norfolk",
          "Normal",
          "Norman",
          "North Charleston",
          "North Las Vegas",
          "North Port",
          "Norwalk",
          "Norwich",
          "Oakland",
          "Ocala",
          "Oceanside",
          "Odessa",
          "Ogden",
          "Oklahoma City",
          "Olathe",
          "Olympia",
          "Omaha",
          "Ontario",
          "Orange",
          "Orem",
          "Orlando",
          "Orocovis",
          "Overland Park",
          "Oxnard",
          "Palm Bay",
          "Palm Springs",
          "Palmdale",
          "Panama City",
          "Pasadena",
          "Paterson",
          "Pembroke Pines",
          "Pensacola",
          "Peoria",
          "Peñuelas",
          "Philadelphia",
          "Phoenix",
          "Pittsburgh",
          "Plano",
          "Pomona",
          "Pompano Beach",
          "Port Arthur",
          "Port Orange",
          "Port Saint Lucie",
          "Port St. Lucie",
          "Portland",
          "Portsmouth",
          "Poughkeepsie",
          "Providence",
          "Provo",
          "Pueblo",
          "Punta Gorda",
          "Quebradillas",
          "Racine",
          "Raleigh",
          "Rancho Cucamonga",
          "Reading",
          "Redding",
          "Reno",
          "Richland",
          "Richmond",
          "Richmond County",
          "Rio",
          "Riverside",
          "Roanoke",
          "Rochester",
          "Rockford",
          "Rojo",
          "Roseville",
          "Round Lake Beach",
          "Sabana",
          "Sacramento",
          "Saginaw",
          "Saint Louis",
          "Saint Paul",
          "Saint Petersburg",
          "Salem",
          "Salinas",
          "Salinas",
          "Salt Lake City",
          "San Antonio",
          "San Bernardino",
          "San Buenaventura",
          "San Diego",
          "San Francisco",
          "San Jose",
          "Santa Ana",
          "Santa Barbara",
          "Santa Clara",
          "Santa Clarita",
          "Santa Cruz",
          "Santa Maria",
          "Santa Rosa",
          "Sarasota",
          "Savannah",
          "Scottsdale",
          "Scranton",
          "Seaside",
          "Seattle",
          "Sebastian",
          "Sebastián",
          "Shreveport",
          "Simi Valley",
          "Sioux City",
          "Sioux Falls",
          "South Bend",
          "South Lyon",
          "Spartanburg",
          "Spokane",
          "Springdale",
          "Springfield",
          "St. Louis",
          "St. Paul",
          "St. Petersburg",
          "Stamford",
          "Sterling Heights",
          "Stockton",
          "Sunnyvale",
          "Syracuse",
          "Tacoma",
          "Tallahassee",
          "Tampa",
          "Temecula",
          "Tempe",
          "Thornton",
          "Thousand Oaks",
          "Toledo",
          "Topeka",
          "Torrance",
          "Trenton",
          "Tucson",
          "Tulsa",
          "Tuscaloosa",
          "Tyler",
          "Utica",
          "Vallejo",
          "Vancouver",
          "Vega",
          "Vega",
          "Vero Beach",
          "Victorville",
          "Vieques",
          "Virginia Beach",
          "Visalia",
          "Waco",
          "Warren",
          "Washington",
          "Waterbury",
          "Waterloo",
          "West Covina",
          "West Valley City",
          "Westminster",
          "Wichita",
          "Wilmington",
          "Winston",
          "Winter Haven",
          "Worcester",
          "Yabucoa",
          "Yakima",
          "Yauco",
          "Yonkers",
          "York",
          "Youngstown"];
      const top100Films = [
          // { title: "Computer Science" },
          // { title: "Biological sciences" },
          // { title: "Mathematics" },
          // { title: "Chemistry" },
          // { title: "Psychology" },
          // { title: "Accounting" },
          // { title: "Political science" },
          // { title: "Engineering" },
          // { title: "Anthropology" },
          // { title: "Economics" },
          // { title: "Marketing" },
          // { title: "Physics" },
          // { title: "Biochemistry" },
          // { title: "Mechanical Engineering" },
          // { title: "Philosophy" },
          // { title: "Chemical Engineering" },
          // { title: "Biomedical engineering" },
          // { title: "Business administration" },
          // { title: "Communications" },
          // { title: "Sociology" },
          // { title: "Electrical engineering" },
          // { title: "Computer Engineering" },
          // { title: "Primary education" },
          // { title: "Criminal justice" },
          // { title: "Neither" },
          { title: "Accounting" },
          { title: "Actuarial Science" },
          { title: "African American Studies" },
         { title: "African Studies" },
          { title: "Agricultural Business" },
          { title: "Agricultural Engineering" },
          { title: "Agricultural Science" },
          { title: "American Studies" },
          { title: "Animal Science" },
          { title: "Anthropology" },
          { title: "Applied Mathematics" },
          { title: "Architectural Design" },
          { title: "Architecture" },
          { title: "Art" },
          { title: "Art Education" },
          { title: "Art History" },
          { title: "Asian Studies" },
          { title: "Astronomy" },
          { title: "Astrophysics" },
          { title: "Athletic Training" },
          { title: "Biochemistry" },
          { title: "Biology" },
          { title: "Biomedical Engineering" },
          { title: "Biophysics" },
          { title: "Business Administration" },
          { title: "Business Analytics" },
          { title: "Business Information Systems" },
          { title: "Business Management" },
          { title: "Business Marketing" },
          { title: "Calculus" },
          { title: "Canadian Studies" },
          { title: "Cardiac Science" },
          { title: "Cartography and Geographic Information Science" },
          { title: "Cell and Molecular Biology" },
          { title: "Chemical Engineering" },
          { title: "Chemistry" },
          { title: "Child and Adolescent Development" },
          { title: "Chinese" },
          { title: "Civil Engineering" },
          { title: "Classical Studies" },
          { title: "Clinical Laboratory Science" },
          { title: "Cognitive Science" },
          { title: "Communication Studies" },
          { title: "Computer Engineering" },
          { title: "Computer Science" },
          { title: "Construction Engineering Management" },
          { title: "Culinary Arts and Management" },
          { title: "Dance" },
          { title: "Data Science" },
          { title: "Dental Hygiene" },
          { title: "Dentistry" },
          { title: "Developmental Biology" },
          { title: "Dietetics and Nutrition" },
          { title: "Digital Media" },
          { title: "Drama" },
          { title: "Early Childhood Education" },
          { title: "Economics" },
          { title: "Education" },
          { title: "Educational Psychology" },
          { title: "Electrical Engineering" },
          { title: "Engineering" },
          { title: "Engineering Physics" },
          { title: "English" },
          { title: "English Language Arts" },
          { title: "Entomology" },
          { title: "Entrepreneurship" },
          { title: "Environmental Engineering" },
          { title: "Environmental Science" },
          { title: "European Studies" },
          { title: "Exercise Science" },
          { title: "Exploratory" },
          { title: "Fashion Design" },
          { title: "Finance" },
          { title: "Film and Media" },
          { title: "Financial Planning" },
          { title: "Food and Nutrition" },
          { title: "Food Science and Technology" },
          { title: "Foreign Languages" },
          { title: "Forestry" },
          { title: "French" },
          { title: "Gender Studies" },
          { title: "General Business" },
          { title: "General Engineering" },
          { title: "Geography" },
          { title: "Geology" },
          { title: "German" },
          { title: "Graphic Design" },
          { title: "Greek" },
          { title: "Healthcare Management" },
          { title: "Healthcare Studies" },
          { title: "Health Informatics" },
          { title: "Health Sciences" },
          { title: "Health Services Administration" },
          { title: "History" },
          { title: "Hospitality Management" },
          { title: "Human Evolutionary Biology" },
          { title: "Human Resource Management" },
          { title: "Human Services" },
          { title: "Humanities" },
          { title: "Ibero-American Studies" },
          { title: "Information Systems" },
          { title: "Information Technology" },
          { title: "Interior Design" },
          { title: "International Business" },
          { title: "International Relations" },
          { title: "Italian" },
          { title: "Japanese" },
          { title: "Journalism" },
          { title: "Kinesiology" },
          { title: "Korean" },
          { title: "Landscape Architecture" },
          { title: "Latin" },
          { title: "Law" },
          { title: "Linguistics" },
          { title: "Management" },
          { title: "Marine Biology" },
          { title: "Marketing" },
          { title: "Mathematics" },
          { title: "Mechanical Engineering" },
          { title: "Medical Technology" },
          { title: "Microbiology" },
          { title: "Middle Eastern Studies" },
          { title: "Music" },
          { title: "Music Education" },
          { title: "Music Performance" },
          { title: "Neuroscience" },
          { title: "Neruology" },
          { title: "Nursing" },
          { title: "Nutrition" },
          { title: "Occupational Therapy" },
          { title: "Operations Management" },
          { title: "Optometry" },
          { title: "Organizational Behavior" },
          { title: "Other" },
          { title: "Paramedicine" },
          { title: "Parks, Recreation and Tourism Management" },
          { title: "Persian" },
          { title: "Petroleum Engineering" },
          { title: "Pharmacy" },
          { title: "Philosophy" },
          { title: "Physics" },
          { title: "Physiology" },
          { title: "Political Science" },
          { title: "Pre-Law" },
          { title: "Pre-Med" },
          { title: "Psychology" },
          { title: "Public Health" },
          { title: "Radiologic Technology" },
          { title: "Real Estate" },
          { title: "Religious Studies" },
          { title: "Respiratory Therapy" },
          { title: "Robotics" },
          { title: "Russian" },
          { title: "Social Work" },
          { title: "Sociology" },
          { title: "Spanish" },
          { title: "Speech Pathology" },
          { title: "Statistics" },
          { title: "Sustainable Development" },
          { title: "Teaching English to Speakers of Other Languages (TESOL)" },
          { title: "Theater" },
          { title: "Tourism" },
          { title: "Undeclared" },
          { title: "Veterinary Medicine" },
          { title: "Web Development" },
          { title: "Wildlife Conservation" },
          { title: "Women’s Studies" },
          { title: "Writing" },
          { title: "Zoology" }
          
        ];
        const states = [
          { code: "AL", name: "Alabama" },
          { code: "AK", name: "Alaska" },
          { code: "AZ", name: "Arizona" },
          { code: "AR", name: "Arkansas" },
          { code: "CA", name: "California" },
          { code: "CO", name: "Colorado" },
          { code: "CT", name: "Connecticut" },
          { code: "DE", name: "Delaware" },
          { code: "FL", name: "Florida" },
          { code: "GA", name: "Georgia" },
          { code: "HI", name: "Hawaii" },
          { code: "ID", name: "Idaho" },
          { code: "IL", name: "Illinois" },
          { code: "IN", name: "Indiana" },
          { code: "IA", name: "Iowa" },
          { code: "KS", name: "Kansas" },
          { code: "KY", name: "Kentucky" },
          { code: "LA", name: "Louisiana" },
          { code: "ME", name: "Maine" },
          { code: "MD", name: "Maryland" },
          { code: "MA", name: "Massachusetts" },
          { code: "MI", name: "Michigan" },
          { code: "MN", name: "Minnesota" },
          { code: "MS", name: "Mississippi" },
          { code: "MO", name: "Missouri" },
          { code: "MT", name: "Montana" },
          { code: "NE", name: "Nebraska" },
          { code: "NV", name: "Nevada" },
          { code: "NH", name: "New Hampshire" },
          { code: "NJ", name: "New Jersey" },
          { code: "NM", name: "New Mexico" },
          { code: "NY", name: "New York" },
          { code: "NC", name: "North Carolina" },
          { code: "ND", name: "North Dakota" },
          { code: "OH", name: "Ohio" },
          { code: "OK", name: "Oklahoma" },
          { code: "OR", name: "Oregon" },
          { code: "PA", name: "Pennsylvania" },
          { code: "RI", name: "Rhode Island" },
          { code: "SC", name: "South Carolina" },
          { code: "SD", name: "South Dakota" },
          { code: "TN", name: "Tennessee" },
          { code: "TX", name: "Texas" },
          { code: "UT", name: "Utah" },
          { code: "VT", name: "Vermont" },
          { code: "VA", name: "Virginia" },
          { code: "WA", name: "Washington" },
          { code: "WV", name: "West Virginia" },
          { code: "WI", name: "Wisconsin" },
          { code: "WY", name: "Wyoming" },
          { code: "PR", name: "Puerto Rico" },
          { code: "GU", name: "Guam" },
          { code: "VI", name: "U.S. Virgin Islands" },
          { code: "AS", name: "American Samoa" },
          { code: "MP", name: "Northern Mariana Islands" },
          { code: "INTL", name: "International" }
        ];
        const deleteCourse2 = (indexToDelete) => {
          const updatedCourses = recommenders.filter((_, index) => index !== indexToDelete);
          setRecommenders(updatedCourses);
        };  
      const [age, setAge] = React.useState('');
  
      // const handleChanges = (event) => {
      //   setAge(event.target.value);
      // };
    const [value, setValue] = React.useState(0);
  
    const handleChanges = (event) => {
      const { name, value } = event.target;
      // Update the corresponding state variable based on the input name
      if (name === 'college_name') {
       // setCollegeName(value);
        // Clear the validation error if the user starts typing
        //setCollegeNameError('');
      } else if (name === 'high_school_location') {
        setHighSchoolLocation(value);
        // Clear the validation error if the user starts typing
        setHighSchoolLocationError('');
      } else if (name === 'admission_plan') {
        setAdmissionPlan(value);
        // Clear the validation error if the user starts typing
        setadmissionplanError('');
      } else if (name === 'start_term') {
        setStartTerm(value);
        // Clear the validation error if the user starts typing
        setstarttermError('');
      } else if (name === 'course_type') {
        setCourseType(value);
        // Clear the validation error if the user starts typing
        setcoursetypeError('');
      } else if (name === 'aid_type') {
        setAidType(value);
        // Clear the validation error if the user starts typing
        setaidtypeError('');
      } else if (name === 'duration_of_aid') {
        setDurationOfAid(value);
        // Clear the validation error if the user starts typing
        setdurationofaidError('');
      }
      
  
  
      
  
  
      
  
      
    };
    const handleCollegeNameChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    // };
  
    const addYourInterestAndUserId = () => {
      if (!formData.your_interest) {
        const your_interest = document.getElementsByName('your_interest')[0].value;
        setFormData((prevData) => ({
          ...prevData,
          your_interest,
        }));
      }
    
      if (!formData.userId) {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
        setFormData((prevData) => ({
          ...prevData,
          userId,
        }));
      }
    };
 
    // Initialize navigate
  
    const moveToNextTab = async(userid) => {
      //const formData = {};
      if (urlType !== 'student'  && urlType !== 'collaborator' && urlType !== 'admin') {
      if(value === 0){
        //const collegeName = document.getElementsByName('college_name')[0].value;
        const collegeNamearray = collegeName;
        const highSchoolLocation = document.getElementsByName('high_school_location')[0].value;
        
        if (collegeNamearray.length === 0  && highSchoolLocation.trim() === '') {
          setCollegeNameError('College Name is required.');
          setHighSchoolLocationError('High School Location is required.');
          return false;
        } else if (collegeNamearray.length === 0 ) {
          setCollegeNameError('College Name is required.');
          return false;
        } else if (highSchoolLocation.trim() === '') {
          setHighSchoolLocationError('High School Location is required.');
          return false
        } else  {
          setCollegeNameError('');
          setHighSchoolLocationError('');
          
          formData.collegeName = collegeName;
          formData.highSchoolLocation = highSchoolLocation;
        }
      }
      if(value ===1){
        const major = selectedMajor;
        const admission_plan = document.getElementsByName('admission_plan')[0].value;
        const start_term = document.getElementsByName('start_term')[0].value;
        const course_type = document.getElementsByName('course_type')[0].value;
        const supplementary_eassy = document.getElementsByName('supplementary_eassy')[0].value;
        console.log(selectedMajor,"Major field");
        console.log(admission_plan,"admission_plan field");
        console.log(start_term,"start_term field");
        console.log(course_type,"course_type field");
        console.log(supplementary_eassy,"supplementary_eassy field");
        if (admission_plan.trim() === '' && start_term.trim() === '' && course_type.trim() === '' && supplementary_eassy.trim() === '') {
          setadmissionplanError('Admission Plan  is required.');
          setstarttermError('Start Term is required.');
          setcoursetypeError('Enrollment Type is required.');
          setSelectedMajorError('Major is required.');
      
          return false;
        }else{
          setadmissionplanError('');
          setstarttermError('');
          setcoursetypeError('');
          setsupplementryeassyError('');
          setSelectedMajorError('');
          
          formData.major = major;
          formData.admission_plan = admission_plan;
          formData.course_type = course_type;
          formData.supplementary_eassy = supplementary_eassy;
        }
        if(admission_plan.trim() === ''){
          setadmissionplanError('Admission Plan  is required.');
          return false;
        }else{
          setadmissionplanError('');
        }
        if(start_term.trim() === ''){
          setstarttermError('Start Term is required.');
          return false;
        }else{
          setstarttermError('');
        }
        if(course_type.trim() === ''){
          setcoursetypeError('Enrollment Type is required.');
          return false;
        }else{
          setcoursetypeError('');
        }
        if (major.length === 0) {
          setSelectedMajorError('Major is required.');
          return false;
        } else {
          setSelectedMajorError('');
        }
       
      }
      if(value === 2){
        const recommendersData = recommenders;
        console.log(recommendersData);
        const isDuplicaterec = recommendersData.some((current, index) =>
        recommendersData.slice(0, index).some((other) => {
           const currentKeys = Object.keys(current).sort();
           const otherKeys = Object.keys(other).sort();

           return (
             currentKeys.length === otherKeys.length &&
             currentKeys.every((key, i) => current[key] === other[otherKeys[i]]) &&
             current.recommenders_name === other.recommenders_name &&
             current.recommenders_description === other.recommenders_description 
             
           );
         })
       );
       console.log(isDuplicaterec, "hasDuplicates");
       if (isDuplicaterec) {
         // Scholarships contain duplicates
         setecommerdsError('Duplicate data. Please enter unique values for each recommenders.');
         return false;
       }
       const hasEmptyField = recommendersData.some((recommender, index) => {
        return !recommender[`recommenders_name${index}`] || !recommender[`recommenders_description${index}`];
      });
    
      // if (hasEmptyField) {
      //   setecommerdsError('All fields are mandatory');
      //   return false;
      // }
      setecommerdsError('');
          formData.recommendersData = recommendersData;
        
      }
     
      
      
  
      
      if (value === 3) {
        // If on the last tab, navigate to "/complete_profile" route
        const your_interest = document.getElementsByName('your_interest')[0].value;
        
        // if(your_interest.trim() === ''){
        //   setyourinterestError("Please fill your intrest fields");
        //   return false;
        // }else{
        //   setyourinterestError("");
      
          
        // }
        const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;
          // alert(userId);
          formData.your_interest = your_interest;
          formData.userId = userId;
         
          if(formid!== ''){
            // alert(formid);
            formData.formid = formid; 
          }
          
         
        try {
          // Make a POST request to the API endpoint
          setIsLoading(true);
          setOpen(true);
          addYourInterestAndUserId();
          const apiUrl = process.env.REACT_APP_API_URL;
          
          const response = await fetch(`${apiUrl}/save_student_collges`, {
            method: 'post', 
            body: JSON.stringify(formData),
          }); 
          
          // Check the response status and handle it accordingly
          if (response.status === 201) {
           // setShowAlert(false);
           
          //  setShowAlert(true);
            setShouldNavigate(true);
            //navigate('/complete_profile');     
          } else if(response.status === 500){
            //moveToNextTab();
          }
            // Registration successful, you can handle the response data here
           
        } catch (error) {
          setIsLoading(false);
          setOpen(false);
          // Handle network errors or other exceptions
          console.error('Registration failed:', error);
         
        }
        //navigate('/complete_profile');
      } else {
        // Increment the value state to move to the next tab
        setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
      }
    }
    else if (value === 3 && urlType === 'student'){
      // alert('hello')
      window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}&type=student`;
    }
    else if (value === 3 && urlType === 'collaborator'){
      // alert('hello')
      window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}&type=collaborator`;
    }
    else if (value === 3 && urlType === 'admin'){
      // alert('hello')
      window.location.href = `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}&type=student`;
    }
    else {
      // Increment the value state to move to the next tab
      setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
    }
    };
    
    const handleBackButton = (userid) => {
      if (value === 0) {
        if (urlType === 'student') {
          if (userid) {
            window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}&type=student`;
          } else {
            navigate('/Student');
          }
        } else if (urlType === 'collaborator') {
          if (userid) {
            window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}&type=collaborator`;
          }
           else {
            navigate('/profileview');
          }
        }
        else if (urlType === 'admin') {
          if (userid) {
            window.location.href = `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}&type=student`;
          }
           else {
            navigate('/admin-dashboard');
          }
        }  else {
          navigate('/student_complete_profile');
        }
      } else {
        // Decrement the value state to move to the previous tab
        setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
      }
    };
    const [recommenders, setRecommenders] = useState([
      {  }, // Initial set of fields
    ]);
  
    // const handleChanges2 = (event, index) => {
      
    //   const { name, value } = event.target;
    //   const updatedRecommenders = [...recommenders];
    //   updatedRecommenders[index][name] = value;
    //   console.log(updatedRecommenders);
    //   setRecommenders(updatedRecommenders);
    // };
    const handleChanges2 = (event, index) => {
      const { name, value } = event.target;
      if (name.includes("recommenders_name")) {
        // Validation: Allow only alphabets, spaces, and limit to 75 characters
        const sanitizedValue = value.replace(/[<>\\]/g, '').slice(0, 50);
        if (sanitizedValue.length === value.length) {
          
         
          setecommerdsError(''); // Clear the error if within the limit
          const updatedRecommenders = [...recommenders];
          updatedRecommenders[index][name] = value;
          console.log(updatedRecommenders);
          setRecommenders(updatedRecommenders);
        } else {
          setecommerdsError('Invalid input. Please avoid special characters and limit to 50 characters.');
        }
    
        
      } else {
        // For other fields, update the state directly without validation
        const updatedRecommenders = [...recommenders];
        updatedRecommenders[index][name] = value;
        console.log(updatedRecommenders);
        setRecommenders(updatedRecommenders);
      }
      // const updatedRecommenders = [...recommenders];
      // updatedRecommenders[index][name] = value;
      // console.log(updatedRecommenders);
      // setRecommenders(updatedRecommenders);
    };
  
    // const addRecommender = () => {
    //   setRecommenders([...recommenders, {  }]);
    // };
    
  const addRecommender = () => {
  
    const lastRecommender = recommenders[recommenders.length - 1];
  
    // Check if the last recommender is not empty
    const isLastRecommenderEmpty = Object.values(lastRecommender).every(
      (value) => value === undefined || value === ''
    );
  
    if (!isLastRecommenderEmpty) {
      // Check for duplicates
      const isDuplicateRec = recommenders.slice(0, recommenders.length - 1).some((existingRecommender) =>
        Object.entries(existingRecommender).every(
          ([key, val]) => lastRecommender[key] === val
        )
      );
  
      if (!isDuplicateRec) {
        // Add a new empty recommender
        setRecommenders([...recommenders, {}]);
        setecommerdsError('');
      } else {
        setecommerdsError('Duplicate data. Please enter unique values for each recommender.');
      }
    } else {
      setecommerdsError('Please fill in all fields before adding another recommender.');
    }
  };
  
    const getRecommendersData = () => {
      // Map the recommenders state to get the entered values
      return recommenders.map((recommender) => ({
        name: recommender.recommenders_name,
        description: recommender.recommenders_description,
      }));
    };
    
    useEffect(() => {
      if (shouldNavigate) {
        const navigateTimer = setTimeout(() => {
          // Navigate to the desired page after 2 seconds
          // setShowAlert(false);
          setIsLoading(true);
          setOpen(true);
          navigate('/student_complete_profile'); 
        }, 3000);
  
        // Clean up the timer to avoid memory leaks
        return () => clearTimeout(navigateTimer);
      }
    }, [shouldNavigate]);
    const handleSearchColleges = (searchText) => {
      const apiKey = 'xjbOja8DPH9EuBj6HUj8EJaj'; // Replace with your API key
      const apiUrl = `https://api.collegeai.com/v1/api/autocomplete/colleges?api_key=${apiKey}&query=${searchText}`;
  
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data, "colleges Names");
          console.log(data,"colleges Names");
          const updatedColleges = data.collegeList.map((college) => ({
            title: college.name,
            state: college.state
          }));
          setOptions(updatedColleges);
          if (data && data.collegeList) {
            setOptions(data.collegeList.map((college) => ({
              title: college.name,
              state: college.state
            })));
          }
        })
        .catch((error) => {
          console.error('Error fetching colleges:', error); 
        });
    };
   
    // const handleSupplementaryChange = (e) => {
    //   const inputValue = e.target.value;
    
    //   // Check for special characters using a regular expression
    //   const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(inputValue);
    
    //   // Limit word count to 200
    //   const wordCount = inputValue.trim().split(/\s+/).length;
    
    //   if (!hasSpecialCharacters && wordCount <= 200) {
    //     setFormData({ ...formData, supplementary_eassy: inputValue });
    //   }
    // };
    const handleSupplementaryChange = (value) => {
      // Remove special characters using a regular expression
      // const sanitizedValue = value.replace(/[^A-Za-z0-9\s.-]/g, '');`s
    
      // Split the string into words and check the word count
      const words = value.split(/\s+/);
      const wordCount = words.length;
    
      if (wordCount <= 20000) {
        // Update the state with the sanitized value
        setFormData({ ...formData, supplementary_eassy: value });
        setsupplementryeassyError(''); // Clear the error if it was previously set
      } else {
        setsupplementryeassyError('Supplementary essay cannot exceed 200 words.');
      }
    };
    const handleGpaScoreChange = (e) => {
      const inputValue = e.target.value;
    
      // Check for special characters using a regular expression
      const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(inputValue);
    
      // Limit word count to 200
      const wordCount = inputValue.trim().split(/\s+/).length;
    
      if (!hasSpecialCharacters && wordCount <= 300) {
        setFormData({ ...formData, your_interest: e.target.value });
      }
  
      
    };
    const handleYourInterestChange = (value) => {
      // Validation: Allow only alphabets, spaces, and limit to 650 words
      const sanitizedValue = value.replace(/[<>\\]/g, '');
    
      // Check if the sanitized value is numeric
      const isNumeric = /^\d+$/.test(sanitizedValue);
    
      // Check if the length exceeds 650 words
      const words = sanitizedValue.split(/\s+/);
      const wordCount = words.length;
    
      if (!isNumeric && wordCount <= 65000) {
        // Update the state with the sanitized value
        setFormData({ ...formData, your_interest: sanitizedValue });
        setyourinterestError(''); // Clear the error if it was previously set
      } else {
        setyourinterestError('Invalid input. Please avoid special characters, numeric values, and limit your response to 650 words.');
      }
    };
    const filteredOptions = top100Films.filter(
      (option) => !selectedMajor.some((selected) => selected.title === option.title)
    );
    
    
    return (
      <div>
    
        
        {/* {urlType === 'student' ?  <Topbar /> : <Tobar/>} */}
        {urlType === 'student' ? <Topbar /> : (urlType === 'admin' ? <Topbar2 /> : <Tobar />)}
        {/* <Topbar /> */}
        <Grid
          container
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{
            padding: "20px",
            minHeight: "92vh",
            backgroundImage: `url(${backgroundimage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
          }}
        >
          <Grid
            item
            lg={12}
            xl={12}
            xs={12}
            sm={12}
            md={12}
            sx={{  padding: "20px",background: "rgba(255, 255, 255, 0.80)",
            backdropfilter: "blur(25px)",
             }}
          >
                    <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
            <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>College ID: {collegeId}</p> */}
      </Stack>
          
        
        <Box className="Test_BoxDiv">
          <Box className="student_collegeAplliedfor_form" sx={{ width:'50%' ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} aria-label="basic tabs example" sx={{    display:"flex",justifyContent:"space-between", '.css-k008qs':{
  display:"flex",justifyContent:"space-between"
              }, ...(isSmallScreen && {
          '.css-heg063-MuiTabs-flexContainer': {
        display:"flex",justifyContent:"space-between"
          },
          
        }),}}>
              <Tab label="COLLEGE INFO" />
          <Tab label="GENERAL" />
          {/* <Tab label="AID PACKAGES" /> */}
          <Tab label="RECOMMENDERS" />
          {/* <Tab label="STATUS" /> */}
          <Tab label="ADDITIONAL QUESTIONS" />
              
              </Tabs>
            </Box>
            <Box className="main-div">
  
           
            <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 0}>
            <div role="tabpanel" hidden={value !== 0} style={{  display: "flex", flexDirection: "column", justifyContent: "space-between", }}>
          <Box className="Test_paddingBox" style={{display:"flex",flexDirection:"column",flexWrap:"nowrap",alignSelf:"stretch",justifyContent:"space-between"}} >
    <div className='Test_FlexRow'>
    <Autocomplete
         fullWidth
      id="search-term-colleges"
      options={options}
      getOptionLabel={(option) => option.title || ''}
      value={collegeName}
      onChange={(event, newValue) => {
        setCollegeName(newValue);
        
        // Update the major field in your state when the user selects options
        setCollegeFormData((prevData) => ({
          ...prevData,
          collegeName: JSON.stringify(newValue), // Convert the selected options to a string and store them
          highSchoolLocation: newValue?.state || '',
        }));
        setFormData((prevData) => ({
          ...prevData,
          collegeName: JSON.stringify(newValue), 
          highSchoolLocation: newValue?.state || '', 
        }));
      }}
      onInputChange={(_, newInputValue) => {
        handleSearchColleges(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="College Name *"
          name="college_name"
          fullWidth
          size="small"
          error={Boolean(collegeNameError)}
          helperText={collegeNameError}
        />
      )}
      noOptionsText="Search College"
      disabled={urlType === 'student'|| urlType === 'collaborator'|| urlType === 'admin' }
    />
       {/*<TextField id="outlined-basic" label="College Name" name="college_name" variant="outlined" error={Boolean(collegeNameError)} helperText={collegeNameError} fullWidth size="small"   value={formData.collegeName} onChange={(e) => setFormData({ ...formData, collegeName: e.target.value })}  />*/}
      
      <FormControl fullWidth size="small" error={Boolean(highSchoolLocationError)}>
          <InputLabel id="demo-simple-select-label">College Location *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="high_school_location"
            value={formData.highSchoolLocation} // Set the value from formData
            label="College Location *"
            onChange={(e) => setFormData({ ...formData, highSchoolLocation: e.target.value })}
            error={Boolean(highSchoolLocationError)}
          helperText={highSchoolLocationError}
          disabled
            
          >
            {states.map((stateName, index) => (
            <MenuItem key={index} value={stateName.code}>
              {stateName.name}
            </MenuItem>
          ))}
          </Select>
          {highSchoolLocationError && (
    <FormHelperText sx={{color:"#d32f2f"}}>{highSchoolLocationError}</FormHelperText>
  )}
        </FormControl>
        
    </div>
  
    
    </Box>
   
  </div>
            </div>
            <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 1}>
            <Box >
              <div  className='Test_DivColumn'>
  
             
    <div className='sTest_FlexRow1122'>
    <Autocomplete
         disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
    size="small"
    sx={{ mb: 2 ,marginTop:"20px"}}
    fullWidth
    multiple
    id="tags-outlined"
    options={filteredOptions} // Make sure top100Films has the same structure as collegeData.major
    getOptionLabel={(option) => option.title} // Assuming option.title exists in top100Films
    // filterSelectedOptions
    value={selectedMajor} // Set the selected values based on collegeData.major
    onChange={(event, newValue) => {
      setSelectedMajor(newValue);
      // Update the major field in your state when the user selects options
      setCollegeFormData((prevData) => ({
        ...prevData,
        major: JSON.stringify(newValue), // Convert the selected options to a string and store them
      }));
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Major *"
        name="major"
        placeholder="option"
        error={Boolean(selectedMajorError)}
        helperText={selectedMajorError}
      />
    )}
  />
  
  
      <FormControl fullWidth size="small" sx={{mb:2,}} error={Boolean(admissionplanError)}>
          <InputLabel id="demo-simple-select-label">Admission plan *</InputLabel>
          <Select 
           disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="admission_plan"
            value={formData.admission_plan}
            label="Admission plan *"
            onChange={(e) => setFormData({ ...formData, admission_plan: e.target.value })}
            error={Boolean(admissionplanError)}
            helperText={admissionplanError}
          >
            <MenuItem value={"Early Action"}>Early Action</MenuItem>
            <MenuItem value={"Early Decision"}>Early Decision</MenuItem>
            <MenuItem value={"Regular Decision"}>Regular Decision</MenuItem>
            <MenuItem value={"Rolling Admission"}>Rolling Admission</MenuItem>
          </Select>
          {admissionplanError && (
    <FormHelperText>{admissionplanError}</FormHelperText>
  )}
        </FormControl>
     
 
    </div>
    <div className='Test_FlexRow'>
    <FormControl fullWidth size="small" error={Boolean(starttermError)}>
          <InputLabel id="demo-simple-select-label">Start term *</InputLabel>
          <Select
           disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="start_term"
            value={formData.start_term}
            label="Start term *"
            onChange={(e) => setFormData({ ...formData, start_term: e.target.value })}
            error={Boolean(starttermError)}
            helperText={starttermError}
          >
            <MenuItem value={"Fall"}>Fall</MenuItem>
            <MenuItem value={"Winter"}>Winter</MenuItem>
            <MenuItem value={"Spring"}>Spring</MenuItem>
            <MenuItem value={"Summer"}>Summer</MenuItem>
          </Select>
          {starttermError && (
    <FormHelperText>{starttermError}</FormHelperText> )}
        </FormControl>
       
      <FormControl fullWidth size="small" error={Boolean(coursetypeError)}>
          <InputLabel id="demo-simple-select-label">Type *</InputLabel>
          <Select
           disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="course_type"
            value={formData.course_type}
            label="Type *"
            onChange={(e) => setFormData({ ...formData, course_type: e.target.value })}
            error={Boolean(coursetypeError)}
          helperText={coursetypeError}
          >
            <MenuItem value={"Full-time"}>Full-time</MenuItem>
            <MenuItem value={"Part-time"}>Part-time</MenuItem>
            <MenuItem value={"Transfer"}>Transfer</MenuItem>
          </Select>
          {coursetypeError && (
    <FormHelperText>{coursetypeError}</FormHelperText> )}
        </FormControl>
       
    </div>
    <div className='Test_paddingDiv'>
    <TextField id="outlined-basic" label="Supplementary essay "    sx={{
    '&': {
      userSelect: urlType === 'collaborator' || urlType === 'student' ? 'none' : 'auto',
      pointerEvents: urlType === 'collaborator' || urlType === 'student' ? 'none' : 'auto',
    },
  }} disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' } name="supplementary_eassy" error={Boolean(supplementryeassyError)} helperText={supplementryeassyError} variant="outlined" fullWidth   minRows={4}  multiline size="small"value={formData. supplementary_eassy} onChange={(e) => handleSupplementaryChange(e.target.value)}
   />
    </div>
    
    </div>
   
    </Box>
   
            </div>
            {/* <div role="tabpanel" hidden={value !== 3}>
            <Box >
              <Box className='Test_paddingBox2'>
                  <Typography>Did this college provide aid package?</Typography>
                  <div >
        <RadioGroup
          aria-label="Options"
          name="options"
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{display:"flex",flexDirection:"row"}}
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Yes"
           
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
    
      </div>
              </Box>
              {isFieldsVisible && (
                  <>
          <div className='Test_FlexRow2'>
          
            <FormControl sx={{width:"48.5%",mb:3}} size="small" >
              <InputLabel id="demo-simple-select-label">Aid/Scholarship</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="aid_type"
                value={formData.aid_type}
                label="Aid/Scholarship"
                onChange={(e) => setFormData({ ...formData, aid_type: e.target.value })}
              >
                <MenuItem value={10}>Aid</MenuItem>
                <MenuItem value={20}>Scholarship</MenuItem>
              
              </Select>
            </FormControl>
            {aidtypeError && (
      <FormHelperText>{aidtypeError}</FormHelperText> )}
          </div>
              <div className='Test_FlexRow'>
              <TextField id="outlined-basic" label="Aid/Scholarship name" name="socholarship_name" variant="outlined" error={Boolean(socholarshipError)} helperText={socholarshipError} fullWidth size="small" value={formData.socholarship_name}    onChange={(e) => setFormData({ ...formData, socholarship_name: e.target.value })} />
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="duration_of_aid"
                  value={formData.duration_of_aid}
                  label="Duration"
                  onChange={(e) => setFormData({ ...formData, duration_of_aid: e.target.value })}
                >
                  <MenuItem value={10}>Semesterly</MenuItem>
                  <MenuItem value={20}>Yearly</MenuItem>
                
                </Select>
              </FormControl>
              {durationofaidError && (
      <FormHelperText>{durationofaidError}</FormHelperText> )}
            </div>
            <TextField id="outlined-basic" label="Total package" name="aid_package_per_unit" error={Boolean(aidpackageperunitError)} helperText={aidpackageperunitError} variant="outlined" value={formData.aid_package_per_unit}  onChange={(e) => setFormData({ ...formData, aid_package_per_unit: e.target.value })} sx={{width:"48.5%",marginTop: 3}} size="small" />
            </>
        )}
  
    
    </Box>
    
            </div> */}
           
            <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 2}>
            <Box className='Test_paddingBox2' >
            <div>
        {recommenders.map((recommender, index) => (
          <div key={index} className='Test_FlexRow1122'>
            <TextField
             disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
            sx={{mb:2}}
              id={`name-${index}`}
              name={`recommenders_name${index}`}
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              value={recommender[`recommenders_name${index}`] || ''}
              onChange={(event) => handleChanges2(event, index)}
            />
            <FormControl fullWidth size="small"  sx={{mb:2}}>
              <InputLabel  id={`description-label-${index}`}>Recommender</InputLabel>
              <Select
               disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
                labelId={`description-label-${index}`}
                id={`description-${index}`}
                name={`recommenders_description${index}`}
                value={recommender[`recommenders_description${index}`]?.toString() || ''}  
                label="Recommender"
                onChange={(event) => handleChanges2(event, index)}
              >
                <MenuItem value="Teacher">Teacher</MenuItem>
                <MenuItem value="Parent">Parent</MenuItem>
                <MenuItem value="Adviser">Adviser</MenuItem>
                <MenuItem value="Other Recommenders">Other Recommenders</MenuItem>
                {/* <MenuItem value="40">Faculty Sponsor</MenuItem>
                <MenuItem value="50">Colleague/friend</MenuItem>
                <MenuItem value="60">Community Member</MenuItem> */}
              </Select>
            </FormControl>
            {index !== 0 && (
        <DeleteOutlinedIcon   className='Mui_edit_icon' onClick={() => deleteCourse2(index)}  sx={{color:'#D32F2F',padding:0,display: urlType === 'student' || urlType === 'collaborator' || urlType === 'admin'  ? 'none' : 'block',}}/>
           )}
          </div>
        ))}
  
        <div className='Test_paddingDiv'>
          <Button
           disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
          sx={{boxShadow:"none"}}
            variant="contained"
            color="inherit"
            size="large"
            fullWidth
            onClick={addRecommender}
          >
            + ADD ANOTHER RECOMMENDER       
          </Button>
          {RecommerdsError && (
    <FormHelperText sx={{color:"#d32f2f"}}>{RecommerdsError}</FormHelperText>
  )}
        </div>
      </div>
    
   
    </Box>
  
      </div>
      {/* <div role="tabpanel" hidden={value !== 6}>
      <Box className='Test_paddingBox'>
    <div className='Test_DivColumn_center'>
   <Typography>Did you get admission in this college?</Typography>
   <div className='Test_paddingDiv'>
          
          <RadioGroup
            aria-label="Decision Options"
            name="decisionOptions"
            value={selectedOption2}
            onChange={(event) => handleDecisionChange(event.target.value)}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="accepted"
              control={<Radio sx={{ color: 'green' }} />}
              label={<span style={{ color: 'green' }}>Accepted</span>}
            />
            <FormControlLabel
              value="rejected"
              control={<Radio sx={{ color: 'red' }} />}
              label={<span style={{ color: 'red' }}>Rejected</span>}
            />
            <FormControlLabel
              value="waitlisted"
              control={<Radio sx={{ color: 'orange' }} />}
              label={<span style={{ color: 'orange' }}>Waitlisted</span>}
            />
            <FormControlLabel
              value="deferred"
              control={<Radio sx={{ color: 'purple' }} />}
              label={<span style={{ color: 'purple' }}>Deferred</span>}
            />
          </RadioGroup>
          {DecisionoptionError && (
      <FormHelperText>{DecisionoptionError}</FormHelperText>
    )}
        </div>
    </div>
  
   
    </Box>
     
  
      </div> */}
      <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 3}>
        <Box className='Test_paddingBox2' sx={{marginTop:"20px"}} >
        <TextField  disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }    sx={{
    '&': {
      userSelect: urlType === 'collaborator' || urlType === 'student'|| urlType === 'admin' ? 'none' : 'auto',
      pointerEvents:  urlType === 'collaborator' || urlType === 'student'|| urlType === 'admin' ? 'none' : 'auto',
    },
  }} id="outlined-basic" label="Response" name="your_interest" variant="outlined" fullWidth  value={formData.your_interest} error={Boolean(yourinterestError)} helperText={yourinterestError}    minRows={12}  multiline size="small" onChange={(e) => handleYourInterestChange(e.target.value)}/>
     
        </Box>
     
      </div>
      </Box>
      <div className='Test_buttonDiv'>
          <Button variant="contained" color="inherit" size="large" sx={{width:"180px" ,boxShadow:"none"}} onClick={() => handleBackButton(userid)} >Back</Button>
  
          <Button variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}}  onClick={() => moveToNextTab(userid)}   disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    urlType === 'student' && value === 3 ||  urlType === 'collaborator'|| urlType === 'admin' && value === 3 ? "CLOSE" : (value === 3 ? "SAVE" : "NEXT")
                  )}</Button>
        </div>
        
    </Box>
  
    </Box>
    {/* {showAlert && (
          <Alert
            severity="success"
            sx={{
              position: 'absolute',
              bottom: 0, // Place the Alert at the bottom
              left: 0,
              right: 0,
            }}
          >
            <AlertTitle>Success</AlertTitle>
            Your data was successfully saved! Redirecting...
          </Alert>
        )} */}
       
    
  </Grid>
           
  
     
        </Grid>
        
      </div>
      
    );
  }
export default Student_college_applied_for
