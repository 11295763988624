import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import { Box, Chip, Divider } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Topbar from '../Students/Topbar'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tobar from '../collaborator/Tobar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DropzoneArea } from 'mui-file-dropzone';
const Summary_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
  const [type, setType] = useState()
  const [userId, setUserId] = useState();
  const [view, setView] = useState();
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 
  const breadcrumbs = [
    <Link
      underline="hover"
      style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}
      key="1"
      color="inherit"
      href={
        type === 'admin' && view === 'student'
          ? `/admin_student_profileview?id=${encodeAlphanumeric(userId)}`
          : type === 'admin'
            ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
            : type === 'student' && view === 'student'
            ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}&type=student`
            : type === 'student'
              ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
              : type === 'collaborator' && view === 'student'
              ? `/studentProfile?id=${encodeAlphanumeric(userId)}&type=collaborator`
              : null
      } 
     
    >
      <ArrowBackIosIcon fontSize="meduim" />
     BACK TO PROFILE
    </Link>
 
   
  ];
  const [files, setFiles] = useState([]);
  const[iddata,setDataId]=useState();
  const[Type , setTypes]=useState()
  const [Errorpersonaleassay, setErrorpersonaleassay] = useState('');
  const [Errorfiles, setErrorfiles] = useState('');
  const [open, setOpen] = React.useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [transformedImageUrls, settransformedImageUrls] = useState([]);
  const [personalEssayData, setPersonalEssayData] = useState([]);
  const [formid, setformid] = useState('');
  const [formData, setFormData] = useState({
    personal_eassay: '',
    path_images:''
    
    
  });
  const parsedImages = JSON.parse(formData.path_images || '[]');
  useEffect(() => {
  
     
    const searchParams = new URLSearchParams(location.search);
    const selectedPersonalEssayId = searchParams.get('id'); //change this const to form id 
    const view = searchParams.get('view');
    setformid(selectedPersonalEssayId);
    setView(view)
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    // const userId = userData.id;
    const urlParams = new URLSearchParams(window.location.search);
    const userid = urlParams.get('userid');
    setDataId(userid)
    console.log('Highschool ID:', selectedPersonalEssayId); 
 
  const type = searchParams.get('type');
  setType(type)
  // Now, Type should be accessible
  // alert(Type); 
    if(selectedPersonalEssayId !== ''){
      const apiEndpoint = view === 'student' ? '/get_student_personal_eassy_form_data' : '/get_personal_eassy_form_data';
      try {
      fetch(`${apiUrl}${apiEndpoint}`, {   // chgange this url 
        method: 'post',
        body: JSON.stringify({ formid:selectedPersonalEssayId }), // pass the form id const here 
    })
    .then(response => {
        if (response.status === 200) {
            return response.json(); // Convert the response to JSON
        } else {
            throw new Error('Request failed');
        }
    })
    .then(data => {
      const personal_eassaydata = data.formdata[0];
     
      setPersonalEssayData(data.formdata);      
      const fetchedUserId = personal_eassaydata.userId || '';
      setUserId(fetchedUserId);               
        console.log(data, "setPersonalEssayData"); 
        const pathImagesArray = JSON.parse(personal_eassaydata.path_images);

      const transformedImageUrlsimages = pathImagesArray.map((imageUrl) => {
        // Unescape forward slashes
        
        
        // Perform the replacements
        const laravelUrl = imageUrl.replace('/public/storage/', '/storage/app/public/');
        
         return laravelUrl;
      });
      
      settransformedImageUrls(transformedImageUrlsimages);
      console.log(transformedImageUrlsimages,"images urlsssddd");
        setFormData({
          personal_eassay:personal_eassaydata.personal_eassay || '',
          path_images: personal_eassaydata.path_images || '',
       
        });
     
    })
    .catch(error => {
        console.error('Data fetch failed:', error);
    });
} catch (error) {
    console.error('Data upload failed:', error);
}
    }

    
}, []); 
const replaceUrl = (url) => {
  return url.replace('public/storage', 'storage/app/public');
};

  return (
    <div>
       {type === 'student' ? <Topbar /> : type === 'collaborator' ? <Tobar />  : <Topbar2 />}
     <Grid container 
gap="20px"
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Grid_Collaborator_Summary_detailpage'
>
<Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
<Grid
item
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Collaborator_Summary_info_detailpage'>
<Box className='Summary_info_Box1'>
<WorkOutlineOutlinedIcon className='College_info_Avatar' />
<typography className='College_heading'>
Personal Essay</typography>
</Box>
<Box className='Summary_info_main_Box'>
<Box className='Summary_info_Box2'>
  <Box className='Summary_info_Box2_secondary11'>
    <typography className='College_heading_secondary'>Job Description</typography>
    <typography className='College_name_secondary'>{formData.personal_eassay} </typography>
  </Box>
 
</Box>
<Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />
<Box className='Summary_info_Box2'>
  <Box className='Summary_info_Box2_secondary1'>
  <UploadFileOutlinedIcon className='College_info_Avatar' />
    {/* <typography className='College_name_secondary'>{formData.path_images}</typography> */}
   
    {Array.isArray(parsedImages) && parsedImages.length > 0 && (
  parsedImages.map((fileUrl, index) => (
    <div key={index}>
      {console.log("File Path:", fileUrl)}
      <a
        href={replaceUrl(fileUrl)}
        target="_blank"
        rel="noopener noreferrer"
        style={{textDecoration:"none"}}
      >
        {/* {getFileNameFromUrl(fileUrl)} */}
        Uploaded File
      </a>
    </div>
  ))
)}
  </Box>
 
</Box>
</Box>

</Grid>




</Grid>
  </div>
  )
}

export default Summary_detailpage