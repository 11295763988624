import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import centerImage from "../../assets/img/loginbg.png";
import studentLoginImage from "../../assets/img/studentlogin.png";
import Logo1 from "../../assets/img/logo.png";
import Line from "../../assets/img/Vector_line.png";

import Google from "../../assets/img/Googelimge.png";
import "./login.css";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
const defaultTheme = createTheme(); 

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [loginError, setLoginError] = React.useState(null);
  const [loginSuccess, setLoginSuccess] = React.useState(null);
  const [firstText, setFirstText] = useState("");
  const [secondtext, setSecondText] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failMessage, setFailMessage] = useState('');
  const [data, setData] = useState({});
  const [user, setUser] = useState(null);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    type: "",
  });

  // Define a default image source
  let centerImageSrc = centerImage; // Default image

  useEffect(() => {
    // Check if the type is 'collaborator' or 'student' and set the image source and login text accordingly
    if (type === "collaborator") {
      setFirstText("Earn money by");
      setSecondText("helping students");
      setBottomText("Collaborator");
    } else if (type === "student") {
      setFirstText("Apply to college");
      setSecondText("the smart way");
      setBottomText("Student");
    } else if (type === "null") {
      navigate("/welcome");
    } else if (type === "") {
      navigate("/welcome");
    }
  }, [type]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //const data = new FormData(event.currentTarget);
    formData.type = type;
    setPasswordError(null);
    setEmailError(null);
    setLoginError(null);
    setLoginSuccess(null);
    try {
      setIsLoading(true);
      // Make a POST request to the API endpoint
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/login`, {
        method: "post",
        body: JSON.stringify(formData),
      });

      // Check the response status and handle it accordingly
      if (response.status === 404) {
        // Registration successful, you can handle the response data here
        const data = await response.json();

        setLoginError(data.error);
        setIsLoading(false);
        //history.push(`/signupprofile?type=${type}`);
        // You can redirect the user to a success page or perform other actions 
      } else if (response.status === 401) {
        // Handle other response statuses or errors
        const data = await response.json();

        setLoginError(data.error);
        setIsLoading(false);
      } else if (response.status === 201) {
        const data = await response.json();
        console.log("user detail :", data.user);
        localStorage.setItem("userData", JSON.stringify(data.user));
        setLoginSuccess(data.message);
        setIsLoading(false);
        if (type === "collaborator") {
          navigate(`/profileview`);
        } else if (type === "student") {
          navigate(`/Student`);
        }
      } else if (response.status === 400) {
        // Handle other response statuses or errors
        const data = await response.json();
        setIsLoading(false);
        if (data.error) {
          if (data.error.email) {
            setEmailError(data.error.email[0]);
          }
          if (data.error.password) {
            setPasswordError(data.error.password[0]);
          }
        }
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Registration failed:", error);
    }
  };
  const location2 = useLocation();

  useEffect(() => {
    // Check if the URL contains the 'code' parameter
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    
      // If the URL contains the 'code' parameter, set isAuthenticated to true
      
// 
      // Fetch user data after authentication

      
      fetch(`${process.env.REACT_APP_API_URL}/auth/google/callback${location2.search}`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Data from server:', data);
          window.location.href = '/welcome';
          if (data.status === 'success') {
            // Store user details in localStorage
            localStorage.setItem('userDetails', JSON.stringify(data.user));
            localStorage.setItem('accessToken', data.access_token);
            setLoading(false);
            setData(data);
            // Redirect to the appropriate dashboard or URL
            // window.location.href = data.redirect_url;
           
          } else {
            // Handle login failure
            console.error('Login failed:', data.message);
          }
        })
        .catch((error) => {
          console.error('Error during login:', error);
        });
   
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userDetailsParam = searchParams.get('userDetails');
    const failsParam = searchParams.get('fails');
    if (userDetailsParam) {
      try {
        // Parse the JSON string from the URL
        const userData = JSON.parse(userDetailsParam);

        // Set the entire userDetails object in localStorage
        localStorage.setItem('userData', JSON.stringify(userData));

        // Check the 'type' property
        if (userData.type === 'student') {
          // If 'type' is 'student', navigate to '/student'
          navigate('/student');
        } else if (userData.type === 'collaborator') {
          // If 'type' is 'collaborator', navigate to '/profileview'
          navigate('/profileview');
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }else if (failsParam) {
      // Handle failed login due to email already being registered for a different user type
      console.error('Login failed:', failsParam);
      setFailMessage(failsParam);
      // Display an appropriate message to the user or handle the failure as needed
    }
  }, [location.search]);

  const handleGoogleSignIn = () => {
    // Make an HTTP request to your Laravel backend to get the Google Sign-In URL
    fetch(`${process.env.REACT_APP_API_URL}/auth/google?type=${type}`)
      .then((response) => response.json())
      .then((data) => {
        // Open the Google Sign-In URL in a new tab
        setIsAuthenticated(true);
        window.open(data.url, '_blank');
        setUser(data);
        console.log(data,"data")
      })
      .catch((error) => {
        console.error('Error during Google Sign-In:', error);
      });
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        className="main_container_login"
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
      >
        <CssBaseline />
        <Grid
          item
          lg={4}
          xl={4}
          xs={false}
          sm={4}
          md={5.5}
          className="Login_Grid"
        >
          <Box></Box>
          
          <Box className="Login_text_area">
         
            <div>
              <img src={Logo1} alt="Logo1" className="Login_logo_img" />
              <div className="login_text_div">
                <typography className="Login_side_text">{firstText}</typography>
              </div>
              <div>
                <typography className="Login_side_text">
                  {secondtext}
                </typography>
              </div>
            </div>
          </Box>
          <Box className="Login_Bottom_box">
            <div className="Login_Bottom_div">
              <img src={Line} alt="Line" className="Login_Bottom_img" />
              <typography className="Login_Bottom_text">
                {bottomText}
              </typography>
            </div>
          </Box>
        </Grid>
        <Grid
          item
          lg={8}
          xl={8}
          xs={12}
          sm={8}
          md={6.5}
          component={Paper}
          elevation={6}
          square
          className="Login_Textfield_grid"
          sx={{display:"flex",alignItems:"center"}}
        >
          <Box className="Login_Textfiled_Box">
          <Grid item lg={11} xl={11} xs={10} sm={10} md={11}>
          <div className="Login_Google" onClick={handleGoogleSignIn}>
                  <img
                    src={Google}
                    alt="Center Image"
                   
                  />
                  <typography className="Login_google">
                    Sign In with Google
                  </typography>
                </div>
                <div style={{display:"flex",justifyContent:'center',marginTop:'10px'}}>
                {failMessage ? (
        <typography style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>{failMessage}</typography>
      ) : (
        
          <div></div>
         
          )}
          </div>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              className="Login_Form_Textfield"
            >
              
              <div className="Login_header_text">
                <typography className="Login_Welcome">Welcome back</typography>
                <typography className="Login_Welcome_text">
                  Good to see you again!
                </typography>
              </div>
              {/* <div className='Login_Google'>
                <img src={Google} alt="Google Image" />
                <typography className="Login_google">Login with Google</typography>
              </div>
              <div className='Login_divider'>
                <div className="custom-divider" />
                <typography className="Login_ortext">or</typography>
                <div className="custom-divider" />
              </div> */}
              {loginError && <div className="error-message">{loginError}</div>}
              {loginSuccess && (
                <div className="success-message">{loginSuccess}</div>
              )}
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={Boolean(emailError)}
                helperText={emailError}
                onChange={handleChange}
              />
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={Boolean(passwordError)}
                helperText={passwordError}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="Login_button"
                style={{ boxShadow: 'none' }}
                disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    "LOGIN"
                  )}
              </Button>
              <Grid>
                <Link   sx={{ textDecoration: "none" ,cursor:'pointer'}} href={`/Forgot_password?type=${type}`} >
                <typography className="Welcome__already">Forgot your password?</typography>
                </Link>
              </Grid>
              <Grid container className="Login_Textfield_Last_grid">
                <Grid item>
                  <p className="Welcome__already">
                    Don&apos;t have an account?{" "}
                    <Link
                      href={`/signup?type=${type}`}
                      sx={{ textDecoration: "none" }}
                      
                    >
                      <typography className="Signup_text">Signup</typography>
                      
                    </Link>{" "}
                  </p>
                </Grid>
              </Grid>
            </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
