import React , { useState, useRef,useEffect  } from 'react'
import Grid from '@mui/material/Grid';

import backgroundimage from "../../assets/img/bg-img.png"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import bgimagecover from '../../assets/img/bgcover.svg';

import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import PropTypes from 'prop-types'; // Import PropTypes
import { Link,useNavigate } from 'react-router-dom';
import profile1 from '../../assets/img/profile.jpg';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Premium from '../../assets/img/Rectangle.png'

import Profile_submitted from './Profile_submitted';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import animationData from '../../assets/lottiefinal.json'
import animationData2 from '../../assets/message.json'

import honoursimage from '../../assets/img/honours.png';
import Highschoolimage from '../../assets/img/highschool.png';
import collegeimage from '../../assets/img/college.png';
import sportimage from '../../assets/img/sports.png';
import testingimage from '../../assets/img/testing.png';
// import { useNavigate } from 'react-router-dom';
import test1 from '../../assets/img/Testlogo.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import Badge from '@mui/material/Badge';
import { CardHeader } from '@mui/material';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Typography, Tabs, Tab,InputLabel } from '@mui/material' 
import { useLocation,useParams } from 'react-router-dom'; 
import Lottie from 'react-lottie';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Experiance_icon from '../../assets/img/experiance_icon.jpeg'
import '../Students/Students.css'
import Tobar from './Tobar';

const StudentProfile = () => {
    const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  function decodeAlphanumeric(encoded) {
    const decoded = atob(encoded); // Using atob to decode the Base64 string
    // You can further process the decoded string if needed
    return decoded;
  }
  const location = useLocation();
    useEffect(() => {
   
        fetchUsersFromApi();
      }, []);
    
      const fetchUsersFromApi = () => {
        // Fetch all users from the API
        // Replace 'api/users' with your actual API endpoint for users
        const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;
        fetch(`${apiUrl}/get_profileformessage`, {
          method: 'post',
          body: JSON.stringify({ userId }), // Send the entire formData object as the body
          }) 
          .then((response) => response.json())
          .then((data) => {
            console.log(data.collaborators); 
            //setMessages(data.collaborators);
            setcollaboratorschatusers(data.collaborators);
          })
          .catch((error) => {
            console.error("Error fetching users: ", error);
          });
      };
      
    
        const useStyle = {
            Button: {
              "&:hover": {
                backgroundColor: "#ffffff !important",
                boxShadow: "none !important",
              },
              "&:active": {
                boxShadow: "none !important",
                backgroundColor: "#3c52b2 !important",
              },
            },
          };
         
          const [Collegesdata, setCollegesdata] = useState([]);
          const [HighSchooldata, setHighSchooldata] = useState([]);
          const [Honoursdata, setHonoursdata] = useState([]); 
          const [Testingdata,setTestingdata] = useState([]);
          const [Activitiesdata,setActivitiesdata] = useState([]);
          const [Experiencedata,setExperiencedata] = useState([]);
          const [Summarydata, setSummarydata]  = useState([]);
          const [collaboratordetail, setCollaboratordetail]  = useState('');
          const [collegeId, setCollegeId] = useState(null);
          const [open2, setOpen2] = React.useState(false);
          const [collaboratorjoindate, setcollaboratorjoindate] = useState(null);
          const [collaboratorcity, setcollaboratorcity] = useState(null);
          const [ collaboratorID, setCollaboratorID]=useState(null);
          const [collaboratorlaravelUrlfinal, setcollaboratorlaravelUrlfinal] = useState(null);
          const [collaboratorbackgroundurlimagefinal, setcollaboratorbackgroundurlimagefinal] = useState(null);
          const [collaboratorname, setcollaboratorcollaboratorname] = useState(null);
          const [collaboratoraddress, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hideChip, setHideChip] = useState(false);
  const [messagerequesterror, setmessagerequesterror] = useState([]);
  const [showFullSummary, setShowFullSummary] = useState(false);

          const handleViewMore = () => {
            setShowFullSummary(!showFullSummary);
          };
          const [collaboratorDescritpion, setcCollaboratorDescritpion] = useState(null);
          const handleClose2 = () => {
            setOpen2(false);
          };
          function sendMessageRequest(collaboratorId) {
            // You can pass the collaborator's ID or any other identifier to identify the recipient.
            // You can also add additional data to the request, such as the message content.
          
              try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const userData = JSON.parse(localStorage.getItem('userData'));
                const userId = userData.id;
        
                fetch(`${apiUrl}/collaborator_message_request`, {
                    method: 'post',
                    body: JSON.stringify({ userId, collaboratorId }), 
                })
                .then(response => {
                    if (response.status === 201) {
                        return response.json(); 
                    } else {
                        throw new Error('Request failed');
                    }
                })
                .then(data => {
                   
                    console.log(data.message); 
                    setmessagerequesterror(data.message);
                    // setTimeout(() => {
                    //   setmessagerequesterror(''); 
                    // }, 5000); 
                  
                })
                .catch(error => {
                    console.error('Data fetch failed:', error);
                })
                .finally(() =>{
                  setLoading(false);
                  setOpen2(true);
                })
            } catch (error) {
                console.error('Data upload failed:', error);
            }
           
          
           
               
          }
        // const [courseLength, setCourseLength] = useState('')
        // const [courseDataCount,setCourseDataCount]=useState('')
        // useEffect(() => {
        //   const userData = collaboratordetail[0];
        //   console.log(userData, 'userData');
        //   alert("here");
        //   //setcollaboratorcollaboratorname(userData.name);
        //   const joinDate = new Date(userData.created_at);
          
        //   const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
        //   setcollaboratorjoindate(formattedJoinDate);
        //   const profile1url = userData.avatarImage;
        //   const laravelUrl = profile1url.replace('/public/', '/storage/app/public/');
        //   const laravelUrlfinal = laravelUrl.replace('/public/storage/', '/public/');
        //   setcollaboratorlaravelUrlfinal(laravelUrlfinal);
        //   const backgroundurl = userData.background_image;
      
        //   let backgroundurlimagefinal;
      
        //   if (backgroundurl) {
        //     const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/');
        //     backgroundurlimagefinal = backgroundurlimage.replace('/public/storage/', '/public/');
        //     setcollaboratorbackgroundurlimagefinal(setcollaboratorbackgroundurlimagefinal);
        //   } else {
        //     backgroundurlimagefinal = '';
        //   }
          
          
        // }, [collaboratordetail]);
          // Testingdata.forEach((item, index) => {
          //   const testData = JSON.parse(item.testdata);
          //   if (testData && testData.length > 0) {
          //     testData.forEach((test, testIndex) => {
          //       const testname = test[`testname-${testIndex}`];
          //       const startDate = new Date(test[`start-date-${testIndex}`]);
          //       const year = startDate.getFullYear();
          //       const attempts = test[`attempts-${testIndex}`];
          //       const score = test[`score-${testIndex}`];
          
          //       console.log(`Test ${testIndex + 1}:`);
          //       console.log(`Test Name: ${testname}`);
          //       console.log(`Year: ${year}`);
          //       console.log(`Attempts: ${attempt  s}`);
          //       console.log(`Score: ${score}`);
          //     });
          //   }
          // });
          
          
          // console.log(Testingdata,"Testingdata");
          // console.log(laravelUrlfinal,"user image");
          // console.log( Collegesdata,"colleges data")
          console.log( Activitiesdata,"Activitiesdata")   
       
          

          HighSchooldata.forEach((item, index) => {
          
            const coursesData = JSON.parse(item.coursesData);
            // console.log('coursedata', coursesData);
          
            // Store the total number of coursesData objects in the array
            const courseDataCount = coursesData.length;
           console.log(courseDataCount)
          
          });
          
          // Now you have the course counts in the `courseCounts` array
         
          


          // console.log( Activitiesdata,"Activitiesdata")
              
          // if (Activitiesdata && Activitiesdata.length > 0) {
          //   const sportsActivities = JSON.parse(Activitiesdata[0].sports_activities); // Assuming there is only one item in Activitiesdata
          
          //   if (sportsActivities && sportsActivities.length > 0) {
          //     sportsActivities.forEach((activity, index) => {
          //       const highSchoolName = activity[`high-school-name-${index}`];
          //       const highActivitiesSelect = activity[`high-activities-select-${index}`];
          
          //       console.log(`High School Name: ${highSchoolName}`);
          //       console.log(`High Activities Select: ${highActivitiesSelect}`);
          //     });
          //   }
          // }
          
          // HighSchooldata.map((item, index) => {
          //   const endDateYear = new Date(item.end_date).getFullYear(); // Extract the year from end_date
          //   const startDateYear = new Date(item.start_date).getFullYear(); // Extract the year from start_date
          
          //   console.log(`High School Name: ${item.hight_school_name}`);
          //   console.log(`End Date Year: ${endDateYear}`);
          //   console.log(`Start Date Year: ${startDateYear}`);
            
          //   // Do whatever you want with this data
          //   // You can also store it in an array or state if needed
          // });
          
          // console.log(Summarydata,"summary data")
      // Check if Honoursdata exists and has data
      // if (Honoursdata && Honoursdata.length > 0) {
      //   Honoursdata.forEach((item, index) => {
      //     const awards = JSON.parse(item.awards);
      
      //     if (awards && awards.length > 0) {
      //       awards.forEach((award, awardIndex) => {
      //         const awardTitle = award[`award-title-${awardIndex}`];
      //         const level = award[`level-${awardIndex}`];
      //         const awardDescription = award[`award-description-${awardIndex}`];
      
      //         console.log(`Award Title ${awardIndex + 1}: ${awardTitle}`);
      //         console.log(`Level ${awardIndex + 1}: ${level}`);
      //         console.log(`Award Description ${awardIndex + 1}: ${awardDescription}`);
      //       });
      //     }
      //   });
      // }
      
      
          // Collegesdata.forEach((college, index) => {
          //   console.log(`College ${index + 1}:`);
          //   console.log(`Major: ${college.major}`);
          //   console.log(`Supplementary Essay: ${college.supplementary_eassy}`);
          //   console.log(`High School Location: ${college.highSchoolLocation}`);
          //   console.log(`College Name: ${college.collegeName}`);
           
          // });
        //   React.useEffect(() => {
        //     const timer = setInterval(() => {
        //       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        //     }, 800);
        //     return () => {
        //       clearInterval(timer);
        //     };
        //   }, []);
          const [selectedImage, setSelectedImage] = useState(null);
      
          // Function to handle image selection
       
          const [image, setImage] = useState(null); // State to store the uploaded image file
          const [showImageUploader, setShowImageUploader] = useState(false); // State to control the image uploader visibility
          const fileInputRef = useRef(null); // Ref to access the hidden file input
        
        
        
      
        
          const [open, setOpen] = React.useState(false);
          const handleClose = () => {
            setOpen(false);
            
            
          };
          const handleOpen = () => {
            setOpen(true);
          };
      
          
        const backdropStyle = {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // You can adjust the background color and opacity
          display: open ? 'block' : 'none',
          zIndex: 1000, // Adjust the z-index as needed to ensure it's above other elements
        };
      const[userType1,setUserType]=useState('')
        useEffect(() => {
          try {
            const apiUrl = process.env.REACT_APP_API_URL;
           // const userData = JSON.parse(localStorage.getItem('userData'));
            const searchParams = new URLSearchParams(location.search);
            const collaboratorid = searchParams.get('id'); //change this const to form id 
            const userId = decodeAlphanumeric(collaboratorid);
            const userType = searchParams.get('type');
console.log(userType,"userType")
            // Conditionally set the API endpoint based on userType
            let endpoint = '/get_all_profile_data'; // Default endpoint
      
            if (userType === 'collaborator') {
              endpoint = '/get_all_students_forms_profile_data'; // Update endpoint for student
              setHideChip(true);
              setUserType('collaborator')
            }
    
            fetch(`${apiUrl}${endpoint}`, {
                method: 'post',
                body: JSON.stringify({ userId }), // Send the entire formData object as the body
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json(); // Convert the response to JSON
                } else {
                    throw new Error('Request failed');
                }
            })
            .then(data => {
                const collegesdata = data.CollegesData;
                const highschooldata = data.HighschoolData;
                const hounoursdata = data.awardsData;
                const testingdata = data.TestingData;
                const activitiesdata = data.ExperiencesData;
                const personalessay = data.SummaryData;
                const collaboratordetail=data.collaboratorDetails;
                const collaboratorExperience=data.colaboratorworkexperience;
                const studentExperience=data.studentExperiencesData;
                setCollegesdata(collegesdata);
                setHighSchooldata(highschooldata);
                setHonoursdata(hounoursdata);
                setTestingdata(testingdata);
                setActivitiesdata(activitiesdata);
                if (userType === 'collaborator') {
                  setExperiencedata(studentExperience)
                }
                else{
                  setExperiencedata(collaboratorExperience)
                }
               
                setSummarydata(personalessay);
                setCollaboratordetail(collaboratordetail);
                const userData = collaboratordetail[0];
                console.log(userData, 'userData');
              
                  setcCollaboratorDescritpion(userData.Headline)
                
               
                setAddress(userData.address)
                setcollaboratorcollaboratorname(userData.legal_name);
                
                setcollaboratorcity(userData.city);
                setCollaboratorID(userData.id)
                const joinDate = new Date(userData.created_at);
                
                const formattedJoinDate = `${joinDate.toLocaleString('en-us', { month: 'short' })} ${joinDate.getFullYear()}`;
                setcollaboratorjoindate(formattedJoinDate);
                const profile1url = userData.avatarImage;
                const laravelUrl = profile1url.replace('/public/', '/storage/app/public/');
                const laravelUrlfinal = laravelUrl.replace('/public/storage/', '/public/');
                setcollaboratorlaravelUrlfinal(laravelUrlfinal);
                const backgroundurl = userData.background_image;
               
                let backgroundurlimagefinal;
            
                if (backgroundurl) {
                  const backgroundurlimage = backgroundurl.replace('/public/', '/storage/app/public/');
                  backgroundurlimagefinal = backgroundurlimage.replace('/public/storage/', '/public/');
                  setcollaboratorbackgroundurlimagefinal(backgroundurlimagefinal);
                } else {
                  backgroundurlimagefinal = '';
                }
                console.log(data,"Data of user"); // Log the awardsData
               // alert("here");
            })
            .catch(error => {
                console.error('Data fetch failed:', error);
            });
        } catch (error) {
            console.error('Data upload failed:', error);
        }
        }, [collegeId,  location.search]);
        
         // Initialize with null or a default value
    
    
        const [openClick, setOpenClick] = useState(false);
       const [deleteType, setDeleteType] = useState('');
       const [deleteId, setDeleteId] = useState(null);
     const[confirmationText,setConfirmationText ]=useState('')
       const handlecollegeDelete = (id, type) => {
         setDeleteType(type); // Set the delete type (college or high school)
         setDeleteId(id); // Set the selected college/high school id
         setOpenClick(true); // Open the dialog`
         if(type === 'college'){
          setConfirmationText('College');
         }
         else if (type === 'highschool') {
          setConfirmationText('High school');
         }
         else if (type === 'honours') {
          setConfirmationText('Honours');
    
         }
         else if (type === 'testing') {
          setConfirmationText('Testing');
    
         }
         else if (type === 'activity') {
          setConfirmationText('Activity');
    
         }
         else if (type === 'summary') {
          setConfirmationText('Personal Essay'); 
    
         }
       };
     
       const handleCloseDialog = () => { 
         setOpenClick(false);
       };
     
       const handleDeleteConfirmation = async () => {
         try {
           // Make a POST request to the appropriate API endpoint based on deleteType
           const apiUrl = process.env.REACT_APP_API_URL;
           const userData = JSON.parse(localStorage.getItem('userData'));
           const userId = userData.id;
     
           let endpoint = '';
       
    
           if (deleteType === 'college') {
             endpoint = `${apiUrl}/del_student_college_appliedfor_form_data`;
            
           } else if (deleteType === 'highschool') {
             endpoint = `${apiUrl}/del_student_high_school_form_data`;
             
           }
           else if (deleteType === 'honours') {
            endpoint = `${apiUrl}/del_student_honours_form_data`;
            
          }
          else if (deleteType === 'testing') {
            endpoint = `${apiUrl}/del_student_testing_form_data`;
            
          }
          else if (deleteType === 'activity') {
            endpoint = `${apiUrl}/del_student_activities_form_data`;
            
          }
          else if (deleteType === 'summary') {
            endpoint = `${apiUrl}/del_student_personal_form_form_data`;
            
          }
           if (endpoint) {
             const response = await fetch(endpoint, {
               method: 'post',
               body: JSON.stringify({ userId, formid: deleteId }),
             });
     
             if (response.status === 200) {
              setCollegeId(deleteId);
               const responseData = await response.json();
               const message = responseData.message;
               console.log(message);
               // Handle success or show a notification
             }
           }
         } catch (error) {
           // Handle network errors or other exceptions
           console.error('Delete failed:', error);
         }
     
         setOpenClick(false);
       };
          const navigate = useNavigate();
          const [selectedCollegeId, setSelectedCollegeId] = useState('');
          const [selectedHighschoolId, setSelectedHighschoolId] = useState('');
          const [selectedPersonalEssayId, setSelectedPersonalEssayId] = useState('');
          const [selectedTestingId, setSelectedTestingId] = useState('');
          const [selectedHonoursId, setSelectedHonoursId] = useState('');
          const [selectedActivityId, setSelectedActivityId] = useState('');
          const [selectedExperienceId, setSelectedExperienceId] = useState('');
          const handleEditClickExperience = (id) => {
            // alert(id)
            setSelectedExperienceId(id); // Set the selected college id in the state
            console.log(setSelectedExperienceId,"selectedTestingId")
          };
          const handleEditClickActivities = (id) => {
            setSelectedActivityId(id); // Set the selected college id in the state
            console.log(setSelectedActivityId,"selectedTestingId")
          };
          const handleEditClickTesting = (id) => {
            ;
            setSelectedTestingId(id); // Set the selected college id in the state
            console.log(selectedTestingId,"selectedTestingId")
          };
    
          const handleEditClickHonours = (id) => {
            setSelectedHonoursId(id); // Set the selected college id in the state
            console.log(setSelectedHonoursId,"selectedTestingId")
          };
          const handleEditClickHighschool = (id) => {
            setSelectedHighschoolId(id); // Set the selected college id in the state
            
          };
          const handleEditClickSummary = (id) => {
            setSelectedPersonalEssayId(id); // Set the selected college id in the state
            
          };
          // Function to handle clicking the edit icon
          const handleEditClick = (id) => {
            setSelectedCollegeId(id); // Set the selected college id in the state
            
          };
          useEffect(() => {
          
            if(selectedCollegeId){
            if(userType1==="collaborator"){
              navigate(`/Student_detail?id=${selectedCollegeId}&userid=${collaboratorID}&type=collaborator&view=student`);
            }
            else{
              navigate(`/Collaborator_college_form?id=${selectedCollegeId}&userid=${collaboratorID}&type=collaborator`);
            }
         
            }
            else if(selectedHighschoolId){
              if(userType1==="collaborator"){
                navigate(`/Highschool_detail?id=${selectedHighschoolId}&userid=${collaboratorID}&type=collaborator&view=student`);
              }
              else{
                navigate(`/Collaborator_highschool_form?id=${selectedHighschoolId}&userid=${collaboratorID}&type=collaborator`);
              }
            
            }
            else if(selectedPersonalEssayId){
              // navigate(`/Student_summary?id=${selectedPersonalEssayId}`);
              if(userType1==="collaborator"){
                navigate(`/Summary_detail?id=${selectedPersonalEssayId}&userid=${collaboratorID}&type=collaborator&view=student`);
              }
              else{
                navigate(`/Summary_detail?id=${selectedPersonalEssayId}&userid=${collaboratorID}&type=collaborator`);
              }
            }
            else if(selectedHonoursId){
              // navigate(`/Student_Honors?id=${selectedHonoursId}`);
              if(userType1==="collaborator"){
                navigate(`/Honours_detail?id=${selectedHonoursId}&userid=${collaboratorID}&type=collaborator&view=student`);
              }
              else{
                navigate(`/Honours_detail?id=${selectedHonoursId}&userid=${collaboratorID}&type=collaborator`);
              }
            }
            else if(selectedTestingId){
              // navigate(`/testing?id=${selectedTestingId}`);
              if(userType1==="collaborator"){
                navigate(`/Testing_detail?id=${selectedTestingId}&userid=${collaboratorID}&type=collaborator&view=student`);
              }
              else{
                navigate(`/Testing_detail?id=${selectedTestingId}&userid=${collaboratorID}&type=collaborator`);
              }
            }
            else if(selectedActivityId){
              // navigate(`/Student_activities?id=${selectedActivityId}`);
              if(userType1==="collaborator"){
                navigate(`/Activities_detail?id=${selectedActivityId}&userid=${collaboratorID}&type=collaborator&view=student`);
              }
              else{
                navigate(`/Activities_detail?id=${selectedActivityId}&userid=${collaboratorID}&type=collaborator`);
              }
            }
            else if(selectedExperienceId){
              // navigate(`/Student_activities?id=${selectedActivityId}`);
              if(userType1==="collaborator"){
                navigate(`/Experience_detail?id=${selectedExperienceId}&userid=${collaboratorID}&type=collaborator&view=student`);
              } 
              else{
                navigate(`/Experience_detail?id=${selectedExperienceId}&userid=${collaboratorID}&type=collaborator`);
              }
            }
          }, [selectedCollegeId,selectedHighschoolId,selectedPersonalEssayId,selectedTestingId,selectedHonoursId,selectedExperienceId,selectedActivityId,collaboratorID]);
      
          const [activeComponent, setActiveComponent] = useState('collaborator_home');
          const [selectedMessage, setSelectedMessage] = useState(null);
          const [messages, setMessages] = useState([]);
          const [value, setValue] = React.useState(0);
        const [collaboratorschatusers, setcollaboratorschatusers] = useState([]);
      
        const handleItemClick = (message) => {
          // Update the badge number to 0 when a message is clicked
          message.badgenumber = 0;
          console.log(message, "new id message");
          setSelectedMessage(message);
        };
        const handleChange = (event, newValue) => {
            setValue(newValue);
          };

          const calculateProgress = () => {
            let progress = 0;
            if (Collegesdata.length > 0) progress += 50;
            if (HighSchooldata.length > 0) progress += 10;
            if (Honoursdata.length > 0) progress += 10;
            if (Testingdata.length > 0) progress += 10;
            if (Activitiesdata.length > 0) progress += 10;
            if (Summarydata.length > 0) progress += 10;
            return progress;
          };
        
          const progress = calculateProgress();









          const states = [
            { code: "AL", name: "Alabama" },
            { code: "AK", name: "Alaska" },
            { code: "AZ", name: "Arizona" },
            { code: "AR", name: "Arkansas" },
            { code: "CA", name: "California" },
            { code: "CO", name: "Colorado" },
            { code: "CT", name: "Connecticut" },
            { code: "DE", name: "Delaware" },
            { code: "FL", name: "Florida" },
            { code: "GA", name: "Georgia" },
            { code: "HI", name: "Hawaii" },
            { code: "ID", name: "Idaho" },
            { code: "IL", name: "Illinois" },
            { code: "IN", name: "Indiana" },
            { code: "IA", name: "Iowa" },
            { code: "KS", name: "Kansas" },
            { code: "KY", name: "Kentucky" },
            { code: "LA", name: "Louisiana" },
            { code: "ME", name: "Maine" },
            { code: "MD", name: "Maryland" },
            { code: "MA", name: "Massachusetts" },
            { code: "MI", name: "Michigan" },
            { code: "MN", name: "Minnesota" },
            { code: "MS", name: "Mississippi" },
            { code: "MO", name: "Missouri" },
            { code: "MT", name: "Montana" },
            { code: "NE", name: "Nebraska" },
            { code: "NV", name: "Nevada" },
            { code: "NH", name: "New Hampshire" },
            { code: "NJ", name: "New Jersey" },
            { code: "NM", name: "New Mexico" },
            { code: "NY", name: "New York" },
            { code: "NC", name: "North Carolina" },
            { code: "ND", name: "North Dakota" },
            { code: "OH", name: "Ohio" },
            { code: "OK", name: "Oklahoma" },
            { code: "OR", name: "Oregon" },
            { code: "PA", name: "Pennsylvania" },
            { code: "RI", name: "Rhode Island" },
            { code: "SC", name: "South Carolina" },
            { code: "SD", name: "South Dakota" },
            { code: "TN", name: "Tennessee" },
            { code: "TX", name: "Texas" },
            { code: "UT", name: "Utah" },
            { code: "VT", name: "Vermont" },
            { code: "VA", name: "Virginia" },
            { code: "WA", name: "Washington" },
            { code: "WV", name: "West Virginia" },
            { code: "WI", name: "Wisconsin" },
            { code: "WY", name: "Wyoming" },
            { code: "PR", name: "Puerto Rico" },
            { code: "GU", name: "Guam" },
            { code: "VI", name: "U.S. Virgin Islands" },
            { code: "AS", name: "American Samoa" },
            { code: "MP", name: "Northern Mariana Islands" },
            { code: "INTL", name: "International" }
          ];
      
          const getStateFullName = (stateCode) => {
            const state = states.find((s) => s.code === stateCode);
            return state ? state.name : '';
          };
        
  return (
    <div >
    <Tobar/>
      
      <Grid container 
      gap="20px"
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
       padding:"20px",
  minHeight:"92vh",
        backgroundImage: `url(${backgroundimage})`,
        
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }} >
   <Grid item

    lg={8.5}
    xl={9.7}
    xs={12}
    sm={8.7}
    md={9.5} sx={{padding:"4px",display:"flex",flexDirection:"column" ,gap: 4 }}>
        <Grid item
   
   sx={{backgroundColor:"white" ,padding:"4px",height:"auto",paddingTop:0,paddingLeft:0,paddingRight:0 }}>
 
 <Box
 className="collaborator_view_profile_maindiv"
  sx={{
    display: 'flex',
    flexDirection: 'column', // Use a column layout to stack icon and avatar vertically
    alignItems: 'center', // Center items horizontally
    justifyContent: 'flex-end', // Center items vertically
    height: '40vh', // Adjust the height as needed
    position: 'relative',
  }}
>
  <Box
  className="collaborator_view_profile_coverimg "
    sx={{
      height: '40vh',
      padding: '10px',
     width:"-webkit-fill-available",
      // backgroundImage: collaboratorbackgroundurlimagefinal ? `url(${collaboratorbackgroundurlimagefinal})` : 'none',
      backgroundImage: collaboratorbackgroundurlimagefinal ? `url(${collaboratorbackgroundurlimagefinal})` : `url(${bgimagecover})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // backgroundColor: image ? 'transparent' : 'black',
      display: 'flex',
      justifyContent: 'flex-end', // Place the icon at the flex end
      position: 'relative',
    }}
  >
    
  </Box>
  <div>
     

      <div >
        <Avatar
          alt="Cindy Baker"
          src={selectedImage || collaboratorlaravelUrlfinal}
          sx={{
            border: '2px solid white',
            width: '80px',
            height: '80px',
            transform: "translate(-50%, -50%) !important",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
          }}
          
        />
      </div>
    </div>
</Box>
<Dialog
      open={open2}
      // TransitionComponent={Transition}
      keepMounted
   
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent  className="Dialog_content">
      <Lottie    options={{
        loop: true,
        autoplay: true,
        animationData: messagerequesterror === 'Your request is already sent.' ? animationData2 : animationData,
      }}
       height={150} width={150} />
     
          <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
          {messagerequesterror === 'Your request is already sent.' ? 
        null
       : (
        <typography className="Success">Success</typography>
      )}
       
        <typography className='Submitted_text2'> {messagerequesterror && (
            <div className="success-message">{messagerequesterror}</div>
          )}</typography>
     
    
      </div>
      <DialogActions sx={{display:"flex",justifyContent:"center"}}>
      <Button onClick={handleClose2}>Ok</Button>
      
   </DialogActions>
      </DialogContent>
      
    </Dialog>
<div className='Student_Edit_icon_profile' >

{/* <ModeEditOutlineOutlinedIcon sx={{color:'#58595B'}} onClick={handleprofileView}/> */}
</div>

   <Box className="Student_profile_view_mainDiv2" >
 
   <typography className="Profile_view_welcome"> {collaboratorname}</typography>
   <typography className="Profile_view_desc"> {(hideChip && !collaboratorDescritpion)
? (userType1 === 'student' ? 'Applying to college the smart way' : 'Helping Students apply to college the smart way')
: collaboratorDescritpion || 'Helping Students apply to college the smart way'}</typography>
  <Box className="Complete_profile">
  {Collegesdata.some(college => college.decisionOptions === "Attended") ? (
<>
{Collegesdata
  .filter(college => college.decisionOptions === "Attended")
  .map((college, index) => {
    const collegeNamecollaborator = JSON.parse(college.collegeName);
    const Collegenameadded = collegeNamecollaborator ? collegeNamecollaborator.title : "";

    return (
      <div key={index} className="university-name">
        <typography className='Profile_view_location2'>
          <SchoolOutlinedIcon sx={{ height: "15px", width: "15px" }} />
          {Collegenameadded}
        </typography>
        {index < Collegesdata.length - 1 && <Divider variant="middle" />}
      </div>
    );
  })}
</>
) : (
null
)}
  {/* <typography className='Profile_view_location2'><LocationOnIcon sx={{height:"15px",width:"15px"}} />{collaboratoraddress}, {collaboratorcity}</typography> */}
    <typography className='Profile_view_date2'><CalendarMonthIcon sx={{height:"15px",width:"15px"}}/>joined {collaboratorjoindate}</typography>
  </Box>
   <Button  onClick={() => sendMessageRequest(collaboratorID)} variant='contained' sx={{mt:2}}>Message</Button>
   </Box>
   </Grid>
<Grid item
   
   sx={{backgroundColor:"white" ,padding:"4px",height:"auto" }}>
<Box sx={{display:'flex',flexDirection:'column'}}>
   
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div style={{ display: 'flex' }}>
<Tabs
value={value}
onChange={handleChange}
aria-label="basic tabs example"
sx={{
  '& .MuiTabs-flexContainer': {
    justifyContent: 'flex-start',
  },
}}
>
<Tab label="OVERVIEW" />
<Tab label="HONORS" />
<Tab label="ACTIVITIES" />
</Tabs>
</div>

<style>
{`
.MuiTabs-flexContainer {
 
  gap: 30px; /* Adjust the gap to control the space between tab labels */
}
`}
</style>


        </Box>
        <div role="tabpanel" hidden={value !== 0}>
  <Box sx={{display:'flex',flexDirection:'column'}}>


  <Box className="Collaborator_view_details_div" sx={{ display: "flex", flexDirection: "column", padding: "35px 20px 20px 30px" }}>
<Box sx={{ display: "flex", flexDirection: "row", marginBottom: 3 }}>
<typography className='Personal_text'>Personal Essay</typography>

</Box>

<div>
{Summarydata.length > 0 ? (
Summarydata.map((summary, index) => (
  <>

  <div key={index}>
  <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickSummary(summary.id);
  }}
>
    <typography className='Personal_text2' style={{userSelect: 'none'}}>
    {showFullSummary
            ? summary.personal_eassay
            : summary.personal_eassay.length > 100
            ? `${summary.personal_eassay.substring(0, 600)}...`
            : summary.personal_eassay}
          {summary.personal_eassay.length > 100 && (
            <span className="ViewMoreLink" onClick={handleViewMore}>
              {showFullSummary ? '  View Less' : '  View More'}
            </span>
          )}
    </typography>
    </Link>
  </div>
  
      </>
)) ) : (
  <div style={{display:"flex",justifyContent:"center"}}><typography className='Personal_text2'>No data added </typography></div>
  )}
</div>
</Box>
<Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"22px"}}  />

<Box className="collaborator_highschool_view_text" sx={{ display: "flex", flexDirection: "row", marginBottom: 6,padding: "20px"  }}>
<typography className='Personal_text'>High School Education</typography>

</Box>
<div>


{HighSchooldata && HighSchooldata.length > 0 ? (

HighSchooldata.map((item, index) => {
const coursesData = JSON.parse(item.coursesData);
// console.log('coursedata', coursesData);

// Store the total number of coursesData objects in the array
const courseDataCount = coursesData.length;
console.log(courseDataCount)
console.log(item,"This is high school data")
return (
  <>
  
<Box sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>

  <div key={index}>
 
    <div className='Collaborator_view_highschool_divs' style={{display:"flex",justifyContent:"space-between"}}>

    <div className='Collaborator_view_highschool_name' style={{display:"flex",gap:10}}>

  
     <Avatar className='Collaborator_view_avatar_img' src={Highschoolimage}>

</Avatar>

<div  style={{display:"flex",flexDirection:"column",gap:10}}>

<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
<Link
to="#"
className='Text_decoration'
onClick={(e) => {
e.preventDefault();
handleEditClickHighschool(item.id);
}}
>
    <typography className='Personal_text_main'> {item.hight_school_name} <KeyboardArrowRightIcon/></typography>
    </Link>
</div>
{/* <typography  className='Personal_text'>Degree: {item.degree_high}</typography> */}
    <Box style={{display:"flex",gap:8,alignItems:'center'}}><CalendarTodayIcon sx={{color:'#58595B',height:"15px",width:"15px"}}/>
    <typography  className='Personal_text2'> {new Date(item.start_date).getFullYear()}&nbsp;&nbsp;-</typography>
    <typography  className='Personal_text2'>{new Date(item.end_date).getFullYear()}</typography>
 
    </Box>
 
    </div>
     {/* <div style={{ borderLeft: "1px solid #ccc", height: "100%" }}></div> */}
    </div>
    <Divider className='Collaborator_highschool_view_divider' orientation="vertical" variant="middle" flexItem sx={{marginRight:"10px"}} />
<div  className='Collaborator_highschool_view_divider1'> </div>
    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
      <typography className='Personal_text2'>{item.gpa_type} GPA</typography>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
      <typography className='Personal_text_main'>{item.gpa_score}</typography><typography className='Personal_text2'>/out of {item.gpa_out_of}</typography>
      </div>
    </div>
    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
      <typography className='Personal_text2'>Rank in class</typography>
      <typography className='Personal_text_main'>{item.rank_in_class}</typography>
    </div>
    {/* <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
      <typography className='Personal_text2'>Credit hours</typography>
      <typography className='Personal_text_main'>125 hrs          </typography>
    </div> */}
    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
      <typography className='Personal_text2'>Total Courses</typography>
      <typography className='Personal_text_main'>{courseDataCount}</typography>
    </div>
    </div>  
  </div>
     {index < HighSchooldata.length - 1 && (
      // Add Divider only if it's not the last item in the array
      <Divider  variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"49px"}}  />
    )}
    </Box>
   
      </>
);
})
) : (
<div style={{display:"flex",justifyContent:"center"}}><typography className='Personal_text2'>No data added</typography></div>
)}
<Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"22px"}}  />
</div>

<Box className="collaborator_highschool_view_text" sx={{ display: "flex", flexDirection: "row", marginBottom: 6,padding: "20px" }}>
<typography className='Personal_text'>{hideChip ? 'Colleges interested in' : 'Colleges Applied to'}</typography>

</Box>


<div>
<div>

{Collegesdata.length > 0 ? (
// Render content related to the data
<div className='Collaborator_colleges_view_maindiv' style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}>
{Collegesdata.map((college, index) => {
const majorData = JSON.parse(college.major)[0]; // Parse the JSON and access the first item
const majorTitle = majorData ? majorData.title : ""; // Access the "title" property
const collegeNameObj = JSON.parse(college.collegeName); // Parse the JSON string
const collegeTitle = collegeNameObj.title; 
console.log(college,"This is college data")


return (
  <>
  
<Box sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>
  <div key={index} style={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}>

    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
      <div>
        <Avatar className='Collaborator_view_avatar_img' src={collegeimage}></Avatar>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
           justifyContent:'space-between'
          }}
        >
          <div className='Collaborator_view_colleges_div' style={{display:"flex",justifyContent:"space-between"}}>
          <Link
to="#"
className='Text_decoration'
onClick={(e) => {
e.preventDefault();
handleEditClick(college.id);
}}
>
          <typography className='Personal_text_main'>  {collegeTitle} <KeyboardArrowRightIcon/></typography></Link>
          <div style={{display:'flex',gap:10}}>
          {/* <Chip
label={college.decisionOptions}
color={college.decisionOptions === "accepted" ? "success" : college.decisionOptions === "rejected" ? "error" : "default"}
size="small"
variant="filled"
/> */}


</div>

          </div>

          <typography style={{ fontWeight: '600', fontSize: '16px' }}>{getStateFullName(college.highSchoolLocation)}</typography>
          
          <typography className='Personal_text2'>{majorTitle}</typography>
        </div>
      </div>
  
    </div>
    {!hideChip && (
    <Chip sx={{padding:"16px 8px"}}
label={college.decisionOptions}
color={college.decisionOptions === "Accepted" ? "success" : college.decisionOptions === "Rejected" ? "error": college.decisionOptions === "Waitlisted" ? "warning" : college.decisionOptions === "Deferred" ? "secondary" : college.decisionOptions === "Attended" ? "primary" :
"default"}
size="small"
variant="filled"
/> 
)}
  </div>

  </Box>
 </>
 
);
})
}
</div>
) : (
<div style={{display:"flex",justifyContent:"center"}}><typography className='Personal_text2'>No data added</typography></div>
)}

</div>
<Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"22px"}}  />
</div>


<Box className="collaborator_highschool_view_text" sx={{ display: "flex", flexDirection: "row", marginBottom: 6,padding: "20px" }}>
<typography  className='Personal_text'>Testing</typography>

</Box>
<div>

{Testingdata && Testingdata.length > 0 ? (
<div className='Collaborator_view_testing_maindiv' style={{display:"grid",gridTemplateColumns:"repeat(3,1fr)"}}>
{Testingdata.map((item, index) => {
const testData = JSON.parse(item.testdata);
console.log(item,"this is testting data")
return (
<Box key={index}  sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>

  {/* <div style={{display:"flex",gap:"30%",paddingTop:"20px",width:'fit-content'}}> */}
    {testData.map((test, testIndex) => (
      // <div key={testIndex}>
        <div key={testIndex} style={{display:"flex",flexWrap:"nowrap",gap:"20%",paddingBottom:"40px"}}>
        <div>
          <div style={{display:"flex",flexDirection:"row",gap:10}}>

          <Avatar className='Colleges_Avatar_img' src={testingimage}>

</Avatar>
<div style={{display:"flex",flexDirection:"column",gap:10}}>
<Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickTesting(item.id);
  }}
>
        <typography className='Personal_text_main'> {test[`testname-${testIndex}`]} <KeyboardArrowRightIcon/></typography></Link>
        <typography className="TypographyCustom11">Attempts: {test[`attempts-${testIndex}`]}</typography>
        <Box>
       <CalendarTodayIcon sx={{height:"15px",width:"15px"}}/> Taken In: <typography>{new Date(test[`start-date-${testIndex}`]).getFullYear()}</typography>
        </Box>
        <div style={{ display: "flex", flexDirection: "column",justifyContent:"space-between" }}>
        <Chip label={`Highest Score:  ${test["score-" + testIndex]}`} color="primary" />


{/* <typography><span className='Personal_text'>{test[`score-${testIndex}`]}</span></typography> */}
</div>
        </div>
        </div>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row",  gap: "10px",alignItems:"stretch" }}>
<div style={{ borderLeft: "1px solid #ccc", height: "100%" }}></div>


</div> */}
<Divider className='collaborator_view_Testing_Divider' orientation="vertical" variant="middle" flexItem />
        </div>
         

      // </div>
      
    ))}

  {/* </div> */}
  </Box>
);
})
}
</div>
) : (
<div style={{display:"flex",justifyContent:"center",marginBottom:"30px"}}><typography className='Personal_text2'>No data added</typography></div>
)}

</div>

  </Box>
</div>
        <div role="tabpanel" hidden={value !== 1}>
        <Box sx={{display:'flex',flexDirection:'column'}}>
        <Box sx={{ display: "flex", flexDirection: "row", marginBottom: 6,padding: "35px 20px 20px 30px"}}>
<typography className='Personal_text'>Honors</typography>

</Box>

        {Honoursdata && Honoursdata.length > 0 ? (
// Render the awards/honours data
Honoursdata.map((item, index) => {
const awards = JSON.parse(item.awards);
if (awards && awards.length > 0) {
  return awards.map((award, awardIndex) => (
    <>
    <div key={awardIndex}>
 
       <div style={{display:"flex",flexDirection:"row",gap:10,padding: "35px 20px 20px 30px" }}>
      <Avatar src={honoursimage}>

      </Avatar>
  
      <div style={{display:"flex",flexDirection:"column",width:'100%'}}>
      <div style={{ marginBottom:"20px",display:"flex",flexDirection:"column",gap:10}}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickHonours(item.id);
  }}
>
      <typography  className='Personal_text_main'> {award[`award-title-${awardIndex}`]} <KeyboardArrowRightIcon/></typography></Link>
    
</div>
<typography  className='Personal_text'> {award[`issuing-organization-${awardIndex}`]}</typography>
<Box>
       <CalendarTodayIcon sx={{height:"15px",width:"15px"}}/> <typography className='Personal_text2'> Awarded in: {new Date(award[`start-date-${awardIndex}`]).getFullYear()}</typography>
        </Box>
     
      <Chip
label={`Level: ${award[`level-${awardIndex}`]} `}
color="primary"
size="small"
variant="filled"
sx={{width:"fit-content"}}
/>
  </div>
  <typography  className='Personal_text2' style={{userSelect: 'none'}}>
{award && award[`award-description-${awardIndex}`] && award[`award-description-${awardIndex}`].length > 400
? `${award[`award-description-${awardIndex}`].substring(0, 400)}...`
: award && award[`award-description-${awardIndex}`]}
</typography>

      </div>
      </div>
    </div>
     {index < Honoursdata.length - 1 && (
      // Add Divider only if it's not the last item in the array
      <Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"49px"}}  />
    )}
    </>
  ));
}
return null;
})
) : (
<div style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}><typography className='Personal_text2'>No data added</typography></div>
)}

</Box>

        </div>
        <div role="tabpanel" hidden={value !== 2}>
        <Box className='Test_paddingBox2'  >
        <Box className="collaborator_highschool_view_text" sx={{ display: "flex", flexDirection: "row", marginBottom: 6,padding: "35px 20px 20px 30px"  }}>
<typography className='Personal_text'>Activities</typography>

</Box>
<div>
        {Activitiesdata && Activitiesdata.length > 0 ? (
<div className='Collaborator_view_sports' style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)"}}>
{ Activitiesdata.map((activity, index) => {
const sportsActivities = JSON.parse(activity.sports_activities);

return (
  <>
  
  <div key={index} style={{display:"flex",padding:"20px",justifyContent:"space-between"}}>
    {sportsActivities.map((item, i) => (
      <div key={i} >
        <div style={{display:"flex",flexDirection:"row",}}>

    <div style={{display:"flex",flexDirection:"row",gap:10}}>
         <div style={{display:"flex",flexDirection:'row',justifyContent:'space-between'}}>
          <Avatar  className='Collaborator_view_avatar_img' src={sportimage} sx={{width:"40px",height:"40px"}}>

</Avatar>

</div>
<div style={{display:"flex",flexDirection:"column",gap:10}}>
<Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickActivities(activity.id);
  }}
>
        <typography className='Personal_text_main'> {item[`high-school-name-${i}`]} <KeyboardArrowRightIcon/></typography></Link>
        <typography className='Personal_text3'>{item[`participated-as${i}`]}</typography>
        {/* <typography style={{ fontWeight: '600', fontSize: '16px' }}>Level {item[`high-activities-select-${i}`]}</typography> */}
        <Chip
label={`Level: ${item[`high-activities-select-${i}`]} `}
color="primary"
size="small"
variant="filled"
sx={{width:"fit-content"}}
/>
<typography  className='Personal_text2' style={{userSelect: 'none'}}>{item[`description-${i}`]}</typography>

        </div>
      
        </div>
        <div style={{ borderLeft: "2px solid black", height: "100%" }}></div>  
        </div>
      </div>
    ))}
      {index % 2 === 0 && (
    <div className='Sports_collaborator_view' style={{ borderLeft: "1px solid #ccc", height: "100%", marginLeft: "10px" }}></div>
  )}
  </div>
   
    </>
);
})}
</div>
) : (
<div style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
    <typography className='Personal_text2'>No data added</typography>
  </div>
)}
</div>
<Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"22px"}}  />
 
<Box sx={{ display: "flex", flexDirection: "row", padding: "20px" }}>
<typography  className='Personal_text'>Work Experience</typography>

</Box>
<div>
        {Experiencedata && Experiencedata.length > 0 ? (
// Render the extra-curricular activities data
Experiencedata.map((activity, index) => {
const sportsActivities = JSON.parse(activity.work_experience);

return (
  <>
  
  <div className='collaborator_view_experience_div' key={index} style={{display:"flex",padding:"20px",justifyContent:"space-between"}}>
    {sportsActivities.map((item, i) => (
      <div key={i} >
        <div style={{display:"flex",flexDirection:"row",gap:'15px'}}>
        <Avatar  src={Experiance_icon} />
    <div style={{display:"flex",flexDirection:"row",gap:10}}>
 
<div style={{display:"flex",flexDirection:"column",gap:10}}>
<Link
  to="#"
  className='Text_decoration'
  onClick={(e) => {
    e.preventDefault();
    handleEditClickExperience(activity.id);
  }}
>
        <typography  className='Personal_text_main'> {item[`Title-${i}`]} <KeyboardArrowRightIcon/></typography></Link>
        <typography style={{ fontWeight: '600', fontSize: '16px' }}>{item[`organization-name-${i}`]} - {item[`job-type-${i}`]}</typography>
        <Box>
       <CalendarTodayIcon sx={{height:"15px",width:"15px",color:"#2E7D32"}}/> <typography className="workDate">
{new Date(item[`start-date-${i}`]).toLocaleString('default', { month: 'short' })}{' '}
{new Date(item[`start-date-${i}`]).getFullYear()} -{' '}
{item[`options-${i}`] !== 'yes' ? (
<>
{new Date(item[`end-date-${i}`]).toLocaleString('default', { month: 'short' })}{' '}
{new Date(item[`end-date-${i}`]).getFullYear()}
</>
) : (
'Present' // or any text you want to display when `item[`options-${i}`]` is 'no'
)}
</typography>
        </Box>
<typography className="job_text">Job Description</typography>
<typography  className='Personal_text2' style={{userSelect: 'none'}}>{item[`job-description-${i}`]}</typography>

        </div>
      
        </div>
    
        </div>
      </div>
    ))}
  
  </div>
  {index < Experiencedata.length - 1 && (
      // Add Divider only if it's not the last item in the array
      <Divider variant="inset" sx={{paddingTop:"30px",marginBottom:"30px",marginLeft:"60px"}}  />
    )}
    </>
);
})
) : (
<div style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}><typography className='Personal_text2'>No data added</typography></div>
)}
</div>
</Box>
        </div>
     
      </Box>








   
   </Grid>
 
  
   </Grid>
   <Grid item 
   className='Messaging_collaborator_view_hide'
       lg={3.2}
       xl={2.13}
       xs={5.3}
       sm={2.7}
       md={2.2}
     sx={{backgroundColor:"transparent",padding:"4px"}}>
  
     <Grid item 
   
   sx={{backgroundColor:"white",padding:"20px",marginTop:'20px'}}>
     <Box sx={{display:'flex',flexDirection:'column',paddingRight:'10px'}}>
         <typography className='Student_message_text'>Messaging</typography>
         <TextField
   id="outlined-basic"
   label="Search Chats"
   name="Search Chats"
   variant="outlined"
   fullWidth
   size="small"
   InputLabelProps={{
     sx: {
       fontSize: '14px', // Set the desired font size for the label
     },
   }}
 />
 
       
 
      </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', cursor: 'pointer' }}>
   {collaboratorschatusers && collaboratorschatusers.map((message) => (
     <div
       key={message.id}
       className={activeComponent === 'collaboratorschatusers' && selectedMessage?.id === message.id ? 'active' : ''}
       onClick={() => {
         setActiveComponent('collaboratorschatusers');
         setSelectedMessage({ ...message, badgenumber: 0 }); // Set the selected message when clicked
 
         handleItemClick(message)
       }}
     >
       <CardHeader
         sx={{
           padding: '20px 0px 0px 0px',
         }}
         avatar={
           <Badge
             badgeContent={message.badgenumber}
             color="primary"
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
             }}
           >
             <Avatar
               alt={message.sender}
               src={message.senderpic}
               sx={{
                 width: '40px',
                 height: '40px',
                 borderRadius: '20px',
               }}
             />
           </Badge>
         }
         title={
           <span
             style={{
               fontSize: '14px',
               fontWeight: 'bold',
             }}
           >
             {message.sender}
           
           </span>
         }
         subheader={
           <span
             style={{
               fontSize: '12px',
             }}
           >
             {message.content}
           </span>
         }
       />
     </div>
   ))}
 </Box>
 
 </Grid>

       </Grid>






      </Grid>
      </div>
  )
}

export default StudentProfile