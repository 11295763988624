import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import { Box, Chip, Divider, Typography } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Topbar from '../Students/Topbar'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tobar from '../collaborator/Tobar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Activities_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
  const [type, setType] = useState()
  const [view, setView] = useState();
  const [userId, setUserId] = useState();
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 
  const breadcrumbs = [
    <Link
      underline="hover"
      style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}
      key="1"
      color="inherit"
      href={
        type === 'admin' && view === 'student'
          ? `/admin_student_profileview?id=${encodeAlphanumeric(userId)}`
          : type === 'admin'
            ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
            : type === 'student' && view === 'student'
            ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}&type=student`
            : type === 'student'
              ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
              : type === 'collaborator' && view === 'student'
              ? `/studentProfile?id=${encodeAlphanumeric(userId)}&type=collaborator`
              : null
      }
    >
      <ArrowBackIosIcon fontSize="meduim" />
     BACK TO PROFILE
    </Link>
 
   
  ];
  const [activityData, setActivityData] = useState('');
  const [formData, setFormData] = useState({
    highest_degree_you_earn: '',
    career_intrest: '',
    awards: [],
    // Add other fields as needed
  });
const [formid, setFormid] = useState('');
const checkuser = JSON.parse(localStorage.getItem('userData'));
const urlParams = new URLSearchParams(window.location.search);

useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTestingId = searchParams.get('id');
    const view = searchParams.get('view');
    setFormid(selectedTestingId );
    const apiUrl = process.env.REACT_APP_API_URL;
    const type = searchParams.get('type');
    setType(type)
    setView(view)
    if (selectedTestingId !== '') {
      const apiEndpoint = view === 'student' ? '/get_student_activities_form_data' : '/get_activities_form_data';
        try {
            fetch(`${apiUrl}${apiEndpoint}`, {
                method: 'post',
                body: JSON.stringify({ formid: selectedTestingId }),
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Request failed');
                }
            })
            .then(data => {
                const personalEssayData = data.formdata[0];
                console.log(personalEssayData, "setHonoursData");
                const fetchedUserId = personalEssayData.userId || '';
                setUserId(fetchedUserId);
                setActivityData(data.formdata);
                const AwardDataArray = JSON.parse(personalEssayData.sports_activities
                   || '[]').map((award, index) => ({
                  title: award[`high-school-name-${index}`] || '',
                  level: award[`high-activities-select-${index}`] || '',
                  
                  participated: award[`participated-as${index}`] || '',
                 
                  description: award[`description-${index}`] || '',
                }));
               
                setFormData({
                  highest_degree_you_earn: personalEssayData.highest_degree_you_earn || '',
                  career_intrest: personalEssayData.career_intrest || '',
                  awards: AwardDataArray,
                });
             
              
                console.log(AwardDataArray, "awardsArray");
            })
            .catch(error => {
                console.error('Data fetch failed:', error);
            });
        } catch (error) {
            console.error('Data upload failed:', error);
        }
    }
}, []);

  
  
  return (
    <div>
  {type === 'student' ? <Topbar />: type === 'collaborator' ? <Tobar />  : <Topbar2 />}
     <Grid container 
gap="20px"
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Grid_Collaborator_Activities_detailpage'
>
<Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
<Grid
item
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Collaborator_Activities_info_detailpage'>
<Box className='Sports_info_Box1'>
<DirectionsRunIcon className='College_info_Avatar' />
<typography className='College_heading'>
Sports</typography>
</Box>
<Box className='Sports_info_main_Box'>
<Box className='Sports_info_Box2'>
  <Box className='Sports_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Sports Activity</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.title}</typography>
  </Box>
  <Box className='Sports_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Level</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.level}</typography>
  </Box>
  <Box className='Sports_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Participated as</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.participated}</typography>
  </Box>
</Box>
<Box className='Sports_info_Box2'>
  <Box className='Sports_info_Box2_secondary11'>
    <typography className='College_heading_secondary'>Description</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.description} </typography>
  </Box>
 
</Box>
</Box>
<Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />

</Grid>




</Grid>
  </div>
  )
}

export default Activities_detailpage