import React,{useState,useEffect}  from 'react'
import Grid from '@mui/material/Grid';
import Topbar from '../Topbar';
import backgroundimage from "../../../assets/img/bg-img.png";
import { Box, Typography,InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import { useLocation,useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const Student_profileedit = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
    const location = useLocation();
    const navigate = useNavigate();
    const breadcrumbs = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href={`/student_complete_profile`} 
       
      >
        Profile
      </Link>,
   
      <Typography key="3" color="text.primary">
     Personal Information
      </Typography>
    ];
    const [value, setValue] = React.useState(0);
 
    const [ErrorTest,setErrorTest] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [open, setOpen] = React.useState(false);
 
    const cities = [
        "Aberdeen",
        "Abilene",
        "Aguada",
        "Aguas",
        "Aibonito",
        "Akron",
        "Albany",
        "Albuquerque",
        "Alexandria",
        "Allentown",
        "Alta",
        "Alto",
        "Amarillo",
        "Anaheim",
        "Anchorage",
        "Ann Arbor",
        "Antioch",
        "Apple Valley",
        "Appleton",
        "Arlington",
        "Arroyo",
        "Arvada",
        "Asheville",
        "Athens",
        "Atlanta",
        "Atlantic City",
        "Augusta",
        "Aurora",
        "Austin",
        "Baja",
        "Bakersfield",
        "Baltimore",
        "Barceloneta",
        "Barnstable",
        "Baton Rouge",
        "Bayamón",
        "Beaumont",
        "Bel Air",
        "Bellevue",
        "Berkeley",
        "Bethlehem",
        "Billings",
        "Birmingham",
        "Bloomington",
        "Boise",
        "Boise City",
        "Bonita Springs",
        "Boston",
        "Boulder",
        "Bradenton",
        "Bremerton",
        "Bridgeport",
        "Brighton",
        "Brownsville",
        "Bryan",
        "Buffalo",
        "Burbank",
        "Burlington",
        "Cambridge",
        "Camuy",
        "Canton",
        "Cape Coral",
        "Carolina",
        "Carrollton",
        "Cary",
        "Cathedral City",
        "Cayey",
        "Cedar Rapids",
        "Champaign",
        "Chandler",
        "Charleston",
        "Charlotte",
        "Chattanooga",
        "Chesapeake",
        "Chicago",
        "Chula Vista",
        "Ciales",
        "Cincinnati",
        "Clarke County",
        "Clarksville",
        "Clearwater",
        "Cleveland",
        "Coamo",
        "College Station",
        "Colorado Springs",
        "Columbia",
        "Columbus",
        "Concord",
        "Coral Springs",
        "Corona",
        "Corozal",
        "Corpus Christi",
        "Costa Mesa",
        "Dallas",
        "Daly City",
        "Danbury",
        "Davenport",
        "Davidson County",
        "Dayton",
        "Daytona Beach",
        "Deltona",
        "Denton",
        "Denver",
        "Des Moines",
        "Detroit",
        "Dorado",
        "Downey",
        "Duluth",
        "Durham",
        "Díaz",
        "El Monte",
        "El Paso",
        "Elizabeth",
        "Elk Grove",
        "Elkhart",
        "Erie",
        "Escondido",
        "Eugene",
        "Evansville",
        "Fairfield",
        "Fargo",
        "Fayetteville",
        "Fitchburg",
        "Flint",
        "Florida",
        "Fontana",
        "Fort Collins",
        "Fort Lauderdale",
        "Fort Smith",
        "Fort Walton Beach",
        "Fort Wayne",
        "Fort Worth",
        "Frederick",
        "Fremont",
        "Fresno",
        "Fullerton",
        "Gainesville",
        "Garden Grove",
        "Garland",
        "Gastonia",
        "Germán",
        "Gilbert",
        "Glendale",
        "Grand Prairie",
        "Grand Rapids",
        "Grayslake",
        "Green Bay",
        "GreenBay",
        "Greensboro",
        "Greenville",
        "Guayanilla",
        "Gulfport-Biloxi",
        "Gurabo",
        "Hagerstown",
        "Hampton",
        "Harlingen",
        "Harrisburg",
        "Hartford",
        "Hatillo",
        "Havre de Grace",
        "Hayward",
        "Hemet",
        "Henderson",
        "Hesperia",
        "Hialeah",
        "Hickory",
        "High Point",
        "Hollywood",
        "Honolulu",
        "Houma",
        "Houston",
        "Howell",
        "Humacao",
        "Huntington",
        "Huntington Beach",
        "Huntsville",
        "Independence",
        "Indianapolis",
        "Inglewood",
        "Irvine",
        "Irving",
        "Isabel",
        "Jackson",
        "Jacksonville",
        "Jayuya",
        "Jefferson",
        "Jersey City",
        "Johnson City",
        "Joliet",
        "Juan",
        "Kailua",
        "Kalamazoo",
        "Kaneohe",
        "Kansas City",
        "Kennewick",
        "Kenosha",
        "Killeen",
        "Kissimmee",
        "Knoxville",
        "Lacey",
        "Lafayette",
        "Lajas",
        "Lake Charles",
        "Lakeland",
        "Lakewood",
        "Lancaster",
        "Lansing",
        "Laredo",
        "Las",
        "Las",
        "Las Cruces",
        "Las Vegas",
        "Layton",
        "Leominster",
        "Lewisville",
        "Lexington",
        "Lincoln",
        "Little Rock",
        "Loiza",
        "Long Beach",
        "Lorain",
        "Lorenzo",
        "Los Angeles",
        "Louisville",
        "Lowell",
        "Lubbock",
        "Macon",
        "Madison",
        "Manatí",
        "Manchester",
        "Marina",
        "Marysville",
        "Maunabo",
        "McAllen",
        "McHenry",
        "Medford",
        "Melbourne",
        "Memphis",
        "Merced",
        "Mesa",
        "Mesquite",
        "Miami",
        "Milwaukee",
        "Minneapolis",
        "Miramar",
        "Mission Viejo",
        "Mobile",
        "Moca",
        "Modesto",
        "Monroe",
        "Monterey",
        "Montgomery",
        "Moreno Valley",
        "Murfreesboro",
        "Murrieta",
        "Muskegon",
        "Myrtle Beach",
        "Naguabo",
        "Naperville",
        "Naples",
        "Nashua",
        "Nashville",
        "New Bedford",
        "New Haven",
        "New London",
        "New Orleans",
        "New York",
        "New York City",
        "Newark",
        "Newburgh",
        "Newport News",
        "Norfolk",
        "Normal",
        "Norman",
        "North Charleston",
        "North Las Vegas",
        "North Port",
        "Norwalk",
        "Norwich",
        "Oakland",
        "Ocala",
        "Oceanside",
        "Odessa",
        "Ogden",
        "Oklahoma City",
        "Olathe",
        "Olympia",
        "Omaha",
        "Ontario",
        "Orange",
        "Orem",
        "Orlando",
        "Orocovis",
        "Overland Park",
        "Oxnard",
        "Palm Bay",
        "Palm Springs",
        "Palmdale",
        "Panama City",
        "Pasadena",
        "Paterson",
        "Pembroke Pines",
        "Pensacola",
        "Peoria",
        "Peñuelas",
        "Philadelphia",
        "Phoenix",
        "Pittsburgh",
        "Plano",
        "Pomona",
        "Pompano Beach",
        "Port Arthur",
        "Port Orange",
        "Port Saint Lucie",
        "Port St. Lucie",
        "Portland",
        "Portsmouth",
        "Poughkeepsie",
        "Providence",
        "Provo",
        "Pueblo",
        "Punta Gorda",
        "Quebradillas",
        "Racine",
        "Raleigh",
        "Rancho Cucamonga",
        "Reading",
        "Redding",
        "Reno",
        "Richland",
        "Richmond",
        "Richmond County",
        "Rio",
        "Riverside",
        "Roanoke",
        "Rochester",
        "Rockford",
        "Rojo",
        "Roseville",
        "Round Lake Beach",
        "Sabana",
        "Sacramento",
        "Saginaw",
        "Saint Louis",
        "Saint Paul",
        "Saint Petersburg",
        "Salem",
        "Salinas",
        "Salinas",
        "Salt Lake City",
        "San Antonio",
        "San Bernardino",
        "San Buenaventura",
        "San Diego",
        "San Francisco",
        "San Jose",
        "Santa Ana",
        "Santa Barbara",
        "Santa Clara",
        "Santa Clarita",
        "Santa Cruz",
        "Santa Maria",
        "Santa Rosa",
        "Sarasota",
        "Savannah",
        "Scottsdale",
        "Scranton",
        "Seaside",
        "Seattle",
        "Sebastian",
        "Sebastián",
        "Shreveport",
        "Simi Valley",
        "Sioux City",
        "Sioux Falls",
        "South Bend",
        "South Lyon",
        "Spartanburg",
        "Spokane",
        "Springdale",
        "Springfield",
        "St. Louis",
        "St. Paul",
        "St. Petersburg",
        "Stamford",
        "Sterling Heights",
        "Stockton",
        "Sunnyvale",
        "Syracuse",
        "Tacoma",
        "Tallahassee",
        "Tampa",
        "Temecula",
        "Tempe",
        "Thornton",
        "Thousand Oaks",
        "Toledo",
        "Topeka",
        "Torrance",
        "Trenton",
        "Tucson",
        "Tulsa",
        "Tuscaloosa",
        "Tyler",
        "Utica",
        "Vallejo",
        "Vancouver",
        "Vega",
        "Vega",
        "Vero Beach",
        "Victorville",
        "Vieques",
        "Virginia Beach",
        "Visalia",
        "Waco",
        "Warren",
        "Washington",
        "Waterbury",
        "Waterloo",
        "West Covina",
        "West Valley City",
        "Westminster",
        "Wichita",
        "Wilmington",
        "Winston",
        "Winter Haven",
        "Worcester",
        "Yabucoa",
        "Yakima",
        "Yauco",
        "Yonkers",
        "York",
        "Youngstown",
      ];
      const states = [
        "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
      "Puerto Rico",
      "Guam",
      "U.S. Virgin Islands",
      "American Samoa",
      "Northern Mariana Islands",
      "International"
      ];
      
      const [formData, setFormData] = useState({
        Headline: '',
      });
      const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        console.log('Field Name:', name, 'New Value:', value);
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
      
        try {
          fetch(`${apiUrl}/getuserdetailsforeditpage`, {
            method: 'post',
            body: JSON.stringify({ userId: userId }),
          })
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                throw new Error('Request failed');
              }
            })
            .then((userData) => {
              console.log(userData.user.legal_name, "legalname");
              console.log(userData.user.address, "address");
              console.log(userData.user.city, "city");
      
              // Check if userData is present in the received data
              if (userData.user) {
                const { legal_name, address, city ,Headline} = userData.user;
      
                // Set the formData state with the received data
                setFormData({
                  legalName: legal_name,
                  address,
                  city:city,
                  Headline:Headline,
                });
              }
            })
            .catch((error) => {
              console.error('Data fetch failed:', error);
            });
        } catch (error) {
          console.error('Data upload failed:', error);
        }
      }, []);
      
  

  
      const handleSubmit = async (event) => {
        event.preventDefault();
      
        // Your existing code to validate test data
        // ...
      
        try {
          setOpen(true);
          const apiUrl = process.env.REACT_APP_API_URL;
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userId = userData.id;
      
          // Create an object to hold the user's profile data
          const profileData = {
            legal_name: formData.legalName,
            address: formData.address,
            city: formData.city,
            Headline: formData.Headline,
            // Add other fields as needed
          };
      
          // Send both test data and profile data in the request
          const response = await fetch(`${apiUrl}/update_profile_of_user`, {
            method: 'post',
            body: JSON.stringify({  profile: profileData, userId }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
      
          // setShowAlert(true);
      
          if (response.status === 201) {
            const data = await response.json();
            console.log(data.user[0]);
            localStorage.removeItem("userData"); 
            localStorage.setItem("userData", JSON.stringify(data.user[0]));
            setShouldNavigate(true);
          } else {
            console.error('Data upload failed:', response.statusText);
          }
        } catch (error) {
          console.error('Data upload failed:', error);
        }
      };
      
    useEffect(() => {
      if (shouldNavigate) {
        const navigateTimer = setTimeout(() => {
          // Navigate to the desired page after 2 seconds
          // setShowAlert(false);
          setOpen(true);
          navigate('/student_complete_profile');
        }, 3000);
  
        // Clean up the timer to avoid memory leaks
        return () => clearTimeout(navigateTimer);
      }
    }, [shouldNavigate]);
  return (
    <div>
         <Topbar />
    <Grid
      container
      lg={12}
      xl={12}
      xs={12}
      sm={12}
      md={12}
      sx={{
        padding: "20px",
        minHeight: "92vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{ background: "rgba(255, 255, 255, 0.80)",
        backdropfilter: "blur(25px)", padding: "20px" }}
      >
          <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
       <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
        
    
        <Box className="Test_BoxDiv">
        <Box className="profile_edit_page_maindiv" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <div>

            </div>
          <div role="tabpanel" hidden={value !== 0}>
          
          <Box className="Test_paddingBox" >
             
            
           
             
  <div  style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 20, justifyContent: "center", paddingTop: 20 }}>
  <div className='Test_FlexRow_special2'>
       <TextField id="legalName" name="legalName"    label="Legal Name" variant="outlined"   value={formData.legalName}    onChange={handleTextFieldChange}     fullWidth size="small"    InputLabelProps={{
    shrink: formData.legalName !== '', // Shrink label when value is not empty
  }}  />
     
      </div>
      <TextField id="Headline" value={formData.Headline || "Applying to college the smart way"} onChange={handleTextFieldChange} name="Headline" label="Headline" variant="outlined" fullWidth size="small" />
    <div className='Test_FlexRow_special2'>
      <TextField   id="address" name="address"  label="Address"   onChange={handleTextFieldChange} variant="outlined" value={formData.address}  fullWidth size="small"  InputLabelProps={{
    shrink: formData.address !== '', // Shrink label when value is not empty
  }}   />
      <TextField
  key={formData.city} // Add a unique key
  value={formData.city}
  size="small"
  fullWidth
  select
  label="State"
  onChange={(event) => {
    setFormData({ ...formData, city: event.target.value });
  }}
>
  {states.map((stateName, index) => (
    <MenuItem key={index} value={stateName}>
      {stateName}
    </MenuItem>
  ))}
</TextField>

    </div>
  </div>



  </Box>
  {ErrorTest && (
    <FormHelperText>{ErrorTest}</FormHelperText>
  )}


          </div>
          <div className='Test_buttonDiv'>
  <Link
      underline="hover"
      key="1"
      color="inherit"
      href={`/student_complete_profile`} 
     
    >
    <Button className='sudent_editprofile_button' variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:"none"}}>Back</Button></Link>
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={`/student_complete_profile`} 
     
    >
    <Button className='sudent_editprofile_button'  variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:"none"}} onClick={handleSubmit}>Save</Button></Link>
  </div>
         </Box>
         
        </Box>
        {/* {showAlert && (
        <Alert
          severity="success"
          sx={{
            position: 'absolute',
            bottom: 0, // Place the Alert at the bottom
            left: 0,
            right: 0,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Your data was successfully saved! Redirecting...
        </Alert>
      )} */}
      </Grid>
    </Grid></div>
  )
}

export default Student_profileedit