import React,{useState,useEffect}  from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import { Box, Chip, Divider } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import dayjs from 'dayjs';
import Topbar from '../Students/Topbar'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tobar from '../collaborator/Tobar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Honours_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const urlParams = new URLSearchParams(window.location.search);
  const [type, setType] = useState()
  const [view, setView] = useState();
  const [userId, setUserId] = useState();
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 
  const breadcrumbs = [
    <Link
      underline="hover"
      style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}    
        key="1"
      color="inherit"
      href={
        type === 'admin' && view === 'student'
          ? `/admin_student_profileview?id=${encodeAlphanumeric(userId)}`
          : type === 'admin'
            ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
            : type === 'student' && view === 'student'
            ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}&type=student`
            : type === 'student'
              ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
              : type === 'collaborator' && view === 'student'
              ? `/studentProfile?id=${encodeAlphanumeric(userId)}&type=collaborator`
              : null
      }
     
    >
         <ArrowBackIosIcon fontSize="meduim" />
     BACK TO PROFILE
    </Link>
 
   
  ];

    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
    const [formData, setFormData] = useState({
      highest_degree_you_earn: '',
      career_intrest: '',
      awards: [],
      // Add other fields as needed
    });
  
  const [formid, setformid] = useState('');
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
   
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedHonoursId = searchParams.get('id');
    const view = searchParams.get('view');
    setView(view)
    setformid(selectedHonoursId);
    const apiUrl = process.env.REACT_APP_API_URL;
    const type = searchParams.get('type');
    setType(type)
    if (selectedHonoursId !== '') {
      const apiEndpoint = view === 'student' ? '/get_students_honours_form_data' : '/get_honours_form_data';
      try {
        fetch(`${apiUrl}${apiEndpoint}`, {
          method: 'post',
          body: JSON.stringify({ formid: selectedHonoursId }),
        })
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error('Request failed');
            }
          })
          .then(data => {
            const Honours_Data = data.formdata[0];
            console.log(Honours_Data, "setHonoursData");
            const fetchedUserId = Honours_Data.userId || '';
            setUserId(fetchedUserId);
            const AwardDataArray = JSON.parse(Honours_Data.awards || '[]').map((award, index) => ({
              title: award[`award-title-${index}`] || '',
              organization: award[`issuing-organization-${index}`] || '',
              startDate: dayjs(award[`start-date-${index}`]).format('MM/DD/YYYY') || '',
              level: award[`level-${index}`] || '',
              gradeAward: award[`grade-award-${index}`] || '',
              description: award[`award-description-${index}`] || '',
            }));
            setFormData({
              highest_degree_you_earn: Honours_Data.highest_degree_you_earn || '',
              career_intrest: Honours_Data.career_intrest || '',
              awards: AwardDataArray,
            });
         
          
            console.log(AwardDataArray, "awardsArray");
          
          })
          .catch(error => {
            console.error('Data fetch failed:', error);
          });
      } catch (error) {
        console.error('Data upload failed:', error);
      }
    }
  }, []);
  return (
    <div>
        {type === 'student' ? <Topbar /> : type === 'collaborator' ? <Tobar />  : <Topbar2 />}
     <Grid container 
gap="20px"
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Grid_Collaborator_Honors_detailpage'
>
<Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
<Grid
item
lg={12}
xl={12}
xs={12}
sm={12}
md={12}
className='Collaborator_Honors_info_detailpage'>
<Box className='Honors_info_Box1'>
<EmojiEventsOutlinedIcon className='College_info_Avatar' />
<typography className='College_heading'>
Honors</typography>
</Box>
<Box className='Honors_info_main_Box'>
<Box className='Honors_info_Box2'>
  <Box className='Honors_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Award Title</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.title}</typography>
  </Box>
  <Box className='Honors_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Issuing Organization</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.organization}</typography>
  </Box>
  <Box className='Honors_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Issue Date</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.startDate}</typography>
  </Box>
</Box>
<Box className='Honors_info_Box2'>
  <Box className='Honors_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Level</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.level}</typography>
  </Box>
  <Box className='Honors_info_Box2_secondary1'>
    <typography className='College_heading_secondary'>Grade Level</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.gradeAward}</typography>
  </Box>
  <Box className='Honors_info_Box2_secondary1'>
    {/* <typography className='College_heading_secondary'>How many Attempts?</typography>
    <typography className='College_name_secondary'>01</typography> */}
  </Box>
</Box>
<Box className='Honors_info_Box2'>
  <Box className='Honors_info_Box2_secondary11'>
    <typography className='College_heading_secondary'>Award Description</typography>
    <typography className='College_name_secondary'>{formData.awards[0]?.description}</typography>
  </Box>

</Box>

</Box>
<Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />
</Grid>




</Grid>
  </div>
  )
}

export default Honours_detailpage