import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import './detailpage.css';
import { Topbar as Topbar2 } from '../Admin/Topbar';
import { Box, Chip, Divider } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import Topbar from '../Students/Topbar'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tobar from '../collaborator/Tobar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Highschool_detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when it is loaded
  }, []);
  const [formid, setformid] = useState('');
  const [type, setType] = useState()
  const [view, setView] = useState();
  const [userId, setUserId] = useState();
  function encodeAlphanumeric(id) {
    const encoded = btoa(id); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;
  } 
  const breadcrumbs = [
    <Link
      underline="hover"
      style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:'bold'}}
      key="1"
      color="inherit"
      href={
        type === 'admin' && view === 'student'
          ? `/admin_student_profileview?id=${encodeAlphanumeric(userId)}`
          : type === 'admin'
            ? `/admin_Collaborator_profileview?id=${encodeAlphanumeric(userId)}`
            : type === 'student' && view === 'student'
            ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}&type=student`
            : type === 'student'
              ? `/Collaborator_Profile?id=${encodeAlphanumeric(userId)}`
              : type === 'collaborator' && view === 'student'
              ? `/studentProfile?id=${encodeAlphanumeric(userId)}&type=collaborator`
              : null
      }
     
    >
         <ArrowBackIosIcon fontSize="meduim" />
     BACK TO PROFILE
    </Link>
 
   
  ];
  const [formData, setFormData] = useState({
    hight_school_name: '',
    high_school_location: '',
    start_date: '',
    end_date: '',
    gpa_score: '',
    gpa_out_of: '',
    gpa_type: '',
    rank_in_class: '',
    coursesData: '',
    degree_high:'',
    userId:'',
  formid:'',

  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedHighschoolId = searchParams.get('id');
    const view = searchParams.get('view');
    setformid(selectedHighschoolId);
    setView(view)
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;
    const type = searchParams.get('type');
    setType(type)
    if (selectedHighschoolId !== '') {
      const apiEndpoint = view === 'student' ? '/get_student_high_school_form_data' : '/get_high_school_form_data';
      try {
        fetch(`${apiUrl}${apiEndpoint}`, {
          method: 'post',
          body: JSON.stringify({ formid: selectedHighschoolId }),
        })
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error('Request failed');
            }
          })
          .then(data => {
            const highschool = data.formdata[0];
  console.log('High School Data:', highschool);
            // const coursesData = JSON.parse(highschool.coursesData || '[]');
            const startDate = highschool.start_date ? new Date(highschool.start_date) : null;
            const formattedStartDate = startDate
              ? `${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')}/${startDate.getFullYear()}`
              : '';
              const EndDate = highschool.end_date ? new Date(highschool.end_date) : null;
              const formattedEndDate = EndDate
                ? `${(EndDate.getMonth() + 1).toString().padStart(2, '0')}/${EndDate.getDate().toString().padStart(2, '0')}/${EndDate.getFullYear()}`
                : '';
                const CourseDataArray = JSON.parse(highschool.coursesData || '[]');
                const fetchedUserId = highschool.userId || '';
                setUserId(fetchedUserId);
            setFormData(prevState => ({
              ...prevState,
              hight_school_name: highschool.hight_school_name || '',
              high_school_location: highschool.high_school_location || '',
              start_date: formattedStartDate,
              end_date: formattedEndDate,
              gpa_score: highschool.gpa_score || '',
              gpa_out_of: highschool.gpa_out_of || '',
              gpa_type: highschool.gpa_type || '',
              rank_in_class: highschool.rank_in_class || '',
              degree_high: highschool.degree_high || '',
              userId: highschool.userId || '',
              coursesData: CourseDataArray.map((dataCourse, index) => ({
                title: dataCourse[`course_tittle${index}`] || '',
                grades: dataCourse[`grades_earned_in_course${index}`] || '',
              })),
            }));
            console.log('High School Name:', highschool.hight_school_name);
            console.log('Form Data:', formData);
          })
          .catch(error => {
            console.error('Data fetch failed:', error);
          });
      } catch (error) {
        console.error('Data upload failed:', error);
      }
    }
  }, [location.search]);

  return (
    <div>
    {type === 'student' ? <Topbar /> : type === 'collaborator' ? <Tobar />  : <Topbar2 />}
         <Grid container 
    gap="20px"
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    className='Grid_Collaborator_Highschool_detailpage'
   >
        <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      {/* <p>College ID: {collegeId}</p> */}
    </Stack>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_Highschool_info_detailpage'>
 <Box className='Highschool_info_Box1'>
 <SchoolOutlinedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  High School information
  </typography>
 </Box>
 <Box className='Highschool_info_main_Box'>
    <Box className='Highschool_info_Box2'>
      <Box className='Highschool_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>High school name</typography>
        <typography className='College_name_secondary'>{formData.hight_school_name}</typography>
      </Box>
      <Box className='Highschool_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>College Location</typography>
        <typography className='College_name_secondary'>{formData.high_school_location}</typography>
      </Box>
      <Box className='Highschool_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Start Date</typography>
        <typography className='College_name_secondary'>{formData.start_date}</typography>
      </Box>
    </Box>
    <Box className='Highschool_info_Box2'>
      <Box className='Highschool_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>End Date</typography>
        <typography className='College_name_secondary'>{formData.end_date}</typography>
      </Box>
     
    </Box>
    </Box>
  </Grid>
  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_Gpa_info_detailpage'>
    <Box className='Gpa_info_Box1'>
 <SpeedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  GPA 
  </typography>
 </Box>
 <Box className='Gpa_info_main_Box'>

      <Box className='Gpa_info_Box2'>
      <Box className='Gpa_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>GPA</typography>
        <typography className='College_name_secondary'>{formData.gpa_score} /out of {formData.gpa_out_of}</typography>
      </Box>
      <Box className='Gpa_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>GPA Type</typography>
        <typography className='College_name_secondary'>{formData.gpa_type}</typography>
      </Box>
      <Box className='Gpa_info_Box2_secondary1'>
      <typography className='College_heading_secondary'>Rank in class</typography>
        <typography className='College_name_secondary'>{formData.rank_in_class}</typography>
      </Box>
    </Box>
 
      </Box>
  </Grid>

  <Grid
  item
   lg={12}
   xl={12}
   xs={12}
   sm={12}
   md={12}
   className='Collaborator_coursesinfo_detailpage'>
 <Box className='courses_info_Box1'>
 <MenuBookOutlinedIcon className='College_info_Avatar' />
  <typography className='College_heading'>
  Courses
  </typography>
 </Box>
 {Array.isArray(formData.coursesData) ? (
  formData.coursesData.map((course, index) => (
    <>
    <Box key={index} className='Courses_info_Box2'>
      <Box className='Courses_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Course Title</typography>
        <typography className='College_name_secondary'>{course.title}</typography>
      </Box>
      <Box className='Courses_info_Box2_secondary1'>
        <typography className='College_heading_secondary'>Grades earned in this course</typography>
        <typography className='College_name_secondary'>{course.grades}</typography>
      </Box>
      <Box className='Courses_info_Box2_secondary1'>
      
      </Box>
    </Box>
     {index < formData.coursesData.length - 1 && <Divider variant="middle"   sx={{
    my: 5, // This sets margin top and bottom to 5 units
    marginLeft: 0, // Sets left margin to 0
    marginRight: 0, // Sets right margin to 0
  }} />}
     </>
  ))
) : (
  <p>No course data available.</p>
)}
  </Grid>
 
   </Grid>
      </div>
  )
}

export default Highschool_detailpage