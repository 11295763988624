import { ThemeProvider } from '@material-ui/styles'
import { Box, Button, CircularProgress, CssBaseline, Grid, Paper, TextField } from '@mui/material'
import React,{useEffect,useState} from 'react'
import { Link } from "react-router-dom";

import Logo1 from '../../assets/img/logo.png'
import { useLocation } from "react-router-dom";
import Line from "../../assets/img/Vector_line.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefinal.json'; 
const Forgot_password = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [firstText, setFirstText] = useState("");
  const [secondtext, setSecondText] = useState("");
  const [bottomText, setBottomText] = useState("");
  const type = params.get("type");
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [forgetError, setforgetError] = React.useState(null);
  const [forgetSuccess, setforgetSuccess] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [formData, setFormData] = React.useState({
    email: "",
    type: ""
  });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    //const data = new FormData(event.currentTarget);
    formData.type = type;
    
    setEmailError(null);
    setforgetError(null);
    setforgetSuccess(null);
    try {
      setIsLoading(true);
      // Make a POST request to the API endpoint
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/forget-password`, {
        method: "post",
        body: JSON.stringify(formData),
      });

      // Check the response status and handle it accordingly
      if (response.status === 404) {
        // Registration successful, you can handle the response data here
        const data = await response.json();
        
        setforgetError(data.error);
        setIsLoading(false);
        //history.push(`/signupprofile?type=${type}`);
        // You can redirect the user to a success page or perform other actions 
      } else if (response.status === 401) {
        // Handle other response statuses or errors
        const data = await response.json();

        setforgetError(data.error);
        setIsLoading(false);
      } else if (response.status === 201) { 
        const data = await response.json();
       
        setOpen(true);
        setforgetSuccess(data.message);
        setIsLoading(false);
        
      } else if (response.status === 400) {
        // Handle other response statuses or errors
        const data = await response.json();
        setIsLoading(false);
        if (data.error) {
          if (data.error.email) {
            setEmailError(data.error.email[0]);
          }
         
        }
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Registration failed:", error);
    }
  };


  useEffect(() => {
    // Check if the type is 'collaborator' or 'student' and set the image source and login text accordingly
    if (type === "collaborator") {
      setFirstText("Earn money by");
      setSecondText("helping students");
      setBottomText("Collaborator");
    } else if (type === "student") {
      setFirstText("Make an");
      setSecondText("Educated decision");
      setBottomText("Student");
    } else if (type === "null") {
      navigate("/welcome");
    } else if (type === "") {
      navigate("/welcome");
    }
  }, [type]);
  return (
   <ThemeProvider>
    <Grid container
    component="main"
    className="main_container_login"
    lg={12}
    xl={12}
    xs={12}
    sm={12}
    md={12}
    >
      <CssBaseline />
      <Grid item
       lg={4}
       xl={4}
       xs={false}
       sm={4}
       md={5.5}
       className="Login_Grid"
      >
        <Box></Box>
        <Box className="Login_text_area">
            <div>
              <img src={Logo1} alt="Logo1" className="Login_logo_img" />
              <div className="login_text_div">
                <typography className="Login_side_text">{firstText}</typography>
              </div>
              <div>
                <typography className="Login_side_text">
                  {secondtext}
                </typography>
              </div>
            </div>
          </Box>
          <Box className="Login_Bottom_box">
            <div className="Login_Bottom_div">
              <img src={Line} alt="Line" className="Login_Bottom_img" />
              <typography className="Login_Bottom_text">
                {bottomText}
              </typography>
            </div>
          </Box>
      </Grid>
      <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent  className="Dialog_content">
              <Lottie options={defaultOptions} height={200} width={200} />
                <typography className="Success">Success!</typography>
                <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
                  {forgetSuccess && (
                    <div className="success-message">{forgetSuccess}</div>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{display:"flex",justifyContent:"center",paddingBottom:"35px",paddingTop:"30px"}}>
                <Link to={`/welcome`} style={{ textDecoration: "none" }}>
                  {" "}
                  <Button  variant ="outlined"onClick={handleClose}>Back</Button>
                </Link>
              </DialogActions>
            </Dialog>
      <Grid
          item
          lg={8}
          xl={8}
          xs={12}
          sm={8}
          md={6.5}
          component={Paper}
          elevation={6}
          square
          className="Login_Textfield_grid"
        >
          <Box className="Login_Textfiled_Box">
            
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              className="Login_Form_Textfield"
            >
              <div className="Login_header_text">
                <typography className="Login_Welcome">Forgot your password</typography>
                <typography className="Login_Welcome_text">
                Please enter your valid email address that was used for registration
                </typography>
              </div>
              {/* <div className='Login_Google'>
                <img src={Google} alt="Google Image" />
                <typography className="Login_google">Login with Google</typography>
              </div>
              <div className='Login_divider'>
                <div className="custom-divider" />
                <typography className="Login_ortext">or</typography>
                <div className="custom-divider" />
              </div> */}
               {forgetError && <div className="error-message">{forgetError}</div>}
              
                 

              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={Boolean(emailError)}
                helperText={emailError}
                onChange={handleChange}
              />
              {/* <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={Boolean(passwordError)}
                helperText={passwordError}
                onChange={handleChange}
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="Login_button"
                style={{ boxShadow: 'none' }}
                disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    // Display the "SUBMIT" text
                    "request reset link"
                  )}
              </Button>
            
              <Grid container className="Login_Textfield_Last_grid">
                <Grid item>
                  <p className="Welcome__already">
                    Back to{" "}
                    <Link
                        to={`/login?type=${type}`}
                        style={{ textDecoration: "none" }}
                      >
                      <typography className="Signup_text">Login</typography>
                      
                    </Link>{" "}
                  </p>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      
    </Grid>
   </ThemeProvider>
  )
}

export default Forgot_password
