import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';

import backgroundimage from '../../../assets/img/bg-img.png'
import { Box, Typography, Tabs, Tab,InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation,useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import format from 'date-fns/format';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottiefinal.json'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


import './Student_profile.css'
import Topbar from '../Topbar';
import Tobar from '../../collaborator/Tobar';
import { Topbar as Topbar2 } from '../../Admin/Topbar';

const Student_high_school = () => {
  const checkuser = JSON.parse(localStorage.getItem('userData'));
  const urlParams = new URLSearchParams(window.location.search);
  const urlType = urlParams.get('type');
  const userid = urlParams.get('userid');
  function encodeAlphanumeric(userid) {
    const encoded = btoa(userid); // Using btoa to Base64 encode the ID
    // You can further process the encoded string if needed
    return encoded;   
  } 
  console.log('urlType:', urlType);
    if (!checkuser) {
    // Redirect to the welcome page
    window.location.href = '/welcome';
    }
  const isSmallScreen = useMediaQuery('(max-width:680px)');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The Lottie JSON data
  };
    const location = useLocation();

    const navigate = useNavigate();
    const breadcrumbs = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href={urlType === 'student' ? '/student':urlType === 'admin'?`/Admin_student_profileView?id=${encodeAlphanumeric(userid)}` : urlType === 'collaborator'?'/profileview' : '/student_complete_profile'}
      >
        Profile
      </Link>,
   
      <Typography key="3" color="text.primary">
       High school education
      </Typography>
    ];
      const [value, setValue] = React.useState(0);
      const [courses, setCourses] = useState([{  }]);
      const [Errorcourses, setErrorcourses] = useState('');
      const [HighschoolName, SetHighschoolName ] = useState([]);
      const [ErrorHighschoolName,SetHighschoolNameError] = useState('');
      const [HighSchoolLocation, SetHighSchoolLocation] = useState([]);
      const [ErrorHighSchoolLocation, SetHighSchoolLocationError] = useState('');
      const [GpaScore, SetGpaScore] = useState([]);
      const [ErrorGpaScore, SetGpaScoreError] = useState('');
      const [GpaOutof, SetGpaoutof] = useState([]);
      const [ErrorGpaOutof,setGpaOutofError] = useState('');
      const [GpaType, SetGpaType] = useState([]);
      const [ErrorGpaType, SetGpaTypeError] = useState('');
      const [GradesEarninCourse, SetGradesEarninCourse] = useState([]);
      const [GradesEarninCourseError, SetGradesEarninCourseError] = useState([]);
      const [start_date, setStartDate] = useState('');
      const [start_dateError, setstart_date_Error] = useState('');
      const [end_date, setEndDate] = useState('');
      const [end_dateError, setend_dateError] = useState('');
      const [rankinclass, setrankinclass] = useState('');
      const [Errorrankinclass,setErrorrankinclass] = useState('');
      const [formid, setformid] = useState('');
      const [open, setOpen] = React.useState(false);
      const [highschoolFormData, setHighschoolFormData] = useState([]);
  
      // const [showAlert, setShowAlert] = useState(false);
      const [shouldNavigate, setShouldNavigate] = useState(false);
      const [isLoading, setIsLoading] = React.useState(false);
      const [formData, setFormData] = useState({
        hight_school_name: '',
        high_school_location: '',
        start_date: '',
        end_date: '',
        gpa_score: '',
        gpa_out_of: '',
        gpa_type: '',
        rank_in_class: '',
        coursesData: '',
      degree_high:'',
        userId:'',
      formid:'',
  
      });
      useEffect(() => {
    
       
        const searchParams = new URLSearchParams(location.search);
        const selectedHighschoolId = searchParams.get('id'); //change this const to form id 
        setformid(selectedHighschoolId);
        const apiUrl = process.env.REACT_APP_API_URL;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.id;
        console.log('Highschool ID:', selectedHighschoolId); 
  
        if(selectedHighschoolId !== ''){
          try {
          fetch(`${apiUrl}/get_student_high_school_form_data`, {   // chgange this url 
            method: 'post',
            body: JSON.stringify({ formid:selectedHighschoolId }), // pass the form id const here 
        })
        .then(response => {
            if (response.status === 200) {
                return response.json(); // Convert the response to JSON
            } else {
                throw new Error('Request failed');
            }
        })
        .then(data => {
          const highschool = data.formdata[0];
          const coursesData = JSON.parse(highschool.coursesData || '[]');
          // console.log('Course Titles:', coursesData.map(course => course.course_tittle0));
          // console.log('Start Date:', dayjs(highschool.start_date).format('MM/DD/YYYY'));
          // console.log('End Date:', dayjs(highschool.end_date).format('MM/DD/YYYY'));
  
          // const formateddate = format(new Date(highschool.end_date),'MM/dd/yyyy');
          // console.log(formateddate,"enddate")
          setCourses(coursesData);
          setHighschoolFormData(data.formdata);                     
            console.log(data, "highschooldata"); 
            setStartDate(dayjs(highschool.start_date));
            setEndDate(dayjs(highschool.end_date)); 
            setFormData({
              hight_school_name: highschool.hight_school_name || '',
              high_school_location: highschool.high_school_location || '',
              start_date: highschool.start_date || '',
              end_date:  highschool.end_date || '',
              gpa_score: highschool.gpa_score || '',
              gpa_out_of: highschool.gpa_out_of || '',
              gpa_type: highschool.gpa_type || '',
              rank_in_class: highschool.rank_in_class|| '',
            degree_high:highschool.degree_high||'',

              // coursesData: highschool.coursesData || '',
              userId: highschool.userId || '',
            });
         
        })
        .catch(error => {
            console.error('Data fetch failed:', error);
        });
    } catch (error) {
        console.error('Data upload failed:', error);
    }
        }
  
        
    }, []);    
      // const handleChange = (event, newValue) => {
      //   setValue(newValue);
      // };
      const [age, setAge] = React.useState('');
  
      const handleChanges = (event) => {
        const { name, value } = event.target;
        if (name === 'high_school_location') {
          SetHighSchoolLocation(value);
          SetHighSchoolLocationError('');
          // Clear the validation error if the user starts typing
          
        } else if (name === 'gpa_score') {
          SetGpaScore(value);
          SetGpaScoreError('');
          // Clear the validation error if the user starts typing
          
        } else if (name === 'gpa_out_of') {
          SetGpaoutof(value);
          setGpaOutofError('');
          // Clear the validation error if the user starts typing
          
        } else if (name === 'gpa_type') {
          SetGpaType(value);
          SetGpaTypeError('');
          // Clear the validation error if the user starts typing
          
        } else if (name === 'grades_earned_in_course') {
          SetGradesEarninCourse(value);
          SetGradesEarninCourseError('');
          // Clear the validation error if the user starts typing
          
        }
        
  
        
        setAge(event.target.value);
      };
  
      
       // Initialize navigate
      const moveToNextTab = async() => {
        if (urlType !== 'student' && urlType !== 'collaborator'&& urlType !== 'admin' ) {
        if(value === 0){
          const hight_school_name = document.getElementsByName('hight_school_name')[0].value;
          const high_school_location = document.getElementsByName('high_school_location')[0].value;
          if (end_date && start_date && end_date <= start_date) {
            setend_dateError('End date should be greater than start  date.');
            return false;
          }
          if (hight_school_name.trim() === '' && high_school_location.trim() === '' && start_date === '' && end_date === '') {
            SetHighschoolNameError('High School Name is required.');
            SetHighSchoolLocationError('High School location is required.');
            setend_dateError("End date required");
            setstart_date_Error("Start date required");
            return false;
          }else{
            
            // alert(start_date);
            // alert(end_date);
            formData.hight_school_name = hight_school_name;
            formData.high_school_location = high_school_location;
            formData.start_date = start_date;
            formData.end_date = end_date;
          }
          if (hight_school_name.trim() === '' ) {
            SetHighschoolNameError('High School Name is required.');
            return false;
          }else{
            SetHighschoolName(hight_school_name);
            SetHighschoolNameError('');
          }
          if (high_school_location.trim() === '' ) {
            SetHighSchoolLocationError('High School location is required.');
            return false;
          }else{
            SetHighSchoolLocation(high_school_location);
            SetHighSchoolLocationError('');
          }
  
          if (start_date === '' ) {
            setend_dateError("End date required");
            return false;
          }else{
            setend_dateError("");
          }
          if (end_date === '' ) {
            setend_dateError("End date required");
            return false;
          }else{
            setend_dateError("");
          }
         
  
        }
        if(value === 1){
          const gpa_score = document.getElementsByName('gpa_score')[0].value;
          const gpa_out_of = document.getElementsByName('gpa_out_of')[0].value;
          const gpa_type = document.getElementsByName('gpa_type')[0].value;
          const rank_in_class = document.getElementsByName('rank_in_class')[0].value;
          // const roundedGpaScore = parseFloat(gpa_score);
          // const roundedGpaOutOf = parseFloat(gpa_out_of);

          // if (roundedGpaScore > roundedGpaOutOf) {
          //   SetGpaScoreError('GPA field must be less then out of field');
          //   return false;
          // }
          if (gpa_score.trim() === '' && gpa_out_of.trim() === '' && gpa_type === '' ) {
            SetGpaScoreError('GPA Required');
            setGpaOutofError('GPA Scale Required');
            SetGpaTypeError('GPA Type Required');
            // setErrorrankinclass("Rank in class required");
            return false;
          }else{
           
            formData.gpa_score = gpa_score;
            formData.gpa_out_of = gpa_out_of;
            formData.gpa_type = gpa_type;
            formData.rank_in_class = rank_in_class;
          }
          if (gpa_score.trim() === '' ) {
            SetGpaScoreError('GPA Required');
            
            return false;
          }else{
            SetGpaScoreError('');
          }
          if ( gpa_out_of.trim() === '' ) {
           
            setGpaOutofError('GPA Scale Required');
            
            return false;
          }else{
            setGpaOutofError('');
          }
          if ( gpa_type === '' ) {
           
            SetGpaTypeError('GPA Type Required');
  
            return false;
          }else{
            SetGpaTypeError('');
          }
          // if ( rank_in_class === '') {
           
          //   setErrorrankinclass("Rank in class required");
          //   return false;
          // }else{
          //   setErrorrankinclass("");
          // }
        }
        if (value === 2) {
          const coursesData = courses;
          const isDuplicate = coursesData.some((current, index) =>
          coursesData.slice(0, index).some((other) => {
            const currentKeys = Object.keys(current).sort();
            const otherKeys = Object.keys(other).sort();

            return (
              currentKeys.length === otherKeys.length &&
              currentKeys.every((key, i) => current[key] === other[otherKeys[i]]) &&
              current.course_title === other.course_title &&
              current.grades_earned_in_course === other.grades_earned_in_course
            );
          })
        );
        
          if (isDuplicate) {
            // Scholarships contain duplicates
            setErrorcourses('Duplicate data. Please enter unique values for each courses.');
            return false;
          }
          const hasEmptyField = coursesData.some((course, index) => {
            return !course[`course_tittle${index}`] || !course[`grades_earned_in_course${index}`]  ;
          });
        
          if (hasEmptyField) {
            setErrorcourses('All fields are mandatory');
            return false;
          }
          try {
            setIsLoading(true);
            setOpen(true);
            formData.coursesData = coursesData;
            // Make a POST request to the API endpoint
            const userData = JSON.parse(localStorage.getItem('userData'));
            const userId = userData.id;
            
         
            formData.userId = userId;
            const apiUrl = process.env.REACT_APP_API_URL;
            if(formid!== ''){
              // alert(formid);
              formData.formid = formid; 
            }
            const response = await fetch(`${apiUrl}/save_students_highschool`, {
              method: 'post', 
              body: JSON.stringify(formData),
            }); 
            // setShowAlert(true);
            // Check the response status and handle it accordingly
            if (response.status === 201) {
            
              //setShowAlert(false);
              setShouldNavigate(true);
              //navigate('/complete_profile');     
            }
            if (response.status === 500) {
              //setShowAlert(false);
              
              //navigate('/complete_profile');     
            }
              // Registration successful, you can handle the response data here
             
          } catch (error) {
            setIsLoading(false);
            setOpen(false);
            // Handle network errors or other exceptions
            console.error('Registration failed:', error);
           
          }
          
          // If on the last tab, navigate to "/complete_profile" route
         // navigate('/complete_profile');
        } else {
          // Increment the value state to move to the next tab
          setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
        }
      }
      else if (value === 2 && urlType === 'student'){
        // alert('hello')
        window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}&type=student`;
      }
      else if (value === 2 && urlType === 'collaborator'){
        // alert('hello')
        window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}&type=collaborator`;
      }
      else if (value === 2 && urlType === 'admin'){
        // alert('hello')
        window.location.href = `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}&type=student`;

      }
      else {
        // Increment the value state to move to the next tab
        setValue((prevValue) => (prevValue === 5 ? 0 : prevValue + 1));
      }
      };
      const handleBackButton = (userid) => {
        if (value === 0) {
          if (urlType === 'student') {
            if (userid) {
              window.location.href = `/Collaborator_Profile?id=${encodeAlphanumeric(userid)}&type=student`;
            } else {
              navigate('/Student');
            }
          } else if (urlType === 'collaborator') {
            if (userid) {
              window.location.href = `/studentProfile?id=${encodeAlphanumeric(userid)}&type=collaborator`;
            } else {
              navigate('/profileview');
            }
          }
          else if (urlType === 'admin') {
            if (userid) {
              window.location.href = `/Admin_student_profileView?id=${encodeAlphanumeric(userid)}&type=student`;

            } else {
              navigate('/admin-dashboard');
            }
          } else {
            navigate('/student_complete_profile');
          }
        } else {
          // Decrement the value state to move to the previous tab
          setValue((prevValue) => (prevValue === 0 ? 5 : prevValue - 1));
        }
      };
      const addCourse = () => {
    
        const lastRecommender = courses[courses.length - 1];
      
        // Check if the last recommender is not empty
        const isLastRecommenderEmpty = Object.values(lastRecommender).every(
          (value) => value === undefined || value === ''
        );
      
        if (!isLastRecommenderEmpty) {
          // Check for duplicates
          const isDuplicateRec = courses.slice(0, courses.length - 1).some((existingRecommender) => 
            Object.entries(existingRecommender).every(
              ([key, val]) => lastRecommender[key] === val
            )
          );
      
          if (!isDuplicateRec) {
            // Add a new empty recommender
            setCourses([...courses, { }]);
            setErrorcourses('');
          } else {
            setErrorcourses('Duplicate data. Please enter unique values for each courses.');
          }
        } else {
          setErrorcourses('Please fill in all fields before adding another courses.');
        }
      };
      const deleteCourse = (indexToDelete) => {
        const updatedCourses = courses.filter((_, index) => index !== indexToDelete);
        setCourses(updatedCourses);
      };
      // const addCourse = () => {
      //   setCourses([...courses, { course_title: '' }]);
      // };
      const handleChanges2 = (event, index) => {
        const { name, value } = event.target;
       
        if (name.includes("course_tittle")) {
          
          // Validation: Allow only alphabets, spaces, and limit to 75 characters
          const sanitizedValue = value.replace(/[<>\\]/g, '').slice(0, 50);
          if (sanitizedValue.length === value.length) {
            // No special characters detected
            setErrorcourses(''); // Clear the error if within the limit
            const updatedCourses = [...courses];
            updatedCourses[index][name] = sanitizedValue;
            setCourses(updatedCourses);
          } else {
            // Special characters detected
            setErrorcourses('Invalid input. Please avoid special characters limit upto 50 characters.');
          }
      
          
        } else {
          // For other fields, update the state directly without validation
          const updatedCourses = [...courses];
          updatedCourses[index][name] = value;
          console.log(updatedCourses);
          setCourses(updatedCourses);
        }
  
  
        // const updatedCourses = [...courses];
        // updatedCourses[index][name] = value;
        // console.log(updatedCourses);
        // setCourses(updatedCourses);
      };
  
      useEffect(() => {
        if (shouldNavigate) {
          const navigateTimer = setTimeout(() => {
            // Navigate to the desired page after 2 seconds
            // setShowAlert(false);
            setIsLoading(true);
            setOpen(true);
            navigate('/student_complete_profile');
          }, 3000);
    
          // Clean up the timer to avoid memory leaks
          return () => clearTimeout(navigateTimer);
        }
      }, [shouldNavigate]);
  
      const cities = [
        "Aberdeen",
        "Abilene",
        "Aguada",
        "Aguas",
        "Aibonito",
        "Akron",
        "Albany",
        "Albuquerque",
        "Alexandria",
        "Allentown",
        "Alta",
        "Alto",
        "Amarillo",
        "Anaheim",
        "Anchorage",
        "Ann Arbor",
        "Antioch",
        "Apple Valley",
        "Appleton",
        "Arlington",
        "Arroyo",
        "Arvada",
        "Asheville",
        "Athens",
        "Atlanta",
        "Atlantic City",
        "Augusta",
        "Aurora",
        "Austin",
        "Baja",
        "Bakersfield",
        "Baltimore",
        "Barceloneta",
        "Barnstable",
        "Baton Rouge",
        "Bayamón",
        "Beaumont",
        "Bel Air",
        "Bellevue",
        "Berkeley",
        "Bethlehem",
        "Billings",
        "Birmingham",
        "Bloomington",
        "Boise",
        "Boise City",
        "Bonita Springs",
        "Boston",
        "Boulder",
        "Bradenton",
        "Bremerton",
        "Bridgeport",
        "Brighton",
        "Brownsville",
        "Bryan",
        "Buffalo",
        "Burbank",
        "Burlington",
        "Cambridge",
        "Camuy",
        "Canton",
        "Cape Coral",
        "Carolina",
        "Carrollton",
        "Cary",
        "Cathedral City",
        "Cayey",
        "Cedar Rapids",
        "Champaign",
        "Chandler",
        "Charleston",
        "Charlotte",
        "Chattanooga",
        "Chesapeake",
        "Chicago",
        "Chula Vista",
        "Ciales",
        "Cincinnati",
        "Clarke County",
        "Clarksville",
        "Clearwater",
        "Cleveland",
        "Coamo",
        "College Station",
        "Colorado Springs",
        "Columbia",
        "Columbus",
        "Concord",
        "Coral Springs",
        "Corona",
        "Corozal",
        "Corpus Christi",
        "Costa Mesa",
        "Dallas",
        "Daly City",
        "Danbury",
        "Davenport",
        "Davidson County",
        "Dayton",
        "Daytona Beach",
        "Deltona",
        "Denton",
        "Denver",
        "Des Moines",
        "Detroit",
        "Dorado",
        "Downey",
        "Duluth",
        "Durham",
        "Díaz",
        "El Monte",
        "El Paso",
        "Elizabeth",
        "Elk Grove",
        "Elkhart",
        "Erie",
        "Escondido",
        "Eugene",
        "Evansville",
        "Fairfield",
        "Fargo",
        "Fayetteville",
        "Fitchburg",
        "Flint",
        "Florida",
        "Fontana",
        "Fort Collins",
        "Fort Lauderdale",
        "Fort Smith",
        "Fort Walton Beach",
        "Fort Wayne",
        "Fort Worth",
        "Frederick",
        "Fremont",
        "Fresno",
        "Fullerton",
        "Gainesville",
        "Garden Grove",
        "Garland",
        "Gastonia",
        "Germán",
        "Gilbert",
        "Glendale",
        "Grand Prairie",
        "Grand Rapids",
        "Grayslake",
        "Green Bay",
        "GreenBay",
        "Greensboro",
        "Greenville",
        "Guayanilla",
        "Gulfport-Biloxi",
        "Gurabo",
        "Hagerstown",
        "Hampton",
        "Harlingen",
        "Harrisburg",
        "Hartford",
        "Hatillo",
        "Havre de Grace",
        "Hayward",
        "Hemet",
        "Henderson",
        "Hesperia",
        "Hialeah",
        "Hickory",
        "High Point",
        "Hollywood",
        "Honolulu",
        "Houma",
        "Houston",
        "Howell",
        "Humacao",
        "Huntington",
        "Huntington Beach",
        "Huntsville",
        "Independence",
        "Indianapolis",
        "Inglewood",
        "Irvine",
        "Irving",
        "Isabel",
        "Jackson",
        "Jacksonville",
        "Jayuya",
        "Jefferson",
        "Jersey City",
        "Johnson City",
        "Joliet",
        "Juan",
        "Kailua",
        "Kalamazoo",
        "Kaneohe",
        "Kansas City",
        "Kennewick",
        "Kenosha",
        "Killeen",
        "Kissimmee",
        "Knoxville",
        "Lacey",
        "Lafayette",
        "Lajas",
        "Lake Charles",
        "Lakeland",
        "Lakewood",
        "Lancaster",
        "Lansing",
        "Laredo",
        "Las",
        "Las",
        "Las Cruces",
        "Las Vegas",
        "Layton",
        "Leominster",
        "Lewisville",
        "Lexington",
        "Lincoln",
        "Little Rock",
        "Loiza",
        "Long Beach",
        "Lorain",
        "Lorenzo",
        "Los Angeles",
        "Louisville",
        "Lowell",
        "Lubbock",
        "Macon",
        "Madison",
        "Manatí",
        "Manchester",
        "Marina",
        "Marysville",
        "Maunabo",
        "McAllen",
        "McHenry",
        "Medford",
        "Melbourne",
        "Memphis",
        "Merced",
        "Mesa",
        "Mesquite",
        "Miami",
        "Milwaukee",
        "Minneapolis",
        "Miramar",
        "Mission Viejo",
        "Mobile",
        "Moca",
        "Modesto",
        "Monroe",
        "Monterey",
        "Montgomery",
        "Moreno Valley",
        "Murfreesboro",
        "Murrieta",
        "Muskegon",
        "Myrtle Beach",
        "Naguabo",
        "Naperville",
        "Naples",
        "Nashua",
        "Nashville",
        "New Bedford",
        "New Haven",
        "New London",
        "New Orleans",
        "New York",
        "New York City",
        "Newark",
        "Newburgh",
        "Newport News",
        "Norfolk",
        "Normal",
        "Norman",
        "North Charleston",
        "North Las Vegas",
        "North Port",
        "Norwalk",
        "Norwich",
        "Oakland",
        "Ocala",
        "Oceanside",
        "Odessa",
        "Ogden",
        "Oklahoma City",
        "Olathe",
        "Olympia",
        "Omaha",
        "Ontario",
        "Orange",
        "Orem",
        "Orlando",
        "Orocovis",
        "Overland Park",
        "Oxnard",
        "Palm Bay",
        "Palm Springs",
        "Palmdale",
        "Panama City",
        "Pasadena",
        "Paterson",
        "Pembroke Pines",
        "Pensacola",
        "Peoria",
        "Peñuelas",
        "Philadelphia",
        "Phoenix",
        "Pittsburgh",
        "Plano",
        "Pomona",
        "Pompano Beach",
        "Port Arthur",
        "Port Orange",
        "Port Saint Lucie",
        "Port St. Lucie",
        "Portland",
        "Portsmouth",
        "Poughkeepsie",
        "Providence",
        "Provo",
        "Pueblo",
        "Punta Gorda",
        "Quebradillas",
        "Racine",
        "Raleigh",
        "Rancho Cucamonga",
        "Reading",
        "Redding",
        "Reno",
        "Richland",
        "Richmond",
        "Richmond County",
        "Rio",
        "Riverside",
        "Roanoke",
        "Rochester",
        "Rockford",
        "Rojo",
        "Roseville",
        "Round Lake Beach",
        "Sabana",
        "Sacramento",
        "Saginaw",
        "Saint Louis",
        "Saint Paul",
        "Saint Petersburg",
        "Salem",
        "Salinas",
        "Salinas",
        "Salt Lake City",
        "San Antonio",
        "San Bernardino",
        "San Buenaventura",
        "San Diego",
        "San Francisco",
        "San Jose",
        "Santa Ana",
        "Santa Barbara",
        "Santa Clara",
        "Santa Clarita",
        "Santa Cruz",
        "Santa Maria",
        "Santa Rosa",
        "Sarasota",
        "Savannah",
        "Scottsdale",
        "Scranton",
        "Seaside",
        "Seattle",
        "Sebastian",
        "Sebastián",
        "Shreveport",
        "Simi Valley",
        "Sioux City",
        "Sioux Falls",
        "South Bend",
        "South Lyon",
        "Spartanburg",
        "Spokane",
        "Springdale",
        "Springfield",
        "St. Louis",
        "St. Paul",
        "St. Petersburg",
        "Stamford",
        "Sterling Heights",
        "Stockton",
        "Sunnyvale",
        "Syracuse",
        "Tacoma",
        "Tallahassee",
        "Tampa",
        "Temecula",
        "Tempe",
        "Thornton",
        "Thousand Oaks",
        "Toledo",
        "Topeka",
        "Torrance",
        "Trenton",
        "Tucson",
        "Tulsa",
        "Tuscaloosa",
        "Tyler",
        "Utica",
        "Vallejo",
        "Vancouver",
        "Vega",
        "Vega",
        "Vero Beach",
        "Victorville",
        "Vieques",
        "Virginia Beach",
        "Visalia",
        "Waco",
        "Warren",
        "Washington",
        "Waterbury",
        "Waterloo",
        "West Covina",
        "West Valley City",
        "Westminster",
        "Wichita",
        "Wilmington",
        "Winston",
        "Winter Haven",
        "Worcester",
        "Yabucoa",
        "Yakima",
        "Yauco",
        "Yonkers",
        "York",
        "Youngstown"];
        const states = [
          "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
        "Puerto Rico",
        "Guam",
        "U.S. Virgin Islands",
        "American Samoa",
        "Northern Mariana Islands",
        "International"
        ];
        const handleGpaScoreChange = (e) => {
          const inputValue = e.target.value;
      
          // Regular expression to match numbers with up to 4 digits
          const regex = /^\d{0,3}(\.\d{0,2})?$/;
      
          if (regex.test(inputValue) || inputValue === '') {
            setFormData({ ...formData, gpa_score: inputValue });
          }
        };
        const  handleNameChange = (value) => {
          // Validation: Allow only alphabets, spaces, and limit to 150 characters
          const sanitizedValue = value.replace(/[<>\\]/g, '').slice(0, 75);
        
          // Check if the sanitized value is numeric
          const isNumeric = /^\d+$/.test(sanitizedValue);
        
          if (!isNumeric) {
            // Update the state with the sanitized value
            setFormData({ ...formData, hight_school_name: sanitizedValue });
            SetHighschoolNameError(''); // Clear the error if it was previously set
          } else {
            SetHighschoolNameError('Invalid input. Please avoid special characters, numeric values, and limit to 75 characters.');
          }
          if (sanitizedValue.length === 75) {
            SetHighschoolNameError('Input exceeds the limit of 75 characters.');
          }
        };
      
        const handleRankInClassChange = (e) => {
          const inputValue = e.target.value;
        
          // Regular expression to match a 2-digit number
          const regex = /^\d{0,4}$/;
        
          if (regex.test(inputValue) || inputValue === '') {
            setFormData({ ...formData, rank_in_class: inputValue });
            setErrorrankinclass(''); // Clear the error if it was previously set
          } else {
            setErrorrankinclass('Invalid input. Please enter a 4-digit number.');
          }
        };
    return (
      <div>
      {/* <Topbar /> */}
      {/* {urlType === 'student' ? <Topbar /> : (urlType === 'collaborator' ? <Tobar /> : <Topbar />)} */}
      {urlType === 'student' ? <Topbar /> : (urlType === 'admin' ? <Topbar2 /> : <Tobar />)}


      <Grid
        container
        lg={12}
        xl={12}
        xs={12}
        sm={12}
        md={12}
        sx={{
          padding: "20px",
          minHeight: "92vh",
          backgroundImage: `url(${backgroundimage})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <Grid
          item
          lg={12}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          sx={{ background: "rgba(255, 255, 255, 0.80)",
          backdropfilter: "blur(25px)", padding: "20px" }}
        >
                <Dialog
          open={open}
          // TransitionComponent={Transition}
          keepMounted
       
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent  className="Dialog_content">
          <Lottie options={defaultOptions} height={200} width={200} />
            {/* <typography className="Success">Success!</typography>
            <DialogContentText id="alert-dialog-slide-description" sx={{width:"60%",}}>
              {registrationSuccess && (
                <div className="success-message">{registrationSuccess}</div>
              )}
            </DialogContentText> */}
              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <typography className="Success">Success</typography>
            {/* <typography className='Submitted_text22'>Dear Collaborator,your profile has been  submitted and <span className='Bold_text'>pending verification.</span></typography> */}
            <typography className='Submitted_text2'>Your data has been successfully saved!</typography>
         
        
          </div>
          {/* <DialogActions sx={{display:"flex",justifyContent:"center"}}> */}
           
          
       {/* </DialogActions> */}
          </DialogContent>
          
        </Dialog>
         <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
          
          <Box className="Test_BoxDiv Test_paddingBox2">
          <Box className="Student_highschool_form" sx={{ width: "50%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value}  aria-label="basic tabs example"
              sx={{
                  '& .MuiTabs-flexContainer': {
                    justifyContent: 'space-between', // This adds space between the tabs
                  },
                  // ...(isSmallScreen && {
                  //   '& .css-heg063-MuiTabs-flexContainer': {
                  //     overflow: 'scroll',
                  //     // Add other styles you want to apply to .css-heg063-MuiTabs-flexContainer
                  //   },
                  // }),
                }}>
                <Tab label="SCHOOL INFORMATION" />
                <Tab label="GPA" />
                <Tab label="CURRENT COURSES" />
          
              </Tabs>
            </Box>
            <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 0}>
            <Box className='Test_paddingBox' >
    <div className='Test_FlexRow_highschool' >
      <TextField disabled={urlType === 'student'|| urlType === 'collaborator'|| urlType === 'admin' } id="outlined-basic" label="High School Name *" name="hight_school_name" error={Boolean(ErrorHighschoolName)} helperText={ErrorHighschoolName} variant="outlined" fullWidth size="small" sx={{mb:2}}    value={formData.hight_school_name} onChange={(e) =>handleNameChange(e.target.value)}/>
      <FormControl fullWidth size="small" sx={{mb:2}} error={Boolean(ErrorHighSchoolLocation)}
      helperText={ErrorHighSchoolLocation}>
          <InputLabel id="demo-simple-select-label">High School Location *</InputLabel>
          <Select
          disabled={urlType === 'student'|| urlType === 'collaborator'|| urlType === 'admin' }
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="high_school_location"
            value={formData.high_school_location}
            label="high school location *"
            onChange={(e) => setFormData({ ...formData, high_school_location: e.target.value })}
            error={Boolean(ErrorHighSchoolLocation)}
          >
            {states.map((stateName, index) => (
            <MenuItem key={index} value={stateName}>
              {stateName}
            </MenuItem>
          ))}
          </Select>
          {ErrorHighSchoolLocation && (
    <FormHelperText sx={{color:"#d32f2f"}}>{ErrorHighSchoolLocation}</FormHelperText>
  )}
        </FormControl>
      
    </div >
    {/* <FormControl fullWidth size="small" sx={{mb:2}} >
        <InputLabel id="demo-simple-select-label">Degree Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="degree_high"
          value={formData.degree_high}
          label="Degree Type"
          onChange={(e) => setFormData({ ...formData, degree_high: e.target.value })}
        >
          <MenuItem value={'AP'}>AP</MenuItem>
          <MenuItem value={'BI'}>IB</MenuItem>
        </Select>
      </FormControl> */}
      {ErrorGpaOutof && (
    <FormHelperText>{ErrorGpaOutof}</FormHelperText>
  )}
    <div className='Test_FlexRow'>
    <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      id="start-date"
      name="start_date"
      label={<span className="centered-label">start date *</span>}
      size="small"
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': {
          top: '-6px', // Center align the label
          color: start_dateError ? '#d32f2f' : '',
        },
        '& .MuiInputBase-root': {
          height: '2.6rem',
        },
      }}
      value={formData.start_date ? dayjs(formData.start_date) : null}
      onChange={(date) => setStartDate(date)} // Update the state variable
      maxDate={dayjs()}
      disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
      
    />
     </LocalizationProvider>
     {start_dateError && (
    <FormHelperText sx={{color:"#d32f2f"}}>{start_dateError}</FormHelperText>
  )}
 
 </div>
 <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      id="end-date"
      name="end_date"
      label="end date *"
      size="small"
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': {
          top: '-6px', // Center align the label
          color: start_dateError ? '#d32f2f' : '',
        },
        '& .MuiInputBase-root': {
          height: '2.6rem',
        },
      }}
      value={formData.end_date ? dayjs(formData.end_date) : null}
      onChange={(date) => setEndDate(date)} // Update the state variable
      // maxDate={dayjs()}
      disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
    />
  </LocalizationProvider>
  {end_dateError && (
    <FormHelperText sx={{color:"#d32f2f"}}>{end_dateError}</FormHelperText>
  )}
    </div>
  </div>
  
  
    </Box>
   
  
            </div>
            <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 1}>
            <Box className='Test_paddingBox' >
    <div className='Test_FlexRow21'>
    {/* <FormControl fullWidth size="small" sx={{mb:2}} >
          <InputLabel id="demo-simple-select-label">GPA score</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="gpa_score"
            value={GpaScore}
            label="GPA score"
            onChange={handleChanges}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
          {ErrorGpaScore && (
      <FormHelperText>{ErrorGpaScore}</FormHelperText>
    )}
        </FormControl> */}
         <TextField disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' } id="outlined-basic" label="GPA *" name="gpa_score" variant="outlined" fullWidth size="small" sx={{mb:2}} onChange={handleGpaScoreChange} value={formData.gpa_score} error={Boolean(ErrorGpaScore)}   helperText={ErrorGpaScore}
         inputProps={{
          inputMode: 'numeric',
        }}/>
      
      <FormControl fullWidth size="small" sx={{mb:2}} error={Boolean(ErrorGpaOutof)}>
          <InputLabel id="demo-simple-select-label">GPA Scale *</InputLabel>
          <Select
          disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="gpa_out_of"
            value={formData.gpa_out_of}
            label="GPA Scale *"
            onChange={(e) => setFormData({ ...formData, gpa_out_of: e.target.value })}
            error={Boolean(ErrorGpaOutof)}
          helperText={ErrorGpaOutof}
          >
            <MenuItem value={4}>4.00</MenuItem>
            <MenuItem value={5}>5.00</MenuItem>
            <MenuItem value={6}>6.00</MenuItem>
          <MenuItem value={10}>10.00</MenuItem>
            <MenuItem value={100}>100.00</MenuItem>
           
          </Select>
          {ErrorGpaOutof && (
    <FormHelperText sx={{color:"#f44336"}}>{ErrorGpaOutof}</FormHelperText>
  )}
        </FormControl>
      
    </div >
    <div className='Test_FlexRow'>
    
    <FormControl fullWidth size="small"  error={Boolean(ErrorGpaType)}>
          <InputLabel id="demo-simple-select-label">GPA Type *</InputLabel>
          <Select
          disabled={urlType === 'student' || urlType === 'collaborator' || urlType === 'admin'}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="gpa_type"
            value={formData.gpa_type}
            label="GPA Type *"
            onChange={(e) => setFormData({ ...formData, gpa_type: e.target.value })}
            error={Boolean(ErrorGpaType)}
            helperText={ErrorGpaType}
          >
            <MenuItem value={'Weighted'}>Weighted</MenuItem>
            <MenuItem value={'Unweighted'}>Unweighted</MenuItem>
            {/* <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
          {ErrorGpaType && (
      <FormHelperText>{ErrorGpaType}</FormHelperText>
    )}
        </FormControl>
    
    
      <TextField disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' } id="outlined-basic" label="Rank in class" name="rank_in_class" error={Boolean(Errorrankinclass)} helperText={Errorrankinclass} variant="outlined" fullWidth size="small" value={formData.rank_in_class} onChange={handleRankInClassChange}/>
    
  </div>
  
  
    </Box>
   
            </div>
            <div className='Student_colleges_toppadding' role="tabpanel" hidden={value !== 2} >
            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center',marginBottom:"20px",marginTop:"20px"}}>
              
              <typography className='Note'>Note:</typography>
              <typography className='Note_secondary'> Please list all courses you are taking this academic year. If you are not currently enrolled,<br/>please list courses from your most recent academic year.</typography>
            </div>
            <Box className='Test_paddingBox2' sx={{gap:'20px',display:'flex',flexDirection:'column'}} >
    
          
  
  {courses.map((course,index) => (
    
          <div key={course.id} className='Test_FlexRow1'>
           
            <TextField disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' } id={`outlined-basic-${index}`} label="Course Title *" name={`course_tittle${index}`} variant="outlined" fullWidth size="small" onChange={(event) => handleChanges2(event, index)}     value={course[`course_tittle${index}`] || ''}  />
            <FormControl fullWidth size="small">
              <InputLabel id={`demo-simple-select-labe-${index}`}>Earned Grades *</InputLabel>
              <Select
              disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' }
                labelId={`demo-simple-select-label-${index}`}
                id={`grades_earned_in_course${index}`}
                name={`grades_earned_in_course${index}`}
                // value={formData.coursesData}
                label="Earned Grades *"
                value={course[`grades_earned_in_course${index}`] || course[formData.coursesData] || ''}
                onChange={(event) => handleChanges2(event, index)}
              >
               <MenuItem value={'A+'}>A+</MenuItem>
              <MenuItem value={'A'}>A</MenuItem>
              <MenuItem value={'A-'}>A-</MenuItem>
              <MenuItem value={'B+'}>B+</MenuItem>
              <MenuItem value={'B'}>B</MenuItem>
              <MenuItem value={'B-'}>B-</MenuItem>
              <MenuItem value={'C+'}>C+</MenuItem>
              <MenuItem value={'C'}>C</MenuItem>
              <MenuItem value={'C-'}>C-</MenuItem>
              <MenuItem value={'D+'}>D+</MenuItem>
              <MenuItem value={'D'}>D</MenuItem>
              <MenuItem value={'D-'}>D-</MenuItem>
              <MenuItem value={'F'}>F</MenuItem>
              </Select>
            </FormControl>
            {index !== 0 && (
          <DeleteOutlinedIcon  className='Mui_edit_icon' sx={{color:'#D32F2F',padding:0,display: urlType === 'student' || urlType === 'collaborator' || urlType === 'admin' ? 'none' : 'block',}}  onClick={() => deleteCourse(index)}/> )}
          </div>
        ))}
        <div className='Test_paddingDiv'>
          <Button variant="contained" disabled={urlType === 'student' || urlType === 'collaborator'|| urlType === 'admin' } sx={{boxShadow:" none"}} color="inherit" size="large" fullWidth onClick={addCourse}>
            + ADD ANOTHER COURSE
          </Button>
          {Errorcourses && (
    <FormHelperText sx={{color:"#d32f2f"}}>{Errorcourses}</FormHelperText>
  )}
        </div>
  
    </Box>
            </div>
            <div className='Test_buttonDiv'>
          <Button variant="contained" color="inherit" size="large" sx={{width:"180px",boxShadow:" none"}} onClick={() => handleBackButton(userid)} >Back</Button>
          <Button variant="contained" color="primary" size="large" sx={{width:"180px",boxShadow:" none"}} onClick={() => moveToNextTab(userid)}  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    // Display the circular loading icon
                    <CircularProgress size={24} color="inherit" />
                  )
                  : (
                    urlType === 'student' && value === 2 ||  urlType === 'collaborator'|| urlType === 'admin' && value === 2 ? "CLOSE" : (value === 3 ? "SAVE" : "NEXT")
                  )}</Button>
        </div>
           </Box>
          </Box>
          {/* {showAlert && (
          <Alert
            severity="success"
            sx={{
              position: 'absolute',
              bottom: 0, // Place the Alert at the bottom
              left: 0,
              right: 0,
            }}
          >
            <AlertTitle>Success</AlertTitle>
            Your data was successfully saved! Redirecting...
          </Alert>
        )} */}
        </Grid>
      </Grid>
    </div>
    )
  }
  

export default Student_high_school
